import React from "react";
import myproject from "../../../src/assets/images/supportportal/myproject.png";
import addicon from "../../../src/assets/images/supportportal/addicon.png";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Loader from "../../Layout/Loader";
import { useUserState } from "../../context/Usercontext";
import { useDispatch, useSelector } from "react-redux";
import { additem } from "../../utils/supportrequestSlice";
import { CSVLink } from "react-csv";
import { Input } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Viewproject = () => {
  const baseurl = useSelector((store) => store.baseUrl);
  const supportlist = useSelector((store) => store.supportrequest.projectdata);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [searchtext, setSearchtext] = useState("");
  const [projectdata, setProjectdata] = useState(supportlist);
  const [filteredprojectdata, setFilteredProjectdata] = useState(supportlist);
  const [searchproject, setSearchproject] = useState("");
  const ExcelJS = require("exceljs");

  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  const [currentRow, setCurrentRow] = useState(null);

  const configContactDisplay = localStorage.getItem("config_contact_display");
  const timesheet_enable_on_login = localStorage.getItem(
    "timesheet_enable_on_login"
  );
  console.log(timesheet_enable_on_login, "timesheet_enable_on_login..");
  console.log(configContactDisplay, "configContactDisplay..");

  // added by umang 08/03/2024
  // start by umang
  // const [empIn, setEmpIn] = useState(localStorage.getItem("emp_in_time"));
  // const [empOut, setEmpOut] = useState(localStorage.getItem("emp_out_time"));
  const [formattedEmpInDate, setFormattedEmpInDate] = useState(null);
  const [formattedEmpOutDate, setFormattedEmpOutDate] = useState(null);
  const [isPunchInAvailable, setIsPunchInAvailable] = useState(false);
  const [isPunchOutAvailable, setIsPunchOutAvailable] = useState(false);
  const [isBothPunchInAndOutToday, setIsBothPunchInAndOutToday] =
    useState(false);
  const [punchIn, setPunchIn] = useState(localStorage.getItem("punchin"));
  const [punchOut, setPunchOut] = useState(localStorage.getItem("punchout"));
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !(
      (punchIn !== null && punchIn !== "false") ||
      (punchOut !== null && punchOut !== "false")
    )
  );
  const emp_in = localStorage.getItem("emp_in_time");
  const emp_out = localStorage.getItem("emp_out_time");
  console.log(!!emp_in && !!emp_out, "(!!emp_in && !!emp_out)");
  console.log(typeof emp_out, "emp_out type");
  console.log(emp_in == null && emp_out == null, "scsdcsdcsdvdfv");
  console.log(!emp_in, "emp_in == null..");
  console.log(!emp_out, "emp_out == null...");

  console.log(isButtonDisabled, "isButtonDisabled...");
  // console.log(empIn, "empIn..");
  console.log(emp_in, "emp_in..");
  console.log(emp_out, "emp_out..");

  useEffect(() => {
    setIsButtonDisabled(
      !(
        (punchIn !== null && punchIn !== "false") ||
        (punchOut !== null && punchOut !== "false")
      )
    );
  }, [punchIn, punchOut]);

  let formatted_Emp_In_Date = null;
  let formatted_Emp_Out_Date = null;

  // useEffect(() => {
  //   if(!!empIn){
  //     setEmpIn(empIn)
  //   }
  //   if(!!empOut){
  //     setEmpOut(empOut)
  //   }
  //   if (!!empIn) {
  //     console.log("umang...")
  //     const formattedDate = new Intl.DateTimeFormat("en-US", {
  //       weekday: "long",
  //       year: "numeric",
  //       month: "long",
  //       day: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       timeZone: "Asia/Kolkata",
  //     }).format(empIn * 1000);

  //     setFormattedEmpInDate(formattedDate);
  //     setIsPunchInAvailable(true);
  //   }

  //   if (!!empOut) {
  //     const formattedDate = new Intl.DateTimeFormat("en-US", {
  //       weekday: "long",
  //       year: "numeric",
  //       month: "long",
  //       day: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       timeZone: "Asia/Kolkata",
  //     }).format(empOut * 1000);

  //     setFormattedEmpOutDate(formattedDate);
  //     setIsPunchOutAvailable(true);
  //   }
  // }, [empIn,empOut]);

  // useEffect(() => {
  //   setIsBothPunchInAndOutToday(isPunchInAvailable && isPunchOutAvailable);
  // }, [isPunchInAvailable,isPunchOutAvailable]);

  // console.log(!!emp_in, "not...emp")

  console.log(!!formatted_Emp_In_Date, "formatted_Emp_In_Date..");
  console.log(formatted_Emp_Out_Date, "formatted_Emp_Out_Date..");
  // const isPunchInAvailable = !!formatted_Emp_In_Date;
  // const isPunchOutAvailable = !!formatted_Emp_Out_Date;
  // const isBothPunchInAndOutToday = isPunchInAvailable && isPunchOutAvailable;
  console.log(isPunchInAvailable, "isPunchInAvailable..");
  console.log(isBothPunchInAndOutToday, "isBothPunchInAndOutToday...");

  // end by umang
  // added by umang 08/03/2024

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  const dispatch = useDispatch();
  console.log(supportlist);
  console.log(filteredprojectdata, "filtereddata");

  useEffect(() => {
    const emp_id = JSON.parse(localStorage.getItem("employeeid")); // Replace 'yourKey' with your actual key
    console.log(emp_id);
    if (emp_id) {
      setEmpid(emp_id);
    }
  }, []);
  // const {emp_id} = useUserState()
  // console.log(emp_id + "umangrtkghnfgdfkg")

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (supportlist.length == 0) {
      console.log("useffect off view project.....");
      // If supportlist is an empty array, make the API call
      getSupportRequest();
    } else {
      // If supportlist already has data, set projectdata
      setProjectdata(supportlist);
      setFilteredProjectdata(supportlist);
      localStorage.setItem("object_type", supportlist[0]?.object_type);
      console.log("filtreddata called....");
      // setIsLoading(false); // Set loading to false when data is received
    }

  }, []);

  // filter
  const handleSearch = (e) => {
    console.log("handleSearch");
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = projectdata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] && typeof item[key] === "string") {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilteredProjectdata(filteredItems);
  };

  const filterByProject = (e) => {
    const inputText = e.target.value;
    setSearchproject(inputText);
    // Function to remove extra spaces and special characters from a string
    const cleanString = (inputString) => {
      return inputString
        .trim()
        .replace(/[^a-z0-9]+/g, "") // Remove special characters
        .replace(/\s+/g, " ");
    };

    if (!inputText || cleanString(inputText).length === 0) {
      setFilteredProjectdata(projectdata);
      return;
    }

    // Convert the searchTerm to lowercase for case-insensitive search
    const searchTermCleaned = cleanString(searchproject.toLowerCase());

    // Use Array.filter to filter objects based on problem_title
    const filteredData = projectdata.filter((item) =>
      cleanString(item.problem_title.toLowerCase()).includes(searchTermCleaned)
    );

    setFilteredProjectdata(filteredData);
  };

  const getSupportRequest = async (data) => {
    setIsLoading(true);
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/search_support_api/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          created_by: emp_id,
        },
      );
      console.log(res, "supportrequest");

      if (res && res.data && res.data[0].DATA) {
        setProjectdata(res?.data[0]?.DATA?.supportlist);
        setFilteredProjectdata(res?.data[0]?.DATA?.supportlist);
        localStorage.setItem(
          "object_type",
          res?.data[0]?.DATA?.supportlist[0]?.object_type
        );
        dispatch(additem(res?.data[0]?.DATA?.supportlist));
        setIsLoading(false);
        // localStorage.setItem("supportlist",JSON.stringify(res?.data[0]?.DATA?.supportlist))
      } else {
        setProjectdata([]);
        setFilteredProjectdata([]);
        setIsLoading(false);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
    }
  };

  const columns = [];

  if (isMobile) {
    columns.push(
      {
        name: (
          <div>
            Project Name
            <Input
              type="text"
              className="form-control register-full column-register"
              placeholder="Search project..."
              value={searchproject}
              onChange={(e) => {
                filterByProject(e);
              }}
              style={{
                fontSize: "0.8rem",
                // width: "90%",
                marginInline: "2px",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <div
            style={{
              textAlign: "start",
              overflow: "hidden",
              whiteSpace: "normal",
            }}
          >
            {row.problem_title ? row.problem_title : "---"}{" "}
            {/* {config_product_code_display == "Y" && row?.product_code
              ? `(${row.product_code})`
              : ""} */}
          </div>
        ),
        width: "200px",
        // sortable: true,
      },

      {
        name: (
          <span>
            Project Assigned
            <br />
            Days
          </span>
        ),
        selector: (row) =>
          row.projectrunningdays ? row.projectrunningdays : "---",
        width: "140px",
        // sortable: true,
      }
      // {
      //   name: "Priority",
      //   selector: (row) => row.year,
      //   width: "auto",
      // },
    );
  } else {
    columns.push(
      // {
      //   name: "Company",
      //   selector: (row) => (
      //     <div>
      //       <div>{row.problem_title}</div>
      //       <div>{row.title}</div>
      //     </div>
      //   ),
      //   width: "180px",
      // },{
      //   name: "Branch",
      //   selector: (row) => (
      //     <div>
      //       <div>{row.problem_title}</div>
      //       <div>{row.title}</div>
      //     </div>
      //   ),
      //   width: "180px",
      // },
      {
        name: "",
        cell: (row) => (
          <>
            {timesheet_enable_on_login == "Y" ? (
              <div
                className="addicon-container"
                // data-tip=""
                // data-for="addIconTooltip"
                // data-event="mouseover"
                data-tooltip-id="addIconTooltip"
              >
                {(emp_in != "" && emp_out != "") ||
                (emp_in == "" && emp_out == "") ||
                isButtonDisabled ? (
                  <div className="disabled">
                    <img
                      src={`${baseurl}/public/images/supportportal/addicon.png`}
                      alt="addiconimg"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </div>
                ) : (
                  <Link to={`${process.env.PUBLIC_URL}/addtimesheet/${row.id}`}>
                    <div
                      className="addicon-wrapper"
                      data-tooltip-id="addIconTooltip"
                    >
                      <img
                        src={`${baseurl}/public/images/supportportal/addicon.png`}
                        alt="addiconimg"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                  </Link>
                )}

                <ReactTooltip
                  id="addIconTooltip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                  content={() => {
                    if (
                      (emp_in == "" || emp_in == "null") &&
                      (emp_out == "" || emp_out == "null")
                    ) {
                      return "Please Punch in first.";
                    } else if (emp_in > 0 && emp_out > 0) {
                      return "You've already Punched-in and Punched-out today. Cannot add timesheet.";
                    } else if (isButtonDisabled) {
                      return "You've already Punched-in and Punched-out today. Cannot add timesheet.";
                    } else {
                      return "Click here to add timesheet.";
                    }
                  }}
                />
              </div>
            ) : (
              <Link
                to={`${process.env.PUBLIC_URL}/addtimesheet/${row.id}?allocated_hours=${row.allocated_hours}`}
              >
                <div className="addicon-wrapper">
                  <img
                    src={`${baseurl}/public/images/supportportal/addicon.png`}
                    alt="addiconimg"
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
              </Link>
            )}
          </>
        ),
        width: "50px",
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Project Name</span>
            <Input
              type="text"
              className="form-control register-full column-register"
              placeholder="Search project..."
              value={searchproject}
              onChange={(e) => {
                filterByProject(e);
              }}
              style={{
                fontSize: "0.8rem",
                width: "90%",
                marginInline: "2px",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              {/* {config_product_code_display == "Y" && row?.product_code
                ? `(${row.product_code})`
                : ""} */}
            </div>
            <div style={{ textAlign: "start" }}>{row.title}</div>
          </>
        ),
        width: "180px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Branch</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.branch_name ? row.branch_name : "---"}
            </div>
          </>
        ),
        width: "80px",
        // sortable: true,
        // Set width to 0 if "Client Name" is hidden
      },
      {
        name: <span style={{ fontSize: "11px" }}>Client Name</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.contactname ? row.contactname : "---"}
            </div>
          </>
        ),
        width: "150px",
        // sortable: true,
        omit: configContactDisplay == "N",
        // Set width to 0 if "Client Name" is hidden
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Budget
            <br />
            hours
          </span>
        ),
        selector: (row) => (row.budget_hours ? row.budget_hours : "---"),
        width: "72px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Your Time
            <br />
            Invested
          </span>
        ),
        selector: (row) => (
          <span className="badge rounded-pill text-bg-success">
            {row.current_year_hours ? row.current_year_hours : "---"}
          </span>
        ),
        width: "95px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Project <br /> Start
            <br />
            Date
          </span>
        ),
        selector: (row) =>
          row.projectstartdate ? row.projectstartdate : "---",
        width: "115px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Project
            <br />
            Assign
            <br />
            Days
          </span>
        ),
        selector: (row) =>
          row.projectrunningdays ? row.projectrunningdays : "---",
        width: "75px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Project <br /> Due
            <br />
            Date
          </span>
        ),
        selector: (row) => (row.projectduedate ? row.projectduedate : "---"),
        width: "115px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Year</span>,
        selector: (row) => new Date().getFullYear(),
        width: "62px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Priority</span>,
        selector: (row) => (row.priority ? row.priority : "---"),
        width: "78px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Status</span>,
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.status ? row.status : "---"}
            </div>
          </div>
        ),
        width: "84px",
        // sortable: true,
      }
    );
  }

  const ExpandedComponent = ({ data }) => (
    <div>
      {configContactDisplay == "Y" ? (
        <p style={{ marginBottom: "0px" }}>
          <span className="expand-header">Client Name</span> :
          <span className="expand-subheader">
            {" "}
            {data.contactname ? data.contactname : "---"}
          </span>
        </p>
      ) : (
        ""
      )}
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Priority</span> :
        <span className="expand-subheader">
          {" "}
          {data.priority ? data.priority : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Status</span> :
        <span className="expand-subheader">
          {" "}
          {data.status ? data.status : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Project Start Date</span>:
        <span className="expand-subheader">
          {" "}
          {data.projectstartdate ? data.projectstartdate : "---"}
        </span>
      </p>
      {/* <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Project Assigned days</span>:
        <span className="expand-subheader"> {data.projectrunningdays}</span>
      </p> */}
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Project Due Date</span>
        </span>
        :
        <span className="expand-subheader">
          {" "}
          {data.projectduedate ? data.projectduedate : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Year</span>
        </span>
        :<span className="expand-subheader"> {new Date().getFullYear()}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Budget hours</span>
        </span>
        :
        <span className="expand-subheader">
          {" "}
          {data.budget_hours ? data.budget_hours : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Your Time Invested</span>
        </span>
        :
        <span className="expand-subheader badge rounded-pill text-bg-success">
          {" "}
          {data.current_year_hours ? data.current_year_hours : "---"}
        </span>
      </p>
      <div style={{ marginBottom: "0px", marginTop: "5px" }}>
        <span className="expand-header">
          <span>Actions</span>
        </span>
        :
        <span className="expand-subheader">
          <Link to={`${process.env.PUBLIC_URL}/addtimesheet/${data.id}`}>
            <Button size="sm" className="punchinbtn headerbtn-mobile ms-2">
              Add
            </Button>
          </Link>
        </span>
      </div>
    </div>
  );

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      {
        header: "Project name",
        key: "productname",
        width: 45,
      },
      // {
      //   header: "Project",
      //   key: "project",
      //   width: 18,
      // },
      {
        header: "Branch",
        key: "branch",
        width: 12,
      },
      {
        header: "Budget Hours",
        key: "budget_hours",
        width: 15,
      },
      {
        header: "Your Total Time Invested",
        key: "current_year_hours",
        width: 15,
      },
      {
        header: "Project start date",
        key: "projectstartdate",
        width: 12,
      },
      {
        header: "Project assign days",
        key: "projectrunningdays",
        width: 12,
      },
      {
        header: "Project due date",
        key: "projectduedate",
        width: 12,
      },
      {
        header: "Year",
        key: "year",
        width: 8,
      },
      {
        header: "Priority",
        key: "priority",
        width: 20,
      },
      {
        header: "Status",
        key: "status",
        width: 20,
      },
    ];

    const promise = Promise.all(
      filteredprojectdata.map(async (product, index) => {
        let date = product?.date;
        let year;

        if (date && date.includes("29/03/2024")) {
          year = date.split("/")[2];
        }
        sheet.addRow({
          id: product?.id,
          productname: product?.problem_title,
          // project: product?.project,
          branch: product?.branch_name,
          budget_hours: product?.budget_hours,
          current_year_hours: product?.current_year_hours,
          projectstartdate: product?.projectstartdate,
          projectrunningdays: product?.projectrunningdays,
          projectduedate: product?.projectduedate,
          year: new Date().getFullYear(),
          priority: product?.priority,
          status: product?.status,
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "My_Projects.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <div>
      {projectdata.length == 0 ? (
        // <Loader isloading={isLoading} setIsloading={setIsLoading} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <>
          <div className="text-center">
            <img
              src={`${baseurl}/public/images/supportportal/myproject.png`}
              alt="track-order"
              style={{ width: "80px", height: "80px" }}
            />
            <h1 className="login-header">
              {userdetails?.username}, View projects & Add timesheet!
            </h1>
            <p className="sub-header">
              Manage your projects and effortlessly add timesheets all in one
              place. Stay organized, track your
              {isMobile ? "" : <br />}
              progress, and ensure accurate time recording with ease.
            </p>
          </div>
          <div>
            {isMobile ? (
              <Container>
                <div>
                  <div className="d-flex justify-content-center align-items-center my-3">
                    <Input
                      className="form-control register-full input-register"
                      type="text"
                      placeholder="Search all fields..."
                      value={searchtext}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="searchbtn my-2"
                      onClick={exportExcelFile}
                    >
                      Export
                    </Button>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredprojectdata}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    customStyles={customStyles}
                    expandableRows
                    expandableRowExpanded={(row) => row === currentRow}
                    expandOnRowClicked
                    onRowClicked={(row) => setCurrentRow(row)}
                    onRowExpandToggled={(bool, row) => {
                      setCurrentRow(row);
                    }}
                    expandableRowsComponent={ExpandedComponent}
                    persistTableHead={true}
                  />
                </div>
              </Container>
            ) : (
              <div>
                <div className="export-viewproject d-flex justify-content-between my-2">
                  <div>
                    <Input
                      className="form-control register-full input-register"
                      type="text"
                      placeholder="Search all fields..."
                      value={searchtext}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="export-new">
                    <Button
                      className="searchbtn my-2"
                      onClick={exportExcelFile}
                    >
                      Export
                    </Button>
                  </div>
                </div>
                <DataTable
                  // className="table-extra"
                  className="custom-data-table data-project"
                  columns={columns}
                  data={filteredprojectdata}
                  pagination
                  paginationComponentOptions={paginationOptions}
                  customStyles={customStyles}
                  persistTableHead={true}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Viewproject;

import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Spinner,
  Form,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";

const Attendencereport = () => {
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );

  const [generatebtn, setGeneratebtn] = useState(false);
  const [isloading, setIsloading] = useState(false);

  console.log(baseurl, "from store");
  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);
  const [searchtext, setSearchtext] = useState("");
  const [from_date, setFromdate] = useState(new Date());
  const [to_date, setTodate] = useState(moment().toDate());
  const [attendencedata, setAttendencedata] = useState([]);
  const [filterattendencedata, setFilterattendencedata] = useState([]);
  const [days, setDays] = useState();
  const [totalworkinghours, setTotalworkinghours] = useState();
  const [data_info, setDatainfo] = useState("");
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  console.log(filterattendencedata, "filtereddata");
  console.log(attendencedata, "logindata");

  // const generatePDF = () => {
  //   setGeneratebtn(true);
  //   window.html2canvas = html2canvas;
  //   var rep = new jsPDF("p", "px", "a4"); // Set the width and height here
  //   rep.html(document.querySelector("#rep1"), {
  //     callback: function (pdf) {
  //       pdf.save("report.pdf");
  //       setGeneratebtn(false);
  //       console.log("done");
  //     },
  //   });
  // };

  const generatePDF = () => {
    setGeneratebtn(true);
    window.html2canvas = html2canvas;
    var rep = new jsPDF("l", "px", "a4");
    const pdfBlob = rep.output("blob");

    // const formData = new FormData();
    console.log(pdfBlob, "pdfBlob...");
    // formData.append("pdfFile", pdfBlob);
    // console.log(formData, "formData.....blob");

    rep.html(document.querySelector("#rep1"), {
      callback: function (pdf) {
        pdf.save("Attendance_Report.pdf");
        setGeneratebtn(false);
        console.log("done");
      },
    });
  };

  function isSunday(dateString) {
    try {
      // Parse the date string using Moment.js
      const dateObj = moment(dateString, ["DD/MM/YYYY", "DD/MM/YY"]);
      console.log(dateObj, "dateObj....");
      // Check if the day of the week is Sunday (0 is Sunday)
      return dateObj.day() == 0;
    } catch (error) {
      return false; // Invalid date format
    }
  }
  // const status = isSunday("5/5/2024");
  // console.log(status, "status....");

  const handleChangeFromTime = (date) => {
    setFromdate(date);
    getattendencedata(date);
  };

  const handleChangeToTime = (date) => {
    setTodate(date);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const columns = [];

  if (isMobile) {
    // Define columns for mobile view
    columns.push(
      // {
      //   name: "User Id",
      //   selector: (row) => row.employee_id,
      //   width: "100px",
      // },
      {
        name: "User name",
        selector: (row) => row.username,
        width: "170px",
        sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.att_date,
        width: "170px",
        sortable: true,
      }
    );
  } else {
    // Define columns for desktop view
    columns.push(
      // {
      //   name: "User Id",
      //   selector: (row) => row.employee_id,
      //   width: "100px",
      // },
      {
        name: "User Name",
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.username ? row.username : "---"}
            </div>
          </div>
        ),
        width: "120px",
        sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.att_date,
        width: "110px",
        sortable: true,
      },
      {
        name: "Login Time",
        selector: (row) => row.login_time,
        width: "120px",
        sortable: true,
      },
      {
        name: "Logout Time",
        selector: (row) => row.logout_time,
        width: "140px",
        sortable: true,
      },
      {
        name: "Login Hour",
        selector: (row) => row.login_hours,
        width: "120px",
        sortable: true,
      }
    );
  }

  const ExpandedComponent = ({ data }) => (
    <div>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Login Time</span> :
        <span className="expand-subheader"> {data.login_time}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Logout Time</span> :
        <span className="expand-subheader"> {data.logout_time}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Login Hour</span> :
        <span className="expand-subheader"> {data.login_hours}</span>
      </p>
    </div>
  );

  // filterdata
  const applyDateFilter = (from_date, to_date) => {
    if (from_date && to_date) {
      const filteredData = attendencedata.filter((item) => {
        const attDate = moment(item.att_date, "DD/MM/YYYY"); // Parse date with moment.js
        const isAfterOrEqualFromDate = attDate.isSameOrAfter(
          moment(from_date, "DD/MM/YYYY"),
          "day"
        );
        const isBeforeOrEqualToDate = attDate.isSameOrBefore(
          moment(to_date, "DD/MM/YYYY"),
          "day"
        );
        return isAfterOrEqualFromDate && isBeforeOrEqualToDate;
      });
      console.log("applydatafilter:", filteredData); // Log the array directly without concatenation
      setFilterattendencedata(filteredData);
    } else {
      // If start date or end date is not selected, set filtered data to empty array
      setFilterattendencedata([]);
    }
  };

  const getattendencedata = async (from_date) => {
    setIsloading(true);
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/employee_attendence_report/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          month: moment(from_date).format("MM"),
          year: moment(from_date).format("YYYY"),
        }
      );

      const { data } = response; // Destructure response data
      console.log(data, "data......");

      if (data && data[0]?.DATA?.employee_attendence_report) {
        const message = data[0].MSG;
        setDatainfo(message);
        const attendencesheetdata = data[0]?.DATA?.employee_attendence_report;
        const days =
          data[0]?.DATA?.employee_attendence_report[0]?.totalmonth_days;
        const total_working_hours =
          data[0]?.DATA?.employee_attendence_report[0]
            ?.monthlytotalworkinghours;
        setAttendencedata(attendencesheetdata); // Combined state updates
        setTotalworkinghours(total_working_hours);
        setDays(days);
        setFilterattendencedata(attendencesheetdata);
        setIsloading(false);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setAttendencedata([]);
        setFilterattendencedata([]);
        setIsloading(false);
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
      // toast.error("Error retrieving attendence report", {
      //   position: "top-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setIsloading(false);
    }
  };

  useEffect(() => {
    getattendencedata(from_date);
  }, []);

  const columnsdays = Array.from({ length: days }, (_, index) => index + 1);

  const dashboardboxes = [
    {
      text: <span>My Timesheet</span>,
      img: `${baseurl}/public/images/supportportal/myreport.png`,
      linkTo: `${process.env.PUBLIC_URL}/timesheetreport`, // Specify the path you want to link to
    },
    {
      text: (
        <span>
          My working
          <br />
          hours
        </span>
      ),
      img: `${baseurl}/public/images/supportportal/my_working_hours.png`,
      linkTo: `${process.env.PUBLIC_URL}/workinghoursreport`, // Specify the path you want to link to
    },
    {
      text: (
        <span>
          My break
          <br />
          hours
        </span>
      ),
      img: `${baseurl}/public/images/supportportal/my_break_hours.png`,
      linkTo: `${process.env.PUBLIC_URL}/breakhoursreport`, // Specify the path you want to link to
    },
  ];

  // const dates = Array.from({ length: 30 }, (_, i) => moment().startOf('day').add(i, 'days').format('DD/MM/YY'));

  // const tableData = [];
  // for (const date of dates) {
  //   const rowData = { date };
  //   for (const dayData of attendencedata) {
  //     if (dayData.date === date) {
  //       rowData[dayData.date] = {
  //         login: dayData.login,
  //         logout: dayData.logout,
  //         working_hours: dayData.working_hours,
  //         breaking_hours: dayData.breaking_hours,
  //       };
  //     }
  //   }
  //   tableData.push(rowData);
  // }

  return (
    <div className="overflow-hidden">
      {isloading ? (
        <div className="text-center" style={{ marginTop: "100px" }}>
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <>
          {" "}
          <div className="text-center">
            <img
              src={`${baseurl}/public/images/supportportal/my_working_hours.png`}
              alt="track-order"
              style={{ width: "80px", height: "80px" }}
            />
            <h1 className="login-header">
              {userdetails?.username}, View your Attendence report!
            </h1>
            <p className="sub-header">
              Review your timesheet reports with precision and clarity. Stay
              informed about your work hours,
              {isMobile ? "" : <br />}
              tasks completed, and project progress effortlessly.
            </p>
          </div>
          <Form className="theme-form">
            <FormGroup className="d-flex justify-content-center align-items-center">
              <Row>
                <Col className="attendence-col" md={6} sm={12}>
                  <Label
                    className={`timesheet-label ${
                      isMobile
                        ? "d-flex justify-content-center align-items-center mt-3"
                        : ""
                    }`}
                  >
                    Month/Year:
                  </Label>
                  <InputGroup
                    className="date"
                    id="dt-minimum"
                    data-target-input="nearest"
                  >
                    <DatePicker
                      className="form-control datetimepicker-input digits input-register"
                      selected={from_date}
                      onChange={handleChangeFromTime}
                      dateFormat="MM/yyyy "
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                    <div
                      className="input-group-text"
                      data-target="#dt-date"
                      data-toggle="datetimepicker"
                    >
                      <i className="fa fa-calendar"></i>
                    </div>
                  </InputGroup>
                </Col>

                <Col
                  md={5}
                  sm={12}
                  className={`${
                    isMobile
                      ? "d-flex justify-content-center align-items-center mt-3"
                      : ""
                  }`}
                >
                  {!isMobile && (
                    <Label className={`timesheet-label invisible`}>
                      Month/Year:
                    </Label>
                  )}
                  <Button
                    className={`searchbtn`}
                    //   onClick={() => getloginhours(from_date, to_date)}
                    disabled={generatebtn}
                    onClick={generatePDF}
                  >
                    Generate Pdf
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Form>
          {generatebtn && (
            <div className="text-center timesheet-label">
              Generating PDF... Please wait.
            </div>
          )}
        </>
      )}
      {/* visibility: "hidden", height: 0 */}
      {/* PDF view  */}
      <div id="main" style={{ visibility: "hidden", height: 0 }}>
        <div id="rep1">
          <div
            style={{
              fontFamily: "Roboto, sans-serif",
              // width: "447px",
              // height: "631.2px",
              width: "600px",
              // height: "1500px",

              //   marginBottom: index > 2 ? "0.47px" : "0px",
            }}
          >
            {/* top portion  */}

            <div className="top-portion">
              <p className="fw-bold">{userdetails?.company_name}</p>
            </div>

            {/* middle portion  */}
            <div className="ms-3">
              {attendencedata.length != 0 &&
                attendencedata.map((emp_data, i) => {
                  return (
                    <div
                      style={{
                        marginBottom: [
                          3, 7, 11, 15, 19, 23, 27, 31, 35, 39, 43,
                        ].includes(i)
                          ? "60px"
                          : "27px",
                      }}
                    >
                      {/* //  <div style={{ marginBottom: "51px" }}> */}
                      <div className="middle-portion">
                        <div>
                          <p className="para common-para">
                            <span className="title-sheet">
                              Employee code :{" "}
                            </span>{" "}
                            <span style={{ fontSize: "7px" }}>
                              {emp_data?.employee_id}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className="para common-para ">
                            <span className="title-sheet">
                              Employee Name :{" "}
                            </span>{" "}
                            <span style={{ fontSize: "7px" }}>
                              {emp_data?.employee_name}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className="para common-para ">
                            <span className="title-sheet">Month : </span>{" "}
                            <span style={{ fontSize: "7px" }}>
                              {moment(from_date).format("MM")}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className="para common-para">
                            <span className="title-sheet">Year : </span>{" "}
                            <span style={{ fontSize: "7px" }}>
                              {moment(from_date).format("YYYY")}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div>
                        <table
                          style={{
                            fontFamily: "arial, sans-serif",
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  padding: "0.1rem",
                                  // fontSize: "25%",
                                  fontSize: "6px",
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                }}
                              >
                                #
                              </th>
                              {columnsdays.map((day, index) => (
                                <th
                                  key={index}
                                  style={{
                                    padding: "0.1rem",
                                    // fontSize: "25%",
                                    fontSize: "6px",
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                  }}
                                >
                                  {day}
                                </th>
                              ))}
                              <th
                                style={{
                                  padding: "0.1rem",
                                  // fontSize: "25%",
                                  fontSize: "6px",
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                }}
                              >
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  padding: "0.11rem",
                                  // fontSize: "25%",
                                  fontSize: "6px",
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                }}
                              >
                                date
                                <br />
                                login
                                <br />
                                logout
                                <br />
                                break
                                <br />
                                Working
                              </td>
                              {emp_data.days.map((value, index) => (
                                <td
                                  key={index}
                                  style={{
                                    padding: "0.11rem",
                                    fontSize: "6px",
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    backgroundColor: isSunday(value.date)
                                      ? "yellow"
                                      : "white",
                                  }}
                                >
                                  {isSunday(value.date) ? (
                                    "H"
                                  ) : (
                                    <>
                                      {value.login !== "" ? "P" : "Ab"}
                                      <br />
                                      {value.login !== ""
                                        ? moment(
                                            value.login,
                                            "hh:mm:ss A"
                                          ).format("HH:mm")
                                        : "Ab"}
                                      <br />
                                      {value.logout !== ""
                                        ? moment(
                                            value.logout,
                                            "hh:mm:ss A"
                                          ).format("HH:mm")
                                        : "Ab"}
                                      <br />
                                      {value.breaking_hours !== ""
                                        ? moment(
                                            value.breaking_hours,
                                            "hh:mm:ss A"
                                          ).format("HH:mm")
                                        : "Ab"}
                                      <br />
                                      {value.working_hours !== ""
                                        ? moment(
                                            value.working_hours,
                                            "hh:mm:ss A"
                                          ).format("HH:mm")
                                        : "Ab"}
                                    </>
                                  )}
                                </td>
                              ))}

                              <td
                                style={{
                                  padding: "0.11rem",
                                  // fontSize: "25%",
                                  fontSize: "6px",
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                }}
                              >
                                <br />
                                <br />
                                <br />
                                <br />
                                {emp_data?.monthlytotalworkinghours}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}

              <div
                id="loadingIndicator"
                style={{
                  display: "none",
                  textAlign: "right",
                  fontSize: "16px",
                }}
              >
                Generating PDF... Please wait.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendencereport;

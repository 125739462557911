// chartUtils.js
import ConfigDB from "../../Config/ThemeConfig";
const primary = ConfigDB.data.color.primary_color;
//  '#f73164'
const secondary =
  localStorage.getItem("secondary_color") ||
  ConfigDB.data.color.secondary_color;

const colorArray = ["#7366ff", "#f73164", "#a927f9", "#F8D62B", "#51BB25"];

function getColor(index) {
  return colorArray[index % colorArray.length];
}

// Monthly Attendence Graph
export const Monthly_Attendence_Graph_data = (apiData) => {
  // Extract series data and categories from API response
  const seriesData = apiData?.series;
  const categoriesData = apiData?.options?.categories;

  // Additional properties for yaxis, colors, and plotOptions
  const additionalOptions = {
    chart: {
      type: "bar",
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 8,
        left: 0,
        blur: 10,
        color: "#7064F5",
        opacity: 0.1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25px",
        borderRadius: 0,
      },
    },
    grid: {
      show: true,
      borderColor: "var(--chart-border)",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      dashArray: 0,
      lineCap: "butt",
      colors: "#fff",
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "" + val + "%";
        },
      },
    },

    colors: [primary, "#AAAFCB"],
    yaxis: {
      tickAmount: 4,
      title: {
        text: "Attendence in Percentage",
        style: {
          fontSize: "12px",
          fontFamily: "Roboto, sans-serif",
          fontWeight: 500,
        },
      },
      labels: {
        show: true,
        style: {
          fontFamily: "Rubik, sans-serif",
        },
      },
      min: 0,
      max: 100,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };

  // Format series data
  const formattedSeries = seriesData.map((series) => ({
    name: series.name,
    data: series.data,
  }));

  // Create options object with only categories
  const options = {
    xaxis: {
      categories: categoriesData,
      labels: {
        style: {
          fontFamily: "Rubik, sans-serif",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    ...additionalOptions, // Merge additional options into options object
  };

  // Combine formatted series data and chart options
  const formattedData = {
    series: formattedSeries,
    options: options,
  };

  return formattedData;
};

// Category_Wise_Time_Spent
export const Category_Wise_Time_Spent = (apiData) => {
  return apiData.series.map((item, index) => ({
    series: [
      {
        name: item?.name ? item.name.toString() : "",
        data: [item?.data ? item.data.toString() : ""],
      },
    ],
    options: {
      chart: {
        height: 70,
        type: "bar",
        stacked: true,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "15%",
          colors: {
            backgroundBarColors: [getColor(index)],
            backgroundBarOpacity: 0.2,
          },
        },
      },
      colors: [getColor(index)],
      stroke: {
        width: 0,
      },
      fill: {
        colors: [getColor(index)],
        type: "gradient",
        opacity: 1,
        gradient: {
          gradientToColors: [getColor(index)],
        },
      },
      title: {
        floating: true,
        offsetX: -10,
        offsetY: 5,
        text: item?.name ? item.name.toString() : "", // Set title text based on the name from API data
        style: {
          fontSize: "18px",
          fontFamily: "Roboto, sans-serif",
          fontWeight: 500,
        },
      },
      subtitle: {
        floating: true,
        align: "right",
        offsetY: 0,
        text: item?.data ? item.data.toString() : "", // Set title text based on the name from API data
        style: {
          fontSize: "14px",
        },
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: ["Packed"],
      },
      yaxis: {
        max: 100,
      },
      responsive: [
        {
          breakpoint: 767,
          options: {
            title: {
              style: {
                fontSize: "16px",
              },
            },
          },
        },
      ],
    },
  }));
};

// Project_Wise_Time_Spent
export const Project_Wise_Time_Spent = (apiData) => {
  return apiData.series.map((item, index) => ({
    series: [
      {
        name: item.name,
        data: [item.data],
      },
    ],
    options: {
      chart: {
        height: 70,
        type: "bar",
        stacked: true,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "15%",
          colors: {
            backgroundBarColors: [getColor(index)],
            backgroundBarOpacity: 0.2,
          },
        },
      },
      colors: [getColor(index)],
      stroke: {
        width: 0,
      },
      fill: {
        colors: [getColor(index)],
        type: "gradient",
        opacity: 1,
        gradient: {
          gradientToColors: [getColor(index)],
        },
      },
      title: {
        floating: true,
        offsetX: -10,
        offsetY: 5,
        text:
          item.name.length > 30
            ? item.name.substring(0, 30) + "..." // Truncate text if it exceeds the maximum length
            : item.name, // Set title text based on the name from API data
        style: {
          fontSize: "18px",
          fontFamily: "Roboto, sans-serif",
          fontWeight: 500,
        },
      },
      subtitle: {
        floating: true,
        align: "right",
        offsetY: 0,
        text: item.data, // Set subtitle text based on the data from API data
        style: {
          fontSize: "14px",
        },
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: ["Packed"],
      },
      yaxis: {
        max: 100,
      },
      responsive: [
        {
          breakpoint: 767,
          options: {
            title: {
              style: {
                fontSize: "16px",
              },
            },
          },
        },
      ],
    },
  }));
};

const convertTimeFormat = (timeString) => {
  // Split the time string by ':'
  const timeParts = timeString.split(":");

  // Extract hours and minutes
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);

  // Convert minutes to a string with two digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes.toString();

  // Concatenate hours and minutes with a dot in between
  const formattedTime = `${hours}.${formattedMinutes}`;

  return formattedTime;
};

export const Working_Hours_data = (apiData) => {
  // Extract series data and categories from API response
  const seriesData = apiData?.series;
  const categoriesData = apiData?.categories;
  // const categoriesData = ["umang nikhare","mehul parmar","akshay panchal"];
  console.log(seriesData, "seriesData of Working_Hours_data");

  // Additional properties for yaxis, colors, and plotOptions
  const additionalOptions = {
    chart: {
      type: "bar",
      height: 380,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
      curve: "smooth",
      lineCap: "butt",
    },

    yaxis: {
      tickAmount: 11,
      min: 0,
      max: 22,
      // title: {
      //   text: 'Dollars in thounand',
      //   style: {
      //     fontSize: '14px',
      //     fontFamily: 'Roboto, sans-serif',
      //     fontWeight: 500,
      //   },
      // },
    },
    colors: [secondary, "#51bb25", primary],
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    tooltip: {
      y: {
        formatter: function (val, opt) {
          console.log(val, " val.....");
          console.log(opt, "opt....");
          console.log(val % 1 !== 0, "check for decimal...");
          console.log(val % 1 == 0, "check for not decimal.......");
          // Check if the value has a decimal point
          if (val % 1 !== 0) {
            // Convert decimal to time format (HH:MM)
            const hours = Math.floor(val);
            const minutes = Math.round((val - hours) * 100);
            console.log(hours, "hours..tooltip..");
            console.log(minutes, "minutes..tooltip");
            const formattedTime = `${hours}${"hours"}${" "}${minutes}${"minutes"}`;
            return formattedTime;
          } else if (
            (val % 1 == 0 && opt.seriesIndex == 2) ||
            (val % 1 == 0 && opt.seriesIndex == 1)
          ) {
            return val + " hours";
          } else {
            // Return the value as it is
            return val;
          }
        },
      },
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
  };

  const formatNames = (names) => {
    return names.map((name) => {
      const splitName = name.split(" ");
      if (splitName.length == 1) {
        return splitName[0];
      } else {
        const [firstName, lastName] = splitName;
        return [firstName, lastName];
      }
    });
  };

  // Format series data
  const modifiedSeriesData = seriesData.map((series) => ({
    name: series.name,
    data:
      series.name == "Working hours" || series.name == "Timesheethours"
        ? series.data.map((timeString) => convertTimeFormat(timeString))
        : series.data,
  }));

  console.log(modifiedSeriesData, "modifiedSeriesData....");

  // Other options...

  const formattedSeries = modifiedSeriesData.map((series) => ({
    name: series.name,
    data: series.data,
  }));

  // Create options object with only categories
  const options = {
    xaxis: {
      // categories: [
      //   ['John', 'Doe'],
      //   ['Joe', 'Smith'],
      //   ['Jake', 'Williams'],
      //   'Amber',
      //   ['Peter', 'Brown'],
      //   ['Mary', 'Evans'],
      //   ['David', 'Wilson'],
      //   ['Lily', 'Roberts'],
      //   ['Peter', 'Brown'],
      //   ['Mary', 'Evans'],
      // ],
      categories: formatNames(categoriesData),
      floating: false,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: "#C4C4C4",
      },
    },
    ...additionalOptions, // Merge additional options into options object
  };

  // Combine formatted series data and chart options
  const formattedData = {
    series: formattedSeries,
    options: options,
  };

  return formattedData;
};

import React from "react";
import { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";

// setup fake backend

const Routers = () => {
  const login = useState(JSON.parse(localStorage.getItem("login")))[0];
  const [authenticated, setAuthenticated] = useState(false);
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  // useEffect(() => {
  //   let abortController = new AbortController();
  //   setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
  //   console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
  //   console.disableYellowBox = true;
  //   console.log("dvbdvdfvndfvndfkvnv......");
  //   return () => {
  //     abortController.abort();
  //   };
  // }, []);

  console.log(login + "browserroute");

  return (
    <HashRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={"/"} element={<PrivateRoute />}>
            {login ? (
              <>
                <Route
                  path={`/`}
                  element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />}
                />
              </>
            ) : (
              ""
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/login`}
            index
            element={<Signin />}
          />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default Routers;

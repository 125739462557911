import React, { useState } from "react";
import DataTable from "react-data-table-component";
import myreport from "../../../src/assets/images/supportportal/myreport.png";
import { isMobile } from "react-device-detect";
import { Button, Col, Container, Spinner, Form, Row } from "react-bootstrap";
import myworkinghours from "../../../src/assets/images/supportportal/my_working_hours.png";
import myloginhours from "../../../src/assets/images/supportportal/my_login_hours.png";
import mybreakhours from "../../../src/assets/images/supportportal/my_break_hours.png";
import { useEffect } from "react";
import { Link, json } from "react-router-dom";
import axios from "axios";
// import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { MdEditSquare } from "react-icons/md";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import log_logout_edit from "../../assets/images/log_logout_edit.jpg";
import { Image } from "../../AbstractElements";
import { addlogindata } from "../../utils/supportrequestSlice";
import { Tooltip as ReactTooltip } from "react-tooltip";
const ExcelJS = require("exceljs");

const Timesheetapprovalreport = () => {
  // const baseurl = "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const logindata = useSelector(
    (store) => store.supportrequest.login_logout_data
  );
  const dispatch = useDispatch();
  console.log(logindata, "logindata...");
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  const is_admin = localStorage.getItem("is_admin");

  console.log(baseurl, "from store");
  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);
  const [searchtext, setSearchtext] = useState("");
  const [from_date, setFromdate] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [to_date, setTodate] = useState(moment().toDate());
  const [loginhoursdata, setLoginhoursdata] = useState([]);
  const [filterloginhoursdata, setFilterloginhoursdata] = useState([]);
  const [data_info, setDatainfo] = useState("");
  const [searchbtn, setSearchbtn] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  console.log(filterloginhoursdata, "filtereddata");
  console.log(loginhoursdata, "logindata");

  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  const handleChangeFromTime = (date) => {
    setFromdate(date);
  };

  const handleChangeToTime = (date) => {
    setTodate(date);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // filter
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = loginhoursdata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key]) {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilterloginhoursdata(filteredItems);
  };
  const [searchusername, setSearchusername] = useState("");

  // filter by username
  const filterByUsername = (e) => {
    const inputText = e.target.value;
    setSearchusername(inputText);

    // Function to remove extra spaces and special characters from a string
    const cleanString = (inputString) => {
      return inputString
        ? inputString
            .trim()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ")
        : "";
    };

    if (!inputText || cleanString(inputText).length === 0) {
      setFilterloginhoursdata(loginhoursdata);
      return;
    }

    // Convert the searchTerm to lowercase for case-insensitive search
    const searchTermCleaned = cleanString(inputText.toLowerCase());

    // Use Array.filter to filter objects based on username
    const filteredData = loginhoursdata.filter((item) =>
      cleanString(item?.employee_name?.toLowerCase())?.includes(
        searchTermCleaned
      )
    );

    setFilterloginhoursdata(filteredData);
  };

  const columns = [];

  if (isMobile) {
    // Define columns for mobile view
    columns.push(
      // {
      //   name: "User Id",
      //   selector: (row) => row.employee_id,
      //   width: "100px",
      // },
      {
        name: (
          <div>
            User Name
            <Input
              type="text"
              className="form-control register-full column-register"
              placeholder="Search username..."
              value={searchusername}
              onChange={(e) => {
                filterByUsername(e);
              }}
              style={{
                fontSize: "0.8rem",
                width: "90%",
                marginInline: "2px",
                marginBlock: "2px",
              }}
            />
          </div>
        ),
        selector: (row) => row.employee_name,
        width: "150px",
        // sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.date,
        width: "110px",
        // sortable: true,
      },
      {
        name: "",
        cell: (row) => (
          <>
            {row.status == 1 && (
              <>
                <FaCheckCircle
                  color="#005e32"
                  size={24}
                  onClick={() => {
                    handleApproveClick(row.att_id);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <FaTimesCircle
                  color="#ff3131"
                  size={24}
                  onClick={() => {
                    handleRejectClick(row.att_id);
                  }}
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                />
              </>
            )}
          </>
        ),
        width: "80px",
      }
    );
  } else {
    // Define columns for desktop view
    columns.push(
      // {
      //   name: "User Id",
      //   selector: (row) => row.employee_id,
      //   width: "100px",
      // },
      {
        name: (
          <div>
            User Name
            <Input
              type="text"
              className="form-control register-full column-register"
              placeholder="Search username..."
              value={searchusername}
              onChange={(e) => {
                filterByUsername(e);
              }}
              style={{
                fontSize: "0.8rem",
                width: "90%",
                marginInline: "2px",
                marginBlock: "2px",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {" "}
              {row.employee_name ? row.employee_name : "---"}
            </div>
          </div>
        ),
        width: "240px",
        // sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.date,
        width: "120px",
        // sortable: true,
      },
      {
        name: "Login Time",
        selector: (row) => row.in_time,
        width: "120px",
        // sortable: true,
      },
      {
        name: "Logout Time",
        selector: (row) => row.in_out,
        width: "120px",
        // sortable: true,
      },
      {
        name: "Remark",
        selector: (row) => (
          <div
          style={{
            textAlign: "start",
            overflow: "hidden",
            whiteSpace: "normal",
          }}
        >
          {row.remarks ? row.remarks : "---"}
        </div>
        ),
        width: "150px",
        // sortable: true,
      },
      {
        name: "Status",
        selector: (row) => (
          <div
            style={{
              textAlign: "start",
              overflow: "hidden",
              whiteSpace: "normal",
            }}
          >
            {row.status == 1
              ? "Pending for approval"
              : row.status == 2
              ? "Request Approved"
              : row.status == 3
              ? "Request rejected"
              : row.status == 4
              ? "Completed"
              : ""}
          </div>
        ),
        width: "100px",
        // sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <>
            {row.status == 1 ? (
              <>
                <FaCheckCircle
                  color="#005e32"
                  size={24}
                  onClick={() => {
                    handleApproveClick(row.att_id);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <FaTimesCircle
                  color="#ff3131"
                  size={24}
                  onClick={() => {
                    handleRejectClick(row.att_id);
                  }}
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                />
              </>
            ) : (
              <>
                <FaCheckCircle
                  color="#005e32"
                  size={24}
                  style={{ color: "#d3d3d3", cursor: "not-allowed" }}
                  data-tooltip-id="approveTooltip"
                />
                <FaTimesCircle
                  color="#ff3131"
                  size={24}
                  style={{
                    color: "#d3d3d3",
                    cursor: "not-allowed",
                    marginLeft: "3px",
                  }}
                  data-tooltip-id="rejectTooltip"
                />
                <ReactTooltip
                  id="approveTooltip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                  content={ row.status != 1 && "You can't approve this request"}
                />
                <ReactTooltip
                  id="rejectTooltip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                  content={ row.status != 1 && "You can't reject this request"}
                />
              </>
            )}
          </>
        ),
        width: "90px",
      }
    );
  }

  const ExpandedComponent = ({ data }) => (
    <div>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Login Time</span> :
        <span className="expand-subheader"> {data.in_time}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Logout Time</span> :
        <span className="expand-subheader"> {data.in_out}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Remark</span> :
        <span className="expand-subheader"> {data.remarks ? data.remarks : "---"}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Status</span> :
        <span className="expand-subheader">
          {" "}
          {data.status == 1
            ? "Pending for approval"
            : data.status == 2
            ? "Request Approved"
            : data.status == 3
            ? "Request rejected"
            : data.status == 4
            ? "Completed"
            : ""}
        </span>
      </p>
    </div>
  );

  const Absenceemployeereq_list = async (from_date, to_date) => {
    setSearchbtn(true);
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/Absenceemployeereq/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
        },
      );

      const { data } = response; // Destructure response data
      console.log(data, "data...approval list,..");

      if (data && data[0]?.Data?.Absenceemployeereq) {
        const message = data[0].MSG;
        setDatainfo(message);
        const loginHours = data[0].Data.Absenceemployeereq;
        console.log("umaheg test,,");
        console.log(loginHours, "approval data......");
        // dispatch(addlogindata(loginHours));
        setLoginhoursdata(loginHours); // Combined state updates
        setFilterloginhoursdata(loginHours);
        setSearchbtn(false);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setLoginhoursdata([]);
        setFilterloginhoursdata([]);
        setSearchbtn(false);
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  const handleApproveClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to approve this request.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Absenceemployeereq_update(id, 2);
      }
    });
  };

  const handleRejectClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to reject this request.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Absenceemployeereq_update(id, 3);
      }
    });
  };

  const Absenceemployeereq_update = async (id, status_value) => {
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/Absenceemployeereq_update/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          att_id: id,
          status: status_value,
        },
      );

      const { data } = response; // Destructure response data
      console.log(data, "data...approval list,..");

      if (data && data[0]?.STATUS == "success") {
        setLoginhoursdata([]);
        setFilterloginhoursdata([]);
        Absenceemployeereq_list();
        toast.success(data[0]?.MSG, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(data[0]?.MSG, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  useEffect(() => {
    Absenceemployeereq_list(from_date, to_date);
  }, []);

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      { header: "User name", key: "username", width: 30 },
      {
        header: "Date",
        key: "date",
        width: 15,
      },
      {
        header: "Login Time",
        key: "login_time",
        width: 15,
      },
      {
        header: "Logout Time",
        key: "logout_time",
        width: 15,
      },
      {
        header: "Status",
        key: "status",
        width: 20,
      },
    ];

    const promise = Promise.all(
      filterloginhoursdata.map(async (product, index) => {
        sheet.addRow({
          id: product?.att_id,
          username: product?.employee_name,
          date: product?.date,
          login_time: product?.in_time,
          logout_time: product?.in_out,
          status:
            product?.status == 1
              ? "Pending for approval"
              : product?.status == 2
              ? "Request Approved"
              : product?.status == 3
              ? "Request rejected"
              : product?.status == 4
              ? "Completed"
              : "",
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "TimesheetApproval_list.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <>
      <div>
        <div className="text-center mt-2">
          <img
            src={`${baseurl}/public/images/supportportal/my_login_hours.png`}
            alt="track-order"
            style={{ width: "80px", height: "80px" }}
          />
          <h1 className="login-header">
            {userdetails?.username}, View your Timesheet approvals list!
          </h1>
          <p className="sub-header">
            Review your timesheet reports with precision and clarity. Stay
            informed about your work hours,
            {isMobile ? "" : <br />}
            tasks completed, and project progress effortlessly.
          </p>
        </div>

        {/* <Col sm="12" xl="3"> */}
        {/* hide for temparory  */}
        <div className="d-flex justify-content-center align-items-center mx-3 d-none">
          <Row className="d-flex justify-content-around align-items-center">
            <Col sm="12" xl="5">
              <Form className="theme-form">
                <FormGroup
                  className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
                >
                  <Label
                    className={`col-form-label timesheet-label ${
                      isMobile ? "" : ""
                    }`}
                  >
                    From Date:
                  </Label>
                  <InputGroup
                    className="date"
                    id="dt-minimum"
                    data-target-input="nearest"
                  >
                    <DatePicker
                      className="form-control datetimepicker-input digits input-register"
                      selected={from_date}
                      onChange={handleChangeFromTime}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy "
                      // minDate={moment().startOf('month').toDate()}
                      // maxDate={moment().endOf('month').toDate()}
                    />
                    <div
                      className="input-group-text"
                      data-target="#dt-date"
                      data-toggle="datetimepicker"
                    >
                      <i className="fa fa-calendar"></i>
                    </div>
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>

            {/* </Col> */}

            <Col sm="12" xl="4">
              <Form className="theme-form">
                <FormGroup className={` label-gap ${isMobile ? "" : "mb-4"}`}>
                  <Label
                    className={`col-form-label timesheet-label ${
                      isMobile ? "" : ""
                    }`}
                  >
                    To Date:
                  </Label>
                  {/* <Col xl="5" sm="7" lg="8"> */}
                  <InputGroup
                    className="date flex-nowrap"
                    id="dt-minimum"
                    data-target-input="nearest"
                  >
                    <DatePicker
                      className="form-control datetimepicker-input digits input-register"
                      selected={to_date}
                      onChange={handleChangeToTime}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy "
                      // minDate={from_date}
                      // maxDate={moment().endOf('month').toDate()}
                    />
                    <div
                      className="input-group-text"
                      data-target="#dt-date"
                      data-toggle="datetimepicker"
                    >
                      <i className="fa fa-calendar"></i>
                    </div>
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>

            <Col
              sm="12"
              xl="3"
              className="align-items-center justify-content-center"
              style={{ marginTop: "10px" }}
            >
              {/* <Col xl="5" sm="7" lg="8"> */}
              <Button
                className="searchbtn "
                onClick={() => {
                  setLoginhoursdata([]);
                  setFilterloginhoursdata([]);
                  Absenceemployeereq_list(from_date, to_date);
                }}
                disabled={searchbtn}
              >
                Search
              </Button>
            </Col>
          </Row>
        </div>
        {/* </Col> */}
        {/* </div> */}
        {isMobile ? (
          <Container>
            {data_info == "Data Not Found" ? (
              <div
                className="text-center mt-3 py-3"
                style={{ backgroundColor: "#dfdcdc" }}
              >
                No Records found
              </div>
            ) : loginhoursdata?.length == 0 ? (
              <div className="text-center mt-3">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <>
                <div>
                  <div className="d-flex justify-content-center align-items-center my-3">
                    <Input
                      className="form-control register-full input-register"
                      type="text"
                      placeholder="Search all fields..."
                      value={searchtext}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="searchbtn my-2"
                      onClick={exportExcelFile}
                    >
                      Export
                    </Button>
                  </div>
                  <DataTable
                    //  className="custom-data-table"
                    columns={columns}
                    data={filterloginhoursdata}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    customStyles={customStyles}
                    expandableRows
                    expandableRowExpanded={(row) => row === currentRow}
                    expandOnRowClicked
                    onRowClicked={(row) => setCurrentRow(row)}
                    onRowExpandToggled={(bool, row) => {
                      setCurrentRow(row);
                    }}
                    expandableRowsComponent={ExpandedComponent}
                    persistTableHead={true}
                  />
                </div>
              </>
            )}
          </Container>
        ) : (
          <div>
            {data_info == "Data Not Found" ? (
              <div
                className="text-center mt-3 py-3"
                style={{ backgroundColor: "#dfdcdc" }}
              >
                No Records found
              </div>
            ) : loginhoursdata?.length == 0 ? (
              <div className="text-center mt-3">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <>
                <div>
                  <div className="export-approvalist d-flex justify-content-between my-2">
                    <div>
                      <Input
                        className="form-control register-full input-register"
                        type="text"
                        placeholder="Search all fields..."
                        value={searchtext}
                        onChange={handleSearch}
                      />
                    </div>
                    <div>
                      <Button
                        className="searchbtn my-2"
                        onClick={exportExcelFile}
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                  <DataTable
                    className="custom-data-table data-loginreport"
                    columns={columns}
                    data={filterloginhoursdata}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    customStyles={customStyles}
                    persistTableHead={true}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Timesheetapprovalreport;

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import myreport from "../../../src/assets/images/supportportal/myreport.png";
import { isMobile } from "react-device-detect";
import myworkinghours from "../../../src/assets/images/supportportal/my_working_hours.png";
import myloginhours from "../../../src/assets/images/supportportal/my_login_hours.png";
import mybreakhours from "../../../src/assets/images/supportportal/my_break_hours.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
import { Button, Col, Container, Spinner, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import moment from "moment";
const ExcelJS = require("exceljs")

const Breakreport = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);
  const [searchtext, setSearchtext] = useState("");
  const [from_date, setFromdate] = useState(moment().subtract(30, 'days').toDate());
  const [to_date, setTodate] = useState(moment().toDate());
  const [breakhoursdata, setBreakhoursdata] = useState([]);
  const [filterbreakhoursdata, setFilterbreakhoursdata] = useState([]);
  const [data_info, setDatainfo] = useState("");
  const [searchbtn,setSearchbtn] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  // Recalculate the total sum whenever dataTableData changes
 
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  console.log(from_date, "fromdate")
  console.log(to_date, "todate")

 

  console.log(breakhoursdata + "break");
  console.log(filterbreakhoursdata + "filtered");
  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  const handleChangeFromTime = (date) => {
    setFromdate(date);
  };

  const handleChangeToTime = (date) => {
    setTodate(date);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // filter
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = breakhoursdata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key]) {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilterbreakhoursdata(filteredItems);
  };

  const columns = [];

  if (isMobile) {
    // Define columns for mobile view
    columns.push(
      {
        name: "User name",
        selector: (row) => row.username ?  row.username : "---",
        width: "170px",
        // sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.date ? row.date : "---",
        width: "170px",
        // sortable: true,
      }
    );
  } else {
    // Define columns for desktop view
    columns.push(
      {
        name: "User Name",
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {
                row.username ? row.username : "---" // Join the first four words with spaces
              }
            </div>
          </div>
        ),
        width: "200px",
        // sortable: true,    
      },
      {
        name: "Date",
        selector: (row) => row.date ? row.date : "---",
        width: "140px",
        // sortable: true,    
      },
      {
        name: "Total Break Count",
        selector: (row) => row.totalbreakcount,
        width: "175px",
        // sortable: true,    
      },
      {
        name: "Total Break Hours",
        selector: (row) => row.totalbreakhours,
        width: "200px",
        // sortable: true,    
      }
    );
  }

  const ExpandedComponent = ({ data }) => (
    <div>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Total Break Count</span> :
        <span className="expand-subheader"> {data.totalbreakcount}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Total Break Hours</span> :
        <span className="expand-subheader"> {data.totalbreakhours}</span>
      </p>
    </div>
  );

  const getbreakhours = async (from_date, to_date) => {
    setSearchbtn(true);
  
    try {
      const res = await axios.post(
        `${baseurl}/expo_access_api/breaking_hours/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          employee_id: emp_id,
          fromdt: moment(from_date).format('DD-MM-YY'),
          todt: moment(to_date).format('DD-MM-YY'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log(res + "workinghours");
  
      const { data } = res; // Destructure response data
  
      if (data && data[0]?.DATA?.breaking_hours) {
        const message = data[0].MSG;
        setDatainfo(message);
        const BreakingHours = data[0].DATA.breaking_hours;
        setBreakhoursdata(BreakingHours);
        setFilterbreakhoursdata(BreakingHours);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setBreakhoursdata([]);
        setFilterbreakhoursdata([]);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSearchbtn(false);
    }
  };

  useEffect(() => {
    getbreakhours(from_date,to_date);
  }, []);

  // for export to xlx 
  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      { header: "User name", key: "username", width: 30},
      {
        header: "Date",
        key: "date",
        width: 15,
      },
      {
        header: "Total Break Count",
        key: "totalbreakcount",
        width: 18,
      },
      {
        header: "Total Break Hours",
        key: "totalbreakhours",
        width: 18,
      },
    ];

    const promise = Promise.all(
      filterbreakhoursdata.map(async (product, index) => {
        sheet.addRow({
          username: product?.username,
          date:  product?.date,
          totalbreakcount: product?.totalbreakcount,
          totalbreakhours: product?.totalbreakhours,
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Break_hours.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <div>
      <div className="text-center">
        <img
          src={`${baseurl}/public/images/supportportal/my_break_hours.png`}
          alt="track-order"
          style={{ width: "120px", height: "120px" }}
        />
        <h1 className="login-header">
          {userdetails?.username}, View your Break-hours reports!
        </h1>
        <p className="sub-header">
          Review your timesheet reports with precision and clarity. Stay
          informed about your work hours,
          {isMobile ? "" : <br />}
          tasks completed, and project progress effortlessly.
        </p>
      </div>
      
        {/* <Col sm="12" xl="6"> */}
        <div className="d-flex justify-content-center align-items-center mx-3">
      <Row className="d-flex justify-content-around align-items-center">
        <Col sm="12" xl="5">
          <Form className="theme-form">
            <FormGroup
              className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
            >
              <Label
                className={`col-form-label timesheet-label ${
                  isMobile ? "" : ""
                }`}
              >
                From Date:
              </Label>
              <InputGroup
                className="date"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits input-register"
                  selected={from_date}
                  onChange={handleChangeFromTime}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy "
                  // minDate={moment().startOf('month').toDate()}
                  // maxDate={moment().endOf('month').toDate()}
                />
                <div
                  className="input-group-text"
                  data-target="#dt-date"
                  data-toggle="datetimepicker"
                >
                  <i className="fa fa-calendar"></i>
                </div>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>

        {/* </Col> */}

        <Col sm="12" xl="4">
          <Form className="theme-form">
            <FormGroup className={` label-gap ${isMobile ? "" : "mb-4"}`}>
              <Label
                className={`col-form-label timesheet-label ${
                  isMobile ? "" : ""
                }`}
              >
                To Date:
              </Label>
              {/* <Col xl="5" sm="7" lg="8"> */}
              <InputGroup
                className="date flex-nowrap"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits input-register"
                  selected={to_date}
                  onChange={handleChangeToTime}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy "
                  // minDate={from_date}
                  // maxDate={moment().endOf('month').toDate()}
                />
                <div
                  className="input-group-text"
                  data-target="#dt-date"
                  data-toggle="datetimepicker"
                >
                  <i className="fa fa-calendar"></i>
                </div>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>

        <Col sm="12" xl="3" className="align-items-center justify-content-center" style={{marginTop:"10px"}}>
          {/* <Col xl="5" sm="7" lg="8"> */}
          <Button
            className="searchbtn "
            onClick={() => getbreakhours(from_date, to_date)}
            disabled={searchbtn}
          >
            Search
          </Button>
        </Col>
      </Row>
      </div>
        {/* </Col> */}
      {/* </div> */}
      {isMobile ? (
        <Container>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : breakhoursdata?.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-center my-3">
                <Input
                  className="form-control register-full input-register"
                  type="text"
                  placeholder="Search all fields..."
                  value={searchtext}
                  onChange={handleSearch}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button className="searchbtn my-2" onClick={exportExcelFile}>
                    Export
                </Button>
              </div>
              <DataTable
                //  className="custom-data-table"
                columns={columns}
                data={filterbreakhoursdata}
                pagination
                paginationComponentOptions={paginationOptions}
                customStyles={customStyles}
                expandableRows
                expandableRowExpanded={(row) => row === currentRow}
                expandOnRowClicked
                onRowClicked={(row) => setCurrentRow(row)}
                onRowExpandToggled={(bool, row) => {
                  setCurrentRow(row);
                }}
                expandableRowsComponent={ExpandedComponent}
                persistTableHead={true}
              />
            </div>
          )}
        </Container>
      ) : (
        <div>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : breakhoursdata?.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="export-break d-flex justify-content-between my-2">
                <div>
                  <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Search all fields..."
                    value={searchtext}
                    onChange={handleSearch}
                  />
                </div>
                <div>
                <Button className="searchbtn my-2" onClick={exportExcelFile}>
                    Export
                </Button>
                </div>
              </div>
              <DataTable
                className="custom-data-table data-breakreport"
                columns={columns}
                data={filterbreakhoursdata}
                pagination
                paginationComponentOptions={paginationOptions}
                customStyles={customStyles}
                persistTableHead={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Breakreport;

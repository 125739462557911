import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import axios from "../../../axios/axios";
import { useForm } from "react-hook-form";

const AddAddress = ({contact_id,contact_type,address_id,setAddressModalShow,setSelRandomNumber}) => {
  const {
    register,
    setValue,
    handleSubmit,
    // Read the formState before render to subscribe the form state through the Proxy
    formState: { errors, isDirty, isSubmitting, touchedFields, submitCount },
  } = useForm();
  const AUTHORIZE_KEY = process.env.REACT_APP_AUTHORIZE_KEY;
  const [selectedcountry, setSelectedcountry] = useState();
  const [Country, setCountry] = useState([]);
  const [State, setState] = useState([]);
  const [selcountry, setSelCountry] = useState();
  const [selstate, setSelState] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(()=>{
    if(address_id > 0){
      axios.post("/ecommerce_api/getContactAddressByID/", {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        address_id: address_id,
      })
      .then((response) => {
        if (response.data[0].STATUS === "SUCCESS") {
          console.log(response.data[0].DATA);
          const contact_address = response.data[0].DATA?.contact_address?.Contact_address;
          setValue("address_type",contact_address?.nickname);
          setValue("address1",contact_address?.address_1);

          setValue("area",contact_address?.area);
          setValue("zipcode",contact_address?.zipcode);
          setValue("city",contact_address?.city_name);
          setSelCountry(contact_address?.country)
          setSelState(contact_address?.state);
        }
      });
    }
  },[address_id])
  useEffect(() => {
    async function getmySetting() {
      const res = await axios.post("/expo_access_api/getBasicSettings/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          type: 2,
        }
      );
      if (res) {
        if (res.data[0].STATUS === "SUCCESS") {
          if (res.data[0].DATA.country) {
            await setCountry(res.data[0].DATA.country);
            await setSelCountry("India");
            await changestate(null,"India");
            
          }
        }
      }
    }
    getmySetting();
  }, []);
  const changestate = (e, val) => {
    var element_val = val == 0 ? e.target.value : val;
    // setSelCountry(element_val);
    async function getStates() {
      const res = await axios.post("/expo_access_api/getstatelist/", {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        COUNTRY: element_val,
      });
      if (res) {
        if (res.data[0].STATUS === "SUCCESS") {
          if (res.data[0].DATA.state) {
            setState(res.data[0].DATA.state);
          }
        } else {
          //swal("Not Found", "State Not Found", "danger");
          setState([]);
        }
      }
    }
    getStates();
  };
  const onSubmit = (data) => {
    axios.post("/expo_access_api/saveContactAddress/", {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        contact_id: contact_id,
        contact_type: contact_type,
        address_type: data.address_type,
        address1: data.address1,
        address2: "",
        area: data.area,
        city: data.city,
        selcountry: selcountry,
        selstate: selstate,
        zipcode: data.zipcode,
        billing_flg: "Y",
        address_id: address_id > 0 ? address_id : ""
      })
      .then((response) => {
        if (response.data[0].STATUS === "SUCCESS") {
          setAddressModalShow(false);
          setSelRandomNumber(Math.random())
        }
      });
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      id="txt_add_type"
                      name="txt_add_type"
                      placeholder="Type Of Address"
                      {...register("address_type")}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      id="txt_address_line1"
                      name="txt_address_line1"
                      placeholder="Address"
                      required
                      rows={2}
                      {...register("address1")}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={6} md={6}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      id="txt_area"
                      name="txt_area"
                      placeholder="Area"
                      required
                      {...register("area")}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={6}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      id="txt_zipcode"
                      name="txt_zipcode"
                      placeholder="Pincode"
                      maxLength={6} 
                      required
                      onKeyPress={(event) => {
                        if (!/^[+-]?\d*(?:[.,]\d*)?$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("zipcode")}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={12} md={12}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      id="txt_city"
                      name="txt_city"
                      placeholder="City"
                      required
                      {...register("city")}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-1">
                <Col xs={6} md={6}>
                  <Form.Group controlId="sel_country">
                    <Form.Select
                      id="sel_country"
                      name="sel_country"
                      onChange={(e) => {
                        changestate(e, 0);
                        setSelCountry(e.target.value);
                      }}
                      value={selcountry}
                      required
                    >
                      <option value=""> -- Select Country -- </option>
                      {Country
                        ? [
                            Country.map((C) => (
                              <option
                                key={`country_${C.Country.country_code}`}
                                value={C.Country.country_name}
                              >
                                {C.Country.country_name}
                              </option>
                            )),
                          ]
                        : ""}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a country.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={6} md={6}>
                  <Form.Group controlId="sel_state_new">
                    <Form.Select
                      id="sel_state"
                      name="sel_state"
                      value={selstate}
                      onChange={(e)=>setSelState(e.target.value)}
                      required
                    >
                      <option value=""> -- Select State -- </option>
                      {State
                        ? [
                            State.map((S) => (
                              <option
                                key={`state_${S.State.state_name}`}
                                value={S.State.state_name}
                              >
                                {S.State.state_name}
                              </option>
                            )),
                          ]
                        : ""}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a state.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                size="sm"
                onClick={()=>setAddressModalShow(false)}
              >
                Close
              </Button>
              <Button variant="primary" type="submit" size="sm">
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
    </>
  );
};

export default AddAddress;

import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
//import DebouncedInput from "./DebounceInput";

function Filter({ column, table }) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);
  console.log(firstValue, "firstValue");

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );
  console.log(sortedUniqueValues, "sortedUniqueValues");

  const [inputValue, setInputValue] = useState(columnFilterValue || "");

  useEffect(() => {
    setInputValue(columnFilterValue || "");
  }, [columnFilterValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      column.setFilterValue(inputValue);
    }, 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  
  return (
    <div>
      {typeof firstValue === "number" ? (
        <div>
          <div>
            <input
              type="number"
              min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
              max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="min"
            />
            <input
              type="number"
              min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
              max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="max"
            />
          </div>
        </div>
      ) : (
        <>
          <datalist id={column.id + "list"}>
            {sortedUniqueValues.slice(0, 5000).map((value) => (
              <option value={value} key={value} />
            ))}
          </datalist>
          <Form.Control
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={`Search... `}
            list={column.id + "list"}
            style={{ width: "100%",borderRadius:"0px" }}
            size="sm"
          />
        </>
      )}
    </div>
  );
}

export default Filter;

import React from "react";
import myproject from "../../../src/assets/images/supportportal/myproject.png";
import addicon from "../../../src/assets/images/supportportal/addicon.png";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Loader from "../../Layout/Loader";
import { useUserState } from "../../context/Usercontext";
import { useDispatch, useSelector } from "react-redux";
import { additem, addtask } from "../../utils/supportrequestSlice";
import { CSVLink } from "react-csv";
import { Input } from "reactstrap";

const Viewtask = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const supportlist = useSelector((store) => store.supportrequest.taskdata);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [searchtext, setSearchtext] = useState("");
  const [taskdata, settaskdata] = useState(supportlist);
  const [filteredtaskdata, setFilteredtaskdata] = useState(supportlist);
  const [searchproject, setSearchproject] = useState("");
  const [data_info, setDatainfo] = useState("");

  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  const [currentRow, setCurrentRow] = useState(null);

  const configContactDisplay = localStorage.getItem("config_contact_display");
  const config_product_code_display = localStorage.getItem(
    "config_product_code_display"
  );

  console.log(data_info, "data_info...");
  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  const dispatch = useDispatch();
  console.log(supportlist);
  console.log(filteredtaskdata, "filtereddata");

  useEffect(() => {
    const emp_id = JSON.parse(localStorage.getItem("employeeid")); // Replace 'yourKey' with your actual key
    console.log(emp_id);
    if (emp_id) {
      setEmpid(emp_id);
    }
  }, []);
  // const {emp_id} = useUserState()
  // console.log(emp_id + "umangrtkghnfgdfkg")

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (supportlist.length == 0) {
      // If supportlist is an empty array, make the API call
      gettask();
    } else {
      // If supportlist already has data, set taskdata
      settaskdata(supportlist);
      setFilteredtaskdata(supportlist);
      localStorage.setItem("object_type", supportlist[0]?.object_type);
      console.log("filtreddata called....");
      // setIsLoading(false); // Set loading to false when data is received
    }
  }, []);

  // filter
  const handleSearch = (e) => {
    console.log("handleSearch");
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = taskdata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] && typeof item[key] === "string") {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilteredtaskdata(filteredItems);
  };

  const filterByProject = (e) => {
    const inputText = e.target.value;
    setSearchproject(inputText);

    // Function to remove extra spaces and special characters from a string
    const cleanString = (inputString) => {
      return inputString
        .trim()
        .replace(/[^a-z0-9]+/g, "") // Remove special characters
        .replace(/\s+/g, " ");
    };

    // Check if inputText is blank or null, then set filtertimesheetdata to timesheetdata
    if (!inputText || cleanString(inputText).length === 0) {
      setFilteredtaskdata(taskdata);
      return;
    }

    // Ensure searchproject is not null or undefined before calling toLowerCase
    const searchTermCleaned = cleanString(inputText || "").toLowerCase();
    console.log(searchTermCleaned, "searchTermCleaned");

    // Use Array.filter to filter objects based on problem_title
    const filteredData = taskdata.filter((item) => {
      // Ensure item.problem_title is not null or undefined before calling toLowerCase
      const problemTitleCleaned = cleanString(item.subject || "").toLowerCase();
      return problemTitleCleaned.includes(searchTermCleaned);
    });

    setFilteredtaskdata(filteredData);
  };

  const gettask = async (data) => {
    setIsLoading(true);
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/search_task_api/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          created_by: emp_id,
        },
      );
      console.log(res, "supportrequest");
      const { data } = res; // Destructure response data

      if (data && data[0]?.DATA) {
        const message = data[0]?.MSG;
        setDatainfo(message);
        settaskdata(res?.data[0]?.DATA?.supportlist);
        setFilteredtaskdata(res?.data[0]?.DATA?.supportlist);
        localStorage.setItem(
          "object_type",
          res?.data[0]?.DATA?.supportlist[0]?.object_type
        );
        dispatch(addtask(res?.data[0]?.DATA?.supportlist));
        setIsLoading(false);
        // localStorage.setItem("supportlist",JSON.stringify(res?.data[0]?.DATA?.supportlist))
      } else {
        const message = data[0]?.MSG;
        setDatainfo(message);
        settaskdata([]);
        setFilteredtaskdata([]);
        setIsLoading(false);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
    }
  };

  const columns = [];

  if (isMobile) {
    columns.push(
      {
        name: (
          <div>
            Task Name
            <Input
              type="text"
              className="form-control register-full input-register"
              placeholder="Search task..."
              value={searchproject}
              onChange={(e) => {
                filterByProject(e);
              }}
              style={{
                fontSize: "0.8rem",
                width: "90%",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <div
            style={{
              textAlign: "start",
              overflow: "hidden",
              whiteSpace: "normal",
            }}
          >
            {row.subject ? row.subject : "---"}{" "}
            {/* {config_product_code_display == "Y" && row?.product_code
              ? `(${row.product_code})`
              : ""} */}
          </div>
        ),
        width: "200px",
        // sortable: true,
      },

      {
        name: (
          <span>
            Task Assigned
            <br />
            Days
          </span>
        ),
        selector: (row) =>
          row.projectrunningdays ? row.projectrunningdays : "---",
        width: "140px",
        // sortable: true,
      }
      // {
      //   name: "Priority",
      //   selector: (row) => row.year,
      //   width: "auto",
      // },
    );
  } else {
    columns.push(
      // {
      //   name: "Company",
      //   selector: (row) => (
      //     <div>
      //       <div>{row.problem_title}</div>
      //       <div>{row.title}</div>
      //     </div>
      //   ),
      //   width: "180px",
      // },{
      //   name: "Branch",
      //   selector: (row) => (
      //     <div>
      //       <div>{row.problem_title}</div>
      //       <div>{row.title}</div>
      //     </div>
      //   ),
      //   width: "180px",
      // },
      {
        name: "",
        cell: (row) => (
          <div>
            <Link to={`${process.env.PUBLIC_URL}/addtimesheet/${row.id}`}>
              <img
                src={`${baseurl}/public/images/supportportal/addicon.png`}
                className="addicon"
                alt="addiconimg"
                style={{ width: "30px", height: "30px" }}
              />
            </Link>
          </div>
        ),
        width: "50px",
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Task Name</span>
            <Input
              type="text"
              className="form-control register-full input-register"
              placeholder="Search task..."
              value={searchproject}
              onChange={(e) => {
                filterByProject(e);
              }}
              style={{
                fontSize: "0.8rem",
                width: "90%",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.subject ? row.subject : "---"}{" "}
              {/* {config_product_code_display == "Y" && row?.product_code
                ? `(${row.product_code})`
                : ""} */}
            </div>
            {/* <div style={{ textAlign: "start" }}>{row.title}</div> */}
          </>
        ),
        width: "270px",
        // sortable: true,
      },
      //   {
      //     name: <span style={{ fontSize: "11px" }}>Branch</span>,
      //     selector: (row) => (
      //       <>
      //         <div
      //           style={{
      //             textAlign: "start",
      //             overflow: "hidden",
      //             whiteSpace: "normal",
      //           }}
      //         >
      //           {row.branch_name ? row.branch_name : "---"}
      //         </div>
      //       </>
      //     ),
      //     width: "80px",
      //     // sortable: true,
      //     // Set width to 0 if "Client Name" is hidden
      //   },
      {
        name: <span style={{ fontSize: "11px" }}>Client Name</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.contactname ? row.contactname : "---"}
            </div>
          </>
        ),
        width: "160px",
        // sortable: true,
        omit: configContactDisplay == "N",
        // Set width to 0 if "Client Name" is hidden
      },
      //   {
      //     name: (
      //       <span style={{ fontSize: "11px" }}>
      //         Budget
      //         <br />
      //         hours
      //       </span>
      //     ),
      //     selector: (row) => (row.budget_hours ? row.budget_hours : "---"),
      //     width: "72px",
      //     // sortable: true,
      //   },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Your Time
            <br />
            Invested
          </span>
        ),
        selector: (row) => (
          <span className="badge rounded-pill text-bg-success">
            {row.current_year_hours ? row.current_year_hours : "---"}
          </span>
        ),
        width: "120px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Task Start Date</span>,
        selector: (row) => (row.created_dt ? row.created_dt : "---"),
        width: "120px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Task Assign Days</span>,
        selector: (row) =>
          row.projectrunningdays ? row.projectrunningdays : "---",
        width: "130px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Task Due Date</span>,
        selector: (row) => (row.due_dt ? row.due_dt : "---"),
        width: "140px",
        // sortable: true,
      },
      //   {
      //     name: <span style={{ fontSize: "11px" }}>Year</span>,
      //     selector: (row) => new Date().getFullYear(),
      //     width: "62px",
      //     // sortable: true,
      //   },
      {
        name: <span style={{ fontSize: "11px" }}>Priority</span>,
        selector: (row) => (row.priority ? row.priority : "---"),
        width: "100px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Status</span>,
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.task_status ? row.task_status : "---"}
            </div>
          </div>
        ),
        width: "100px",
        // sortable: true,
      }
    );
  }

  const ExpandedComponent = ({ data }) => (
    <div>
      {configContactDisplay == "Y" ? (
        <p style={{ marginBottom: "0px" }}>
          <span className="expand-header">Client Name</span> :
          <span className="expand-subheader">
            {" "}
            {data.contactname ? data.contactname : "---"}
          </span>
        </p>
      ) : (
        ""
      )}
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Priority</span> :
        <span className="expand-subheader">
          {" "}
          {data.priority ? data.priority : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Status</span> :
        <span className="expand-subheader">
          {" "}
          {data.task_status ? data.task_status : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Task Start Date</span>:
        <span className="expand-subheader">
          {" "}
          {data.created_dt ? data.created_dt : "---"}
        </span>
      </p>
      {/* <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Project Assigned days</span>:
        <span className="expand-subheader"> {data.projectrunningdays}</span>
      </p> */}
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Task Due Date</span>
        </span>
        :
        <span className="expand-subheader">
          {" "}
          {data.due_dt ? data.due_dt : "---"}
        </span>
      </p>
      {/* <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Year</span>
        </span>
        :<span className="expand-subheader"> {new Date().getFullYear()}</span>
      </p> */}
      {/* <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Budget hours</span>
        </span>
        :
        <span className="expand-subheader">
          {" "}
          {data.budget_hours ? data.budget_hours : "---"}
        </span>
      </p> */}
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Your Time Invested</span>
        </span>
        :
        <span className="expand-subheader badge rounded-pill text-bg-success">
          {" "}
          {data.current_year_hours ? data.current_year_hours : "---"}
        </span>
      </p>
      <div style={{ marginBottom: "0px", marginTop: "5px" }}>
        <span className="expand-header">
          <span>Actions</span>
        </span>
        :
        <span className="expand-subheader">
          <Link to={`${process.env.PUBLIC_URL}/addtimesheet/${data.id}`}>
            <Button size="sm" className="punchinbtn headerbtn-mobile ms-2">
              Add
            </Button>
          </Link>
        </span>
      </div>
    </div>
  );

  return (
    <div>
      <>
        <div className="text-center">
          <img
            src={`${baseurl}/public/images/supportportal/myproject.png`}
            alt="track-order"
            style={{ width: "80px", height: "80px" }}
          />
          <h1 className="login-header">
            {userdetails?.username}, View projects & Add timesheet!
          </h1>
          <p className="sub-header">
            Manage your projects and effortlessly add timesheets all in one
            place. Stay organized, track your
            {isMobile ? "" : <br />}
            progress, and ensure accurate time recording with ease.
          </p>
        </div>
        <div>
          {isMobile ? (
            <Container>
              {data_info == "Data Not Found" ? (
                <div
                  className="text-center mt-3 py-3"
                  style={{ backgroundColor: "#dfdcdc" }}
                >
                  No Records found
                </div>
              ) : taskdata?.length == 0 ? (
                <div className="text-center mt-3">
                  <Spinner animation="border" variant="success" />
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-center align-items-center my-3">
                    <Input
                      className="form-control register-full input-register"
                      type="text"
                      placeholder="Search all fields..."
                      value={searchtext}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button className="searchbtn my-2">
                      <CSVLink className="export-link" data={filteredtaskdata}>
                        Export
                      </CSVLink>
                    </Button>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredtaskdata}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    customStyles={customStyles}
                    expandableRows
                    expandableRowExpanded={(row) => row === currentRow}
                    expandOnRowClicked
                    onRowClicked={(row) => setCurrentRow(row)}
                    onRowExpandToggled={(bool, row) => {
                      setCurrentRow(row);
                    }}
                    expandableRowsComponent={ExpandedComponent}
                    persistTableHead={true}
                  />
                </div>
              )}
            </Container>
          ) : (
            <div>
              {data_info == "Data Not Found" ? (
                <div
                  className="text-center mt-3 py-3"
                  style={{ backgroundColor: "#dfdcdc" }}
                >
                  No Records found
                </div>
              ) : taskdata?.length == 0 ? (
                <div className="text-center mt-3">
                  <Spinner animation="border" variant="success" />
                </div>
              ) : (
                <div className="">
                  <div className="export-viewtask d-flex justify-content-between my-2">
                    <div>
                      <Input
                        className="form-control register-full input-register"
                        type="text"
                        placeholder="Search all fields..."
                        value={searchtext}
                        onChange={handleSearch}
                      />
                    </div>
                    <div className="export-new">
                      <Button className="searchbtn my-2">
                        <CSVLink
                          className="export-link"
                          data={filteredtaskdata}
                        >
                          Export
                        </CSVLink>
                      </Button>
                    </div>
                  </div>
                  <DataTable
                    // className="table-extra"
                    className="custom-data-table data-viewtask"
                    columns={columns}
                    data={filteredtaskdata}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    customStyles={customStyles}
                    persistTableHead={true}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default Viewtask;

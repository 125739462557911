import React, { useEffect, useState } from "react";
import addtimesheet from "../../../src/assets/images/supportportal/addtimesheet.png";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import moment, { duration } from "moment";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { additem, addtask } from "../../utils/supportrequestSlice";
import { addDays, addYears, parse, subDays } from "date-fns";

const Addtimesheet = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const supportlist = useSelector((store) => store.supportrequest.projectdata);
  const [selectedOption, setSelectedOption] = useState("1");
  const currentDate = new Date();
  const currentTime = new Date().getTime();
  const navigate = useNavigate();
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  const location = useLocation();
  const { absence_date } = location?.state || {};

  const [startDate, setStartDate] = useState(
    absence_date
      ? new Date(
          parseInt(absence_date.split("-")[2]), // Year
          parseInt(absence_date.split("-")[1]) - 1, // Month (0-indexed)
          parseInt(absence_date.split("-")[0]), // Day
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds()
        )
      : new Date().getTime()
  );
  const [fromTime, setFromTime] = useState();
  const [toTime, setToTime] = useState();
  const [billableTime, setBillableTime] = useState();
  const [durationTime, setDurationTime] = useState();
  const [received_date, setReceiveddate] = useState("");
  console.log(startDate, "startDate...");
  console.log(toTime + "totime12345");
  console.log(billableTime);
  console.log(durationTime);
  console.log(fromTime, "fromtime");
  console.log(toTime, "totime");

  const [company_data, setCompanydata] = useState({});
  const [activity, setActivity] = useState([]);
  const [selectedactivity, setSelectedactivity] = useState("");
  const [subactivity, setSubactivity] = useState([]);
  const [selectedsubactivity, setSelectedsubactivity] = useState("");
  const [remark, setRemark] = useState("");
  const [submitbtn, setSubmitbtn] = useState(false);
  const { project_id } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const allocatedHours = searchParams.get("allocated_hours");
  console.log(allocatedHours, "allocatedHours...");

  const configContactDisplay = localStorage.getItem("config_contact_display");
  const config_product_code_display = localStorage.getItem(
    "config_product_code_display"
  );
  console.log(project_id + "params");
  console.log(absence_date, "absence_date from absence report...");
  console.log(selectedactivity + "selected activity");
  const dispatch = useDispatch();

  // Define a function to handle radio button change
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChange = (date) => {
    setStartDate(date);
  };

  const handleChangeFromTime = (date) => {
    setFromTime(date);
  };

  const handleChangeToTime = (date) => {
    setToTime(date);
  };

  const handleChangeDuration = (date) => {
    setDurationTime(date);
  };

  const handleChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  console.log(startDate + "fbfhbfgbb");

  useEffect(() => {
    if (fromTime != undefined && toTime != undefined) {
      const moment1 = moment(fromTime);
      const moment2 = moment(toTime);
      console.log(moment1, "moment1");
      console.log(moment2, "moment2");
      // Calculate the time difference

      const durations = moment.duration(moment2.diff(moment1));
      console.log(durations + "fbfgbfgbfgbfgbfgbfg");

      // Format the result as "hh:mm"
      const hours = Math.floor(durations.asHours());
      const minutes = Math.round(durations.asMinutes() % 60);
      console.log(hours, "hours");
      console.log(minutes, "minutes");

      if (hours < 0 || minutes < 0) {
        // Display an error message using your toast library
        // Replace the following line with your actual toast.error method
        toast.error("From time is greater than To time", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setBillableTime("");
      } else {
        let timedifference = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        console.log(timedifference, "timedifference");
        setBillableTime(timedifference);
      }
    }
  }, [fromTime, toTime]);

  useEffect(() => {
    getActivity();
    getBasicdetails();
    // getSubactivity(selectedactivity);
    window.scrollTo(0, 0);
  }, [ ]);

  useEffect(() => {
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  useEffect(() => {
    if (activity.length > 0) {
      // Check if there are options available
      setSelectedactivity(activity[0]?.category_name);
    }
  }, [activity]);

  console.log(startDate);

  const getActivity = async (data) => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/activity_fetch_api/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          employee_id: localStorage.getItem("employeeid"),
          order_id: 152,
          object_type:
            localStorage.getItem(
              "object_type"
            ) /* it would be change dynamic */,
        },
      );
      console.log(res);

      if (res && res.data && res.data[0].DATA) {
        setActivity(res?.data[0]?.DATA?.activity);
        setSelectedactivity(res?.data[0]?.DATA?.activity[0]?.category_name);
        getSubactivity(res?.data[0]?.DATA?.activity[0]?.category_id);
      } else {
        toast.error("No Activity found", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const getBasicdetails = async (data) => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/besicsetting_api/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          object_id: project_id,
          object_type:
            localStorage.getItem(
              "object_type"
            ) /* it would be change dynamic */,
        },
      );
      console.log(res);

      if (res && res.data && res.data[0].DATA) {
        setCompanydata(res?.data[0]?.DATA?.companie_data[0]);
        setReceiveddate(res?.data[0]?.DATA?.companie_data[0]?.received_dt);
      } else {
        // toast.error("No Companydata found", {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const getSubactivity = async (id) => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/sub_activity_byactivity/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          employee_id: localStorage.getItem("employeeid"),
          activity_id: id,
          object_type:
            localStorage.getItem(
              "object_type"
            ) /* it would be change dynamic */,
        },
      );
      console.log(res);

      if (res && res.data && res.data[0].DATA) {
        setSubactivity(res?.data[0]?.DATA?.sub_activity);
        setSelectedsubactivity(
          res?.data[0]?.DATA?.sub_activity[0]?.company_name
        );
      } else {
        toast.error("No Subactivity found", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const Addtimesheetdata = async (data) => {
    setSubmitbtn(true);
    const Date = moment(startDate).format("DD-MM-YY hh:mm A");

    try {
      if (selectedOption == "0" && company_data?.timesheet_type == "2") {
        if (!fromTime && !toTime) {
          toast.error("Please select from time and to time", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSubmitbtn(false);
          return; // Stop execution if both fromTime and toTime are undefined
        } else if (!fromTime) {
          toast.error("Please select from time", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSubmitbtn(false);
          return; // Stop execution if fromTime is undefined
        } else if (!toTime) {
          toast.error("Please select to time", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSubmitbtn(false);
          return; // Stop execution if toTime is undefined
        }
        // Continue with your code
        // Add your code here
      } else if (selectedOption == "1" && company_data?.timesheet_type == "2") {
        if (!durationTime) {
          toast.error("Please select hours spent", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSubmitbtn(false);
          return; // Stop execution if durationTime is undefined
        }

        // Continue with your code
        // Add your code here
      } else if (company_data?.timesheet_type == "0") {
        if (!fromTime || !toTime) {
          toast.error("Please select from time and to time", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSubmitbtn(false);
          return; // Stop execution if either fromTime or toTime is undefined
        }

        // Continue with your code
        // Add your code here
      } else if (company_data?.timesheet_type == "1") {
        if (!durationTime) {
          toast.error("Please select hours spent", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSubmitbtn(false);
          return; // Stop execution if durationTime is undefined
        }

        // Continue with your code
        // Add your code here
      }

      const requestData = {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        employee_id: localStorage.getItem("employeeid"),
        company_id: company_data?.company_id,
        branch_id: company_data?.branch_id,
        project_id: project_id,
        contact_id: company_data?.contactid,
        activity: selectedactivity,
        sub_activity: selectedsubactivity,
        // time_spend: 2,
        datetime: Date,
        // frm_time: frmTime,
        // to_time: tTime,
        description: remark,
        project_type: localStorage.getItem("object_type"),
      };

      if (selectedOption == "0" && company_data?.timesheet_type == "2") {
        let frmTime = moment(fromTime).format("hh:mm A");
        let tTime = moment(toTime).format("hh:mm A");
        requestData.frm_time = frmTime;
        requestData.to_time = tTime;

        console.log(frmTime + "frmtime");
        console.log(tTime + "frmtime");
        const moment1 = moment(fromTime);
        const moment2 = moment(toTime);

        // Calculate the time difference
        const durations = moment.duration(moment2.diff(moment1));
        console.log(durations, "durations");
        if (durations.asMinutes() < 0) {
          toast.error("From time is greater than To time", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSubmitbtn(false);
          return; // Stop execution if the time difference is negative
        }

        // addded ny umang on 04/03/2023
        //  if (durations.seconds() == 59) {
        //   durations.add(1, 'minutes');
        // }

        // // Round up the hours if minutes are 59
        // if (durations.minutes() == 59) {
        //   durations.add(1, 'hours');
        // }

        // Format the result as "hh:mm"
        const hours = Math.floor(durations.asHours());
        console.log(hours, "hours 111");
        const minutes = Math.floor(durations.asMinutes() % 60);
        console.log(minutes, "minutes 111");
        let timedifference = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        console.log(timedifference, "timediffrence");
        requestData.duration = timedifference;

        console.log("Time Difference:", timedifference);
      } else if (selectedOption == "1" && company_data?.timesheet_type == "2") {
        let duration = moment(durationTime).format("HH:mm");
        requestData.type = 2;
        requestData.duration = duration;
      } else if (company_data?.timesheet_type == "0") {
        let frmTime = moment(fromTime).format("hh:mm A");
        let tTime = moment(toTime).format("hh:mm A");
        requestData.frm_time = frmTime;
        requestData.to_time = tTime;

        console.log(frmTime + "frmtime");
        console.log(tTime + "frmtime");
        const moment1 = moment(fromTime);
        const moment2 = moment(toTime);

        console.log(moment1, "moment1");
        console.log(moment2, "moment2");

        // Calculate the time difference
        const durations = moment.duration(moment2.diff(moment1));
        if (durations.asMinutes() < 0) {
          toast.error("From time is greater than To time", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSubmitbtn(false);
          return; // Stop execution if the time difference is negative
        }

        console.log(durations, "difftime...");

        // addded ny umang on 04/03/2023
        // if (durations.seconds() == 59) {
        //   durations.add(1, 'minutes');
        // }

        // // Round up the hours if minutes are 59
        // if (durations.minutes() == 59) {
        //   durations.add(1, 'hours');
        // }

        // Format the result as "hh:mm"
        const hours = Math.floor(durations.asHours());
        const minutes = Math.floor(durations.asMinutes() % 60);
        let timedifference = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        console.log(timedifference, "timediffrence");
        requestData.duration = timedifference;
      } else if (company_data?.timesheet_type == "1") {
        let duration = moment(durationTime).format("HH:mm");
        // requestData.type = 2;
        requestData.duration = duration;
      }

      const res = await axios.post(
        baseurl + "/expo_access_api/insert_timesheet_api/",
        requestData
      );
      console.log(res);

      if (res && res.data[0]) {
        if (res.data[0]?.STATUS == "success") {
          toast.success("Timesheet Add Succesfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(additem([]));
          dispatch(addtask([]));
          console.log("add succes");
          setSubmitbtn(false);
          // const currentTime = new Date();
          setStartDate(currentDate);
          setFromTime(currentTime);
          setToTime(currentTime);
          setDurationTime(currentTime);
          setRemark("");
          localStorage.getItem("object_type") == "99"
            ? navigate(`${process.env.PUBLIC_URL}/viewproject`)
            : navigate(`${process.env.PUBLIC_URL}/viewtask`);
        } else {
          toast.error("Some data is mising for add timesheet", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSubmitbtn(false);
        }
      } else {
        toast.error("Something gone wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log("Error submitting form:", error);
      toast.error("Error submitting form!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSubmitbtn(false);
    }
  };

  return (
    <div>
      <div className="text-center">
        <img
          src={`${baseurl}/public/images/supportportal/addtimesheet.png`}
          alt="track-order"
          style={{ width: "200px", height: "200px" }}
        />
        <h1 className="login-header">
          {userdetails?.username}, Add your timesheet!
        </h1>
        <p className="sub-header">
          Effortlessly log your daily working hours and stay organized. Input
          your hours worked, tasks
          {isMobile ? "" : <br />}
          completed, and projects managed in just a few clicks.
        </p>
      </div>
      <Container className="py-4">
        <Row>
          <Col sm="12" xl="6">
            <Form className="theme-form">
              <FormGroup
                className={`row d-flex justify-content-center align-items-center formdiv-margin ${
                  isMobile ? "" : ""
                }`}
              >
                <Label
                  className={`col-sm-3 timesheet-label ${
                    isMobile ? "" : "text-end"
                  }`}
                  htmlFor="inputEmail3"
                >
                  Company:
                </Label>
                <Col sm="9">
                  <p className="input-register common-para timesheet-sublabel">
                    {company_data?.company_name}
                  </p>
                </Col>
              </FormGroup>
              <FormGroup
                className={`row d-flex justify-content-center align-items-center formdiv-margin ${
                  isMobile ? "" : "mb-2"
                }`}
              >
                <Label
                  className={`col-sm-3 col-form-label timesheet-label ${
                    isMobile ? "" : "text-end"
                  }`}
                  htmlFor="inputEmail3"
                >
                  Project:
                </Label>
                <Col sm="9">
                  {/* <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Projectname"
                    value={company_data?.title}
                    disabled
                  /> */}
                  <p className="input-register common-para timesheet-sublabel">
                    {company_data?.problem_title}{" "}
                    {config_product_code_display == "Y" &&
                    company_data?.product_code
                      ? `(${company_data.product_code})`
                      : ""}
                  </p>
                </Col>
              </FormGroup>
              <FormGroup
                className={`row formdiv-margin ${isMobile ? "" : "mb-2"}`}
              >
                <Label
                  className={`col-sm-3 col-form-label timesheet-label ${
                    isMobile ? "" : "text-end"
                  }`}
                  htmlFor="inputPassword3"
                >
                  Activity:
                </Label>
                <Col sm="9">
                  {/* <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Activity"
                    value={timesheetdata.activity_name}
                    disabled
                  /> */}
                  <Form.Select
                    className="register-full input-register select-font"
                    // id="country"
                    value={selectedactivity}
                    onChange={(e) => {
                      setSelectedactivity(e.target.value);
                      getSubactivity(e.target.value);
                    }}
                    required
                  >
                    {activity.map((act, index) => (
                      <option key={act?.category_id} value={act?.category_id}>
                        {act?.category_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </FormGroup>
              <FormGroup
                className={`row formdiv-margin ${isMobile ? "" : "mb-2"}`}
              >
                <Label
                  className={`col-sm-3 col-form-label timesheet-label ${
                    isMobile ? "" : "text-end"
                  }`}
                  htmlFor="inputPassword3"
                >
                  Date:
                </Label>
                <Col sm="9">
                  <InputGroup
                    className="date flex-nowrap"
                    id="dt-minimum"
                    data-target-input="nearest"
                  >
                    {absence_date ? (
                      <DatePicker
                        className="form-control datetimepicker-input digits input-register register-full select-font"
                        selected={startDate}
                        onChange={handleChange}
                        timeInputLabel="Time:"
                        showTimeInput
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={
                          absence_date
                            ? new Date(
                                parseInt(absence_date.split("-")[2]), // Year
                                parseInt(absence_date.split("-")[1]) - 1, // Month (0-indexed)
                                parseInt(absence_date.split("-")[0]) // Day
                              )
                            : null
                        }
                        maxDate={
                          absence_date
                            ? new Date(
                                parseInt(absence_date.split("-")[2]), // Year
                                parseInt(absence_date.split("-")[1]) - 1, // Month (0-indexed)
                                parseInt(absence_date.split("-")[0]) // Day
                              )
                            : null
                        }
                      />
                    ) : (
                      <DatePicker
                        className="form-control datetimepicker-input digits input-register register-full select-font"
                        selected={startDate}
                        onChange={handleChange}
                        timeInputLabel="Time:"
                        showTimeInput
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        includeDateIntervals={
                          received_date
                            ? [
                                {
                                  start: subDays(
                                    parse(
                                      received_date,
                                      "dd/MM/yy",
                                      new Date()
                                    ),
                                    2
                                  ),
                                  end: addYears(
                                    parse(
                                      received_date,
                                      "dd/MM/yy",
                                      new Date()
                                    ),
                                    100
                                  ),
                                },
                              ]
                            : []
                        }
                      />
                    )}

                    <div
                      className="input-group-text"
                      data-target="#dt-date"
                      data-toggle="datetimepicker"
                    >
                      <i className="fa fa-calendar"></i>
                    </div>
                  </InputGroup>
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <Col sm="12" xl="6">
            <Form className="theme-form">
              <FormGroup
                className={`row d-flex justify-content-center align-items-center formdiv-margin ${
                  isMobile ? "" : "mb-2"
                }`}
              >
                <Label
                  className={`col-sm-3 timesheet-label ${
                    isMobile ? "" : "text-end"
                  }`}
                  htmlFor="inputEmail3"
                >
                  Branch:
                </Label>
                <Col sm="9">
                  <p className="input-register common-para timesheet-sublabel">
                    {company_data?.branch_name}
                  </p>
                </Col>
              </FormGroup>
              {configContactDisplay == "Y" ? (
                <FormGroup
                  className={`row d-flex justify-content-center align-items-center formdiv-margin ${
                    isMobile ? "" : "mb-2"
                  }`}
                >
                  <Label
                    className={`col-sm-3 col-form-label timesheet-label ${
                      isMobile ? "" : "text-end"
                    }`}
                    htmlFor="inputEmail3"
                  >
                    Client:
                  </Label>
                  <Col sm="9">
                    {/* <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Client"
                    value={company_data?.contactname}
                    disabled
                  /> */}
                    <p className="input-register common-para timesheet-sublabel">
                      {company_data?.contactname}
                    </p>
                  </Col>
                </FormGroup>
              ) : (
                ""
              )}

              <FormGroup
                className={`row label-gap formdiv-margin ${
                  isMobile ? "" : "mb-2"
                }`}
              >
                <Label
                  className={`col-sm-3 col-form-label timesheet-label ${
                    isMobile ? "" : "text-end"
                  }`}
                  htmlFor="inputPassword3"
                >
                  Sub Activity:
                </Label>
                <Col sm="9">
                  <Form.Select
                    className="register-full input-register select-font"
                    id="country"
                    value={selectedsubactivity}
                    onChange={(e) => {
                      setSelectedsubactivity(e.target.value);
                    }}
                    required
                  >
                    {subactivity.map((subact, index) => (
                      <option
                        key={subact?.tasksubcategory_id}
                        value={subact?.company_name}
                      >
                        {subact?.company_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </FormGroup>
            </Form>
          </Col>

          {/* for type = 0 */}
          {company_data?.timesheet_type == "0" ? (
            <>
              <Col sm="12" xl="6">
                <Form className="theme-form">
                  <FormGroup
                    className={`row label-gap formdiv-margin ${
                      isMobile ? "" : "mb-2"
                    }`}
                  >
                    <Label
                      className={`col-sm-3 col-form-label timesheet-label ${
                        isMobile ? "" : "text-end"
                      }`}
                    >
                      From time:
                    </Label>
                    <Col xl="5" sm="7" lg="8">
                      <InputGroup
                        className="date flex-nowrap"
                        id="dt-minimum"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control datetimepicker-input digits input-register select-font"
                          selected={fromTime}
                          onChange={handleChangeFromTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                        <div
                          className="input-group-text"
                          data-target="#dt-time"
                          data-toggle="datetimepicker"
                        >
                          <i className="fa fa-clock-o"></i>
                        </div>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>

              <Col sm="12" xl="6">
                <Form className="theme-form">
                  <FormGroup
                    className={`row label-gap formdiv-margin ${
                      isMobile ? "" : "mb-2"
                    }`}
                  >
                    <Label
                      className={`col-sm-3 col-form-label timesheet-label ${
                        isMobile ? "" : "text-end"
                      }`}
                    >
                      To Time:
                    </Label>
                    <Col xl="5" sm="7" lg="8">
                      <InputGroup
                        className="date flex-nowrap"
                        id="dt-minimum"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control datetimepicker-input digits input-register select-font"
                          selected={toTime}
                          onChange={handleChangeToTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                        <div
                          className="input-group-text"
                          data-target="#dt-time"
                          data-toggle="datetimepicker"
                        >
                          <i className="fa fa-clock-o"></i>
                        </div>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>

              <Col sm="12" xl="6">
                <Form className="theme-form">
                  <FormGroup
                    className={`row formdiv-margin ${isMobile ? "" : "mb-2"}`}
                  >
                    <Label
                      className={`col-sm-3 col-form-label timesheet-label ${
                        isMobile ? "" : "text-end"
                      }`}
                      htmlFor="inputEmail3"
                    >
                      Billable Time:
                    </Label>
                    <Col sm="5">
                      <Input
                        className="form-control register-full input-register select-font"
                        type="text"
                        // placeholder="Projectname"
                        value={billableTime}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </>
          ) : (
            ""
          )}

          {/* for type = 1 */}
          {company_data?.timesheet_type == "1" ? (
            <>
              <Col sm="12" xl="6">
                <Form className="theme-form">
                  <FormGroup
                    className={`row label-gap formdiv-margin ${
                      isMobile ? "" : "mb-2"
                    }`}
                  >
                    <Label
                      className={`col-sm-3 col-form-label timesheet-label ${
                        isMobile ? "" : "text-end"
                      }`}
                    >
                      Hours Spent:
                    </Label>
                    <Col xl="5" sm="7" lg="8">
                      <InputGroup
                        className="date flex-nowrap"
                        id="dt-minimum"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control datetimepicker-input digits input-register select-font"
                          selected={durationTime}
                          onChange={handleChangeDuration}
                          // locale="pt-BR"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          minTime={new Date(0, 0, 0, 0, 0)} // 00:00
                          maxTime={new Date(0, 0, 0, 12, 0)} // 12:00
                        />

                        <div
                          className="input-group-text"
                          data-target="#dt-time"
                          data-toggle="datetimepicker"
                        >
                          <i className="fa fa-clock-o"></i>
                        </div>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </>
          ) : (
            ""
          )}

          {/* for type = 2 */}
          {company_data?.timesheet_type == "2" ? (
            <Col sm="12" xl="12">
              <Form className="d-flex justify-content-center align-items-center">
                <Form.Check
                  className="delivery-radiobtn"
                  type="radio"
                  label="From to To time"
                  id="yesRadio"
                  name="radioGroup"
                  value="0"
                  checked={selectedOption === "0"} // Check if this radio button should be checked
                  onChange={handleRadioChange}
                />
                <Form.Check
                  className="delivery-radiobtn"
                  type="radio"
                  label="Duration"
                  id="noRadio"
                  name="radioGroup"
                  value="1"
                  checked={selectedOption === "1"} // Check if this radio button should be checked
                  onChange={handleRadioChange}
                />
              </Form>
            </Col>
          ) : (
            ""
          )}

          {selectedOption == "0" && company_data?.timesheet_type == "2" && (
            <>
              <Col sm="12" xl="6">
                <Form className="theme-form">
                  <FormGroup
                    className={`row label-gap formdiv-margin ${
                      isMobile ? "" : "mb-2"
                    }`}
                  >
                    <Label
                      className={`col-sm-3 col-form-label timesheet-label ${
                        isMobile ? "" : "text-end"
                      }`}
                    >
                      From time:
                    </Label>
                    <Col xl="5" sm="7" lg="8">
                      <InputGroup
                        className="date flex-nowrap"
                        id="dt-minimum"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control datetimepicker-input digits input-register select-font"
                          selected={fromTime}
                          onChange={handleChangeFromTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                        <div
                          className="input-group-text"
                          data-target="#dt-time"
                          data-toggle="datetimepicker"
                        >
                          <i className="fa fa-clock-o"></i>
                        </div>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>

              <Col sm="12" xl="6">
                <Form className="theme-form">
                  <FormGroup
                    className={`row label-gap formdiv-margin ${
                      isMobile ? "" : "mb-2"
                    }`}
                  >
                    <Label
                      className={`col-sm-3 col-form-label timesheet-label ${
                        isMobile ? "" : "text-end"
                      }`}
                    >
                      To Time:
                    </Label>
                    <Col xl="5" sm="7" lg="8">
                      <InputGroup
                        className="date flex-nowrap"
                        id="dt-minimum"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control datetimepicker-input digits input-register select-font"
                          selected={toTime}
                          onChange={handleChangeToTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                        <div
                          className="input-group-text"
                          data-target="#dt-time"
                          data-toggle="datetimepicker"
                        >
                          <i className="fa fa-clock-o"></i>
                        </div>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>

              <Col sm="12" xl="6">
                <Form className="theme-form">
                  <FormGroup
                    className={`row formdiv-margin ${isMobile ? "" : "mb-2"}`}
                  >
                    <Label
                      className={`col-sm-3 col-form-label timesheet-label ${
                        isMobile ? "" : "text-end"
                      }`}
                      htmlFor="inputEmail3"
                    >
                      Billable Time:
                    </Label>
                    <Col xl="5" sm="7" lg="8">
                      <Input
                        className="form-control input-gray select-font"
                        type="text"
                        // placeholder="Projectname"
                        value={billableTime}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </>
          )}

          {selectedOption == "1" && company_data?.timesheet_type == "2" && (
            <>
              <Col sm="12" xl="6">
                <Form className="theme-form">
                  <FormGroup
                    className={`row label-gap formdiv-margin ${
                      isMobile ? "" : "mb-2"
                    }`}
                  >
                    <Label
                      className={`col-sm-3 col-form-label timesheet-label ${
                        isMobile ? "" : "text-end"
                      }`}
                    >
                      Hours Spent:
                    </Label>
                    <Col xl="5" sm="7" lg="8">
                      <InputGroup
                        className="date flex-nowrap"
                        id="dt-minimum"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control datetimepicker-input digits input-register select-font"
                          selected={durationTime}
                          onChange={handleChangeDuration}
                          // locale="pt-BR"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          minTime={new Date(0, 0, 0, 0, 0)} // 00:00
                          maxTime={new Date(0, 0, 0, 12, 0)} // 12:00
                        />

                        <div
                          className="input-group-text"
                          data-target="#dt-time"
                          data-toggle="datetimepicker"
                        >
                          <i className="fa fa-clock-o"></i>
                        </div>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </>
          )}
        </Row>

        <Row>
          <Col sm="12" xl="6">
            <Form className="theme-form">
              <FormGroup
                className={`row label-gap formdiv-margin ${
                  isMobile ? "" : "mb-2"
                }`}
              >
                <Label
                  className={`col-sm-3 col-form-label timesheet-label ${
                    isMobile ? "" : "text-end"
                  }`}
                >
                  Allocated Hours:
                </Label>
                <Col xl="5" sm="7" lg="8">
                  <Input
                    className="form-control input-gray select-font"
                    type="text"
                    // placeholder="Projectname"
                    value={allocatedHours > 0 ? allocatedHours : 0}
                    disabled
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col sm="12" xl="6">
            <Form className="theme-form">
              <FormGroup
                className={`row label-gap formdiv-margin ${
                  isMobile ? "" : "mb-2"
                }`}
              >
                <Label
                  className={`col-sm-3 col-form-label timesheet-label ${
                    isMobile ? "" : "text-end"
                  }`}
                  htmlFor="inputEmail3"
                >
                  Remarks:
                </Label>
                {/* <Col sm="2" xl="6"></Col> */}
                <Col sm="9">
                  <Input
                    type="textarea"
                    rows="3"
                    className="form-control register-full input-register"
                    placeholder="Type in your timesheet remarks here..."
                    value={remark}
                    onChange={handleChangeRemark}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>

        <Row>
          <div className="text-center">
            <Button
              className="mt-2 submit-btn"
              onClick={Addtimesheetdata}
              disabled={submitbtn}
            >
              Submit
            </Button>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Addtimesheet;

import moment from "moment";

export const Sales_percentage_chart = (salesdate_array, salesprice_array,productname) => {
  // Transform date format using moment
  const transformedCategories = salesdate_array.map((dateString) => {
    const date = moment(dateString, "DD/MM/YYYY").format("MMMM Do YY");
    return date;
  });

  // Additional properties for yaxis, colors, and plotOptions
  const additionalOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    colors: ["#005e32"],
    title: {
      text:  `Sales Cost Chart (${productname})`,
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
  };

  // Create options object with only categories
  const options = {
    xaxis: {
      categories: salesdate_array,
    },
    ...additionalOptions, // Merge additional options into options object
  };

  // Combine formatted series data and chart options
  const formattedData = {
    series: [
      {
        name: "Price",
        data: salesprice_array,
      },
    ],
    options: options,
  };

  return formattedData;
};

export const Purchase_percentage_chart = (
  purchasedate_array,
  purchaseprice_array,productname
) => {
  // Extract series data and categories from API response

  // Transform date format using moment
  const transformedCategories = purchasedate_array.map((dateString) => {
    const date = moment(dateString, "DD/MM/YYYY").format("MMMM Do YY");
    return date;
  });

  // Additional properties for yaxis, colors, and plotOptions
  const additionalOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    colors: ["#005e32"],
    title: {
      text: `Purchase Cost Chart (${productname})`,
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
  };

  // Create options object with only categories
  const options = {
    xaxis: {
      categories: purchasedate_array,
    },
    ...additionalOptions, // Merge additional options into options object
  };

  // Combine formatted series data and chart options
  const formattedData = {
    series: [
      {
        name: "Price",
        data: purchaseprice_array,
      },
    ],
    options: options,
  };

  return formattedData;
};

export const dummy_data = {
  series: [
    {
      name: "Desktops",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Product Trends by Month",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
  },
};

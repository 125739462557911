import React, { useState } from 'react'
import DataTable from "react-data-table-component";
import myreport from "../../../src/assets/images/supportportal/myreport.png";
import { isMobile } from "react-device-detect";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import myworkinghours from "../../../src/assets/images/supportportal/my_working_hours.png";
import myloginhours from "../../../src/assets/images/supportportal/my_login_hours.png";
import mybreakhours from "../../../src/assets/images/supportportal/my_break_hours.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
// import styled from "styled-components";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useContext } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const Timesheetreport_task = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  const [searchtext, setSearchtext] = useState("");
  const [searchproject, setSearchproject] = useState("");
  const [searchuser, setSearchuser] = useState("");
  const [from_date, setFromdate] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [to_date, setTodate] = useState(moment().toDate());
  const [timesheetdata, setTimesheetdata] = useState([]);
  const [filtertimesheetdata, setFiltertimesheetdata] = useState([]);
  const [data_info, setDatainfo] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [searchbtn, setSearchbtn] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const configContactDisplay = localStorage.getItem("config_contact_display");
  const config_product_code_display = localStorage.getItem(
    "config_product_code_display"
  );
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  console.log(timesheetdata, "timesheetdata");
  console.log(moment(from_date).format("DD-MM-YYYY"));
  console.log(to_date, "to_date");

  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  const timeToHours = (time) => {
    // Check if time is non-null and non-undefined
    if (time != null) {
      const [hours, minutes, seconds] = time.split(":");
      return (
        parseFloat(hours) + parseFloat(minutes) / 60 + parseFloat(seconds) / 3600
      );
    } else {
      // Handle the case where time is null or undefined
      console.error("Error: time is null or undefined");
      // You can return a default value or throw an error, depending on your requirements
      return 0; // Default value (you can change it based on your needs)
    }
  };
  

  // Function to format numeric hours to "hh:mm:ss" format
  const formatHoursToTime = (numericHours) => {
    const hours = Math.floor(numericHours);
    const minutes = Math.floor((numericHours - hours) * 60);
    const seconds = Math.floor(((numericHours - hours) * 60 - minutes) * 60);

    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return formattedTime;
  };

  useEffect(() => {
    const sum = filtertimesheetdata.reduce(
      (acc, row) => acc + timeToHours(row.hours),
      0
    );
    setTotalSum(sum);
  }, [filtertimesheetdata]);

  const exportHeaders = true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // filter
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = timesheetdata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key]) {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFiltertimesheetdata(filteredItems);
  };

  const filterByProject = (e) => {
    const inputText = e.target.value;
    setSearchproject(inputText);
  
    // Function to remove extra spaces and special characters from a string
    const cleanString = (inputString) => {
      return inputString
        .trim()
        .replace(/[^a-z0-9]+/g, "") // Remove special characters
        .replace(/\s+/g, " ");
    };
  
    // Check if inputText is blank or null, then set filtertimesheetdata to timesheetdata
    if (!inputText || cleanString(inputText).length === 0) {
      setFiltertimesheetdata(timesheetdata);
      return;
    }
  
    // Ensure searchproject is not null or undefined before calling toLowerCase
    const searchTermCleaned = cleanString(inputText || '').toLowerCase();
    console.log(searchTermCleaned, "searchTermCleaned");
  
    // Use Array.filter to filter objects based on problem_title
    const filteredData = timesheetdata.filter((item) => {
      // Ensure item.problem_title is not null or undefined before calling toLowerCase
      const problemTitleCleaned = cleanString(item.problem_title || '').toLowerCase();
      return problemTitleCleaned.includes(searchTermCleaned);
    });
  
    setFiltertimesheetdata(filteredData);
  };

  const filterByUsername = (e) => {
    setSearchuser(e.target.value);
    // Function to remove extra spaces and special characters from a string
    const cleanString = (inputString) => {
      return inputString
        .trim()
        .replace(/[^a-z0-9]+/g, "") // Remove special characters
        .replace(/\s+/g, " ");
    };

    // Convert the searchTerm to lowercase for case-insensitive search
    const searchTermCleaned = cleanString(searchuser.toLowerCase());

    // Use Array.filter to filter objects based on problem_title
    const filteredData = timesheetdata.filter((item) =>
      cleanString(item.createre_name.toLowerCase()).includes(searchTermCleaned)
    );

    setFiltertimesheetdata(filteredData);
  };

  const columns = [];

  if (isMobile) {
    // Define columns for mobile view
    columns.push(
      {
        name: "Client",
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.clinet ? row.clinet : "---"}
            </div>
          </>
        ),
        width: "113px",
        sortable: true,
        omit: configContactDisplay == "N",
      },
      {
        name: (
          <div>
            Task Name
            <Input
              type="text"
              className="form-control register-full input-register"
              placeholder="Search project..."
              value={searchproject}
              onChange={(e) => {
                filterByProject(e);
              }}
              style={{
                fontSize: "0.68rem",
                width: "100%",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              {/* {config_product_code_display == "Y" && row?.product_code
                ? `(${row.product_code})`
                : ""} */}
            </div>
          </>
        ),
        width: "130px",
        // sortable: true,
      },
      {
        name: (
          <div>
            User Name
            <Input
              type="text"
              className="form-control register-full input-register"
              placeholder="Search user..."
              value={searchuser}
              onChange={(e) => {
                filterByUsername(e);
              }}
              style={{
                fontSize: "0.68rem",
                width: "100%",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {" "}
              {row.createre_name ? row.createre_name : "---"}
            </div>
          </div>
        ),
        width: "120px",
        // sortable: true,
      },
      {
        name: (
          <span>
            Worked On
            <br />
            Date
          </span>
        ),
        selector: (row) => (row.date ? row.date : "---"),
        width: "100px",
        // sortable: true,
        omit: configContactDisplay == "Y",
      }
    );
  } else {
    // Define columns for desktop view
    columns.push(
      // {
      //   name: "Company",
      //   selector: (row) => row.company,
      //   width: "260px",
      // },
      // {
      //   name: "Branch",
      //   selector: (row) => row.branch,
      //   width: "170px",
      // },
      {
        name: <span style={{ fontSize: "11px" }}>Client</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.clinet ? row.clinet : "---"}
            </div>
          </>
        ),
        width: "140px",
        sortable: true,
        omit: configContactDisplay == "N",
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Task Name</span>
            <Input
              type="text"
              className="form-control register-full input-register"
              placeholder="Search task..."
              value={searchproject}
              onChange={(e) => {
                filterByProject(e);
              }}
              style={{
                fontSize: "0.68rem",
                width: "100%",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              {/* {config_product_code_display == "Y" && row?.product_code
                ? `(${row.product_code})`
                : ""} */}
              <div style={{ textAlign: "start" }}>{row.project}</div>
            </div>
          </>
        ),
        width: "180px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>User Name</span>
            <Input
              type="text"
              className="form-control register-full input-register"
              placeholder="Search user..."
              value={searchuser}
              onChange={(e) => {
                filterByUsername(e);
              }}
              style={{
                fontSize: "0.68rem",
                width: "100%",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {" "}
              {row.createre_name ? row.createre_name : "---"}
            </div>
          </div>
        ),
        width: "120px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Worked On
            <br />
            Date
          </span>
        ),
        selector: (row) => (row.date ? row.date : "---"),
        width: "115px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Login Hours
            <br />
            (Login - Break) Hours
          </span>
        ),
        selector: (row) => {
          const totalnet_hours = row.totalnet_hours;

          return totalnet_hours ? totalnet_hours : "00:00:00";
        },
        width: "85px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Time Sheet
            <br />
            Hours
          </span>
        ),
        selector: (row) => (row.hours ? row.hours : "---"),
        width: "85px",
        // sortable: true,
      },
    //   {
    //     name: <span style={{ fontSize: "11px" }}>Year</span>,
    //     selector: (row) => new Date().getFullYear(),
    //     width: "65px",
    //     // sortable: true,
    //   },
    //   {
    //     name: (
    //       <span style={{ fontSize: "11px" }}>
    //         Budget
    //         <br />
    //         Hours
    //       </span>
    //     ),
    //     selector: (row) => (row.budget_hours ? row.budget_hours : "---"),
    //     width: "72px",
    //     // sortable: true,
    //   },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Your Time
            <br />
            Invested
          </span>
        ),
        selector: (row) => (
          <span className="badge rounded-pill text-bg-success">
            {" "}
            {row.current_year_hours ? row.current_year_hours : "---"}
          </span>
        ),
        width: "85px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Activity</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.activity ? row.activity : "---"}
            </div>
          </>
        ),
        width: "100px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Sub Activity</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.subactivity ? row.subactivity : "---"}
            </div>
          </>
        ),
        width: "100px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Remarks</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.remarks ? row.remarks : "---"}
            </div>
          </>
        ),
        width: "120px",
        // sortable: true,
      }
    );
  }
  // filterdata
  const applyDateFilter = (from_date, to_date) => {
    if (from_date && to_date) {
      const filteredData = timesheetdata.filter((item) => {
        const attDate = moment(item.date, "DD/MM/YYYY"); // Parse date with moment.js
        const isAfterOrEqualFromDate = attDate.isSameOrAfter(
          moment(from_date, "DD/MM/YYYY"),
          "day"
        );
        const isBeforeOrEqualToDate = attDate.isSameOrBefore(
          moment(to_date, "DD/MM/YYYY"),
          "day"
        );
        return isAfterOrEqualFromDate && isBeforeOrEqualToDate;
      });
      console.log("applydatafilter:", filteredData); // Log the array directly without concatenation
      setFiltertimesheetdata(filteredData);
    } else {
      // If start date or end date is not selected, set filtered data to empty array
      setFiltertimesheetdata([]);
    }
  };
  console.log(data_info, "data_info");

  const gettimesheetreport = async (from_date, to_date) => {
    setSearchbtn(true);
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/search_timesheetdata_by_task_api/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          created_by: emp_id,
          fromdt: moment(from_date).format("DD-MM-YY"),
          todt: moment(to_date).format("DD-MM-YY"),
        },
      );
      console.log(res, "supportrequest");
      const { data } = res; // Destructure response data

      if (data && data[0]?.DATA?.supportlist) {
        const message = data[0].MSG;
        setDatainfo(message);
        setTimesheetdata(res?.data[0]?.DATA?.supportlist);
        setFiltertimesheetdata(res?.data[0]?.DATA?.supportlist);
        setSearchbtn(false);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setTimesheetdata([]);
        setFiltertimesheetdata([]);
        setSearchbtn(false);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    gettimesheetreport(from_date, to_date);
  }, []);

  const handleChangeFromTime = (date) => {
    setFromdate(date);
  };

  const handleChangeToTime = (date) => {
    setTodate(date);
  };

  const ExpandedComponent = ({ data }) => (
    <div>
      {/* <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Project</span> :
        <span className="expand-subheader">{data.project}</span>
      </p> */}
      {/* <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Year</span> :
        <span className="expand-subheader"> {data.date.split("/")[2]}</span>
      </p> */}
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Login Hours(Login - Break) Hours</span>{" "}
        :
        <span className="expand-subheader">
          {" "}
          {data.totalnet_hours ? data.totalnet_hours : "00:00:00"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Time Sheet Hours</span> :
        <span className="expand-subheader">
          {" "}
          {data.hours ? data.hours : "---"}
        </span>
      </p>
      {configContactDisplay == "Y" ? (
        <p style={{ marginBottom: "0px" }}>
          <span className="expand-header">Worked on date</span> :
          <span className="expand-subheader">
            {" "}
            {data.date ? data.date : "---"}
          </span>
        </p>
      ) : (
        ""
      )}
      {/* <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Year</span>
        </span>
        :<span className="expand-subheader"> {new Date().getFullYear()}</span>
      </p> */}
      {/* <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Budget hours</span>
        </span>
        :
        <span className="expand-subheader">
          {" "}
          {data.budget_hours ? data.budget_hours : "---"}
        </span>
      </p> */}
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Your Time Invested</span>
        </span>
        :
        <span className="expand-subheader badge rounded-pill text-bg-success">
          {"  "}
          {data.current_year_hours ? data.current_year_hours : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Activity</span> :
        <span className="expand-subheader">
          {" "}
          {data.activity ? data.activity : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Sub Activity</span> :
        <span className="expand-subheader">
          {" "}
          {data.subactivity ? data.subactivity : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Remarks</span> :
        <span className="expand-subheader">
          {" "}
          {data.remarks ? data.remarks : "---"}
        </span>
      </p>
    </div>
  );

  const dashboardboxes = [
    {
      text: (
        <span>
          My working
          <br />
          hours
        </span>
      ),
      img: `${baseurl}/public/images/supportportal/my_working_hours.png`,
      linkTo: `${process.env.PUBLIC_URL}/workinghoursreport`, // Specify the path you want to link to
    },
    {
      text: (
        <span>
          My login
          <br />
          hours
        </span>
      ),
      img: `${baseurl}/public/images/supportportal/my_login_hours.png`,
      linkTo: `${process.env.PUBLIC_URL}/loginhoursreport`, // Specify the path you want to link to
    },
    {
      text: (
        <span>
          My break
          <br />
          hours
        </span>
      ),
      img: `${baseurl}/public/images/supportportal/my_break_hours.png`,
      linkTo: `${process.env.PUBLIC_URL}/breakhoursreport`, // Specify the path you want to link to
    },
  ];

  return (
    <div>
      <div className="text-center">
        <img
          src={`${baseurl}/public/images/supportportal/myreport.png`}
          alt="track-order"
          style={{ width: "80px", height: "80px" }}
        />
        <h1 className="login-header">
          {userdetails?.username}, View your timesheet reports!
        </h1>
        <p className="sub-header">
          Review your timesheet reports with precision and clarity. Stay
          informed about your work hours,
          {isMobile ? "" : <br />}
          tasks completed, and project progress effortlessly.
        </p>
        {/* <h1 className="report-txt">Which report do you want to see?</h1> */}
      </div>
      {/* <div className="d-flex flex-wrap justify-content-center align-items-center gap-4 py-2 mx-5">
        {dashboardboxes.map((box, index) => (
          <>
            <Link to={box.linkTo} key={index}>
              <div
                className={`report-box`}
                style={{
                  cursor: "pointer",
                  height: "100px",
                  width: "100px",
                }}
              >
                <div className="mt-3" style={{ textAlign: "center" }}>
                  <img className="report-img" src={box.img} alt={box.text} />
                </div>
                <h4
                  className="report-box-text text-center mt-1 fw-bold"
                  style={{ fontSize: "10px", color: "black" }}
                >
                  {box.text}
                </h4>
              </div>
            </Link>
          </>
        ))}
      </div> */}
      {/* <div
        className={`${
          isMobile ? "mx-3" : "d-flex justify-content-around align-items-center"
        }`}
      > */}
      {/* <Col sm="12" xl="6"> */}
      <div className="d-flex justify-content-center align-items-center mx-3">
      <Row className="d-flex justify-content-around align-items-center">
        <Col sm="12" xl="5">
          <Form className="theme-form">
            <FormGroup
              className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
            >
              <Label
                className={`col-form-label timesheet-label ${
                  isMobile ? "" : ""
                }`}
              >
                From Date:
              </Label>
              <InputGroup
                className="date"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits input-register"
                  selected={from_date}
                  onChange={handleChangeFromTime}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy "
                  // minDate={moment().startOf('month').toDate()}
                  // maxDate={moment().endOf('month').toDate()}
                />
                <div
                  className="input-group-text"
                  data-target="#dt-date"
                  data-toggle="datetimepicker"
                >
                  <i className="fa fa-calendar"></i>
                </div>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>

        {/* </Col> */}

        <Col sm="12" xl="4">
          <Form className="theme-form">
            <FormGroup className={` label-gap ${isMobile ? "" : "mb-4"}`}>
              <Label
                className={`col-form-label timesheet-label ${
                  isMobile ? "" : ""
                }`}
              >
                To Date:
              </Label>
              {/* <Col xl="5" sm="7" lg="8"> */}
              <InputGroup
                className="date flex-nowrap"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits input-register"
                  selected={to_date}
                  onChange={handleChangeToTime}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy "
                  // minDate={from_date}
                  // maxDate={moment().endOf('month').toDate()}
                />
                <div
                  className="input-group-text"
                  data-target="#dt-date"
                  data-toggle="datetimepicker"
                >
                  <i className="fa fa-calendar"></i>
                </div>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>

        <Col sm="12" xl="3" className="align-items-center justify-content-center">
          {/* <Col xl="5" sm="7" lg="8"> */}
          <Button
            className="searchbtn "
            onClick={() => gettimesheetreport(from_date, to_date)}
            disabled={searchbtn}
            style={{marginTop:"10px"}}
          >
            Search
          </Button>
        </Col>
      </Row>
      </div>
      {/* </Col> */}
      {/* </div> */}
      {/* <div className="d-flex justify-content-center align-items-center">
        <Button
          className="searchbtn "
          onClick={() => gettimesheetreport(from_date, to_date)}
          disabled={searchbtn}
        >
          Search
        </Button>
      </div> */}
      {isMobile ? (
        <Container>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : timesheetdata?.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-center my-3">
                <Input
                  className="form-control register-full input-register"
                  type="text"
                  placeholder="Search all fields..."
                  value={searchtext}
                  onChange={handleSearch}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button className="searchbtn my-2">
                  <CSVLink className="export-link" data={filtertimesheetdata}>
                    Export
                  </CSVLink>
                </Button>
              </div>
              <DataTable
                //  className="custom-data-table"
                columns={columns}
                data={filtertimesheetdata}
                pagination
                paginationComponentOptions={paginationOptions}
                customStyles={customStyles}
                expandableRows
                expandableRowExpanded={(row) => row === currentRow}
                expandOnRowClicked
                onRowClicked={(row) => setCurrentRow(row)}
                onRowExpandToggled={(bool, row) => {
                  setCurrentRow(row);
                }}
                expandableRowsComponent={ExpandedComponent}
                persistTableHead={true}
              />
              <div
                className="text-end fw-bold my-2"
                style={{ color: "#098048" }}
              >
                Total Timesheet hours:{" "}
                <span className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="badge rounded-pill text-bg-success">
                    {formatHoursToTime(totalSum)}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
            </div>
          )}
        </Container>
      ) : (
        <div>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : timesheetdata.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="export-timesheet-task d-flex justify-content-between my-2">
                <div>
                  <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Search all fields..."
                    value={searchtext}
                    onChange={handleSearch}
                  />
                </div>
                <div>
                  <Button className="searchbtn my-2">
                    <CSVLink className="export-link" data={filtertimesheetdata}>
                      Export
                    </CSVLink>
                  </Button>
                </div>
              </div>
              <div style={{overflow:"auto"}}>
              <DataTable
                // className="table-extra"
                className="custom-data-table data-task"
                columns={columns}
                data={filtertimesheetdata}
                pagination
                paginationComponentOptions={paginationOptions}
                customStyles={customStyles}
                persistTableHead={true}
              />
              <div
                className="text-center fw-bold my-2"
                style={{ color: "#098048" }}
              >
                Total Timesheet hours:{" "}
                <span>
                  &nbsp;&nbsp;
                  <span className="badge rounded-pill text-bg-success">
                    {formatHoursToTime(totalSum)}
                  </span>
                </span>
              </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Timesheetreport_task;

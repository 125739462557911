import React, { useEffect, useState, useCallback } from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  createColumnHelper,
  getPaginationRowModel,
} from "@tanstack/react-table";
import FilterFunction from "./FilterFunction";
import { Table, Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "../../../axios/axios";
import Form from "react-bootstrap/Form";
import "./table.css";
import { Link, useNavigate } from "react-router-dom";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import { CSVLink } from "react-csv";
const Basictable = () => {
  const baseurl = useSelector((store) => store.baseUrl);
  const supportlist = useSelector((store) => store.supportrequest.projectdata);
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [projectdata, setProjectdata] = useState([]);
  const [filteredprojectdata, setFilteredProjectdata] = useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const columnHelper = createColumnHelper();
  const userdetails = JSON.parse(localStorage.getItem("userdetails"));
  const navigate = useNavigate();
  const columnDefWithFilter = [
    {
      accessorKey: "id",
      header: "ID",
      enableColumnFilter: false,
    },
    {
      accessorKey: "title",
      header: "Ticket Number",
    },
    {
      accessorKey: "problem_title",
      header: "Ticket Title",
    },
    {
      accessorKey: "status",
      header: "Status",
    },
    {
      accessorKey: "priority",
      header: "Priority",
    },
    {
      accessorKey: "ticket_type",
      header: "Ticket Type",
    },
    {
      accessorKey: "projectduedate",
      header: "Project Due Date",
    },
    {
      accessorKey: "category_name",
      header: "Category Name",
    },
    {
      accessorKey: "subcategory_name",
      header: "Subcategory Name",
    },
  ];

  const tableInstance = useReactTable({
    columns: columnDefWithFilter,
    data: projectdata,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnFilters: columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
  });

  React.useEffect(() => {
    console.log(tableInstance.getState().columnFilters);
  });
  useEffect(() => {
    const emp_id = JSON.parse(localStorage.getItem("employeeid"));
    console.log(emp_id);
    if (emp_id) {
      setEmpid(emp_id);
    }
  }, []);
  const getSupportRequest = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        baseurl + "/expo_access_api/search_supportrequest_api/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          created_by: emp_id,
          object_type: userdetails?.user_type,
        }
      );
      if (res && res.data && res.data[0].DATA) {
        setProjectdata(res?.data[0]?.DATA?.supportlist);
        setFilteredProjectdata(res?.data[0]?.DATA?.supportlist);
        localStorage.setItem(
          "object_type",
          res?.data[0]?.DATA?.supportlist[0]?.object_type
        );
        setIsLoading(false);
      } else {
        setProjectdata([]);
        setFilteredProjectdata([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }, []);
  useEffect(() => {
    getSupportRequest();
  }, [getSupportRequest]);

  const handleNavigateRequest = (event_id) =>{
    console.log(event_id);
    navigate(`${process.env.PUBLIC_URL}/supportdetail/${event_id}`)
  }
  return (
    <>
      {isLoading ? (
        // Display a spinner or loading indicator while data is loading
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Container fluid>
            <Row>
              <Col className="text-end">
                <Button
                  variant="success"
                  className="mb-2"
                  style={{ marginRight: "4px",background:"#00980d !important" }}
                  onClick={() =>
                    navigate(`${process.env.PUBLIC_URL}/createrequest`)
                  }
                >
                  <FaPlus /> Create New
                </Button>
                {/* <Button variant="success" className="searchbtn mb-2" style={{ background:"#005e32 !important" }}>
                  <RiFileExcel2Line />{" "}
                  <CSVLink className="export-link" data={projectdata}>
                    Export
                  </CSVLink>
                </Button> */}
              </Col>
            </Row>
            <Row>
              <Col>
                <Table className="supportlist-table"   responsive size="sm">
                  <thead>
                    {tableInstance.getHeaderGroups().map((headerEl) => (
                      <tr key={headerEl.id}>
                        {headerEl.headers.map((columnEl) => (
                          <th key={columnEl.id} colSpan={columnEl.colSpan} >
                            {columnEl.isPlaceholder ? null : (
                              <>
                                {flexRender(
                                  columnEl.column.columnDef.header,
                                  columnEl.getContext()
                                )}
                                {console.log(
                                  columnEl.column.getCanFilter(),
                                  "getcanfilter"
                                )}
                                {columnEl.column.getCanFilter() ? (
                                  <div>
                                    <FilterFunction
                                      column={columnEl.column}
                                      table={tableInstance}
                                    />
                                  </div>
                                ) : null}
                              </>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {tableInstance.getRowModel().rows.map((rowEl) => (
                      <tr key={rowEl.id}>
                        {rowEl.getVisibleCells().map((cellEl) => {
                          var badge_color = ""
                          if(cellEl.column.id === "status"){
                            console.log("Check Array");
                            console.log(cellEl.row.original.status); // Add console.log here
                            if(cellEl.row.original.status == "Discarded"){
                              badge_color = "badge-danger";
                            }else if(cellEl.row.original.status == "Pending"){
                              badge_color = "badge-info";
                            }else if(cellEl.row.original.status == "Assigned"){
                              badge_color = "badge-primary";
                            }else if(cellEl.row.original.status == "Closed"){
                              badge_color = "badge-success";
                            }else{
                              badge_color = "badge-primary";
                            }
                          }
                          return (
                            <td onClick={(e)=>handleNavigateRequest(cellEl.row.original.id)}  key={cellEl.id} style={{textAlign: (cellEl.column.id === "status" || cellEl.column.id == "priority" || cellEl.column.id == "ticket_type") ? "center" : "left",cursor:"pointer"}}>
                              {cellEl.column.id === "status" ? (
                                <span
                                  className={`badge ${badge_color}`}
                                  style={{padding:"5px 10px"}}
                                >
                                  {flexRender(
                                    cellEl.column.columnDef.cell,
                                    cellEl.getContext()
                                  )}
                                </span>
                              ) : (
                                flexRender(
                                  cellEl.column.columnDef.cell,
                                  cellEl.getContext()
                                )
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
          <hr />
          <Container fluid style={{marginBottom:"20px"}}>
            <Row>
              <Col xs={11} className="text-end">
                <Button
                  className="mx-1"
                  variant="dark"
                  size="sm"
                  onClick={() => tableInstance.setPageIndex(0)}
                  disabled={!tableInstance.getCanPreviousPage()}
                >
                  {"<<"}
                </Button>
                <Button
                  className="mx-1"
                  variant="dark"
                  size="sm"
                  onClick={() => tableInstance.previousPage()}
                  disabled={!tableInstance.getCanPreviousPage()}
                >
                  Previous
                </Button>
                <Button
                  className="mx-1"
                  variant="dark"
                  size="sm"
                  onClick={() => tableInstance.nextPage()}
                  disabled={!tableInstance.getCanNextPage()}
                >
                  Next
                </Button>
                <Button
                  className="mx-1"
                  variant="dark"
                  size="sm"
                  onClick={() =>
                    tableInstance.setPageIndex(tableInstance.getPageCount() - 1)
                  }
                  disabled={!tableInstance.getCanNextPage()}
                >
                  {">>"}
                </Button>
              </Col>
              <Col xs={1}>
                <Form.Select
                  size="sm"
                  value={tableInstance.options.state.pagination.pageSize}
                  onChange={(e) => tableInstance.setPageSize(e.target.value)}
                >
                  {[10, 25, 50].map((pageSizeEl) => (
                    <option key={pageSizeEl} value={pageSizeEl}>
                      {pageSizeEl}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Basictable;

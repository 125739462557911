import { createSlice } from "@reduxjs/toolkit";

const supportrequestSlice = createSlice({
  name: "supportrequest",
  initialState: { projectdata: [] , taskdata:[],login_logout_data: []},
  reducers: {
    additem: (state,action) => {
      state.projectdata = action.payload;
    },
    addtask: (state,action) => {
      state.taskdata = action.payload;
    },
    addlogindata: (state,action) => {
      state.login_logout_data = action.payload;
    },
  },
});

console.log(supportrequestSlice + "dfvdfvfdv");

export const { additem ,addtask,addlogindata} = supportrequestSlice.actions;
export default supportrequestSlice.reducer;

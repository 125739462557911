import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import CustomizerContext from "../../_helper/Customizer";
import { MENUITEMS } from "./Menu";
import { useDispatch } from "react-redux";
import { additem } from "../../utils/supportrequestSlice";
// import FontawesomeIcon from "../../Components/Icons/FontawesomeIcon";
import {
  FaCalendar,
  FaCalendarAlt,
  FaChartBar,
  FaClock,
  FaFileAlt,
  FaFileInvoiceDollar,
  FaRupeeSign,
  FaSignOutAlt,
  FaTasks,
  FaUser,
} from "react-icons/fa";
import { AiFillDashboard } from "react-icons/ai";
import { IoIosPerson, IoMdCalendar } from "react-icons/io";
import { MdAssignment, MdPause } from "react-icons/md";
import { TiTime } from "react-icons/ti";
import { ToastContainer } from "react-toastify";
import { FiLogIn } from "react-icons/fi";
import Swal from "sweetalert2";
import {
  FaClipboardList,
  FaMinus,
  FaListUl,
  FaUserMinus,
  FaListAlt,
  FaClipboardCheck,
} from "react-icons/fa";

const SidebarMenuItems = ({
  setMainMenu,
  sidebartoogle,
  setNavActive,
  activeClass,
}) => {
  const { layout } = useContext(CustomizerContext);
  const layout1 = localStorage.getItem("sidebar_layout") || layout;
  const is_admin = localStorage.getItem("is_admin");
  console.log(typeof is_admin, "isadmin......sidebar");
  const IS_POS = process.env.REACT_APP_IS_POS;

  const id = window.location.pathname.split("/").pop();
  const layoutId = id;
  const CurrentPath = window.location.pathname;
  const history = useNavigate();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  console.log(selectedItem, "selectedItem..");
  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  // useEffect(() => {
  //   // Get the pathname from window.location
  //   const pathname = window.location.hash;

  //   // Remove the leading '#/cuba-context/' from the pathname
  //   const sidebarname = pathname.replace("#/cuba-context/", "");
  //   console.log(sidebarname, "trimmedPathname...");
  //   const setTimesheetadmin = (name) => {
  //     switch (name) {
  //       case "timesheetportal":
  //         return 0;
  //       case "viewproject":
  //         return 1;
  //       case "viewtask":
  //         return 2;
  //       case "timesheetreport":
  //         return 3;
  //       case "timesheetreport-task":
  //         return 3;
  //       case "workinghoursreport":
  //         return 4;
  //       case "workinghoursreport-task":
  //         return 4;
  //       case "loginhoursreport":
  //         return 5;
  //       case "missinglogoutreport":
  //         return 6;
  //       case "approvalslist":
  //         return 8;
  //       case "breakhoursreport":
  //         return 9;
  //       case "attendencereport":
  //         return 10;
  //       // Add more cases for POS as needed
  //       default:
  //         return null;
  //     }
  //   };

  //   // Function to set selectedItem for IS_POS != 0
  //   const setTimesheetemployee = (name) => {
  //     switch (name) {
  //       case "approvalslist":
  //         return 10;
  //       case "dashboard":
  //         return 11;
  //       // Add more cases for non-POS as needed
  //       default:
  //         return null;
  //     }
  //   };

  //   // Set selectedItem based on sidebarname and IS_POS
  //   setSelectedItem(
  //     IS_POS == "0"
  //       ? setTimesheetadmin(sidebarname)
  //       : setTimesheetemployee(sidebarname)
  //   );
  // }, []);

  const { t } = useTranslation();
  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon";

      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper";
      }
    }

    if (!item.active) {
      MENUITEMS.forEach((a) => {
        a.Items.forEach((menuItem) => {
          if (menuItem === item) {
            menuItem.active = false;
          } else if (menuItem.children) {
            menuItem.children.forEach((childItem) => {
              if (childItem === item) {
                childItem.active = false;
                menuItem.active = true; // Mark the parent as active if a child is deactivated
              } else if (childItem.children) {
                childItem.children.forEach((grandchildItem) => {
                  if (grandchildItem === item) {
                    grandchildItem.active = false;
                    childItem.active = true; // Mark the parent as active if a grandchild is deactivated
                    menuItem.active = true; // Mark the grandparent as active
                  }
                });
              }
            });
          }
        });
      });
    }

    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const Logout = () => {
    if (
      JSON.parse(localStorage.getItem("punchin")) == null &&
      JSON.parse(localStorage.getItem("punchout")) == null
    ) {
      console.log("if loop");
      // Default case when punchincheck is true
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      localStorage.removeItem("company_logo_pos");
      localStorage.removeItem("contactid");
      localStorage.removeItem("timesheet_enable_on_login");
      history(`${process.env.PUBLIC_URL}/login`);
      dispatch(additem([]));
      // Add any default behavior here
    } else if (
      JSON.parse(localStorage.getItem("punchin")) == false &&
      JSON.parse(localStorage.getItem("punchout")) == true
    ) {
      // Case when punchincheck is false, but punchoutcheck is true
      console.log("first else if loop");
      Swal.fire(
        "Please remember to perform a punch-out before logging out of the system!"
      );
    } else if (
      JSON.parse(localStorage.getItem("punchin")) == false &&
      JSON.parse(localStorage.getItem("punchout")) == false
    ) {
      console.log("second elseif loop");
      // Case when both punchincheck and punchoutcheck are false
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      localStorage.removeItem("company_logo_pos");
      localStorage.removeItem("contactid");
      localStorage.removeItem("timesheet_enable_on_login");
      history(`${process.env.PUBLIC_URL}/login`);
      dispatch(additem([]));
    } else if (JSON.parse(localStorage.getItem("punchin")) == true) {
      console.log("second elseif loop");
      // Case when both punchincheck and punchoutcheck are false
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      localStorage.removeItem("company_logo_pos");
      localStorage.removeItem("contactid");
      localStorage.removeItem("timesheet_enable_on_login");
      history(`${process.env.PUBLIC_URL}/login`);
      dispatch(additem([]));
    }
  };

  // Then call the handleLogout function when the logout button is clicked.

  return (
    <>
      {console.log("render sidebar")}
      {MENUITEMS.map((Item, i) => (
        <Fragment key={i}>
          <li className="sidebar-main-title">
            <div>
              <h6 className="lan-1">{t(Item.menutitle)}</h6>
            </div>
          </li>
          {Item.Items.map((menuItem, i) => (
            <li className="sidebar-list" key={i}>
              {menuItem.type === "sub" ? (
                <a
                  href="javascript"
                  className={`sidebar-link sidebar-title ${
                    CurrentPath.includes(menuItem.title.toLowerCase())
                      ? "active"
                      : ""
                  } ${i === selectedItem ? "sidebar-selected" : ""}`}
                  onClick={(event) => {
                    if (
                      menuItem?.title != "Dashboard" ||
                      menuItem?.title != "My projects"
                    ) {
                      event.preventDefault();
                      setNavActive(menuItem);
                      activeClass(menuItem.active);
                      handleItemClick(i);
                    }
                  }}
                >
                  {menuItem.title == "Timesheet report" && (
                    <FaCalendar
                      className={`sidebar-icon ${
                        i === selectedItem ? "sidebar-selected" : ""
                      }`}
                    />
                  )}
                  {menuItem.title == "Working hours" && (
                    <FaClock
                      className={`sidebar-icon ${
                        i === selectedItem ? "sidebar-selected" : ""
                      }`}
                    />
                  )}
                  {/* <SvgIcon
                    className="stroke-icon"
                    iconId={`stroke-${menuItem.icon}`}
                  />
                  <SvgIcon
                    className="fill-icon"
                    iconId={`fill-${menuItem.icon}`}
                  /> */}
                  <span
                    className={`${
                      i === selectedItem ? "sidebar-selected" : ""
                    }`}
                    style={{ fontSize: "13px" }}
                  >
                    {t(menuItem.title)}
                  </span>
                  {menuItem.badge ? (
                    <label className={menuItem.badge}>
                      {menuItem.badgetxt}
                    </label>
                  ) : (
                    ""
                  )}
                  <div className="according-menu">
                    {menuItem.active ? (
                      <i
                        className={`fa fa-angle-down ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      ></i>
                    ) : (
                      <i
                        className={`fa fa-angle-right ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      ></i>
                    )}
                  </div>
                </a>
              ) : (
                ""
              )}

              {menuItem.type === "link" ? (
                <Link
                  to={menuItem.path}
                  className={`sidebar-link sidebar-title link-nav  ${
                    CurrentPath.includes(menuItem.title.toLowerCase())
                      ? "active"
                      : ""
                  } ${i === selectedItem ? "sidebar-selected" : ""}`}
                  onClick={() => {
                    if (menuItem?.title == "Logout") {
                      console.log("logout call");
                      Logout();
                      toggletNavActive(menuItem);
                      handleItemClick(i);
                    } else {
                      toggletNavActive(menuItem);
                      handleItemClick(i);
                    }
                  }}
                >
                  <>
                    {menuItem.title == "Dashboard" && (
                      <FaChartBar
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "My projects" && (
                      <FaTasks
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "My tasks" && (
                      <MdAssignment
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "Working hours" && (
                      <FaClock
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "My login hours" && (
                      <FiLogIn
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "Missing Logout list" && (
                      <FaClipboardList
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "My Absent list" && (
                      <FaUserMinus
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "Leave Approval list" && (
                      <FaClipboardCheck
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "My break hours" && (
                      <MdPause
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "Timesheet report" && (
                      <FaCalendar
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "Attendence Report" && (
                      <IoMdCalendar
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "Monthly Timesheet Report" && (
                      <FaCalendarAlt
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "Employeewise Report" && (
                      <FaUser
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                    {menuItem.title == "Logout" && (
                      <FaSignOutAlt
                        className={`sidebar-icon ${
                          i === selectedItem ? "sidebar-selected" : ""
                        }`}
                      />
                    )}
                  </>
                  {/* <SvgIcon
                    className="stroke-icon"
                    iconId={`stroke-${menuItem.icon}`}
                  />
                  <SvgIcon
                    className="fill-icon"
                    iconId={`fill-${menuItem.icon}`}
                  /> */}
                  {}
                  <span
                    className={`${
                      i === selectedItem ? "sidebar-selected" : ""
                    }`}
                    style={{ fontSize: "13px" }}
                  >
                    {t(menuItem.title)}
                  </span>
                  {menuItem.badge ? (
                    <label className={menuItem.badge}>
                      {menuItem.badgetxt}
                    </label>
                  ) : (
                    ""
                  )}
                </Link>
              ) : (
                ""
              )}

              {menuItem.children ? (
                <ul
                  className="sidebar-submenu"
                  style={
                    layout1 !== "compact-sidebar compact-small"
                      ? menuItem?.active ||
                        CurrentPath.includes(menuItem?.title?.toLowerCase())
                        ? sidebartoogle
                          ? { opacity: 1, transition: "opacity 500ms ease-in" }
                          : { display: "block" }
                        : { display: "none" }
                      : { display: "none" }
                  }
                >
                  {menuItem.children.map((childrenItem, index) => {
                    return (
                      <li key={index}>
                        {childrenItem.type === "sub" ? (
                          <a
                            href="javascript"
                            className={`${
                              CurrentPath.includes(
                                childrenItem?.title?.toLowerCase()
                              )
                                ? "active"
                                : ""
                            }`}
                            // className={`${childrenItem.active ? 'active' : ''}`}
                            onClick={(event) => {
                              console.log("link com......call....55");
                              event.preventDefault();
                              toggletNavActive(childrenItem);
                            }}
                          >
                            {t(childrenItem.title)}
                            <span className="sub-arrow">
                              <i className="fa fa-chevron-right"></i>
                            </span>
                            <div className="according-menu">
                              {childrenItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : (
                          ""
                        )}

                        {childrenItem.type === "link" ? (
                          <Link
                            to={childrenItem.path}
                            className={`${
                              CurrentPath.includes(
                                childrenItem?.title?.toLowerCase()
                              )
                                ? "active"
                                : ""
                            } `}
                            // className={`${childrenItem.active ? 'active' : ''}`} bonusui
                            onClick={(event) => {
                              console.log("link com......call....");
                              // event.preventDefault();
                              toggletNavActive(childrenItem);
                            }}
                          >
                            <span style={{ fontSize: "13px" }}>
                              {t(childrenItem.title)}
                            </span>
                          </Link>
                        ) : (
                          ""
                        )}

                        {childrenItem.children ? (
                          <ul
                            className="nav-sub-childmenu submenu-content"
                            style={
                              CurrentPath.includes(
                                childrenItem?.title?.toLowerCase()
                              ) || childrenItem.active
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {childrenItem.children.map(
                              (childrenSubItem, key) => (
                                <li key={key}>
                                  {childrenSubItem.type === "link" ? (
                                    <Link
                                      to={childrenSubItem.path}
                                      className={`${
                                        CurrentPath.includes(
                                          childrenSubItem?.title?.toLowerCase()
                                        )
                                          ? "active"
                                          : ""
                                      } ${
                                        i === selectedItem
                                          ? "sidebar-selected"
                                          : ""
                                      }`}
                                      // className={`${childrenSubItem.active ? 'active' : ''}`}
                                      onClick={() => {
                                        console.log(
                                          "link com......call....444"
                                        );
                                        toggletNavActive(childrenSubItem);
                                      }}
                                    >
                                      <span
                                        className={`${
                                          i === selectedItem
                                            ? "sidebar-selected"
                                            : ""
                                        }`}
                                      >
                                        {t(childrenSubItem.title)}
                                      </span>
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </li>
          ))}
          {/* <ToastContainer /> */}
        </Fragment>
      ))}
    </>
  );
};

export default SidebarMenuItems;

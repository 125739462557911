import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Alerts, H5 } from "../../AbstractElements";
import { Label, Input, FormGroup } from "reactstrap";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { useSelector } from "react-redux";
//import CKEditor from "react-ckeditor-component";
import Select from "react-select";
import Dropzone from "react-dropzone-uploader";
import axios from "../../axios/axios";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
//import EditorComponent from "./Basic/EditorComponent";
import AddAddess from "./Basic/AddAddress";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useRecoderFile from "./useRecoderFile";
import support_requests from "../../../src/assets/images/supportrequest/support_requests.png";
import moment, { duration } from "moment";
const CreateSupportRequest = () => {
  const [
    audioURL1,
    setAudioURL1,
    isRecording1,
    startRecording1,
    stopRecording1,
    blobData1,
    setBlobData1,
  ] = useRecoderFile();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const branch_arr = useSelector((store) => store.branches.branchlist);
  const company_arr = useSelector((store) => store.company.companylist);
  const category_arr = useSelector(
    (store) => store.supportcategory.categorylist
  );
  const subcategory_arr = useSelector(
    (store) => store.supportsubcategory.subcategorylist
  );
  const priority_arr = useSelector(
    (store) => store.supportpriority.prioritylist
  );
  const type_arr = useSelector((store) => store.supporttype.supporttypelist);
  const follower_arr = useSelector((store) => store.followerlist.followerlist);
  const product_arr = useSelector((store) => store.products.products);
  const contract_arr = useSelector((store) => store.contracts.contractlist);
  const contact_arr = useSelector((store) => store.contacts.contactlist);
  const status_arr = useSelector(
    (store) => store.supportstatus.supportstatuslist
  );
  const supportmode_arr = useSelector(
    (store) => store.supportmode.supportmodelist
  );
  const employee_arr = useSelector((store) => store.employee.employeelist);

  const userdetails = JSON.parse(localStorage.getItem("userdetails"));
  const [contenteditor, setContentEditor] = useState("");
  const [supsubcategory, setSupportSubCategory] = useState([]);
  const [category_id, setCategoryID] = useState("");
  const [subcategory_id, setSubCategoryID] = useState("");
  const [solutions, setSolutions] = useState([]);
  const [address, setAddress] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  const [addressmodalShow, setAddressModalShow] = useState(false);
  const [contact_id, setContactID] = useState(
    userdetails?.user_type == 1 ? userdetails?.employee_id : ""
  );
  const [contact_name, setContactName] = useState(
    userdetails?.user_type == 1 ? userdetails?.username : ""
  );
  const [contact_mobile, setContactMobile] = useState(
    userdetails?.user_type == 1 ? userdetails?.mobile_no : ""
  );
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedFollower, setSelectedFollower] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selEditAddress, setSelEditAddress] = useState(null);
  const [selRandomNumber, setSelRandomNumber] = useState(0);
  const [receivedDate, setReceivedDate] = React.useState(new Date());
  const [estimatedDate, setEstimatedDate] = React.useState(new Date());
  const [content, setContent] = useState("");
  const [pageerror, setPageError] = useState({
    contact_error: "",
    description_error: "",
    category_error: "",
    subcategory_error: "",
  });
  const baseurl = useSelector((store) => store.baseUrl);
  const navigate = useNavigate();
  const handleSelectContractChange = (selectedOption) => {
    setSelectedContract(selectedOption);
  };
  const handleSelectProductChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
  };
  const handleSelectFollowerChange = (selectedOption) => {
    setSelectedFollower(selectedOption);
  };
  const handleSelectContactChange = (selectedOption) => {
    setSelectedContact(selectedOption);
    const contact_selected_id = selectedOption.value;
    setContactID(contact_selected_id);
    setContactName(selectedOption.label);
    setContactMobile(selectedOption.mobile_no);
  };
  const handleSelectEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };
  useEffect(() => {
    axios
      .post("/expo_access_api/getContactBillingAddressForBot/", {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        PHPTOKEN: localStorage.getItem("id_token"),
        contact_id: contact_id,
        contact_type: 1,
      })
      .then((response) => {
        if (response.data[0].STATUS === "SUCCESS") {
          setAddress(response.data[0].DATA);
        } else {
          setAddress([]);
        }
      });
  }, [contact_id, selRandomNumber]);
  const AUTHORIZE_KEY = process.env.REACT_APP_AUTHORIZE_KEY;
  const productoptions =
    Array.isArray(product_arr) && product_arr.length > 0
      ? product_arr.map((prod) => ({
          value: prod.products.product_id,
          label: prod.products.name,
        }))
      : [];
  const contractoptions =
    Array.isArray(contract_arr) && contract_arr.length > 0
      ? contract_arr.map((cntrr) => ({
          value: cntrr.contract_id,
          label: cntrr.fullcontractnumber,
        }))
      : [];
  const contactoptions =
    Array.isArray(contact_arr) && contact_arr.length > 0
      ? contact_arr.map((cont) => ({
          value: cont.contact_id,
          label: cont.name,
          mobile_no: cont.mobile_no,
        }))
      : [];
  const employeeoptions =
    Array.isArray(employee_arr) && employee_arr.length > 0
      ? employee_arr.map((empp) => ({
          value: empp.Employee.employee_id,
          label: empp.Employee.name,
        }))
      : [];
  const handleEditorOnChange = (evt) => {
    const newContent = evt.editor.getData();
    setContentEditor(newContent);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#B6D8C5" : "#B6D8C5", // Change border color based on focus
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(182, 216, 197, 0.25)"
        : null, // Optional: Add focus box shadow
    }),
  };
  const handleSubcategory = (e) => {
    const targetCatId = e.target.value;
    setCategoryID(targetCatId);
    setSubCategoryID("");
    console.log(targetCatId);
    if (targetCatId > 0) {
      const filteredDataArray = subcategory_arr?.filter(
        (item) => item.category_id === targetCatId
      );
      setSupportSubCategory(filteredDataArray);
    }
  };
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      setUploadedFiles((prevFiles) => [...prevFiles, file]);
    } else if (status === "removed") {
      setUploadedFiles((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile.meta?.id !== meta?.id)
      );
    }
  };
  const handleChangeSubcategory = (e) => {
    const subcategory = e.target.value;
    setSubCategoryID(subcategory);
  };
  const handleChangeReciveDate = (date) => {
    setReceivedDate(date);
  };
  const handleChangeEstimatedDate = (date) => {
    setEstimatedDate(date);
  };
  useEffect(() => {
    axios
      .post("/supportportalapi/get_support_request_solutions/", {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        PHPTOKEN: localStorage.getItem("id_token"),
        category_id: category_id,
        subcategory_id: subcategory_id,
      })
      .then((response) => {
        if (response.data[0].STATUS === "SUCCESS") {
          setSolutions(response.data[0].DATA);
        } else {
          setSolutions([]);
        }
      });
  }, [category_id, subcategory_id]);

  useEffect(() => {
    if (userdetails?.user_type == 1) {
      axios
        .post("/expo_access_api/getContactBillingAddressForBot/", {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          PHPTOKEN: localStorage.getItem("id_token"),
          contact_id: userdetails?.employee_id,
          contact_type: userdetails?.user_type,
        })
        .then((response) => {
          if (response.data[0].STATUS === "SUCCESS") {
            console.log(response.data[0].DATA);
            setAddress(response.data[0].DATA);
          } else {
            setAddress([]);
          }
        });
    }
  }, []);

  const onSubmit = (data) => {
    const startDate = moment(receivedDate).format("DD-MM-YY hh:mm");
    const dueDate = moment(estimatedDate).format("DD-MM-YY hh:mm");
    const frm_contact_id =
      userdetails?.user_type == 1
        ? userdetails?.employee_id
        : selectedContact?.value;
    let error_cnt = 0;
    if (
      frm_contact_id == "" ||
      frm_contact_id == "0" ||
      frm_contact_id === undefined
    ) {
      setPageError((prevErrors) => ({
        ...prevErrors,
        contact_error: "Contact cannot be blank",
      }));
      error_cnt = 1;
    } else {
      setPageError((prevErrors) => ({
        ...prevErrors,
        contact_error: "",
      }));
    }
    if (content.trim() === "") {
      setPageError((prevErrors) => ({
        ...prevErrors,
        description_error: "Description cannot be blank",
      }));
      error_cnt = 1;
    } else {
      setPageError((prevErrors) => ({
        ...prevErrors,
        description_error: "",
      }));
    }
    if (category_id == "" || category_id == "0") {
      setPageError((prevErrors) => ({
        ...prevErrors,
        category_error: "Category cannot be blank",
      }));
      error_cnt = 1;
    } else {
      setPageError((prevErrors) => ({
        ...prevErrors,
        category_error: "",
      }));
    }
    if (subcategory_id == "" || subcategory_id == "0") {
      setPageError((prevErrors) => ({
        ...prevErrors,
        subcategory_error: "Sub-Category cannot be blank",
      }));
      error_cnt = 1;
    } else {
      setPageError((prevErrors) => ({
        ...prevErrors,
        subcategory_error: "",
      }));
    }
    if (error_cnt > 0) {
      return false;
    }
    const count_file = uploadedFiles.length;
    const headers = {
      "content-type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("attachment", uploadedFiles || "");
    formData.append("AUTHORIZEKEY", AUTHORIZE_KEY);
    formData.append("PHPTOKEN", localStorage.getItem("id_token"));
    formData.append("company_id", data.company_id || "");
    formData.append("branch_id", data.branch_id || "");
    formData.append("user_type", userdetails?.user_type || "");
    formData.append(
      "assigned_id",
      userdetails?.user_type == 5 ? selectedEmployee?.value : ""
    );
    formData.append("contact_type", 1);
    formData.append("contact_id", frm_contact_id || "");
    formData.append("category_id", category_id || "");
    formData.append("subcategory_id", subcategory_id || "");
    formData.append("priority_id", data.priority_id || "");
    formData.append("title", data.title || "");
    formData.append("description", content || ""); //content //data.description
    formData.append("product_id", selectedProduct?.value || "");
    formData.append("mode_id", data.mode_id || "");
    formData.append("contract_id", selectedContract?.value || "");
    formData.append("status_id", data.status_id || "");
    formData.append("type_id", data.supporttype_id || "");
    formData.append("followers", JSON.stringify(selectedFollower));
    formData.append("chk_solution_id", data.solutionsid || "");
    formData.append("support_request_file_count", count_file || "");
    formData.append("start_date", startDate || "");
    formData.append("due_date", dueDate || "");
    if (blobData1.size > 0) {
      formData.append("botFile", blobData1);
    }
    for (var v = 0; v < count_file; v++) {
      var num = v + 1;
      formData.append(
        "SupportFile" + num,
        uploadedFiles[v],
        uploadedFiles[v].name
      );
    }
    axios
      .post("/supportportalapi/SaveSupportReq/", formData, headers)
      .then((response) => {
        if (response.data[0].STATUS === "SUCCESS") {
          toast.success(`${userdetails?.support_label} added Succesfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate(`${process.env.PUBLIC_URL}/supportrequestlist`);
        } else {
          toast.error("something gone wrong", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  const onChangeEditor = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };
  const handleDeleteAddress = (address_id, e) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure? You want to delete this address",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios
          .post("/expo_access_api/deleteContactAddressForBot/", {
            AUTHORIZEKEY: AUTHORIZE_KEY,
            address_id: address_id,
            contact_type: 1,
          })
          .then((response) => {
            if (response.data[0].STATUS === "SUCCESS") {
              Swal.fire("Address Deleted!", "", "success");
            } else {
              Swal.fire(response.data[0].MSG);
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handleEditAddress = (address_id) => {
    setSelEditAddress(address_id);
    setAddressModalShow(true);
  };
  return (
    <>
      <div className="text-center">
        <img
          src={`${baseurl}/public/complainportal${support_requests}`}
          alt="track-order"
          style={{ width: "auto", height: "200px" }}
        />
        <h1 className="login-header">
          {userdetails?.username}, Create new {userdetails?.support_label}!
        </h1>
        <p className="sub-header">
          Initiate a New {userdetails?.support_label} and let us assist you promptly – your
          satisfaction is our priority.
        </p>
      </div>
      <Container className="mt-5" style={{ marginBottom: "10px" }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <div className="flex-container">
                <div className="flex-item">
                  <Form.Label className="form-label-secondary">
                    <span style={{ color: "red" }}>*</span> Associated Company :
                  </Form.Label>
                </div>
                <div className="flex-item">
                  <Form.Select
                    size="sm"
                    {...register("company_id", { required: true })}
                    className="flex-fill" // Added flex-fill class to expand the select input
                    style={{
                      border: "none",
                      background: "transparent",
                      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath d='M4 6l4-4H0z'/%3e%3c/svg%3e")`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right .75rem center",
                      backgroundSize: "8px 8px",
                      paddingRight: "10px", // Adjust this value to make space for the arrow
                      boxShadow: "none",
                      margin: 0, // Remove margin
                      padding: 0, // Remove padding
                    }}
                  >
                    <option value="">Select</option>
                    {Array.isArray(company_arr) &&
                      company_arr.length > 0 &&
                      company_arr?.map((cr, j) => (
                        <option value={cr.company_id} key={j}>
                          {cr.name}{" "}
                        </option>
                      ))}
                  </Form.Select>
                  <div className="error-message">
                    {errors.company_id && <p>Company Required</p>}
                  </div>
                </div>
                <div className="flex-item">
                  <Form.Label className="form-label-secondary">
                    <span style={{ color: "red" }}>*</span> Branch :
                  </Form.Label>
                </div>
                <div className="flex-item">
                  <Form.Select
                    size="sm"
                    {...register("branch_id", { required: true })}
                    className="flex-fill" // Added flex-fill class to expand the select input
                    style={{
                      border: "none",
                      background: "transparent",
                      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath d='M4 6l4-4H0z'/%3e%3c/svg%3e")`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right .75rem center",
                      backgroundSize: "8px 8px",
                      paddingRight: "10px", // Adjust this value to make space for the arrow
                      boxShadow: "none",
                      margin: 0, // Remove margin
                      padding: 0, // Remove padding
                    }}
                  >
                    <option value="">Select</option>
                    {Array.isArray(branch_arr) &&
                      branch_arr.length > 0 &&
                      branch_arr?.map((br, i) => (
                        <option value={br.branch_id} key={i}>
                          {br.name}{" "}
                        </option>
                      ))}
                  </Form.Select>
                  <div className="error-message">
                    {errors.branch_id && <p>Branch Required</p>}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {userdetails?.user_type == 5 && (
              <>
                <Col sm="2" className="mb-3">
                  <Form.Label className="form-label-secondary">
                    <span style={{ color: "red" }}>*</span> Contacts:
                  </Form.Label>
                </Col>
                <Col sm="4" className="mb-3">
                  <Select
                    styles={customStyles}
                    options={[
                      { value: "", label: "Select an option" }, // Default option
                      ...contactoptions, // Spread the rest of the options
                    ]}
                    className="js-example-basic-single col-sm-12"
                    onChange={handleSelectContactChange}
                    value={selectedContact}
                  />
                  <div className="error-message">
                    {pageerror.contact_error && (
                      <p>{pageerror.contact_error}</p>
                    )}
                  </div>
                </Col>
              </>
            )}
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                <span style={{ color: "red" }}>*</span> Title:
              </Form.Label>
            </Col>
            <Col sm={userdetails?.user_type == 5 ? "4" : "10"} className="mb-3">
              <Form.Control
                size="sm"
                type="text"
                placeholder={`Enter ${userdetails?.support_label} title.`}
                {...register("title", { required: true })}
              />
              <div className="error-message">
                {errors.title && <p>Title is Required</p>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                <span style={{ color: "red" }}>*</span> Support Description:
              </Form.Label>
            </Col>
            <Col sm="10" className="mb-3">
              {/*<Form.Control
                size="sm"
                type="textarea"
                placeholder="Enter description."
                {...register("description")}
              />*/}
              {/* <CKEditor
                content={content}
                events={{
                  change: onChangeEditor,
                }}
              /> */}
              <ReactQuill theme="snow" value={content} onChange={setContent} />
              <div className="error-message">
                {pageerror.description_error && (
                  <p>{pageerror.description_error}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                Select Contract (If Applicable):
              </Form.Label>
            </Col>
            <Col sm="10" className="mb-3">
              <Select
                styles={customStyles}
                options={[
                  { value: "", label: "Select an option" }, // Default option
                  ...contractoptions, // Spread the rest of the options
                ]}
                className="js-example-basic-single col-sm-12"
                onChange={handleSelectContractChange}
                value={selectedContract}
                //isMulti
              />
            </Col>
          </Row>
          <Row>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                <span style={{ color: "red" }}>*</span> Category:
              </Form.Label>
            </Col>
            <Col sm="4" className="mb-3">
              <Form.Select
                size="sm"
                onChange={handleSubcategory}
                //{...register("category_id")}
              >
                <option>Select</option>
                {category_arr?.map((cat, cc) => (
                  <option value={cat.category_id} key={cc}>
                    {cat.category_name}{" "}
                  </option>
                ))}
              </Form.Select>
              <div className="error-message">
                {pageerror.category_error && <p>{pageerror.category_error}</p>}
              </div>
            </Col>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                <span style={{ color: "red" }}>*</span> Sub-Category:
              </Form.Label>
            </Col>
            <Col sm="4" className="mb-3">
              <Form.Select
                size="sm"
                onChange={handleChangeSubcategory}
                //{...register("subcategory_id")}
              >
                <option>Select</option>
                {supsubcategory.length > 0 &&
                  supsubcategory?.map((subcat, scc) => (
                    <option value={subcat.subcategory_id} key={scc}>
                      {subcat.subcategory_name}{" "}
                    </option>
                  ))}
              </Form.Select>
              <div className="error-message">
                {pageerror.subcategory_error && (
                  <p>{pageerror.subcategory_error}</p>
                )}
              </div>
            </Col>
          </Row>
          {solutions.length > 0 && (
            <Row>
              <Col
                md={{ span: 9, offset: 3 }}
                onClick={() => setLgShow(true)}
                style={{ cursor: "pointer" }}
              >
                <Alerts attrAlert={{ color: "warning dark" }}>
                  <h6 style={{ textAlign: "center", color: "#005e32" }}>
                    {`${solutions.length} Solution articles found for selected Category & Sub Category.`}
                  </h6>
                </Alerts>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">Product:</Form.Label>
            </Col>
            <Col sm="4" className="mb-3">
              <Select
                styles={customStyles}
                //options={productoptions}
                options={[
                  { value: "", label: "Select an option" }, // Default option
                  ...productoptions, // Spread the rest of the options
                ]}
                className="js-example-basic-single col-sm-12"
                onChange={handleSelectProductChange}
                value={selectedProduct}
                //isMulti
              />
            </Col>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                Serial No:
              </Form.Label>
            </Col>
            <Col sm="4" className="mb-3">
              <Form.Control
                size="sm"
                type="text"
                placeholder="Enter Serial No."
                {...register("serialno")}
              />
            </Col>
          </Row>
          {userdetails?.user_type == 5 && (
            <>
              <Row>
                <Col sm="2" className="mb-3">
                  <Form.Label className="form-label-secondary">
                    Support Mode :
                  </Form.Label>
                </Col>
                <Col sm="4" className="mb-3">
                  <Form.Select size="sm" {...register("mode_id")}>
                    <option>Select</option>
                    {supportmode_arr?.map((summm, ipmm) => (
                      <option value={summm.mode_id} key={ipmm}>
                        {summm.mode_name}{" "}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col sm="2" className="mb-3">
                  <Form.Label className="form-label-secondary">
                    <span style={{ color: "red" }}>*</span> Support Status:
                  </Form.Label>
                </Col>
                <Col sm="4" className="mb-3">
                  <Form.Select
                    size="sm"
                    {...register("status_id", { required: true })}
                  >
                    <option value="">Select</option>
                    {status_arr?.map((stttt, itst) => (
                      <option value={stttt.ss_id} key={itst}>
                        {stttt.ss_name}{" "}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="error-message">
                    {errors.status_id && <p>Support Status is Required</p>}
                  </div>
                </Col>
              </Row>
              {/* Support Employee */}
              <Row>
                <Col sm="2" className="mb-3">
                  <Form.Label className="form-label-secondary">
                    Received Date :
                  </Form.Label>
                </Col>
                <Col sm="4" className="mb-3">
                  <DatePicker
                    className="form-control digits"
                    selected={receivedDate}
                    onChange={handleChangeReciveDate}
                  />
                </Col>
                <Col sm="2" className="mb-3">
                  <Form.Label className="form-label-secondary">
                    Estimated Due Date :
                  </Form.Label>
                </Col>
                <Col sm="4" className="mb-3">
                  <DatePicker
                    className="form-control digits"
                    selected={estimatedDate}
                    onChange={handleChangeEstimatedDate}
                  />
                </Col>
              </Row>
              {/* Support Employee End */}
            </>
          )}
          <Row>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                <span style={{ color: "red" }}>*</span> Priority:
              </Form.Label>
            </Col>
            <Col sm="4" className="mb-3">
              <Form.Select
                size="sm"
                {...register("priority_id", { required: true })}
              >
                <option value="">Select</option>
                {priority_arr?.map((pr, ip) => (
                  <option value={pr.priority_id} key={ip}>
                    {pr.priority_name}{" "}
                  </option>
                ))}
              </Form.Select>
              <div className="error-message">
                {errors.priority_id && <p>Priority is Required</p>}
              </div>
            </Col>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                <span style={{ color: "red" }}>*</span> Support Type:
              </Form.Label>
            </Col>
            <Col sm="4" className="mb-3">
              <Form.Select
                size="sm"
                {...register("supporttype_id", { required: true })}
              >
                <option value="">Select</option>
                {type_arr?.map((prt, it) => (
                  <option value={prt.type_id} key={it}>
                    {prt.type_name}{" "}
                  </option>
                ))}
              </Form.Select>
              <div className="error-message">
                {errors.supporttype_id && <p>Support Type is Required</p>}
              </div>
            </Col>
          </Row>
          {contact_id > 0 && (
            <Row>
              <Col sm="2" className="mb-3">
                <Form.Label className="form-label-secondary">
                  Address:
                </Form.Label>
              </Col>
              <Col sm="10" className="mb-3">
                {address.length > 0 &&
                  address?.map((add, scc_add) => (
                    <FormGroup className="m-t-15 custom-radio-ml" key={scc_add}>
                      <div className="radio radio-primary">
                        <Input
                          id={`add-${add.address_id}`}
                          className="radio-button"
                          type="radio"
                          //name="radio1"
                          value={add.address_id}
                          {...register("address_id")}
                          defaultChecked
                        />
                        <Label for={`add-${add.address_id}`}>
                          {Option}
                          <span className="digits">
                            <strong>
                              {add.nickname} ({contact_name} - {contact_mobile})
                            </strong>
                          </span>
                          <span className="digits">
                            {add.address_1 && add.address_1.trim() !== ""
                              ? `${add.address_1},`
                              : ""}
                            {add.address_2 && add.address_2.trim() !== ""
                              ? `${add.address_2},`
                              : ""}
                            {add.area && add.area.trim() !== ""
                              ? `${add.area},`
                              : ""}
                            {add.city_name && add.city_name.trim() !== ""
                              ? `${add.city_name},`
                              : ""}
                            {add.state && add.state.trim() !== ""
                              ? `${add.state},`
                              : ""}
                            {add.zipcode && add.zipcode.trim() !== ""
                              ? `${add.zipcode},`
                              : ""}
                            {add.country && add.country.trim() !== ""
                              ? `${add.country},`
                              : ""}
                          </span>
                        </Label>
                        <RiDeleteBinLine
                          className="delete-button"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            handleDeleteAddress(add.address_id, e)
                          }
                        />
                        <MdModeEdit
                          className="edit-button"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleEditAddress(add.address_id)}
                        />
                      </div>
                    </FormGroup>
                  ))}

                <Button
                  variant="link"
                  onClick={() => {
                    setAddressModalShow(true);
                    setSelEditAddress("");
                  }}
                  className="form-label-secondary"
                >
                  Add New Address
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            {userdetails?.user_type == 5 && (
              <>
                <Col sm="2" className="mb-3">
                  <Form.Label className="form-label-secondary">
                    Assigned To:
                  </Form.Label>
                </Col>
                <Col sm="4" className="mb-3">
                  <Select
                    styles={customStyles}
                    options={[
                      { value: "", label: "Select an option" }, // Default option
                      ...employeeoptions, // Spread the rest of the options
                    ]}
                    //options={employeeoptions}
                    className="js-example-basic-single col-sm-12"
                    onChange={handleSelectEmployeeChange}
                    value={selectedEmployee}
                  />
                </Col>
              </>
            )}
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                Add Followers:
              </Form.Label>
            </Col>
            <Col sm={userdetails?.user_type == 5 ? "4" : "10"} className="mb-3">
              <Select
                styles={customStyles}
                options={follower_arr}
                className="js-example-basic-single col-sm-12"
                isMulti
                onChange={handleSelectFollowerChange}
                value={selectedFollower}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                Document Attachment:
              </Form.Label>
            </Col>
            <Col sm="10" className="mb-3">
              <div className="dz-message needsclick">
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  //onSubmit={handleSubmit}
                  accept="image/*,audio/*,video/*"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="2" className="mb-3">
              <Form.Label className="form-label-secondary">
                Voice Attachment:
              </Form.Label>
            </Col>
            <Col sm="3" className="mb-3">
              {isRecording1 ? (
                <Button
                  onClick={stopRecording1}
                  // disabled={!isRecording1}
                  className="record-btn"
                >
                  <img
                    src={`${baseurl}/public/images/orderbot/stop_record_icon.png`}
                    alt="Recording Icon"
                    className="record-img"
                  />
                  End Recording
                </Button>
              ) : (
                <Button
                  onClick={startRecording1}
                  // disabled={isRecording2}
                  className="record-btn"
                >
                  <img
                    src={`${baseurl}/public/images/orderbot/start_record_icon.png`}
                    alt="Recording Icon"
                    className="record-img"
                  />
                  Record your order
                </Button>
              )}
            </Col>
            <Col sm="7" className="mb-3">
              <audio  src={audioURL1} controls />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <Button
                type="submit"
                variant="success"
                size="lg"
                className="shadow-bottom"
              >
                Submit
              </Button>
            </Col>
          </Row>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="example-modal-sizes-title-xs"
                style={{ fontSize: "15px" }}
              >
                Possible Solution Note: After selecting {userdetails?.support_label} category
                or subcategory if possible solution exits in system you will get
                it here.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {Array.isArray(solutions) &&
                  solutions.length > 0 &&
                  solutions?.map((crsl, jsol) => (
                    <Accordion.Item eventKey={jsol} key={jsol}>
                      <Accordion.Header>
                        <input
                          type="checkbox"
                          name={`checkbox-${crsl.solution_id}`}
                          value={crsl.solution_id}
                          style={{ marginRight: "15px" }}
                          onClick={(e) => e.stopPropagation()}
                          {...register(`solutionsid`)}
                        />{" "}
                        {crsl.title}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: crsl.detail }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </Modal.Body>
          </Modal>
        </Form>
      </Container>
      <Modal
        size="lg"
        show={addressmodalShow}
        onHide={() => setAddressModalShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-xs"
            style={{ fontSize: "15px" }}
          >
            Add / Edit New Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddAddess
            contact_id={contact_id}
            contact_type={1}
            address_id={selEditAddress}
            setAddressModalShow={setAddressModalShow}
            setSelRandomNumber={setSelRandomNumber}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateSupportRequest;

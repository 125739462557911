import { configureStore } from "@reduxjs/toolkit";
import supportrequestReducer from "./supportrequestSlice";
import baseurlReducer from "./baseurlSlice";
import draftReducer from "./draftSlice";
import modalReducer from "./modalSlice";

const store = configureStore({
  reducer: {
    supportrequest: supportrequestReducer,
    baseUrl: baseurlReducer,
    drafts: draftReducer,
    modaldraft: modalReducer,
  },
});

export default store;

import React, { Fragment, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import LoginForm from "./LoginForm";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import loginvector from "../../src/assets/images/supportportal/home_vector_1.jpg";
import { Image } from "../AbstractElements";
import { useSelector } from "react-redux";

const Signin = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  console.log("signindvdhvdnvdnvdvdnvv");
  const navigate = useNavigate();
  const token = localStorage.getItem("id_token");
  const Is_Pos = process.env.REACT_APP_IS_POS;
  const imageUrl_Timesheet = `${baseurl}/public/images/supportportal/home_vector_1.jpg`;
  const imageUrl_POS = `${baseurl}/public/images/supportportal/pos_image.png`;
  const imageUrl_Honest = `${baseurl}/public/images/supportportal/price_vector_1.jpg`;
  console.log(process.env.REACT_APP_IS_POS, "env......");

  useEffect(() => {
    if (token) {
      if (process.env.REACT_APP_IS_POS == 0) {
        navigate(`${process.env.PUBLIC_URL}/timesheetportal`);
      } else if (process.env.REACT_APP_IS_POS == 1) {
        navigate(`${process.env.PUBLIC_URL}/dashboardposmain`);
      } else if (process.env.REACT_APP_IS_POS == 2) {
        navigate(`${process.env.PUBLIC_URL}/supportportal`);
      } else if (process.env.REACT_APP_IS_POS == 3) {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } 
    }
  }, []);

  return (
    <Fragment>
    {/* <div className="welcome-to-h-office-quote-gene-wrapper">
      <div className="welcome-to-h-office-container">
        <p className="welcome-to">
          <b>Welcome To</b>
        </p>
        <p className="h-office-quote-generator">
          <b>H-Office Quote Generator</b>
        </p>
      </div>
    </div> */}
    <section>
      <Container fluid={true} className="p-0">
        <Row className="m-0">
          <Col
            xl="7"
            className="b-center bg-size bg-center"
            style={{
              backgroundImage: `url(${
                Is_Pos == 1
                  ? imageUrl_POS
                  : Is_Pos == 3
                  ? imageUrl_Honest
                  : imageUrl_Timesheet
              })`,
              backgroundSize: "contain",
              backgroundPosition: "center center",
              display: "block",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#b8d9c8",
            }}
          >
            <div>
              <Image
                attrImage={{
                  className: "bg-img-cover bg-center d-none",
                  src: `${require("../assets/images/login/2.jpg")}`,
                  alt: "looginpage",
                }}
              />
            </div>
          </Col>
          <Col xl="5 p-0">
            <LoginForm logoClassMain="text-start" />
          </Col>
        </Row>
      </Container>
    </section>
    <ToastContainer />
  </Fragment>
   
  );
};

export default Signin;

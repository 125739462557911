import React, { Fragment, useContext, useEffect, useState } from "react";
import { Btn, H4, P, H6, Image } from "../AbstractElements";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Facebook, Linkedin, Twitter } from "react-feather";

import logoWhite from "../assets/images/logo/logo.png";
import logoDark from "../assets/images/logo/logo_dark.png";
import { loginUser, useUserDispatch } from "../context/Usercontext";
import CustomizerContext from "../_helper/Customizer";
import { BackgroundColor, SignIn } from "../Constant";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";

const LoginForm = ({ logoClassMain }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [companydetail,setCompanydetail] = useState({})
  const { layoutURL } = useContext(CustomizerContext);
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";

  // const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  // const [name, setName] = useState(localStorage.getItem("Name"));
  const userdispatch = useUserDispatch();
  const history = useNavigate();

  useEffect(() => {
    getcompanydetails();
  },[])

  const getcompanydetails = async (from_date,to_date) => {
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/getCompanyBasicSettings/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
        }
      );

      const { data } = response; // Destructure response data

      if (data && data[0]?.DATA) {
        setCompanydetail(data[0]?.DATA)
      } else {
        setCompanydetail({})
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };
  // const loginAuth = async (e) => {
  //   e.preventDefault();
  //   setValue(man);
  //   setName("Emay Walter");
  //   if (email === "test@gmail.com" && password === "test123") {
  //     localStorage.setItem("login", JSON.stringify(true));
  //     history(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
  //     toast.success("Successfully logged in!..");
  //   } else {
  //     toast.error("You enter wrong password or username!..");
  //   }
  // };
  return (
    <Fragment>
    <div className="login-card " style={{background:"#b8d9c8"}}>
      <div>
        <div className="login-main main-card">
          <Form
            className="theme-form login-form"
            onSubmit={(e) => {
              e.preventDefault();
              loginUser(userdispatch, email, password, history, layoutURL);
            }}
          >
            <div className="text-center py-2">
            <Image
              attrImage={{
                className: "img-fluid",
                src: `${baseurl}/${companydetail?.company_logo}`,
                alt: "",
                style: {
                  width: "170px", // Increase the width for a larger logo
                  height: "auto", // Setting height to auto to maintain aspect ratio
                  // You can adjust other styles as needed
                },
              }}
            />
            </div>
            <h1 className="login-header text-center">
              Login with your password
            </h1>
            <p className="text-center">
              Log in to access a world of seamless <br/> support and assistance.
            </p>
            <FormGroup>
              {/* <Label className="col-form-label m-0 fw-bold">Username</Label> */}
              <Input
                className="register-full input-register"
                // type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="User ID"
              />
            </FormGroup>
            <FormGroup className="position-relative">
              {/* <Label className="col-form-label m-0 fw-bold">Password</Label> */}
              <div className="position-relative">
                <Input
                  className="register-full input-register"
                  type={togglePassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="login[password]"
                  required
                  placeholder="*********"
                />
                <div
                  className="show-hide"
                  onClick={() => setTogglePassword(!togglePassword)}
                >
                  <span
                    className={togglePassword ? "" : "show"}
                    style={{ color: "#005e32" }}
                  ></span>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  className="d-block mt-3 signin-btn"
                  type="submit"
                  // onClick={() => {
                  //   loginUser(
                  //     userdispatch,
                  //     email,
                  //     password,
                  //     history, 
                  //     layoutURL
                  //   )
                  // }}
                >
                  Login
                </Button>
              </div>
            </FormGroup>
          </Form>
          
        </div>
        <p style={{marginTop:"10px",textAlign:"center"}}>Powered by : <a style={{color:"#000"}} href="https://hofficecrm.com/" target="_blank">H-Office</a></p>
      </div>
      
    </div>
    
  </Fragment>
  );
};

export default LoginForm;

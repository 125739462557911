import React, { useContext, useState } from "react";
import { Grid } from "react-feather";
import { Link } from "react-router-dom";
import { Image } from "../../AbstractElements";
import CubaIcon from "../../assets/images/logo/logo.png";
import CustomizerContext from "../../_helper/Customizer";
import { useEffect } from "react";
import hofficeicon from "../../assets/images/supportportal/h_office_logo.png";
import { useSelector } from "react-redux";

const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, toggleIcon, layout, layoutURL } =
    useContext(CustomizerContext);
  // const baseurl = "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector(store => store.baseUrl);
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );

  useEffect(() => {
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  const openCloseSidebar = () => {
    toggleSidebar(!toggleIcon);
  };
  // `${baseurl}/${userdetails?.company_logo}`
  const layout1 = localStorage.getItem("sidebar_layout") || layout;

  function getDashboardLink() {
    const IS_POS = process.env.REACT_APP_IS_POS;
    switch (IS_POS) {
      case "0":
        return `${process.env.PUBLIC_URL}/timesheetportal`;
      case "1":
        return `${process.env.PUBLIC_URL}/dashboardposmain`;
      case "3":
        return `${process.env.PUBLIC_URL}/dashboard`;
      default:
        return `${process.env.PUBLIC_URL}/dashboard`; // Default to /dashboard if IS_POS is not 0, 1, or 3
    }
  }

  return (
    <div className="logo-wrapper">
      {layout1 !== "compact-wrapper dark-sidebar" &&
      layout1 !== "compact-wrapper color-sidebar" &&
      mixLayout ? (
        <Link to={getDashboardLink()}>
          <Image
            attrImage={{
              className: "img-fluid d-inline",
              src: `${baseurl}/${userdetails?.company_logo}`,
              alt: "",
              style: {
                width: "60px", // for example, setting the width to 100px
                height: "auto", // setting height to auto to maintain aspect ratio
              },
            }}
          />
        </Link>
      ) : (
        <Link to={getDashboardLink()}>
          <Image
            attrImage={{
              className: "img-fluid d-inline",
              src: `${baseurl}/${userdetails?.company_logo}`,
              alt: "",
              style: {
                width: "60px", // for example, setting the width to 100px
                height: "auto", // setting height to auto to maintain aspect ratio
              },
            }}
          />
        </Link>
      )}
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
      <div className="toggle-sidebar" onClick={openCloseSidebar}>
        <Grid className="status_toggle middle sidebar-toggle" />
      </div>
    </div>
  );
};

export default SidebarLogo;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import cubaimg from "../../assets/images/logo/logo-icon.png";
import CustomizerContext from "../../_helper/Customizer";
import { useEffect } from "react";
import { useState } from "react";
import hofficeicon from "../../assets/images/supportportal/h_office_logo.png"
import { useSelector } from "react-redux";

const SidebarIcon = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector(store => store.baseUrl);
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );

  useEffect(() => {
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/timesheetportal`}>
        <img
          className="img-fluid"
          src={`${baseurl}/${userdetails?.company_logo}`}
          alt=""
          style={{width:"40px",height:"auto"}}
        />
      </Link>
    </div>
  );
};

export default SidebarIcon;

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";

import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import { useDispatch, useSelector } from "react-redux";
import { additem } from "../../../utils/supportrequestSlice";
import { toast } from "react-toastify";
// import { type } from "world-map-geojson";
import Swal from "sweetalert2";
import { FaUser } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { FaArchive } from "react-icons/fa";
import { closeModal, toggleModal } from "../../../utils/modalSlice";

const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );

  console.log(typeof punchincheck, "checkpunchin");
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const dispatch = useDispatch();
  console.log(name + "gngngngngn");

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    // setName(name?.username);
  }, []);

  const draftsCount = useSelector((state) => state.drafts.count);

  const Logout = () => {
    if (
      JSON.parse(localStorage.getItem("punchin")) == null &&
      JSON.parse(localStorage.getItem("punchout")) == null
    ) {
      console.log("if loop");
      // Default case when punchincheck is true
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      localStorage.removeItem("company_logo_pos");
      localStorage.removeItem("contactid");
      localStorage.removeItem("timesheet_enable_on_login");
      history(`${process.env.PUBLIC_URL}/login`);
      dispatch(additem([]));
      // Add any default behavior here
    } else if (
      JSON.parse(localStorage.getItem("punchin")) == false &&
      JSON.parse(localStorage.getItem("punchout")) == true
    ) {
      // Case when punchincheck is false, but punchoutcheck is true
      console.log("first else if loop");
      Swal.fire(
        "Please remember to perform a punch-out before logging out of the system!"
      );
    } else if (
      JSON.parse(localStorage.getItem("punchin")) == false &&
      JSON.parse(localStorage.getItem("punchout")) == false
    ) {
      console.log("second elseif loop");
      // Case when both punchincheck and punchoutcheck are false
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      localStorage.removeItem("company_logo_pos");
      localStorage.removeItem("contactid");
      localStorage.removeItem("timesheet_enable_on_login");
      history(`${process.env.PUBLIC_URL}/login`);
      dispatch(additem([]));
    } else if (JSON.parse(localStorage.getItem("punchin")) == true) {
      console.log("second elseif loop");
      // Case when both punchincheck and punchoutcheck are false
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      localStorage.removeItem("company_logo_pos");
      localStorage.removeItem("contactid");
      localStorage.removeItem("timesheet_enable_on_login");
      history(`${process.env.PUBLIC_URL}/login`);
      dispatch(additem([]));
    }
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      {currentPath == "/dashboardpos" && (
        <button
          type="button"
          class="btn btn-warning draft-button position-relative"
          // data-toggle="modal"
          // data-target=".draft-modal-lg"
          style={{ width: "120px" }}
          onClick={() => dispatch(toggleModal())}
        >
          <div className="buttonContainer d-flex-row">
            <div
              className="svgButtonContainer"
              style={{ paddingLeft: 8, paddingRight: 10 }}
            >
              <FaArchive />
            </div>
            <span className="bill-button-text">Drafts</span>
          </div>
          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
            {draftsCount}
          </span>
        </button>
      )}
      <li className="profile-nav onhover-dropdown pe-0 py-0">
        <div className="media profile-media">
          {/* <Image
          attrImage={{
            className: "b-r-10 m-0",
            src: `${authenticated ? auth0_profile.picture : profile}`,
            alt: "",
          }}
        /> */}
          {isMobile ? "" : <FaUser size={72} />}
          <div className="media-body">
            <span>{name?.username}</span>
            <P attrPara={{ className: "mb-0 font-roboto" }}>
              {name?.username} <i className="middle fa fa-angle-down"></i>
            </P>
          </div>
        </div>
        <UL
          attrUL={{
            className: "simple-list profile-dropdown onhover-show-div",
          }}
        >
          {/* <LI
          attrLI={{
            onClick: () =>
              UserMenuRedirect(
                `${process.env.PUBLIC_URL}/app/users/profile/${layoutURL}`
              ),
          }}
        >
          <User />
          <span>{Account} </span>
        </LI>
        <LI
          attrLI={{
            onClick: () =>
              UserMenuRedirect(
                `${process.env.PUBLIC_URL}/app/email-app/${layoutURL}`
              ),
          }}
        >
          <Mail />
          <span>{Inbox}</span>
        </LI>
        <LI
          attrLI={{
            onClick: () =>
              UserMenuRedirect(
                `${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`
              ),
          }}
        >
          <FileText />
          <span>{Taskboard}</span>
        </LI> */}
          <LI
            attrLI={{
              onClick: Logout,
            }}
          >
            <LogIn />
            <span>{LogOut}</span>
          </LI>
        </UL>
      </li>
    </>
  );
};

export default UserHeader;

import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import "./supportportal.scss";
import support_requests from "../../../src/assets/images/supportrequest/support_requests.png";
import order_history from "../../../src/assets/images/supportrequest/order_history.png";
import contract_history from "../../../src/assets/images/supportrequest/contract_history.png";
import grn_history from "../../../src/assets/images/supportrequest/grn_history.png";
import report_img from "../../../src/assets/images/supportrequest/report_img.png";
import my_profile from "../../../src/assets/images/supportrequest/my_profile.png";



const Supportdashboard = () => {
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const history = useNavigate();
  const dispatch = useDispatch();
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  // dashboardboxes
  const dashboardboxes = [
    {
      text: `${userdetails?.support_label}`,
      img: `${support_requests}`,
    },
    {
      text: "Order History",
      img: `${order_history}`,
    },
    {
      text: "Contract History",
      img: `${contract_history}`,
    },
    {
        text: "GRN History",
        img: `${grn_history}`,
      },{
        text: "Reports",
        img: `${report_img}`,
      },
      {
        text: "My Profile",
        img: `${my_profile}`,
      },
  ];

  const Logout = () => {
    if (
      JSON.parse(localStorage.getItem("punchin")) == null &&
      JSON.parse(localStorage.getItem("punchout")) == null
    ) {
      console.log("if loop");
      // Default case when punchincheck is true
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      history(`${process.env.PUBLIC_URL}/login`);
      // dispatch(additem([]));
      // Add any default behavior here
    } else if (
      JSON.parse(localStorage.getItem("punchin")) == false &&
      JSON.parse(localStorage.getItem("punchout")) == true
    ) {
      // Case when punchincheck is false, but punchoutcheck is true
      console.log("first else if loop");
      // Swal.fire("Please remember to perform a punch-out before logging out of the system!");
    } else if (
      JSON.parse(localStorage.getItem("punchin")) == false &&
      JSON.parse(localStorage.getItem("punchout")) == false
    ) {
      console.log("second elseif loop");
      // Case when both punchincheck and punchoutcheck are false
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      history(`${process.env.PUBLIC_URL}/login`);
      // dispatch(additem([]));
    } else if (JSON.parse(localStorage.getItem("punchin")) == true) {
      console.log("second elseif loop");
      // Case when both punchincheck and punchoutcheck are false
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      history(`${process.env.PUBLIC_URL}/login`);
      // dispatch(additem([]));
    }
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ fontFamily: "'Poppins', sans-serif" }}
    >
      <Container className="dashboard-center mt-5">
        <div className="text-center mt-3">
          <Image
            src={`${baseurl}/${userdetails?.company_logo}`}
            alt="companylogo"
            fluid
            style={{ maxWidth: "100%", height: "auto" }}
          />
          {/* <h1 className="green-header">Welcome Kush Kalwani</h1> */}
          <p className="mt-4 supportaldashboard-header">
            Welcome {userdetails?.username}
          </p>
          <p className="supportaldashboard-subheader">
          Your gateway to efficient issue resolution and personalized assistance awaits. Let's make your support experience smooth and productive!
          </p>
        </div>
        <div className="d-flex flex-wrap justify-content-center align-items-center gap-4 py-3 mx-5">
          {dashboardboxes.map((box, index) => (
            <Link
              to={
                index == 0
                  ? `${process.env.PUBLIC_URL}/supportrequestlist`
                  : index == 1
                  ? `#`
                  : index == 2
                  ? `#`
                  : null
              }
              key={index}
              /*onClick={() => {
                {
                  index == 2 && Logout();
                }
              }}*/
            >
              <div
                className={isMobile ? `supportdashboard-box-mobile` : `order-box`}
                style={{
                  cursor: "pointer",
                }}
              >
                <div className={isMobile ? `` : `mt-3`} style={{ textAlign: "center" }}>
                  <img
                    className={
                      isMobile ? "dashboardbox-img-mobile" : "dashboardbox-img"
                    }
                    src={`${baseurl}/public/complainportal${box.img}`}
                    alt={box.text}
                  />
                </div>
                <h4
                  className={
                    isMobile
                      ? "supportdashboard-box-text-mobile text-center mt-1 fw-bold"
                      : "supportdashboard-box-text text-center mt-1 fw-bold"
                  }
                  style={{ color: "black" }}
                >
                  {box.text}
                </h4>
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Supportdashboard;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./dashboardmain.css";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Card, CardBody, Input } from "reactstrap";
import { H4 } from "../../AbstractElements";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { MdOutlineCancel } from "react-icons/md";
import { GoIssueClosed } from "react-icons/go";
import { IoAlertCircleOutline, IoLockClosedOutline } from "react-icons/io5";
import CountUp from "react-countup";

const Dashboardmain = () => {
  const navigate = useNavigate();
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";

  const handleComposeButtonClick = () => {
    navigate(`${process.env.PUBLIC_URL}/dashboardpos`);
  };

  const invoiceColumns = [
    {
      name: "Invoice No.",
      selector: (row) => row?.No, // Adjust to match your data structure
      sortable: true,
      center: true,
      minWidth: "100px",
    },
    {
      name: "Date",
      selector: (row) => row?.Date, // Adjust to match your data structure
      sortable: true,
      center: true,
      minWidth: "80px",
    },
    {
      name: "Contacts",
      selector: (row) => (
        <>
          <div
            style={{
              textAlign: "start",
              overflow: "hidden",
              whiteSpace: "normal",
            }}
          >
            {row?.Name.replace(/\+/g, " ")}
          </div>
        </>
      ),
      sortable: true,
      center: true,
      minWidth: "220px",
      // sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row?.Mobile, // Replace '+' with a space in the Name property
      sortable: true,
      center: true,
      minWidth: "100px",
    },
    {
      name: "Amount",
      selector: (row) => (
        <div>
          ₹ {row.Amount} {/* Add the Rupees icon before the Amount */}
        </div>
      ), // Adjust to match your data structure
      sortable: true,
      center: true,
      minWidth: "100px",
    },
  ];

  const [searchtext, setSearchtext] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [filteredinvoiceData, setFilteredInvoiceData] = useState([]);
  const [data_info, setDatainfo] = useState("");
  const [invoiceinfo, setInvoiceinfo] = useState({});

  useEffect(() => {
    const postData = async () => {
      try {
        const res = await axios.post("/expo_access_api/getTransactionList", {
          AUTHORIZEKEY: "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy",
        });

        const { data } = res;

        if (data && data[0]?.DATA?.invoiceList) {
          const message = data[0].MSG;
          setDatainfo(message);
          setInvoiceData(res?.data[0]?.DATA?.invoiceList);
          setFilteredInvoiceData(res?.data[0]?.DATA?.invoiceList);
          setInvoiceinfo(res?.data[0]?.DATA);
        } else {
          const message = data[0].MSG;
          setDatainfo(message);
          setInvoiceData([]);
          setFilteredInvoiceData([]);
          setInvoiceinfo({});
        }
      } catch (error) {
        console.error("Error posting data:", error);
        // You might want to display an error message to the user here
      }
    };

    postData();
  }, []);

  const handleSearch = (e) => {
    console.log("handleSearch");
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = invoiceData.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] && typeof item[key] === "string") {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilteredInvoiceData(filteredItems);
  };

  // const paginationOptions = {
  //   rowsPerPageText: "Rows per page:",
  //   rangeSeparatorText: "of",
  //   selectAllRowsItem: true,
  //   selectAllRowsItemText: "All",
  // };

  return (
    <div className="dashboardposmain">
      <div className="grid-container-dashboard ">
        <Col>
          {/* <Widgets1 data={Widgets2Data} />  */}
          <Card className="widget-1">
            <CardBody>
              <div className="widget-content">
                <div className={`widget-round primary`}>
                  <div className="bg-round">
                    {/* <SvgIcon className='svg-fill' iconId={`${data.icon}`} /> */}
                    {/* <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                    <LiaFileInvoiceSolid color="#7366ff" />
                  </div>
                </div>
                <div>
                  <H4>
                    <CountUp
                      prefix=""
                      duration={3}
                      start={0}
                      separator=","
                      end={invoiceinfo?.total_invoice}
                    />
                  </H4>
                  {/* <H4>{invoiceinfo?.total_invoice}</H4> */}
                  <span className="f-light">Invoice</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          {/* <Widgets1 data={WidgetsData} />  */}
          <Card className="widget-1">
            <CardBody>
              <div className="widget-content">
                <div className={`widget-round warning`}>
                  <div className="bg-round">
                    {/* <SvgIcon className='svg-fill' iconId={`cart`} />
              <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                    <MdOutlineCancel color="#ffaa05" />
                  </div>
                </div>
                <div>
                  <H4>
                    <CountUp
                      prefix=""
                      duration={3}
                      start={0}
                      separator=","
                      end={invoiceinfo?.cancel_invoice}
                    />
                  </H4>
                  {/* <H4>{invoiceinfo?.cancel_invoice}</H4> */}
                  <span className="f-light">Cancel Orders</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          {/* <Widgets1 data={WidgetsData3} />  */}
          <Card className="widget-1">
            <CardBody>
              <div className="widget-content">
                <div className={`widget-round secondary`}>
                  <div className="bg-round">
                    {/* <SvgIcon className='svg-fill' iconId={`cart`} />
              <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                    <IoLockClosedOutline color="#d63384" />
                  </div>
                </div>
                <div>
                  <H4>
                    <CountUp
                      prefix=""
                      duration={3}
                      start={0}
                      separator=","
                      end={invoiceinfo?.close_invoice}
                    />
                  </H4>
                  {/* <H4>{invoiceinfo?.close_invoice}</H4> */}
                  <span className="f-light">Close Orders</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          {/* <Widgets1 data={Widgets2Data} />  */}
          <Card className="widget-1">
            <CardBody>
              <div className="widget-content">
                <div className={`widget-round success`}>
                  <div className="bg-round">
                    {/* <SvgIcon className='svg-fill' iconId={`${data.icon}`} /> */}
                    {/* <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                    <GoIssueClosed color="#54ba4a" />
                  </div>
                </div>
                <div>
                  <H4>
                    <CountUp
                      prefix=""
                      duration={3}
                      start={0}
                      separator=","
                      end={invoiceinfo?.paid_invoice}
                    />
                  </H4>
                  {/* <H4>{invoiceinfo?.paid_invoice}</H4> */}
                  <span className="f-light">Paid Orders</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* <Col>
          <Widgets1 data={Widgets2Data} /> 
          <Card className="widget-1">
            <CardBody>
              <div className="widget-content">
                <div className={`widget-round unpaid`}>
                  <div className="bg-round">
                    <SvgIcon className='svg-fill' iconId={`${data.icon}`} />
                    <SvgIcon className='half-circle svg-fill' iconId='halfcircle' />
                    <IoAlertCircleOutline size={40} color="#dc3545" />
                  </div>
                </div>
                <div>
                <H4><CountUp prefix='' duration={3} start={0} separator=',' end={invoiceinfo?.unpaid_invoice} /></H4> 
                  <span className="f-light">Unpaid Orders</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}
      </div>

      <Row>
        <Col sm="12">
          <Card>
            {/* <HeaderCard title="Select Multiple and Delete Single Data" /> */}
            <CardBody>
              {data_info == "Data Not Found" ? (
                <div
                  className="text-center mt-3 py-3"
                  style={{ backgroundColor: "#dfdcdc" }}
                >
                  No Records found
                </div>
              ) : invoiceData.length == 0 ? (
                <div className="text-center mt-3">
                  <Spinner animation="border" variant="success" />
                </div>
              ) : (
                <div className="datatable-dashboardposmain my-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <Input
                        className="form-control register-full input-register"
                        type="text"
                        placeholder="Search all fields..."
                        value={searchtext}
                        onChange={handleSearch}
                      />
                    </div>
                    <div className="export-new">
                      <Button
                        className="searchbtn my-2"
                        onClick={handleComposeButtonClick}
                      >
                        Create Invoice
                      </Button>
                    </div>
                  </div>

                  <DataTable
                    data={filteredinvoiceData}
                    columns={invoiceColumns}
                    striped={true}
                    center={true}
                    pagination
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboardmain;

import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { isMobile } from "react-device-detect";
import { MdEditSquare } from "react-icons/md";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import { H5 } from "../../AbstractElements";
import HeaderCard from "../Common/Component/HeaderCard";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
// import "./honestchart.css";
import { Badge } from "reactstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ReactApexChart from "react-apexcharts";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactSelect from "react-select";
import { InstagramSubscriptionChart } from "../../Data/Social/Chart";
import {
  Purchase_percentage_chart,
  Sales_percentage_chart,
} from "./HonestChartUtils";
const ExcelJS = require("exceljs");

const Dashboardhonest = () => {
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [Product_list, setProductlist] = useState([]);
  const [filterProductlist, setFilterProductlist] = useState([]);
  const [data_info, setDatainfo] = useState("");
  const [searchtext, setSearchtext] = useState("");
  const [product_history_data, setProducthistorydata] = useState({});
  const [sales_percentage_data, setSalespercentagedata] = useState({});
  const [purchase_percentage_data, setPurchasepercentagedata] = useState({});
  const [divisionlist, setDivisionlist] = useState([]);
  const [divisionid, setDivisionid] = useState("");
  const [divisionwiseproductarray, setDivisionwiseproductarray] = useState([]);

  // for table
  const [currentRow, setCurrentRow] = useState(null);

  console.log(Product_list, "Product_list..");
  console.log(product_history_data, "product_history_data...");
  console.log(purchase_percentage_data, "purchase_percentage_data..");
  console.log(sales_percentage_data, "sales_percentage_data...");
  console.log(divisionlist, "divisionlist..");
  console.log(divisionwiseproductarray, "divisionwiseproductarray");
  console.log(divisionid, "divisionid...");

  useEffect(() => {
    // If divisionlist is available and has at least one element, set the initial division ID
    if (divisionlist.length > 0) {
      setDivisionid(divisionlist[0].division_id);
    }
  }, [divisionlist]);

  // product list
  // const [projectdata, setProjectdata] = useState(supportlist);
  const [selectedProduct, setSelectedProduct] = useState(
    divisionwiseproductarray.length > 0
      ? {
          value: divisionwiseproductarray[0]?.product_id,
          label: divisionwiseproductarray[0]?.product_name,
        }
      : null
  );
  console.log(selectedProduct, "selectedProduct...");

  const handleOnSelectProduct = (selectedOptionproduct) => {
    console.log(selectedOptionproduct, "selectedOptionproduct...");
    setSelectedProduct(selectedOptionproduct);
    Product_chart_data(
      selectedOptionproduct?.value,
      selectedOptionproduct?.label
    );
    setProductlist([]);
    setFilterProductlist([]);
    Product_list_api(selectedOptionproduct?.value);
  };
  // for modal
  const [history_modal, setHistorymodal] = useState(false);

  const toggle_historymodal = () => setHistorymodal(!history_modal);

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  // filter all field
  const handleSearch = (e) => {
    console.log("handleSearch");
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = Product_list.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] && typeof item[key] === "string") {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilterProductlist(filteredItems);
  };

  const columns = [
    {
      name: "",
      cell: (row) => (
        <Badge
          color="primary"
          className="history-btn"
          onClick={() => {
            toggle_historymodal();
            Product_Order_History(row.product_id);
          }}
          style={{ cursor: "pointer" }}
        >
          History
        </Badge>
      ),
      width: "70px",
    },
    {
      name: (
        <div
          style={{
            textAlign: "start",
            overflow: "hidden",
            whiteSpace: "normal",
          }}
        >
          Product Name
        </div>
      ),
      selector: (row) => (
        <div>
          <div
            style={{
              textAlign: "start",
              overflow: "hidden",
              whiteSpace: "normal",
            }}
          >
            {row.productname ? row.productname : "---"}
          </div>
        </div>
      ),
      width: "200px",
      // sortable: true,
    },
    {
      name: "Product Code",
      selector: (row) => (
        <div>
          <div
            style={{
              textAlign: "start",
              overflow: "hidden",
              whiteSpace: "normal",
            }}
          >
            {row.productcode ? row.productcode : "---"}
          </div>
        </div>
      ),
      width: "120px",
      // sortable: true,
    },
    {
      name: "Variance",
      selector: (row) => {
        let icon;
        if (row.salesprice_flg == "up") {
          icon = <FaArrowUp color="green" />;
        } else if (row.salesprice_flg == "down") {
          icon = <FaArrowDown color="red" />;
        }
        return (
          <div>
            <span className="d-flex justify-content-center align-items-center">
              {icon}
              <span className="ms-1">
                {row.salesratepercentage != undefined &&
                row.salesratepercentage != null
                  ? row.salesratepercentage != 0
                    ? `${row.salesratepercentage}%`
                    : "0"
                  : "---"}
              </span>
            </span>
          </div>
        );
      },
      width: "120px",
      // sortable: true,
    },
    {
      name: "Sales Rate",
      selector: (row) => (row.salesrate ? row.salesrate : "---"),
      width: "100px",
      // sortable: true,
    },
    {
      name: "Sales Cost",
      selector: (row) => (row.salescost ? row.salescost : "---"),
      width: "100px",
      // sortable: true,
    },
    {
      name: "Variance",
      selector: (row) => {
        let icon;
        if (row.purchaseprice_flg == "up") {
          icon = <FaArrowUp color="green" />;
        } else if (row.purchaseprice_flg == "down") {
          icon = <FaArrowDown color="red" />;
        }

        return (
          <div>
            <span className="d-flex justify-content-center align-items-center">
              {icon}
              <span className="ms-1">
                {row.purchasepercentage != undefined &&
                row.purchasepercentage != null
                  ? row.purchasepercentage != 0
                    ? `${row.purchasepercentage}%`
                    : "0"
                  : "---"}
              </span>
            </span>
          </div>
        );
      },
      width: "120px",
      // sortable: true,
    },
    {
      name: "Purchase Rate",
      selector: (row) => (row.purchaserate ? row.purchaserate : "---"),
      width: "130px",
      // sortable: true,
    },
    {
      name: "Purchase Cost",
      selector: (row) => (row.purchasecost ? row.purchasecost : "---"),
      width: "130px",
      // sortable: true,
    },
  ];

  // const ExpandedComponent = ({ data }) => {
  //   <div>
  //     <p style={{ marginBottom: "0px" }}>
  //       <span className="expand-header">Product code</span> :
  //       <span className="expand-subheader"> {data.productcode}</span>
  //     </p>
  //     <p style={{ marginBottom: "0px" }}>
  //       <span className="expand-header">Sales Rate</span> :
  //       <span className="expand-subheader"> {data.salesrate}</span>
  //     </p>
  //     <p style={{ marginBottom: "0px" }}>
  //       <span className="expand-header">Sales Cost</span> :
  //       <span className="expand-subheader"> {data.salescost}</span>
  //     </p>
  //     <p style={{ marginBottom: "0px" }}>
  //       <span className="expand-header">Purchase Rate</span> :
  //       <span className="expand-subheader"> {data.purchaserate}</span>
  //     </p>
  //     <p style={{ marginBottom: "0px" }}>
  //       <span className="expand-header">Purchase Cost</span> :
  //       <span className="expand-subheader"> {data.purchasecost}</span>
  //     </p>
  //   </div>;
  // };

  const Product_list_api = async (productid) => {
    let currentDivisionId = "";
    let currentProductId = "";
    if (divisionlist.length > 0) {
      currentDivisionId = divisionlist[0].division_id; // Set the default division_id if divisionlist is not empty
    }
    if (divisionwiseproductarray.length > 0) {
      currentProductId = divisionwiseproductarray[0].product_id; // Set the default division_id if divisionlist is not empty
    }
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/Product_lastprice/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          product_name: productid || "", // place id instead of name
          division_id: divisionid || currentDivisionId, // Use the default division_id if available, otherwise use the current divisionid
        }
      );

      const { data } = response; // Destructure response data

      if (data && data[0]?.Data?.Product_lastprice) {
        const message = data[0].MSG;
        setDatainfo(message);
        const Product_lastprice_list = data[0]?.Data?.Product_lastprice;
        setProductlist(Product_lastprice_list); // Combined state updates
        setFilterProductlist(Product_lastprice_list);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setProductlist([]);
        setFilterProductlist([]);
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  const Product_Order_History = async (id) => {
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/ProductSalesPurchaseTransactionHistory/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          flag: "",
          product_id: id,
          division_id: divisionid,
        }
      );

      const { data } = response; // Destructure response data

      console.log(data, "Product_Order_History....");

      if (data && data[0]?.STATUS == "SUCCESS" && data[0]?.Data) {
        const message = data[0].MSG;
        const historydata = data[0]?.Data;
        setProducthistorydata(historydata); // Set state with fetched data
      } else {
        const message = data[0].MSG;
        setProducthistorydata({}); // Set state to empty object
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  const renderTable = (data) => {
    console.log(data, "data......for table...");
    if (data?.length == 0 || !data) {
      return <p>No records found</p>;
    }

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Invoice No</th>
            <th>Contact Name</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data?.map((item, index) => (
              <tr key={index}>
                <td>{item?.Date}</td>
                <td>{item?.invoiceno}</td>
                <td>{item?.contactname}</td>
                <td>{item?.price}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  };

  const Product_chart_data = async (productid, productname) => {
    let currentDivisionId = "";

    if (divisionlist.length > 0) {
      currentDivisionId = divisionlist[0].division_id; // Set the default division_id if divisionlist is not empty
    }
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/Sales_Purchase_graph/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          product_id: productid,
          division_id: divisionid || currentDivisionId, // Use the default division_id if available, otherwise use the current divisionid
        }
      );
      console.log(res, "Product_chart_data");
      const { data } = res; // Destructure response data
      console.log(
        data && data[0].STATUS === "SUCCESS" && data[0]?.Sales_Purchase_graph,
        "data && data[0].STATUS"
      );

      if (
        data &&
        data[0].STATUS == "SUCCESS" &&
        data[0]?.Sales_Purchase_graph
      ) {
        const salesdate_array = data[0]?.Sales_Purchase_graph?.salesdate;
        const salesprice_array = data[0]?.Sales_Purchase_graph?.salesprice;
        const purchasedate_array = data[0]?.Sales_Purchase_graph?.purchasedate;
        const purchaseprice_array =
          data[0]?.Sales_Purchase_graph?.purchaseprice;

        if (salesdate_array.length > 0 && salesprice_array.length > 0) {
          // Both series and options categories data are available
          const formattedChartData = Sales_percentage_chart(
            salesdate_array,
            salesprice_array,
            productname
          );
          setSalespercentagedata(formattedChartData);
        } else {
          console.log("Salespercentagedata is missing");
          setSalespercentagedata({});
          // Handle the case where either series or options categories data is missing
        }

        if (purchasedate_array.length > 0 && purchaseprice_array.length > 0) {
          // Both series and options categories data are available
          const formattedChartData = Purchase_percentage_chart(
            purchasedate_array,
            purchaseprice_array,
            productname
          );
          setPurchasepercentagedata(formattedChartData);
        } else {
          console.log("Purchasepercentagedata is missing");
          setPurchasepercentagedata({});
          // Handle the case where either series or options categories data is missing
        }
      } else {
        console.log("Data not found or status is not SUCCESS");
        setSalespercentagedata({});
        setPurchasepercentagedata({});
        // Handle the case where data is not found or status is not SUCCESS
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const getdivision = async () => {
    try {
      const res = await axios.post(baseurl + "/expo_access_api/Divisions/", {
        AUTHORIZEKEY: AUTHORIZE_KEY,
      });
      console.log(res, "getdivision");
      const { data } = res; // Destructure response data

      if (
        data &&
        data[0].STATUS == "SUCCESS" &&
        data[0]?.Divisions?.Divisions.length > 0
      ) {
        const message = data[0].MSG;
        setDivisionlist(data[0]?.Divisions?.Divisions);
      } else {
        const message = data[0].MSG;
        setDivisionlist([]);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const getdivisionwiseproduct = async (division_id) => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/DivisonWiseProduct/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          division_id: division_id,
        }
      );
      console.log(res, "getdivisionwiseproduct");
      const { data } = res; // Destructure response data

      if (
        data &&
        data[0].STATUS == "SUCCESS" &&
        data[0]?.DivisonWiseProduct?.Product_Details.length > 0
      ) {
        const message = data[0].MSG;
        const products = data[0]?.DivisonWiseProduct?.Product_Details;
        setDivisionwiseproductarray(products);
        // if (products.length > 0) {
        //   setSelectedproduct(products[0]);
        // }
      } else {
        const message = data[0].MSG;
        setDivisionwiseproductarray([]);
        // setSelectedproduct({})
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    // Product_chart_data();
    getdivision();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (divisionwiseproductarray.length > 0) {
      Product_chart_data(
        divisionwiseproductarray[0]?.product_id,
        divisionwiseproductarray[0]?.product_name
      );
    }
  }, [divisionwiseproductarray]);

  useEffect(() => {
    if (divisionlist.length > 0) {
      console.log("call getdivisionwiseproduct api....");
      getdivisionwiseproduct(divisionlist[0]?.division_id);
    }
  }, [divisionlist]);

  useEffect(() => {
    if (divisionwiseproductarray.length > 0) {
      Product_list_api(""); // Call the API again with the default division_id
    }
  }, [divisionwiseproductarray]);

  useEffect(() => {
    // If divisionlist is available and has at least one element, set the initial division ID
    if (divisionwiseproductarray.length > 0) {
      setDivisionid(divisionlist[0].division_id);
    }
  }, [divisionlist]);

  useEffect(() => {
    if (selectedProduct == null) {
      setDatainfo("Data found");
      setProductlist([]);
      setFilterProductlist([]);
    }
  }, [selectedProduct]);

  const defaultDivisionId =
    divisionlist.length > 0 ? divisionlist[0].division_id : ""; // Get the first division's id if the array is not empty

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      {
        header: "Productname",
        key: "productname",
        width: 15,
      },
      {
        header: "Productcode",
        key: "productcode",
        width: 15,
      },
      {
        header: "Sales rate",
        key: "salesrate",
        width: 15,
      },
      {
        header: "Sales cost",
        key: "salescost",
        width: 15,
      },
      {
        header: "Sales percentage",
        key: "salesratepercentage",
        width: 15,
      },
      {
        header: "Purchase rate",
        key: "purchaserate",
        width: 15,
      },
      {
        header: "Purchase cost",
        key: "purchasecost",
        width: 15,
      },
      {
        header: "Purchase percentage",
        key: "purchasepercentage",
        width: 15,
      },
    ];

    const promise = Promise.all(
      filterProductlist.map(async (product, index) => {
        sheet.addRow({
          id: product?.id,
          productname: product?.productname,
          productcode: product?.productcode,
          salesrate: product?.salesrate,
          salescost: product?.salescost,
          salesratepercentage: product?.salesratepercentage,
          purchaserate: product?.purchaserate,
          purchasecost: product?.purchasecost,
          purchasepercentage: product?.purchasepercentage,
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Purchase_rate_Purchase_cost.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <>
      <div className="mt-2">
        <Row className="mx-1">
          <Col sm="3" xs="12" xl="2">
            {divisionlist.length > 0 && (
              <Form.Select
                className="input-unit-paymentterm input-table"
                value={divisionid}
                onChange={(e) => {
                  setDivisionid(e.target.value);
                  getdivisionwiseproduct(e.target.value);
                  if (selectedProduct == null) {
                    setProductlist([]);
                    setFilterProductlist([]);
                  }
                }}
              >
                {divisionlist.map((division) => (
                  <option
                    key={division.division_id}
                    value={division.division_id}
                  >
                    {division.division_name}
                  </option>
                ))}
              </Form.Select>
            )}
          </Col>
          <Col sm="5" xs="12" xl="3">
            <div className="product-select input-user">
              <ReactSelect
                className=""
                options={divisionwiseproductarray.map((project) => ({
                  value: project.product_id,
                  label: project.product_name,
                }))}
                isClearable={true}
                value={selectedProduct}
                onChange={(selectedoption) => {
                  handleOnSelectProduct(selectedoption);
                }}
                // onMenuClose={(clearoption) => handleOnClearUser(clearoption)} // This will trigger when the dropdown menu is closed, after a selection or clear
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }} // Render the dropdown outside parent containers
                placeholder="Select product..." // Change the placeholder to "Select user"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          {Object.keys(sales_percentage_data).length !== 0 && (
            <Col md="6" sm="12">
              <ReactApexChart
                type="line"
                height={300}
                options={sales_percentage_data.options}
                series={sales_percentage_data.series}
              />
            </Col>
          )}
          {/* <ReactApexChart
              height={300}
              type="line"
              options={InstagramSubscriptionChart.options}
              series={InstagramSubscriptionChart.series}
            /> */}
          {Object.keys(purchase_percentage_data).length !== 0 && (
            <Col md="6" sm="12">
              <ReactApexChart
                type="line"
                height={300}
                options={purchase_percentage_data.options}
                series={purchase_percentage_data.series}
              />
            </Col>
          )}
          {/* <ReactApexChart
              height={300}
              type="line"
              options={InstagramSubscriptionChart.options}
              series={InstagramSubscriptionChart.series}
            /> */}
        </Row>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {isMobile ? (
          <Container>
            {data_info == "Data Not Found" ? (
              <div style={{ width: "100%" }}>
                <div
                  className="text-center mt-3 py-3"
                  style={{ backgroundColor: "#dfdcdc" }}
                >
                  No Records found
                </div>
              </div>
            ) : Product_list?.length == 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <>
                <div>
                  <div className="d-flex justify-content-center align-items-center my-3">
                    <Input
                      className="form-control register-full input-register"
                      type="text"
                      placeholder="Search all fields..."
                      value={searchtext}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="searchbtn my-2"
                      onClick={exportExcelFile}
                    >
                      Export
                    </Button>
                  </div>
                  <Fragment>
                    {/* <Breadcrumbs parent="Table" title="Data Tables" mainTitle="Data Tables" /> */}
                    <Container fluid={true}>
                      <Row>
                        <Col sm="12" style={{ paddingInline: "0px" }}>
                          <Card className="table-header">
                            <HeaderCard title="Latest Price & Rate update of Sales and Purchase" />
                            <CardBody>
                              <div className="honestchart-table">
                                <DataTable
                                  data={filterProductlist}
                                  columns={columns}
                                  striped={true}
                                  center={true}
                                  pagination
                                  responsive
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </Fragment>
                </div>
              </>
            )}
          </Container>
        ) : (
          <div style={{ width: "100%" }}>
            {data_info == "Data Not Found" ? (
              <div
                className="text-center mt-3 py-3"
                style={{ backgroundColor: "#dfdcdc" }}
              >
                No Records found
              </div>
            ) : Product_list?.length == 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <>
                <div>
                  <div className="d-flex justify-content-between my-2 export-honest-dashboard">
                    <div>
                      <Input
                        className="form-control register-full input-register"
                        type="text"
                        placeholder="Search all fields..."
                        value={searchtext}
                        onChange={handleSearch}
                      />
                    </div>
                    <div>
                      <Button
                        className="searchbtn my-2"
                        onClick={exportExcelFile}
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                  <Fragment>
                    {/* <Breadcrumbs parent="Table" title="Data Tables" mainTitle="Data Tables" /> */}
                    <Container fluid={true} style={{ width: "1190px" }}>
                      <Row>
                        <Col sm="12">
                          <Card className="table-header">
                            <HeaderCard title="Latest Price & Rate update of Sales and Purchase" />
                            <CardBody>
                              <div className="honestchart-table">
                                <DataTable
                                  data={filterProductlist}
                                  columns={columns}
                                  striped={true}
                                  center={true}
                                  pagination
                                  responsive
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </Fragment>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <Modal
        isOpen={history_modal}
        toggle={toggle_historymodal}
        keyboard={false}
        backdrop="static"
        size="lg"
      >
        <ModalHeader
          className="history-modal-header"
          toggle={() => {
            setProducthistorydata({});
            toggle_historymodal();
          }}
        >
          Previous Order History
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col sm="12">
                <H5>Sales History</H5>
                {renderTable(product_history_data?.Sales_Product)}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12">
                <H5>Purchase History</H5>
                {renderTable(product_history_data?.Purhcase_Product)}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            className="searchbtn btn btn-primary"
            onClick={() => {
              setProducthistorydata({});
              toggle_historymodal();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Dashboardhonest;

import React from 'react';
import { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { P } from '../../AbstractElements';

const Footer = () => {
    //footer-link
    const handleLinkClick = () => {
      window.open(`https://hofficecrm.com/`);
    };
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
            <h1 className="footer-header">
                Powered By:
                <span
                  className="text-decoration-underline ms-1 h-office"
                  onClick={handleLinkClick}
                >
                  H-Office
                </span>
              </h1>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
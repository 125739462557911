import React, { useState } from "react";
import DataTable from "react-data-table-component";
import myreport from "../../../src/assets/images/supportportal/myreport.png";
import { isMobile } from "react-device-detect";
// import { Container, Spinner } from "react-bootstrap";
import myworkinghours from "../../../src/assets/images/supportportal/my_working_hours.png";
import myloginhours from "../../../src/assets/images/supportportal/my_login_hours.png";
import mybreakhours from "../../../src/assets/images/supportportal/my_break_hours.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Spinner,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import moment from "moment";
const ExcelJS = require("exceljs");

const Workingreport = () => {
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);
  const [searchtext, setSearchtext] = useState("");
  const [from_date, setFromdate] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [to_date, setTodate] = useState(moment().toDate());
  const [workinghoursdata, setWorkinghoursdata] = useState([]);
  const [workinghoursdata_emp, setWorkinghoursdataemp] = useState([]);
  const [filterworkinghoursdata, setFilterworkinghoursdata] = useState([]);
  const [data_info, setDatainfo] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [firstrow, setFirstrow] = useState(null);
  const [searchbtn, setSearchbtn] = useState(false);
  const config_product_code_display = localStorage.getItem(
    "config_product_code_display"
  );
  console.log(data_info, "data_info");
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const firstRow =
    filterworkinghoursdata.length > 0 ? filterworkinghoursdata[0] : null;

  useEffect(() => {
    // Set the first row as the initially expanded row
    if (firstRow) {
      setCurrentRow(firstRow);
      setFirstrow(filterworkinghoursdata[0]);
      getWorkingHours_employee(firstRow.support_order_id);
    }
  }, [firstRow]);

  const isHighlightRow = (row) => {
    // Check if the row is the first row or the currently expanded row
    return row === firstRow || row === currentRow;
  };

  const handleRowExpandToggle = (row) => {
    if (currentRow && currentRow.support_order_id !== row.support_order_id) {
      // Collapse the previously expanded row if it's not the same as the clicked row
      setCurrentRow(null);
    }
    getWorkingHours_employee(row.support_order_id);
    setCurrentRow(row);
  };

  console.log(currentRow, "currentrow");
  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  const handleChangeFromTime = (date) => {
    setFromdate(date);
  };

  const handleChangeToTime = (date) => {
    setTodate(date);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // filter
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = workinghoursdata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] && typeof item[key] === "string") {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilterworkinghoursdata(filteredItems);
  };

  const columns = [];

  if (isMobile) {
    // Define columns for mobile view
    columns.push(
      {
        name: "Date",
        selector: (row) => row.date,
        width: "100px",
        // sortable: true,
      },
      {
        name: (
          <span>
            Project
            <br />
            Name
          </span>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              {/* {config_product_code_display == "Y" && row?.product_code
                ? `(${row.product_code})`
                : ""} */}
            </div>
          </>
        ),
        width: "130px",
        // sortable: true,
      },
      {
        name: (
          <span>
            Your Total
            <br />
            Time Invested
          </span>
        ),
        selector: (row) => (
          <span className="badge rounded-pill text-bg-success">
            {" "}
            {row.current_year_hours ? row.current_year_hours : "---"}
          </span>
        ),
        width: "110px",
        // sortable: true,
      }
    );
  } else {
    // Define columns for desktop view
    columns.push(
      {
        name: "Date",
        selector: (row) => row.date,
        width: "90px",
        // sortable: true,
      },
      {
        name: "Project Name",
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              {/* {config_product_code_display == "Y" && row?.product_code
                ? `(${row.product_code})`
                : ""} */}
            </div>
          </>
        ),
        width: "300px",
        // sortable: true,
      },
      {
        name: <span>Your Total Time Invested</span>,
        selector: (row) => (
          <span className="badge rounded-pill text-bg-success">
            {" "}
            {row.current_year_hours ? row.current_year_hours : "---"}
          </span>
        ),
        width: "130px",
        // sortable: true,
        style: {
          display: "flex !important",
          justifyContent: "center !important",
        },
      },
      {
        name: "Year",
        selector: (row) =>
          row.financialYearStart && row.financialYearEnd
            ? `${2000 + parseInt(row.financialYearStart, 10)}-${
                row.financialYearEnd
              }`
            : "---",
        width: "100px",
        // sortable: true,
        style: {
          display: "flex !important",
          justifyContent: "center !important",
        },
      },
      {
        name: (
          <span>
            Budget
            <br />
            hours
          </span>
        ),
        selector: (row) => (row.budget_hours ? row.budget_hours : "---"),
        width: "90px",
        // sortable: true,
      }
    );
  }

  // const ExpandedComponent = ({ data }) => (
  //   <div>
  //     <p style={{ marginBottom: "0px" }}>
  //       <span className="expand-header">Working hours</span> :
  //       <span className="expand-subheader"> {data.working_h}</span>
  //     </p>
  //   </div>
  // );

  const ExpandedComponent = ({ data }) => (
    <>
      {isMobile ? (
        <>
          <p style={{ marginBottom: "0px", marginBlock: "0px" }}>
            <span className="expand-header">Year</span> :
            <span className="expand-subheader">
              {data.financialYearStart && data.financialYearEnd
                ? `${2000 + parseInt(data.financialYearStart, 10)}-${
                    data.financialYearEnd
                  }`
                : "---"}
            </span>
          </p>
          <p style={{ marginBottom: "0px", marginBlock: "0px" }}>
            <span className="expand-header">Budget hours</span> :
            <span className="expand-subheader">
              {data.budget_hours ? data.budget_hours : "---"}
            </span>
          </p>
        </>
      ) : (
        ""
      )}

      <div className="my-2">
        <Table striped bordered hover size="sm">
          {workinghoursdata_emp.length != 0 ? (
            <>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Username</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {workinghoursdata_emp.map((row, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{row.employee_name}</td>
                    <td>{row.working_h}</td>
                  </tr>
                ))}
              </tbody>{" "}
            </>
          ) : (
            <div className="text-center fw-bold">No data found</div>
          )}
        </Table>
      </div>
    </>
  );

  // filterdata
  const applyDateFilter = (from_date, to_date) => {
    if (from_date && to_date) {
      const filteredData = workinghoursdata.filter((item) => {
        const attDate = moment(item.date, "DD/MM/YYYY"); // Parse date with moment.js
        const isAfterOrEqualFromDate = attDate.isSameOrAfter(
          moment(from_date, "DD/MM/YYYY"),
          "day"
        );
        const isBeforeOrEqualToDate = attDate.isSameOrBefore(
          moment(to_date, "DD/MM/YYYY"),
          "day"
        );
        return isAfterOrEqualFromDate && isBeforeOrEqualToDate;
      });
      console.log("applydatafilter:", filteredData); // Log the array directly without concatenation
      setFilterworkinghoursdata(filteredData);
    } else {
      // If start date or end date is not selected, set filtered data to empty array
      setFilterworkinghoursdata([]);
    }
  };

  const getWorkingHours = async (from_date, to_date) => {
    setSearchbtn(true);
    try {
      const response = await axios({
        url: `${baseurl}/expo_access_api/working_hours/`,
        method: "POST", // Specify the method as "POST"
        headers: {
          Authorization: AUTHORIZE_KEY,
          "Content-Type": "application/json",
        },
        data: {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          employee_id: emp_id,
          fromdt: moment(from_date).format("DD-MM-YY"),
          todt: moment(to_date).format("DD-MM-YY"),
        },
      });

      const { data } = response; // Destructure response data

      if (data && data[0]?.DATA?.working_hours) {
        const message = data[0].MSG;
        setDatainfo(message);
        const workingHours = data[0].DATA.working_hours;
        setWorkinghoursdata(workingHours); // Combined state updates
        setFilterworkinghoursdata(workingHours);
        setSearchbtn(false);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setWorkinghoursdata([]);
        setFilterworkinghoursdata([]);
        setSearchbtn(false);
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  const getWorkingHours_employee = async (orderid) => {
    try {
      const postData = {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        employee_id: emp_id,
        order_id: orderid,
        fromdt: moment(from_date).format("DD-MM-YY"),
        todt: moment(to_date).format("DD-MM-YY"),
      };

      const response = await axios({
        method: "POST",
        url: `${baseurl}/expo_access_api/working_hours_in_details/`,
        headers: {
          Authorization: AUTHORIZE_KEY,
          "Content-Type": "application/json",
        },
        data: postData,
      });

      const { data } = response; // Destructure response data
      console.log(data, "working_emp");

      if (data && data[0]?.DATA?.working_hours) {
        const workingHours = data[0].DATA.working_hours;
        setWorkinghoursdataemp(workingHours); // Combined state updates
      } else {
        // const message = data[0].MSG;
        // setDatainfo(message);
        setWorkinghoursdataemp([]);
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  useEffect(() => {
    getWorkingHours(from_date, to_date);
    // getWorkingHours_employee()
  }, []);

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      {
        header: "Date",
        key: "date",
        width: 12,
      },
      {
        header: "Project name",
        key: "productname",
        width: 45,
      },
      {
        header: "Your Total Time Invested",
        key: "current_year_hours",
        width: 15,
      },
      {
        header: "Year",
        key: "year",
        width: 8,
      },
      {
        header: "Budget Hours",
        key: "budget_hours",
        width: 15,
      },
    ];

    const promise = Promise.all(
      filterworkinghoursdata.map(async (product, index) => {
        let date = product?.date;
        let year;

        if (date && date.includes("29/03/2024")) {
          year = date.split("/")[2];
        }
        sheet.addRow({
          id: product?.id,
          date: product?.date,
          productname: product?.problem_title,
          // project: product?.project,
          branch: product?.branch_name,
          current_year_hours: product?.current_year_hours,
          year:
            product.financialYearStart && product.financialYearEnd
              ? `${2000 + parseInt(product.financialYearStart, 10)}-${
                  product.financialYearEnd
                }`
              : "---",
          budget_hours: product?.budget_hours,
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Working_hours.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <div>
      <div className="text-center">
        <img
          src={`${baseurl}/public/images/supportportal/my_working_hours.png`}
          alt="track-order"
          style={{ width: "80px", height: "80px" }}
        />
        <h1 className="login-header">
          {userdetails?.username}, View your Working-hours reports!
        </h1>
        <p className="sub-header">
          Review your timesheet reports with precision and clarity. Stay
          informed about your work hours,
          {isMobile ? "" : <br />}
          tasks completed, and project progress effortlessly.
        </p>
      </div>

      {/* <Col sm="12" xl="6"> */}
      <div className="d-flex justify-content-center align-items-center mx-3">
        <Row className="d-flex justify-content-around align-items-center">
          <Col sm="12" xl="5">
            <Form className="theme-form">
              <FormGroup
                className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
              >
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  From Date:
                </Label>
                <InputGroup
                  className="date"
                  id="dt-minimum"
                  data-target-input="nearest"
                >
                  <DatePicker
                    className="form-control datetimepicker-input digits input-register"
                    selected={from_date}
                    onChange={handleChangeFromTime}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy "
                    // minDate={moment().startOf('month').toDate()}
                    // maxDate={moment().endOf('month').toDate()}
                  />
                  <div
                    className="input-group-text"
                    data-target="#dt-date"
                    data-toggle="datetimepicker"
                  >
                    <i className="fa fa-calendar"></i>
                  </div>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>

          {/* </Col> */}

          <Col sm="12" xl="4">
            <Form className="theme-form">
              <FormGroup className={` label-gap ${isMobile ? "" : "mb-4"}`}>
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  To Date:
                </Label>
                {/* <Col xl="5" sm="7" lg="8"> */}
                <InputGroup
                  className="date flex-nowrap"
                  id="dt-minimum"
                  data-target-input="nearest"
                >
                  <DatePicker
                    className="form-control datetimepicker-input digits input-register"
                    selected={to_date}
                    onChange={handleChangeToTime}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy "
                    // minDate={from_date}
                    // maxDate={moment().endOf('month').toDate()}
                  />
                  <div
                    className="input-group-text"
                    data-target="#dt-date"
                    data-toggle="datetimepicker"
                  >
                    <i className="fa fa-calendar"></i>
                  </div>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>

          <Col
            sm="12"
            xl="3"
            className="align-items-center justify-content-center"
            style={{ marginTop: "10px" }}
          >
            {/* <Col xl="5" sm="7" lg="8"> */}
            <Button
              className="searchbtn "
              onClick={() => getWorkingHours(from_date, to_date)}
              disabled={searchbtn}
            >
              Search
            </Button>
          </Col>
        </Row>
      </div>
      {/* </Col> */}
      {/* <div className="d-flex justify-content-center align-items-center">
        <Button
          className="searchbtn "
          onClick={() => getWorkingHours(from_date, to_date)}
          disabled={searchbtn}
        >
          Search
        </Button>
      </div> */}
      {isMobile ? (
        <Container>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : workinghoursdata?.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-center my-3">
                <Input
                  className="form-control register-full input-register"
                  type="text"
                  placeholder="Search all fields..."
                  value={searchtext}
                  onChange={handleSearch}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button className="searchbtn my-2" onClick={exportExcelFile}>
                  Export
                </Button>
              </div>
              <DataTable
                // className="custom-data-table"
                columns={columns}
                data={filterworkinghoursdata}
                pagination
                paginationComponentOptions={paginationOptions}
                customStyles={customStyles}
                expandableRows
                expandableRowExpanded={(row) => row === currentRow}
                expandOnRowClicked
                onRowClicked={(row) => {
                  console.log(row, "row");
                  handleRowExpandToggle(row);
                }}
                onRowExpandToggled={(bool, row) => {
                  console.log(row, "rowwww");
                  console.log(bool, "bool");
                  handleRowExpandToggle(row);
                }}
                expandableRowsComponent={ExpandedComponent}
                persistTableHead={true}
              />
            </div>
          )}
        </Container>
      ) : (
        <div>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : workinghoursdata?.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="export-working d-flex justify-content-between my-2">
                <div>
                  <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Search all fields..."
                    value={searchtext}
                    onChange={handleSearch}
                  />
                </div>
                <div>
                  <Button className="searchbtn my-2" onClick={exportExcelFile}>
                    Export
                  </Button>
                </div>
              </div>
              <DataTable
                className="custom-data-table data-workingproject"
                columns={columns}
                data={filterworkinghoursdata}
                pagination
                paginationComponentOptions={paginationOptions}
                customStyles={customStyles}
                expandableRows
                expandableRowExpanded={(row) => row === currentRow}
                expandOnRowClicked
                onRowClicked={(row) => {
                  console.log(row, "row");
                  handleRowExpandToggle(row);
                }}
                onRowExpandToggled={(bool, row) => {
                  console.log(row, "rowwww");
                  console.log(bool, "bool");
                  handleRowExpandToggle(row);
                }}
                expandableRowsComponent={ExpandedComponent}
                persistTableHead={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Workingreport;

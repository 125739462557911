// baseUrlSlice.js
import { createSlice } from "@reduxjs/toolkit";

const baseUrlSlice = createSlice({
  name: "baseUrl",
  initialState: "https://r1services.ondemandcrm.co", //window.location.origin//   // Initial state can be an empty string or your default base URL.
  reducers: {
    setBaseUrl: (state, action) => {
      return action.payload;
    },
  },
});

export const { setBaseUrl } = baseUrlSlice.actions;

export const selectBaseUrl = (state) => state.baseUrl;

export default baseUrlSlice.reducer;

import React, { useRef, useState } from "react";
import DataTable from "react-data-table-component";
import myreport from "../../../src/assets/images/supportportal/myreport.png";
import { isMobile } from "react-device-detect";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import myworkinghours from "../../../src/assets/images/supportportal/my_working_hours.png";
import myloginhours from "../../../src/assets/images/supportportal/my_login_hours.png";
import mybreakhours from "../../../src/assets/images/supportportal/my_break_hours.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
// import styled from "styled-components";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useContext } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactSelect from "react-select";
const ExcelJS = require("exceljs");

const Employeewisereport = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  const [searchtext, setSearchtext] = useState("");
  const [searchproject, setSearchproject] = useState("");
  const [searchuser, setSearchuser] = useState("");
  const [from_date, setFromdate] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [to_date, setTodate] = useState(moment().toDate());
  const [employeewisedata, setEmployeewisedata] = useState([]);
  const [filteremployeewisedata, setFilteremployeewisedata] = useState([]);
  const [data_info, setDatainfo] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [searchbtn, setSearchbtn] = useState(false);
  const [employeelist, setEmployeelist] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  console.log(employeelist, "employeelist..");
  console.log(selectedUser, "selectedUser...");
  const configContactDisplay = localStorage.getItem("config_contact_display");
  console.log(employeewisedata, "employeewisedata...");
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  const IS_ADMIN = localStorage.getItem("is_admin");

  console.log(moment(from_date).format("DD-MM-YYYY"));
  console.log(to_date, "to_date");
  console.log(searchuser, "searchuser...");
  console.log(searchproject, "searchproject...");

  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // filter
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = employeewisedata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (typeof item[key] == "string") {
          // Check if the value is a string
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilteremployeewisedata(filteredItems);
  };

  const columns = [];

  if (isMobile) {
    // Define columns for mobile view
    columns.push(
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>User Name</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.personname ? row.personname : "---"}{" "}
              <div style={{ textAlign: "start" }}>{row.project}</div>
            </div>
          </>
        ),
        width: "120px",
        // sortable: true,
      },
      {
        name: (
          <div className="text-wrap-common">
            <span style={{ fontSize: "11px" }}>Break Time</span>
          </div>
        ),
        selector: (row) => (row.breaktime ? row.breaktime : "---"),
        width: "110px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              <span style={{ fontSize: "11px" }}>Working Hours</span>
            </div>
          </div>
        ),
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.loggedhorus ? row.loggedhorus : "---"}
            </div>
          </div>
        ),
        width: "110px",
        // sortable: true,
      }
    );
  } else {
    // Define columns for desktop view
    columns.push(
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>User Name</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.personname ? row.personname : "---"}{" "}
              <div style={{ textAlign: "start" }}>{row.project}</div>
            </div>
          </>
        ),
        width: "250px",
        // sortable: true,
      },
      {
        name: (
          <div className="text-wrap-common">
            <span style={{ fontSize: "11px" }}>Break Time</span>
          </div>
        ),
        selector: (row) => (row.breaktime ? row.breaktime : "---"),
        width: "165px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              <span style={{ fontSize: "11px" }}>Working Hours</span>
            </div>
          </div>
        ),
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.loggedhorus ? row.loggedhorus : "---"}
            </div>
          </div>
        ),
        width: "165px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Timesheet Hours</span>,
        selector: (row) => (row.timesheethorus ? row.timesheethorus : "---"),
        width: "165px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Difference</span>,
        selector: (row) => (row.difference ? row.difference : "---"),
        width: "165px",
        // sortable: true,
      }
    );
  }

  const getemployeewisereport = async (from_date, to_date, selectedUser_Id) => {
    console.log(selectedUser_Id, "selectedUser_Id....");
    setSearchbtn(true);
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/employeewisedata/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          fromdt: moment(from_date).format("DD-MM-YY"),
          todt: moment(to_date).format("DD-MM-YY"),
          is_admin: selectedUser_Id ? "" : IS_ADMIN,
          employee_id: selectedUser_Id || emp_id,
        },
      );
      console.log(res, "getemployeewisereport");
      const { data } = res; // Destructure response data

      if (
        data &&
        data[0]?.STATUS == "SUCCESS" &&
        data[0]?.DATA?.employeewisedata
      ) {
        const message = data[0].MSG;
        setDatainfo(message);
        setEmployeewisedata(data[0]?.DATA?.employeewisedata);
        setFilteremployeewisedata(data[0]?.DATA?.employeewisedata);
        setSearchbtn(false);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setEmployeewisedata([]);
        setFilteremployeewisedata([]);
        setSearchbtn(false);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const getemployeelist = async () => {
    try {
      const res = await axios.post(
        baseurl + "/supportportalapi/employee_list",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          PHPTOKEN: localStorage.getItem("id_token"),
        },
      );
      console.log(res, "employeelist..");
      const { data } = res; // Destructure response data

      if (data && data[0]?.DATA?.employee_arr) {
        // const message = data[0].MSG;
        setEmployeelist(data[0]?.DATA?.employee_arr);
      } else {
        // const message = data[0].MSG;
        setEmployeelist([]);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    getemployeewisereport(from_date, to_date, selectedUser?.value);
    getemployeelist();
  }, []);

  const handleChangeFromTime = (date) => {
    setFromdate(date);
  };

  const handleChangeToTime = (date) => {
    setTodate(date);
  };

  const ExpandedComponent = ({ data }) => (
    <div>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Time Sheet Hours</span> :
        <span className="expand-subheader">
          {data.timesheethorus ? data.timesheethorus : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Difference</span> :
        <span className="expand-subheader">
          {data.difference ? data.difference : "---"}
        </span>
      </p>
    </div>
  );

  const handleOnSelectUser = (selectedOptionuser) => {
    console.log(selectedOptionuser, "selectedOptionuser...");
    setSelectedUser(selectedOptionuser);
  };

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      {
        header: "User name",
        key: "personname",
        width: 20,
      },
      {
        header: "Break Time",
        key: "breaktime",
        width: 15,
      },
      {
        header: "Working Hours",
        key: "loggedhorus",
        width: 15,
      },
      {
        header: "TimeSheetHours",
        key: "timesheethorus",
        width: 18,
      },
      {
        header: "Difference",
        key: "difference",
        width: 15,
      },
    ];

    const promise = Promise.all(
      filteremployeewisedata.map(async (product, index) => {
        let date = product?.date;
        let year;

        if (date && date.includes("29/03/2024")) {
          year = date.split("/")[2];
        }
        sheet.addRow({
          id: product?.id,
          personname: product?.personname,
          loggedhorus: product?.loggedhorus,
          breaktime: product?.breaktime,
          timesheethorus: product?.timesheethorus,
          difference: product?.difference,
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Employeewise_report.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <div>
      <div className="text-center mt-2">
        <img
          src={`${baseurl}/public/images/supportportal/myreport.png`}
          alt="track-order"
          style={{ width: "80px", height: "80px" }}
        />
        <h1 className="login-header">
          {userdetails?.username}, View your Employeewise reports!
        </h1>
        <p className="sub-header">
          Review your timesheet reports with precision and clarity. Stay
          informed about your work hours,
          {isMobile ? "" : <br />}
          tasks completed, and project progress effortlessly.
        </p>
      </div>
      <div className="d-flex justify-content-center align-items-center mx-3">
        <Row className="d-flex justify-content-around align-items-center">
          <Col sm="12" xl="3">
            <Form className="theme-form">
              <FormGroup
                className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
              >
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  From Date:
                </Label>
                <InputGroup
                  className="date flex-nowrap"
                  id="dt-minimum"
                  data-target-input="nearest"
                >
                  <DatePicker
                    className="form-control datetimepicker-input digits input-register"
                    selected={from_date}
                    onChange={handleChangeFromTime}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy "
                    // minDate={moment().startOf('month').toDate()}
                    // maxDate={moment().endOf('month').toDate()}
                  />
                  <div
                    className="input-group-text"
                    data-target="#dt-date"
                    data-toggle="datetimepicker"
                  >
                    <i className="fa fa-calendar"></i>
                  </div>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>

          {/* </Col> */}

          <Col sm="12" xl="3">
            <Form className="theme-form">
              <FormGroup className={` label-gap ${isMobile ? "" : "mb-4"}`}>
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  To Date:
                </Label>
                {/* <Col xl="5" sm="7" lg="8"> */}
                <InputGroup
                  className="date flex-nowrap"
                  id="dt-minimum"
                  data-target-input="nearest"
                >
                  <DatePicker
                    className="form-control datetimepicker-input digits input-register"
                    selected={to_date}
                    onChange={handleChangeToTime}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy "
                    // minDate={from_date}
                    // maxDate={moment().endOf('month').toDate()}
                  />
                  <div
                    className="input-group-text"
                    data-target="#dt-date"
                    data-toggle="datetimepicker"
                  >
                    <i className="fa fa-calendar"></i>
                  </div>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>

          {/* <Col sm="12" xl="3">
            <Form className="theme-form">
              <FormGroup
                className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
                // style={{ width: "230px" }}
              >
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  Enter Project Name:
                </Label>
                <div>
                  <Input
                    className="form-control register-full input-register input-project"
                    type="text"
                    placeholder="Enter project..."
                    value={searchproject}
                    onChange={(e) => {
                      setSearchproject(e.target.value);
                    }}
                  />
                </div>
              </FormGroup>
            </Form>
          </Col> */}

          <Col sm="12" xl="3">
            <Form className="theme-form">
              <FormGroup
                className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
              >
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  Select User:
                </Label>
                <div className="product-select input-user">
                  <ReactSelect
                    className=""
                    options={employeelist.map((option) => ({
                      value: option.employee_id,
                      label: option.name,
                    }))}
                    isClearable={true}
                    value={selectedUser}
                    onChange={(selectedoption) => {
                      handleOnSelectUser(selectedoption);
                    }}
                    // onMenuClose={(clearoption) => handleOnClearUser(clearoption)} // This will trigger when the dropdown menu is closed, after a selection or clear
                    menuPortalTarget={document.body} // Render the dropdown outside parent containers
                    placeholder="Select user..." // Change the placeholder to "Select user"
                  />
                </div>
              </FormGroup>
            </Form>
          </Col>

          <Col
            sm="12"
            xl="12"
            className="d-flex align-items-center justify-content-center"
          >
            <Button
              className="searchbtn "
              onClick={() => {
                setEmployeewisedata([]);
                getemployeewisereport(from_date, to_date, selectedUser?.value);
              }}
              disabled={searchbtn}
              // style={{ marginTop: "10px" }}
            >
              Search
            </Button>
          </Col>
        </Row>
      </div>
      {/* </Col> */}
      {/* </div> */}
      {isMobile ? (
        <Container>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : employeewisedata?.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-center my-3">
                <Input
                  className="form-control register-full input-register"
                  type="text"
                  placeholder="Search all fields..."
                  value={searchtext}
                  onChange={handleSearch}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button className="searchbtn my-2" onClick={exportExcelFile}>
                  Export
                </Button>
              </div>
              <DataTable
                //  className="custom-data-table"
                columns={columns}
                data={filteremployeewisedata}
                pagination
                paginationComponentOptions={paginationOptions}
                customStyles={customStyles}
                expandableRows
                expandableRowExpanded={(row) => row === currentRow}
                expandOnRowClicked
                onRowClicked={(row) => setCurrentRow(row)}
                onRowExpandToggled={(bool, row) => {
                  setCurrentRow(row);
                }}
                expandableRowsComponent={ExpandedComponent}
                persistTableHead={true}
              />
            </div>
          )}
        </Container>
      ) : (
        <div>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : employeewisedata.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="export-employeewise-report d-flex justify-content-between my-2">
                <div>
                  <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Search all fields..."
                    value={searchtext}
                    onChange={handleSearch}
                  />
                </div>
                <div>
                  <Button className="searchbtn my-2" onClick={exportExcelFile}>
                    Export
                  </Button>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <DataTable
                  // className="table-extra"
                  className="custom-data-table data-timesheetproject"
                  columns={columns}
                  data={filteremployeewisedata}
                  pagination
                  paginationComponentOptions={paginationOptions}
                  customStyles={customStyles}
                  persistTableHead={true}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Employeewisereport;

import React, { Fragment, forwardRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Table,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import myproject from "../../../src/assets/images/supportportal/myproject.png";
import myreport from "../../../src/assets/images/supportportal/myreport.png";
import logout from "../../../src/assets/images/supportportal/logout.png";
import medicalicon from "../../../src/assets/images/supportportal/medical-icon.jpg";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Btn, H4, H5, H6, Image, LI, P, UL } from "../../AbstractElements";
import { WhatsNew } from "../../Constant";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { MdOutlineCancel } from "react-icons/md";
import { IoAlertCircleOutline, IoLockClosedOutline } from "react-icons/io5";
import { GoIssueClosed } from "react-icons/go";
import { additem } from "../../utils/supportrequestSlice";
import ReactApexChart from "react-apexcharts";
import { ShoppingBag } from "react-feather";
import {
  MarketChart,
  progress1,
  progress2,
  progress3,
  progress4,
  progress5,
} from "../Common/Data/ApexChart";
import Chart from "react-apexcharts";
import {
  FaCheckCircle,
  FaClock,
  FaFolder,
  FaHourglass,
  FaStopwatch,
} from "react-icons/fa";
import CountUp from "react-countup";
import HeaderCard from "../Common/Component/HeaderCard";
import {
  Category_Wise_Time_Spent,
  Monthly_Attendence_Graph_data,
  Project_Wise_Time_Spent,
  Working_Hours_data,
} from "./Chartutils";
import DatePicker from "react-datepicker";
import moment from "moment";
import { isMobile } from "react-device-detect";
import avatar_image from "../../../src/assets/images/dashboard/avatar_timesheet_profile.png";
import "./supportportal.scss";

const Timesheetdashboard = () => {
  const baseurl = useSelector((store) => store.baseUrl);
  const is_admin = localStorage.getItem("is_admin");
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [widget_data, setWidgetdata] = useState({});
  const [employee_attendence_data, setEmployeeattendencedata] = useState({});
  const [categorywise_data, setCategorywisedata] = useState([]);
  const [projectwise_data, setProjectwisedata] = useState([]);
  const [employee_tracker_data, setEmployeetrackerdata] = useState([]);
  const [workinghours_data, setWorkinghoursdata] = useState([]);
  const [monthly_attendence_date, setMonthlyattendencedate] = useState(
    new Date()
  );
  const [categorywise_date, setCategorywisedate] = useState(new Date());
  const [projectwise_date, setProjectwisedate] = useState(new Date());
  const [workinghours_date, setWorkinghoursdate] = useState(new Date());

  console.log(employee_attendence_data, "employee_attendence_data...");
  console.log(categorywise_data, "categorywise_data....");

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  // user dashboard start
  const history = useNavigate();
  const dispatch = useDispatch();
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  // dashboardboxes
  const dashboardboxes = [
    {
      text: "My projects",
      img: `${baseurl}/public/images/supportportal/myproject.png`,
    },
    {
      text: "My reports",
      img: `${baseurl}/public/images/supportportal/myreport.png`,
    },
    {
      text: "Logout",
      img: `${baseurl}/public/images/supportportal/logout.png`,
    },
  ];

  const Logout = () => {
    if (
      JSON.parse(localStorage.getItem("punchin")) == null &&
      JSON.parse(localStorage.getItem("punchout")) == null
    ) {
      console.log("if loop");
      // Default case when punchincheck is true
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      history(`${process.env.PUBLIC_URL}/login`);
      dispatch(additem([]));
      // Add any default behavior here
    } else if (
      JSON.parse(localStorage.getItem("punchin")) == false &&
      JSON.parse(localStorage.getItem("punchout")) == true
    ) {
      // Case when punchincheck is false, but punchoutcheck is true
      console.log("first else if loop");
      Swal.fire(
        "Please remember to perform a punch-out before logging out of the system!"
      );
    } else if (
      JSON.parse(localStorage.getItem("punchin")) == false &&
      JSON.parse(localStorage.getItem("punchout")) == false
    ) {
      console.log("second elseif loop");
      // Case when both punchincheck and punchoutcheck are false
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      history(`${process.env.PUBLIC_URL}/login`);
      dispatch(additem([]));
    } else if (JSON.parse(localStorage.getItem("punchin")) == true) {
      console.log("second elseif loop");
      // Case when both punchincheck and punchoutcheck are false
      localStorage.setItem("login", false);
      localStorage.removeItem("id_token");
      localStorage.removeItem("punchin");
      localStorage.removeItem("punchout");
      localStorage.removeItem("breakin");
      localStorage.removeItem("breakout");
      localStorage.removeItem("employeeid");
      localStorage.removeItem("userdetails");
      localStorage.removeItem("attr_id");
      localStorage.removeItem("emp_in_time");
      localStorage.removeItem("emp_out_time");
      localStorage.removeItem("breakid");
      localStorage.removeItem("config_contact_display");
      localStorage.removeItem("config_product_code_display");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("showtask");
      localStorage.removeItem("object_type");
      history(`${process.env.PUBLIC_URL}/login`);
      dispatch(additem([]));
    }
  };

  // userdashboard end

  const employee_attendence_graph_api = async (from_date) => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/employee_attendence_graph/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          month: moment(from_date).format("MM"),
          year: moment(from_date).format("YYYY"),
        },
      );
      console.log(res, "supportrequest");
      const { data } = res; // Destructure response data
      console.log(data, "employee_attendence_graph...");
      console.log(
        data && data[0].STATUS === "SUCCESS" && data[0]?.Daily_Attendence,
        "data && data[0].STATUS"
      );

      if (data && data[0].STATUS === "SUCCESS" && data[0]?.Daily_Attendence) {
        const { series, options } = data[0]?.Daily_Attendence;
        console.log(series, "series..");
        console.log(options, "options..");
        if (series && options && options?.categories) {
          // Both series and options categories data are available
          console.log("Both series and options categories data are available");
          // Set the series and options categories data in state variables
          const formattedChartData = Monthly_Attendence_Graph_data(
            data[0]?.Daily_Attendence
          );
          console.log(formattedChartData, "formattedChartData...");
          setEmployeeattendencedata(formattedChartData);
          console.log(
            employee_attendence_data,
            "setEmployeeattendencedata........."
          );
        } else {
          console.log("Series or options categories data is missing");
          setEmployeeattendencedata([]);
          // Handle the case where either series or options categories data is missing
        }
      } else {
        console.log("Data not found or status is not SUCCESS");
        setEmployeeattendencedata([]);
        // Handle the case where data is not found or status is not SUCCESS
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const category_wise_time_spent_api = async (from_date) => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/Category_graph/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          date: moment(from_date).format("DD-MM-YY"),
        },
      );
      console.log(res, "supportrequest");
      const { data } = res; // Destructure response data
      console.log(data, "category_wise_time_spent...");
      console.log(
        data &&
          data[0].STATUS === "SUCCESS" &&
          data[0]?.Category_Wise_Time_Spent,
        "data && data[0].STATUS"
      );

      if (
        data &&
        data[0].STATUS === "SUCCESS" &&
        data[0]?.Category_Wise_Time_Spent
      ) {
        const { series } = data[0]?.Category_Wise_Time_Spent;
        console.log(series, "series_category_wise_time_spent..");
        if (series) {
          // Both series and options categories data are available
          console.log("Both series and options categories data are available");
          // Set the series and options categories data in state variables
          const formattedChartData = Category_Wise_Time_Spent(
            data[0]?.Category_Wise_Time_Spent
          );
          console.log(formattedChartData, "formattedChartData...");
          setCategorywisedata(formattedChartData);
          console.log(categorywise_data, "setcategorywisedata.........");
        } else {
          console.log("Series or options categories data is missing");
          setCategorywisedata([]);
          // Handle the case where either series or options categories data is missing
        }
      } else {
        console.log("Data not found or status is not SUCCESS");
        setCategorywisedata([]);
        // Handle the case where data is not found or status is not SUCCESS
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const project_wise_time_spent_api = async (from_date) => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/Project_graph/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          date: moment(from_date).format("DD-MM-YY"),
        },
      );
      console.log(res, "supportrequest");
      const { data } = res; // Destructure response data
      console.log(data, "project_wise_time_spent...");
      console.log(
        data && data[0].STATUS === "SUCCESS" && data[0]?.Data,
        "data && data[0].STATUS"
      );

      if (data && data[0].STATUS === "SUCCESS" && data[0]?.Data) {
        const { series } = data[0]?.Data;
        console.log(series, "series..project_wise_time_spent..");
        if (series) {
          // Both series and options categories data are available
          console.log("Both series and options categories data are available");
          // Set the series and options categories data in state variables
          const formattedChartData = Project_Wise_Time_Spent(data[0]?.Data);
          console.log(formattedChartData, "formattedChartData...");
          setProjectwisedata(formattedChartData);
          console.log(projectwise_data, "projectwise_data......");
        } else {
          console.log("Series or options categories data is missing");
          setProjectwisedata([]);
          // Handle the case where either series or options categories data is missing
        }
      } else {
        console.log("Data not found or status is not SUCCESS");
        setProjectwisedata([]);
        // Handle the case where data is not found or status is not SUCCESS
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const employee_attendence_tracker_api = async (from_date, to_date) => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/EmployeeAttendenceTracker/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
        },
      );
      console.log(res, "supportrequest");
      const { data } = res; // Destructure response data
      console.log(data, "employee_attendence_tracker...");
      console.log(
        data && data[0].STATUS === "SUCCESS" && data[0]?.Data,
        "data && data[0].STATUS"
      );

      if (data && data[0].STATUS === "SUCCESS" && data[0]?.Data?.series) {
        // Set the series and options categories data in state variables
        setEmployeetrackerdata(data[0]?.Data?.series);
      } else {
        console.log("Data not found or status is not SUCCESS");
        setEmployeetrackerdata([]);
        // Handle the case where data is not found or status is not SUCCESS
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const employee_working_hours_api = async (from_date) => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/EmployeeProjectParticipation/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          date: moment(from_date).format("DD-MM-YY"),
        },
      );
      console.log(res, "supportrequest");
      const { data } = res; // Destructure response data
      console.log(data, "workinghours_data...");
      console.log(
        data && data[0].STATUS === "SUCCESS" && data[0]?.Data,
        "data && data[0].STATUS"
      );

      if (data && data[0].STATUS === "SUCCESS" && data[0]?.Data) {
        const { series } = data[0]?.Data;
        console.log(series, "series..workinghours_data..");
        if (series) {
          // Both series and options categories data are available
          console.log("Both series and options categories data are available");
          // Set the series and options categories data in state variables
          const formattedChartData = Working_Hours_data(data[0]?.Data);
          console.log(
            formattedChartData,
            "formattedChartData...workinghours_data"
          );
          setWorkinghoursdata(formattedChartData);
          console.log(workinghours_data, "workinghours_data......");
        } else {
          console.log("Series or options categories data is missing");
          setWorkinghoursdata([]);
          // Handle the case where either series or options categories data is missing
        }
      } else {
        console.log("Data not found or status is not SUCCESS");
        setWorkinghoursdata([]);
        // Handle the case where data is not found or status is not SUCCESS
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const widget_data_api = async () => {
    try {
      const res = await axios.post(
        baseurl + "/expo_access_api/EmployeeTodayData/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
        },
      );
      console.log(res, "supportrequest");
      const { data } = res; // Destructure response data
      console.log(data, "widget_data_api...");
      console.log(
        data && data[0].STATUS === "SUCCESS" && data[0]?.Data,
        "data && data[0].STATUS"
      );

      if (data && data[0].STATUS === "SUCCESS" && data[0]?.Data) {
        // Set the series and options categories data in state variables
        setWidgetdata(data[0]?.Data);
      } else {
        console.log("Data not found or status is not SUCCESS");
        setWidgetdata({});
        // Handle the case where data is not found or status is not SUCCESS
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const formatSubtitle = (subTitle) => {
    const [hours, minutes] = subTitle.split(":").map(Number);

    if (hours == 0) {
      return `${minutes} minutes ago`;
    } else if (minutes == 0) {
      return `${hours} hours ago`;
    } else {
      return `${hours} hours ${minutes} minutes ago`;
    }
  };

  useEffect(() => {
    employee_attendence_graph_api(monthly_attendence_date);
    category_wise_time_spent_api(categorywise_date);
    project_wise_time_spent_api(projectwise_date);
    employee_attendence_tracker_api();
    employee_working_hours_api(workinghours_date);
    widget_data_api();
  }, []);

  // Attendence rate
  const presence_employee =
    parseInt(widget_data?.employee_attendence_rate?.presence_employee) || 0;
  const total_employee =
    parseInt(widget_data?.employee_attendence_rate?.total_employee) || 1; // To prevent division by zero

  const attendanceRate = ((presence_employee / total_employee) * 100).toFixed(
    2
  );

  // Average Hours Per Employee
  const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time?.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Convert seconds to time string (HH:MM:SS)
  const secondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    // Round remainingSeconds
    remainingSeconds = Math.round(remainingSeconds);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    console.log(formattedHours, "formattedHours..");
    console.log(formattedMinutes, "formattedMinutes..");
    console.log(formattedSeconds, "formattedSeconds..");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  // Test the function
  console.log(secondsToTime(6217.142857142857338)); // Output: "01:43:37"

  const totalSeconds = timeToSeconds(
    widget_data?.average_hours_per_employee?.total_project_hours || "00:00:00"
  );
  const numberOfEmployee =
    parseInt(widget_data?.average_hours_per_employee?.number_of_employee) || 1; // To prevent division by zero

  const averageSeconds = totalSeconds / numberOfEmployee;
  console.log(totalSeconds, "totalSeconds..");
  console.log(numberOfEmployee, "numberOfEmployee..");

  const averageTime = secondsToTime(averageSeconds);
  console.log(averageTime, "averageTime...");

  //Average Time Spent Per Project

  const timeToSeconds_Project = (time) => {
    const [hours, minutes, seconds] = time?.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Convert seconds to time string (HH:MM:SS)
  // const secondsToTime_Project = (seconds) => {
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);
  //   const remainingSeconds = seconds % 60;
  //   console.log(hours, "hours...")
  //   console.log(minutes, "minutes...")
  //   console.log(remainingSeconds, "remainingSeconds...")
  //   const formattedHours = hours.toString().padStart(2, '0');
  //   const formattedMinutes = minutes.toString().padStart(2, '0');
  //   const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
  //   console.log(formattedHours,"formattedHours")
  //   console.log(formattedMinutes,"formattedMinutes")
  //   console.log(formattedSeconds,"formattedSeconds")

  //   // if (hours == 0 && minutes == 0 && remainingSeconds == 0) {
  //   //   return "00:00:00";
  //   // }

  //   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  // };

  const secondsToTime_Project = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    // Round remainingSeconds
    remainingSeconds = Math.round(remainingSeconds);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    console.log(formattedHours, "formattedHours..");
    console.log(formattedMinutes, "formattedMinutes..");
    console.log(formattedSeconds, "formattedSeconds..");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const total_project_hours = timeToSeconds_Project(
    widget_data?.average_timespent_per_project?.total_project_hours ||
      "00:00:00"
  );
  const number_of_projects =
    parseInt(widget_data?.average_timespent_per_project?.number_of_projects) ||
    1; // To prevent division by zero

  const averageProjectSeconds = total_project_hours / number_of_projects;
  console.log(total_project_hours, "total_project_hours..");
  console.log(number_of_projects, "number_of_projects..");

  const averageProjectTime = secondsToTime_Project(averageProjectSeconds);
  console.log(averageProjectTime, "averageProjectTime...");

  return (
    <>
      {is_admin == "Y" ? (
        <div className="pt-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
          <Container fluid={true}>
            <Row className="widget-grid">
              <Col className="col-xxl-4 col-sm-6 box-col-6">
                <Card className=" profile-box">
                  <CardBody>
                    <Media>
                      <Media body>
                        <div className="greeting-user">
                          <H4 attrH4={{ className: "f-w-600" }}>
                            Welcome To Timesheetportal
                          </H4>
                          <P>
                            Simplify your time tracking with our easy-to-use
                            timesheet portal.
                          </P>
                          <div className="whatsnew-btn">
                            <Btn
                              attrBtn={{
                                color: "transparent",
                                outline: true,
                                className: "btn btn-outline-white",
                              }}
                            >
                              {WhatsNew}
                            </Btn>
                          </div>
                        </div>
                      </Media>
                      <div>
                        <div className="clockbox">
                          <svg
                            id="clock"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 600 600"
                          >
                            <g id="face">
                              <circle
                                className="circle"
                                cx={300}
                                cy={300}
                                r="253.9"
                              />
                              <path
                                className="hour-marks"
                                d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6"
                              />
                              <circle
                                className="mid-circle"
                                cx={300}
                                cy={300}
                                r="16.2"
                              />
                            </g>
                            <g id="hour">
                              <path className="hour-hand" d="M300.5 298V142" />
                              <circle
                                className="sizing-box"
                                cx={300}
                                cy={300}
                                r="253.9"
                              />
                            </g>
                            <g id="minute">
                              <path className="minute-hand" d="M300.5 298V67" />
                              <circle
                                className="sizing-box"
                                cx={300}
                                cy={300}
                                r="253.9"
                              />
                            </g>
                            <g id="second">
                              <path className="second-hand" d="M300.5 350V55" />
                              <circle
                                className="sizing-box"
                                cx={300}
                                cy={300}
                                r="253.9"
                              ></circle>
                            </g>
                          </svg>
                        </div>
                        <div className="badge f-10 p-0" id="txt" />
                      </div>
                    </Media>
                    {/* <div className='cartoon'>
            <Image attrImage={{ src: CarToon, alt: 'vector women with leptop' }} />
          </div> */}
                  </CardBody>
                </Card>
              </Col>

              <Col xxl="auto" xl="3" sm="6" className="box-col-6">
                <Row>
                  <Col xl="12">
                    {/* <Widgets1 data={WidgetsData} /> */}
                    <Card className="widget-1">
                      <CardBody>
                        <div className="widget-content">
                          <div className={`widget-round primary`}>
                            <div className="bg-round">
                              {/* <SvgIcon className='svg-fill' iconId={`${data.icon}`} /> */}
                              {/* <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                              {/* <LiaFileInvoiceSolid color="#7366ff" /> */}
                              <FaHourglass size={24} color="#7366ff" />{" "}
                              {/* Adjust the size as needed */}
                            </div>
                          </div>
                          <div>
                            <H4>
                              {/* <CountUp
                                prefix=""
                                duration={3}
                                start={0}
                                separator=","
                                end={widget_data?.series?.daily_total_working_hours}
                              /> */}
                              {widget_data?.series?.daily_total_working_hours
                                ? widget_data?.series?.daily_total_working_hours
                                : "00:00:00"}
                            </H4>
                            <span className="f-light">
                              Total Hours <br className="tablet-laptop-br" />{" "}
                              Worked
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="12">
                    {/* <Widgets1 data={WidgetsData2} /> */}
                    <Card className="widget-1">
                      <CardBody>
                        <div className="widget-content">
                          <div className={`widget-round warning`}>
                            <div className="bg-round">
                              {/* <SvgIcon className='svg-fill' iconId={`${data.icon}`} /> */}
                              {/* <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                              {/* <MdOutlineCancel color="#ffaa05" /> */}
                              {/* <FaCheckCircle size={24}  color="#ffaa05"/> */}
                              <FaFolder size={24} color="#ffaa05" />
                            </div>
                          </div>
                          <div>
                            <H4>
                              <CountUp
                                prefix=""
                                duration={3}
                                start={0}
                                separator=","
                                end={
                                  widget_data?.series?.total_projects
                                    ? widget_data?.series?.total_projects
                                    : "0"
                                }
                              />
                              {/* {widget_data?.series?.total_projects
                                ? widget_data?.series?.total_projects
                                : "0"} */}
                            </H4>
                            <span className="f-light">
                              Total <br className="tablet-laptop-br" /> Projects
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xxl="auto" xl="3" sm="6" className="box-col-6">
                <Row>
                  <Col xl="12">
                    {/* <Widgets1 data={WidgetsData3} /> */}
                    <Card className="widget-1">
                      <CardBody>
                        <div className="widget-content">
                          <div className={`widget-round secondary`}>
                            <div className="bg-round">
                              {/* <SvgIcon className='svg-fill' iconId={`${data.icon}`} /> */}
                              {/* <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                              {/* <IoLockClosedOutline color="#d63384" /> */}
                              <FaCheckCircle size={24} color="#d63384" />
                            </div>
                          </div>
                          <div>
                            <H4>
                              <CountUp
                                prefix=""
                                duration={3}
                                start={0}
                                separator=","
                                end={parseFloat(attendanceRate)}
                              />
                              %
                            </H4>
                            <span className="f-light">
                              Employee Attendence Rate
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="12">
                    {/* <Widgets1 data={WidgetsData4} /> */}
                    <Card className="widget-1">
                      <CardBody>
                        <div className="widget-content">
                          <div className={`widget-round success`}>
                            <div className="bg-round">
                              {/* <SvgIcon className='svg-fill' iconId={`${data.icon}`} /> */}
                              {/* <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                              {/* <GoIssueClosed color="#54ba4a" /> */}
                              <FaCheckCircle size={24} color="#54ba4a" />
                            </div>
                          </div>
                          <div>
                            <H4>
                              <CountUp
                                prefix=""
                                duration={3}
                                start={0}
                                separator=","
                                end={
                                  widget_data?.series?.completed_projects
                                    ? widget_data?.series?.completed_projects
                                    : "0"
                                }
                              />
                              {/* {widget_data?.series?.completed_projects
                                ? widget_data?.series?.completed_projects
                                : "0"} */}
                            </H4>
                            <span className="f-light">Completed Projects</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xxl="auto" xl="12" sm="6" className="box-col-6">
                <Row>
                  <Col xxl="12" xl="6" className="box-col-12">
                    {/* <Widgets2 data={Widgets2Data} /> */}
                    <Card className="widget-1">
                      <CardBody>
                        <div
                          className="widget-content"
                          style={{ maxHeight: "330px" }}
                        >
                          <div
                            className={`widget-round`}
                            style={{ color: "#6610f2" }}
                          >
                            <div className="bg-round">
                              {/* <SvgIcon className='svg-fill' iconId={`${data.icon}`} /> */}
                              {/* <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                              {/* <LiaFileInvoiceSolid color="#7366ff" /> */}
                              <FaClock size={24} color="#6610f2" />
                            </div>
                          </div>
                          <div>
                            <H4>
                              {/* <CountUp
                                prefix=""
                                duration={3}
                                start={0}
                                separator=","
                                end={parseFloat(averageTime)}
                              /> */}
                              {averageTime ? averageTime : "00:00:00"}
                            </H4>
                            <span className="f-light">
                              Average Hours Per Employee
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl="12" xl="6" className="box-col-12">
                    {/* <Widgets2 chartClass='profit-chart ' data={Widgets2Data2} /> */}
                    <Card className="widget-1">
                      <CardBody>
                        <div className="widget-content">
                          <div
                            className={`widget-round`}
                            style={{ color: "#dc3545" }}
                          >
                            <div className="bg-round">
                              {/* <SvgIcon className='svg-fill' iconId={`${data.icon}`} /> */}
                              {/* <SvgIcon className='half-circle svg-fill' iconId='halfcircle' /> */}
                              {/* <IoAlertCircleOutline size={40} color="#dc3545" /> */}
                              <FaStopwatch size={24} color="#dc3545" />
                            </div>
                          </div>
                          <div>
                            <H4>
                              {/* <CountUp
                                prefix=""
                                duration={3}
                                start={0}
                                separator=","
                                end={6500}
                              /> */}
                              {averageProjectTime
                                ? averageProjectTime
                                : "00:00:00"}
                            </H4>
                            <span className="f-light">
                              Average Time Spent Per Project
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col xxl="12" lg="12" className="box-col-12">
                <Card>
                  <CardHeader className="card-no-border">
                    <H5>Monthly Attendence Graph</H5>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <Row className="m-0 overall-card">
                      <Col xl="12" md="12" sm="7" className="p-0">
                        <div className="chart-right">
                          <Row>
                            <Col xl="12" className="col-xl-12">
                              <CardBody className="p-0">
                                <UL
                                  attrUL={{
                                    horizontal: true,
                                    className: "d-flex balance-data",
                                  }}
                                >
                                  <LI>
                                    <div className="chartbtn">
                                      <DatePicker
                                        selected={monthly_attendence_date}
                                        onChange={(date) => {
                                          setMonthlyattendencedate(date);
                                          employee_attendence_graph_api(date);
                                        }}
                                        customInput={<ExampleCustomInput />}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                      />
                                    </div>
                                  </LI>
                                  <LI>
                                    <span
                                      className="circle"
                                      style={{
                                        backgroundColor: "rgb(170, 175, 203)",
                                      }}
                                    >
                                      {" "}
                                    </span>
                                    <span className="f-light ms-1">
                                      Absence
                                    </span>
                                  </LI>
                                  <LI>
                                    <span className="circle bg-primary"> </span>
                                    <span className="f-light ms-1">
                                      Presence
                                    </span>
                                  </LI>
                                </UL>
                                <div className="current-sale-container">
                                  {Object.keys(employee_attendence_data)
                                    .length !== 0 && (
                                    <ReactApexChart
                                      type="bar"
                                      height={300}
                                      options={employee_attendence_data.options}
                                      series={employee_attendence_data.series}
                                    />
                                  )}
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      {/* <LightCard LightCardData={LightCardData} /> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="12">
                <Card
                  className={`o-hidden ${
                    workinghours_data.length === 0
                      ? "fixed-height-workingchart"
                      : ""
                  }`}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <HeaderCard
                      title={
                        "Employee Project Participation and Working Hours Chart"
                      }
                    />
                    <div className="chartbtn me-2">
                      <DatePicker
                        selected={workinghours_date}
                        onChange={(date) => {
                          setWorkinghoursdate(date);
                          employee_working_hours_api(date);
                        }}
                        customInput={<ExampleCustomInput />}
                        dateFormat="dd/MM/yy"
                      />
                    </div>
                  </div>

                  <div className="bar-chart-widget">
                    <CardBody className="bottom-content">
                      <Row>
                        <Col>
                          <div id="chart-widget4">
                            {/* <Chart
                              options={MarketChart.options}
                              series={MarketChart.series}
                              type="bar"
                              height={360}
                            /> */}
                            {Object.keys(workinghours_data).length !== 0 ? (
                              <Chart
                                options={workinghours_data.options}
                                series={workinghours_data.series}
                                type="bar"
                                height={360}
                              />
                            ) : (
                              <div className="text-center fs-5">
                                No data found
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                </Card>
              </Col>

              <Col xxl="4" md="6" className="appointment-sec box-col-6">
                <div className="appointment">
                  <Card className="fixed-height-card">
                    <CardHeader className="card-no-border">
                      <div className="header-top">
                        <H5 attrH5={{ className: "m-0" }}>
                          Employee Attendence Tracker
                        </H5>
                        {/* <div className="card-header-right-icon">
                      <DropdownCommon
                        icon={false}
                        options={DailyDropdown}
                        btn={{ caret: true }}
                      />
                    </div> */}
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <div
                        className={`appointment-table ${
                          employee_tracker_data.length > 5 ? "scrollable" : ""
                        }`}
                        // style={{ maxHeight: "315px", overflowY: "auto" }}
                      >
                        <Table borderless responsive className="mt-3">
                          <tbody>
                            {employee_tracker_data.length > 0 ? (
                              employee_tracker_data.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <Image
                                      attrImage={{
                                        className:
                                          "img-fluid img-40 rounded-circle",
                                        src: `${item.image}`,
                                        alt: "user",
                                      }}
                                    />
                                  </td>
                                  <td className="img-content-box">
                                    {item.title}
                                    <span
                                      className="f-light"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {item.subTitle
                                        ? formatSubtitle(item.subTitle)
                                        : ""}
                                    </span>
                                  </td>
                                  <td className="text-end pe-2">
                                    <P
                                      attrPara={{
                                        className: "m-0 font-success",
                                      }}
                                    >
                                      {item.ip_address
                                        ? item.ip_address
                                        : "Not Available"}
                                    </P>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <div
                                className="text-center fs-5"
                                style={{ marginTop: "40px" }}
                              >
                                No data found
                              </div>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>

              <Col xl="4" md="4" sm="12" className="box-col-6">
                <Card className="fixed-height-card">
                  <CardHeader>
                    <Row className="gy-2">
                      <Col xs="12">
                        <H5>Category wise Time Spent (hours)</H5>
                      </Col>
                      {/* <Col xs="1" className="text-end">
                    <ShoppingBag className="text-muted" />
                  </Col> */}
                      <Col xs="12" className="text-end">
                        <div className="chartbtn">
                          <DatePicker
                            selected={categorywise_date}
                            onChange={(date) => {
                              setCategorywisedate(date);
                              category_wise_time_spent_api(date);
                            }}
                            customInput={<ExampleCustomInput />}
                            dateFormat="dd/MM/yy"
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div
                      className={`chart-container progress-chart ${
                        categorywise_data.length > 5 ? "scrollable" : ""
                      }`}
                    >
                      {/* <div id="progress1" className="me-2">
                    <Chart
                      options={progress1.options}
                      series={progress1.series}
                      height="70"
                      type="bar"
                    />
                  </div> */}
                      {categorywise_data.length !== 0 ? (
                        categorywise_data.map((data, index) => (
                          <Chart
                            key={index}
                            options={data.options}
                            series={data.series}
                            height="70"
                            type="bar"
                          />
                        ))
                      ) : (
                        <div className="text-center fs-5">No data found</div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="4" md="4" sm="12" className="box-col-6">
                <Card className="fixed-height-card">
                  <CardHeader>
                    <Row className="gy-2">
                      <Col xs="12">
                        <H5>Project wise Time Spent (hours)</H5>
                      </Col>
                      {/* <Col xs="1" className="text-end">
                    <ShoppingBag className="text-muted" />
                  </Col> */}
                      <Col xs="12" className="text-end">
                        <div className="chartbtn">
                          <DatePicker
                            selected={projectwise_date}
                            onChange={(date) => {
                              setProjectwisedate(date);
                              project_wise_time_spent_api(date);
                            }}
                            customInput={<ExampleCustomInput />}
                            dateFormat="dd/MM/yy"
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div
                      className={`chart-container progress-chart ${
                        projectwise_data.length > 5 ? "scrollable" : ""
                      }`}
                    >
                      {/* <div id="progress1" className="me-2">
                    <Chart
                      options={progress1.options}
                      series={progress1.series}
                      height="70"
                      type="bar"
                    />
                  </div> */}
                      {projectwise_data.length !== 0 ? (
                        projectwise_data.map((data, index) => (
                          <Chart
                            key={index}
                            options={data.options}
                            series={data.series}
                            height="70"
                            type="bar"
                          />
                        ))
                      ) : (
                        <div className="text-center fs-5">No data found</div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          <Container className="dashboard-center">
            <div className="text-center mt-5">
              {/* <Image
                src={`${baseurl}/${userdetails?.company_logo}`}
                alt="companylogo"
                fluid
                style={{ maxWidth: "100%", height: "auto" }}
              /> */}
              <Image
                attrImage={{
                  src: `${baseurl}/${userdetails?.company_logo}`,
                  alt: "user",
                }}
                style={{ maxWidth: "100%", height: "auto" }}
              />
              {/* <h1 className="green-header">Welcome Kush Kalwani</h1> */}
              <p className="mt-5">
                (
                <span className="green-header" style={{ fontSize: "16px" }}>
                  Company :
                </span>
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Horus Tech Solutions Private Limited
                </span>
                )
              </p>
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-center gap-4 py-3 mx-5">
              {dashboardboxes.map((box, index) => (
                <Link
                  to={
                    index == 0
                      ? `${process.env.PUBLIC_URL}/viewproject`
                      : index == 1
                      ? `${process.env.PUBLIC_URL}/timesheetreport`
                      : index == 2
                      ? `${process.env.PUBLIC_URL}/login`
                      : null
                  }
                  key={index}
                  onClick={() => {
                    {
                      index == 2 && Logout();
                    }
                  }}
                >
                  <div
                    className={isMobile ? `order-box-mobile` : `order-box`}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="mt-3" style={{ textAlign: "center" }}>
                      <img
                        className={
                          isMobile
                            ? "dashboardbox-img-mobile"
                            : "dashboardbox-img"
                        }
                        src={box.img}
                        alt={box.text}
                      />
                    </div>
                    <h4
                      className={
                        isMobile
                          ? "order-box-text-mobile text-center mt-1 fw-bold"
                          : "order-box-text text-center mt-1 fw-bold"
                      }
                      style={{ color: "black" }}
                    >
                      {box.text}
                    </h4>
                  </div>
                </Link>
              ))}
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default Timesheetdashboard;

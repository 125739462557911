import { toast } from "react-toastify";
import axios from "../axios/axios";
import React from "react";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();
const AUTHORIZE_KEY = process.env.REACT_APP_AUTHORIZE_KEY; //"c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
//let baseURL = window.location.origin;//'http://hofficelocal';
console.log(AUTHORIZE_KEY);
console.log(process.env.REACT_APP_IS_POS, "process.env.REACT_APP_IS_POS....");
function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, emp_id: action.payload.emp_id };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, emp_id: null };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false, emp_id: null };
    case "LOGIN_STATUS":
      return { ...state, isAuthenticated: true, status: action.payload.status };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
// employee_id
function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
    emp_id: null, // Add emp_id to the initial state
    status: [],
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser };

// ###########################################################

function loginUser(dispatch, login, password, history, layoutURL) {
  console.log(layoutURL);
  console.log("Login Check");
  if (!!login && !!password) {
    var postData = {
      AUTHORIZEKEY: AUTHORIZE_KEY,
      txt_username: login,
      txt_password: password,
    };
    axios
      .post(`supportportalapi/verifyLogin`, postData)
      .then(function (response) {
        console.log(response);
        if (response) {
          if (response.data[0].STATUS === "SUCCESS") {
            localStorage.setItem("id_token", response.data[0].PHPTOKEN);
            localStorage.setItem(
              "userdetails",
              JSON.stringify(response.data[0].DATA)
            );
            localStorage.setItem("login", JSON.stringify(true));
            localStorage.setItem(
              "employeeid",
              response.data[0].DATA.employee_id
            );
            if (process.env.REACT_APP_IS_POS == 0) {
              localStorage.setItem("attr_id", response.data[0].DATA.att_id);
              localStorage.setItem(
                "breakid",
                response.data[0].DATA.emp_breakid
              );
              localStorage.setItem(
                "emp_in_time",
                response.data[0].DATA.emp_in_time
              );
              localStorage.setItem(
                "emp_out_time",
                response.data[0].DATA.emp_out_time
              );
              localStorage.setItem(
                "config_contact_display",
                response.data[0].DATA.config_contact_display
              );
              localStorage.setItem(
                "config_product_code_display",
                response.data[0].DATA.config_product_code_display
              );
              localStorage.setItem(
                "is_admin",
                response.data[0].DATA.is_main_admin
              );
              localStorage.setItem(
                "showtask",
                response.data[0].DATA.enable_task
              );
              localStorage.setItem(
                "timesheet_enable_on_login",
                response.data[0].DATA.timesheet_enable_on_login
              );
              dispatch({
                type: "LOGIN_STATUS",
                payload: {
                  status: {
                    emp_in_time: response.data[0].DATA.emp_in_time,
                    emp_out_time: response.data[0].DATA.emp_out_time,
                    break_id: response.data[0].DATA.emp_breakid,
                  },
                },
              });
            } else if (process.env.REACT_APP_IS_POS == 2) {
              localStorage.setItem(
                "config_contact_display",
                response.data[0].DATA.config_contact_display
              );
            } else if (process.env.REACT_APP_IS_POS == 1) {
              localStorage.setItem(
                "company_logo_pos",
                response.data[0].DATA.company_logo
              );
            }

            console.log("Umang");
            console.log(process.env.REACT_APP_IS_POS, "inside login func");
            toast.success("Successfully logged in!..");
            // history(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
            if (process.env.REACT_APP_IS_POS == 0) {
              window.location.reload();
              history(`${process.env.PUBLIC_URL}/timesheetportal`);
            } else if (process.env.REACT_APP_IS_POS == 1) {
              history(`${process.env.PUBLIC_URL}/dashboardposmain`);
            } else if (process.env.REACT_APP_IS_POS == 2) {
              history(`${process.env.PUBLIC_URL}/supportportal`);
            } else if (process.env.REACT_APP_IS_POS == 3) {
              history(`${process.env.PUBLIC_URL}/dashboard`);
            } else {
              // Handle other cases or provide a default redirect
              history(`${process.env.PUBLIC_URL}/defaultredirect`);
            }
          } else {
            console.log("Error");
            toast.error("You enter wrong password or username!..");
            //dispatch({ type: "LOGIN_FAILURE" });
          }
        }
      });
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    toast.error("Something gone wrong!..");
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("userdetails");
  localStorage.removeItem("progress_step");
  localStorage.removeItem("confirmstatus");
  localStorage.removeItem("data_url");
  localStorage.removeItem("estimate_amount");
  localStorage.removeItem("inquiryPayment");
  localStorage.removeItem("inquiryTitle");
  localStorage.removeItem("inquiryType");
  localStorage.removeItem("LeadDetails");
  localStorage.removeItem("is_payment_received");
  localStorage.removeItem("credit_days");
  localStorage.removeItem("assigned_to");
  localStorage.removeItem("advance_amount");
  localStorage.removeItem("is_assign_prod_wise");
  localStorage.removeItem("Lead_assign_array");
  localStorage.removeItem("attach_img");
  localStorage.removeItem("login");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import support_requests from "../../../src/assets/images/supportrequest/support_requests.png";
import BasicTable from "../../Components/Supportportal/TableData/Basictable";

const Supportrequestlist = () => {
  const supportlist = useSelector((store) => store.supportrequest.projectdata);
  const baseurl = useSelector((store) => store.baseUrl);
  
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="text-center">
        <img
          src={`${baseurl}/public/complainportal${support_requests}`}
          alt="track-order"
          style={{ width: "auto", height: "200px" }}
        />
        <h1 className="header-h1">
          {userdetails?.username}, Explore {userdetails?.support_label} & Create New Ones!
        </h1>
        <p className="header-p">
          Effortlessly manage and address your support needs for a smoother
          experience.
          {isMobile ? "" : <br />}
        </p>
      </div>
      <div className="supportlist-table">
        <BasicTable />
      </div>
    </>
  );
};

export default Supportrequestlist;

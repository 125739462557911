import React, { useRef, useState } from "react";
import DataTable from "react-data-table-component";
import myreport from "../../../src/assets/images/supportportal/myreport.png";
import { isMobile } from "react-device-detect";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import myworkinghours from "../../../src/assets/images/supportportal/my_working_hours.png";
import myloginhours from "../../../src/assets/images/supportportal/my_login_hours.png";
import mybreakhours from "../../../src/assets/images/supportportal/my_break_hours.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
// import styled from "styled-components";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useContext } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactSelect from "react-select";
const ExcelJS = require("exceljs");

const Timesheetreport = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  const [searchtext, setSearchtext] = useState("");
  const [searchtext_empwise, setSearchtext_empwise] = useState("");
  const [searchproject, setSearchproject] = useState("");
  const [searchuser, setSearchuser] = useState("");
  const [from_date, setFromdate] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [to_date, setTodate] = useState(moment().toDate());
  const [timesheetdata, setTimesheetdata] = useState([]);
  const [filtertimesheetdata, setFiltertimesheetdata] = useState([]);
  const [timesheetdata_empwise, setTimesheetdata_empwise] = useState([]);
  const [filtertimesheetdata_empwise, setFiltertimesheetdata_empwise] =
    useState([]);
  const [data_info, setDatainfo] = useState("");
  const [data_info_empwise, setDatainfo_empwise] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [searchbtn, setSearchbtn] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const [employeelist, setEmployeelist] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [branchlist, setBranchlist] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedtype, setSelectedtype] = useState("Project Wise");
  const Types = [
    "Project Wise",
    "Employee Wise",
    // Add more years as needed
  ];
  const IS_ADMIN = localStorage.getItem("is_admin");
  console.log(employeelist, "employeelist..");
  console.log(selectedUser, "selectedUser...");
  console.log(selectedBranch, "selectedBranch...");
  const configContactDisplay = localStorage.getItem("config_contact_display");
  const config_product_code_display = localStorage.getItem(
    "config_product_code_display"
  );
  console.log(timesheetdata, "timesheetdata...");
  console.log(branchlist, "branchlist....");
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  console.log(timesheetdata, "timesheetdata");
  console.log(moment(from_date).format("DD-MM-YYYY"));
  console.log(to_date, "to_date");
  console.log(searchuser, "searchuser...");
  console.log(searchproject, "searchproject...");

  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  const timeToHours = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    return (
      parseFloat(hours) + parseFloat(minutes) / 60 + parseFloat(seconds) / 3600
    );
  };

  // Function to format numeric hours to "hh:mm:ss" format
  const formatHoursToTime = (numericHours) => {
    const hours = Math.floor(numericHours);
    const minutes = Math.floor((numericHours - hours) * 60);
    const seconds = Math.floor(((numericHours - hours) * 60 - minutes) * 60);

    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return formattedTime;
  };

  useEffect(() => {
    const sum = filtertimesheetdata.reduce(
      (acc, row) => acc + timeToHours(row.hours),
      0
    );
    setTotalSum(sum);
  }, [filtertimesheetdata]);

  const exportHeaders = true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // filter
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = timesheetdata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key]) {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFiltertimesheetdata(filteredItems);
  };

  // filter for emp_wise
  const handleSearch_empwise = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchtext_empwise(searchValue);

    const filteredItems = timesheetdata_empwise.filter((item) => {
      return Object.keys(item).some((key) => {
        if (typeof item[key] === "string") {
          // Check if item[key] is a string
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFiltertimesheetdata_empwise(filteredItems);
  };

  console.log(timesheetdata, "timesheetdata");

  const filterByProject = (e) => {
    const inputText = e.target.value.toLowerCase();
    setSearchproject(inputText);

    // Check if inputText is blank or null, then set filtertimesheetdata to timesheetdata
    if (!inputText) {
      setFiltertimesheetdata(timesheetdata);
      return;
    }

    // Function to remove extra spaces and special characters from a string
    const cleanString = (inputString) => {
      return inputString
        ? inputString
            .trim()
            .replace(/[^\w\s]/g, "")
            .replace(/\s+/g, " ")
            .toLowerCase()
        : "";
    };

    // Use Array.filter to filter objects based on cleaned problem_title
    const filteredData = timesheetdata.filter((item) => {
      const cleanedProblemTitle = cleanString(item.problem_title);
      console.log(cleanedProblemTitle, "problem_title");
      return cleanedProblemTitle.includes(inputText);
    });

    console.log(filteredData, "filterbytask");

    setFiltertimesheetdata(filteredData);
  };

  const filterByUsername = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchuser(inputValue);

    // Function to remove extra spaces and special characters from a string
    const cleanString = (inputString) => {
      return inputString
        .trim()
        .replace(/[^a-z0-9]+/g, "") // Remove special characters
        .replace(/\s+/g, " ");
    };

    // Ensure inputValue is not null or undefined before proceeding
    const searchTermCleaned = inputValue ? cleanString(inputValue) : "";

    // Use Array.filter to filter objects based on createre_name
    const filteredData = timesheetdata.filter((item) =>
      cleanString(item.createre_name.toLowerCase()).includes(searchTermCleaned)
    );

    setFiltertimesheetdata(filteredData);
  };

  const columns = [];

  const columns_empwise = [];

  if (isMobile && selectedtype == "Project Wise") {
    // Define columns for mobile view
    columns.push(
      {
        name: "Client",
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.clinet ? row.clinet : "---"}
            </div>
          </>
        ),
        width: "113px",
        sortable: true,
        omit: configContactDisplay == "N",
      },
      {
        name: (
          <div>
            Project Name
            {/* <Input
              type="text"
              className="form-control register-full input-register"
              placeholder="Search project..."
              value={searchproject}
              onChange={(e) => {
                filterByProject(e);
              }}
              style={{
                fontSize: "0.68rem",
                width: "100%",
              }}
            /> */}
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              {/* {config_product_code_display == "Y" && row?.product_code
                ? `(${row.product_code})`
                : ""} */}
            </div>
          </>
        ),
        width: "130px",
        // sortable: true,
      },
      {
        name: <div>User Name</div>,
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {" "}
              {row.createre_name ? row.createre_name : "---"}
            </div>
          </div>
        ),
        width: "105px",
        // sortable: true,
      },
      {
        name: (
          <span>
            Worked On
            <br />
            Date
          </span>
        ),
        selector: (row) => (row.date ? row.date : "---"),
        width: "105px",
        // sortable: true,
        omit: configContactDisplay == "Y",
      }
    );
  } else {
    // Define columns for desktop view
    columns.push(
      {
        name: <span style={{ fontSize: "11px" }}>Client</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.clinet ? row.clinet : "---"}
            </div>
          </>
        ),
        width: "140px",
        sortable: true,
        omit: configContactDisplay == "N",
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Project Name</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              <div style={{ textAlign: "start" }}>{row.project}</div>
            </div>
          </>
        ),
        width: "180px",
        // sortable: true,
      },
      {
        name: (
          <div className="text-wrap-common">
            <span style={{ fontSize: "11px" }}>Branch</span>
          </div>
        ),
        selector: (row) => (row.branch ? row.branch : "---"),
        width: "80px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              <span style={{ fontSize: "11px" }}>User Name</span>
            </div>
          </div>
        ),
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {" "}
              {row.createre_name ? row.createre_name : "---"}
            </div>
          </div>
        ),
        width: "95px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Worked On
            <br />
            Date
          </span>
        ),
        selector: (row) => (row.date ? row.date : "---"),
        width: "115px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Login Hours
            <br />
            (Login - Break) Hours
          </span>
        ),
        selector: (row) => {
          const totalnet_hours = row.totalnet_hours;

          return totalnet_hours ? totalnet_hours : "00:00:00";
        },
        width: "85px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Time Sheet
            <br />
            Hours
          </span>
        ),
        selector: (row) => (row.hours ? row.hours : "---"),
        width: "85px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Year</span>,
        selector: (row) => new Date().getFullYear(),
        width: "65px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Budget
            <br />
            Hours
          </span>
        ),
        selector: (row) => (row.budget_hours ? row.budget_hours : "---"),
        width: "72px",
        // sortable: true,
      },
      {
        name: (
          <span style={{ fontSize: "11px" }}>
            Your Total <br /> Time Invested
          </span>
        ),
        selector: (row) => (
          <span className="badge rounded-pill text-bg-success">
            {" "}
            {row.current_year_hours ? row.current_year_hours : "---"}
          </span>
        ),
        width: "95px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Activity</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.activity ? row.activity : "---"}
            </div>
          </>
        ),
        width: "80px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Sub Activity</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.subactivity ? row.subactivity : "---"}
            </div>
          </>
        ),
        width: "100px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Remarks</span>,
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.remarks ? row.remarks : "---"}
            </div>
          </>
        ),
        width: "110px",
        // sortable: true,
      }
    );
  }

  if (isMobile && selectedtype == "Employee Wise") {
    // Define columns for mobile view
    columns_empwise.push(
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Project Name</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              <div style={{ textAlign: "start" }}>{row.project}</div>
            </div>
          </>
        ),
        width: "140px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Allocated Hours</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.allocated_horus ? row.allocated_horus : "---"}{" "}
            </div>
          </>
        ),
        width: "100px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Actual Project Hours</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.totalactulprojecthorus ? row.totalactulprojecthorus : "---"}{" "}
            </div>
          </>
        ),
        width: "100px",
        // sortable: true,
      }
    );
  } else {
    // Define columns for desktop view
    columns_empwise.push(
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Project Name</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              <div style={{ textAlign: "start" }}>{row.project}</div>
            </div>
          </>
        ),
        width: "290px",
        // sortable: true,
      },

      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Allocated Hours</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.allocated_horus ? row.allocated_horus : "---"}{" "}
            </div>
          </>
        ),
        width: "140px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Rate</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.employeerate ? row.employeerate : "---"}{" "}
            </div>
          </>
        ),
        width: "100px",
        omit: IS_ADMIN != "Y",
        // sortable: true,
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Actual Project Hours</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.totalactulprojecthorus ? row.totalactulprojecthorus : "---"}{" "}
            </div>
          </>
        ),
        width: "190px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Budgeted Spend</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.budetedspend ? row.budetedspend : "---"}{" "}
            </div>
          </>
        ),
        width: "150px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Actual Spend</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.actualspend ? row.actualspend : "---"}{" "}
            </div>
          </>
        ),
        width: "150px",
        // sortable: true,
      },
      {
        name: (
          <div>
            <span style={{ fontSize: "11px" }}>Difference</span>
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.difference ? row.difference : "---"}{" "}
            </div>
          </>
        ),
        width: "90px",
        // sortable: true,
      }
    );
  }

  const gettimesheetreport = async (
    from_date,
    to_date,
    project,
    selectedUser_Id,
    selectedBranch_Id
  ) => {
    console.log(selectedUser_Id, "selectedUser_Id....");
    setSearchbtn(true);
    try {
      const postData = {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        created_by: emp_id,
        fromdt: moment(from_date).format("DD-MM-YY"),
        todt: moment(to_date).format("DD-MM-YY"),
        problem_title: project || "",
        employee_id: selectedUser_Id || "",
        branch_id: selectedBranch_Id || "",
      };

      const response = await axios({
        method: "POST",
        url: baseurl + "/expo_access_api/search_timesheet_api/",
        headers: {
          Authorization: AUTHORIZE_KEY,
          "Content-Type": "application/json",
        },
        data: postData,
      });

      console.log(response, "supportrequest");
      const { data } = response; // Destructure response data

      if (data && data[0]?.DATA?.supportlist) {
        const message = data[0].MSG;
        setDatainfo(message);
        setTimesheetdata(data[0]?.DATA?.supportlist);
        setFiltertimesheetdata(data[0]?.DATA?.supportlist);
        setSearchbtn(false);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setTimesheetdata([]);
        setFiltertimesheetdata([]);
        setSearchbtn(false);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const getemployeelist = async () => {
    try {
      const postData = {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        PHPTOKEN: localStorage.getItem("id_token"),
      };

      const response = await axios({
        method: "POST",
        url: `${baseurl}/supportportalapi/employee_list`,
        headers: {
          Authorization: AUTHORIZE_KEY,
          "Content-Type": "application/json",
        },
        data: postData,
      });

      console.log(response, "employeelist..");
      const { data } = response; // Destructure response data

      if (data && data[0]?.DATA?.employee_arr) {
        // const message = data[0].MSG;
        setEmployeelist(data[0]?.DATA?.employee_arr);
      } else {
        // const message = data[0].MSG;
        setEmployeelist([]);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const getbranchlist = async () => {
    try {
      const postData = {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        PHPTOKEN: localStorage.getItem("id_token"),
      };

      const response = await axios({
        method: "POST",
        url: `${baseurl}/supportportalapi/branches`,
        headers: {
          Authorization: AUTHORIZE_KEY,
          "Content-Type": "application/json",
        },
        data: postData,
      });

      console.log(response, "branchlist..");
      const { data } = response; // Destructure response data

      if (data && data[0]?.DATA?.branch_arr) {
        // const message = data[0].MSG;
        setBranchlist(data[0]?.DATA?.branch_arr);
      } else {
        // const message = data[0].MSG;
        setBranchlist([]);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    gettimesheetreport(
      from_date,
      to_date,
      searchproject,
      selectedUser?.value,
      selectedBranch?.value
    );
    getemployeelist();
    getbranchlist();
  }, [ ]);

  const handleChangeFromTime = (date) => {
    setFromdate(date);
  };

  const handleChangeToTime = (date) => {
    setTodate(date);
  };

  const ExpandedComponent = ({ data }) => (
    <div>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Branch</span> :
        <span className="expand-subheader">{data.branch}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Year</span> :
        <span className="expand-subheader">{data.date.split("/")[2]}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Login Hours(Login - Break) Hours</span>{" "}
        :
        <span className="expand-subheader">
          {data.totalnet_hours ? data.totalnet_hours : "00:00:00"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Time Sheet Hours</span> :
        <span className="expand-subheader">
          {data.hours ? data.hours : "---"}
        </span>
      </p>
      {configContactDisplay == "Y" ? (
        <p style={{ marginBottom: "0px" }}>
          <span className="expand-header">Worked on date</span> :
          <span className="expand-subheader">
            {data.date ? data.date : "---"}
          </span>
        </p>
      ) : (
        ""
      )}
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Budget hours</span>
        </span>
        :
        <span className="expand-subheader">
          {" "}
          {data.budget_hours ? data.budget_hours : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">
          <span>Your Total Time Invested</span>
        </span>
        :
        <span className="expand-subheader badge rounded-pill text-bg-success">
          {"  "}
          {data.current_year_hours ? data.current_year_hours : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Activity</span> :
        <span className="expand-subheader">
          {" "}
          {data.activity ? data.activity : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Sub Activity</span> :
        <span className="expand-subheader">
          {" "}
          {data.subactivity ? data.subactivity : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Remarks</span> :
        <span className="expand-subheader">
          {" "}
          {data.remarks ? data.remarks : "---"}
        </span>
      </p>
    </div>
  );

  const ExpandedComponent_Empwise = ({ data }) => (
    <div>
      {IS_ADMIN == "Y" ? (
        <p style={{ marginBottom: "0px" }}>
          <span className="expand-header">Rate</span> :
          <span className="expand-subheader">
            {data.employeerate ? data.employeerate : "---"}
          </span>
        </p>
      ) : (
        ""
      )}
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Budgeted Spend</span> :
        <span className="expand-subheader">
          {data.budetedspend ? data.budetedspend : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Actual Spend</span> :
        <span className="expand-subheader">
          {data.actualspend ? data.actualspend : "---"}
        </span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Difference</span> :
        <span className="expand-subheader">
          {data.difference ? data.difference : "---"}
        </span>
      </p>
    </div>
  );

  const handleOnSelectUser = (selectedOptionuser) => {
    console.log(selectedOptionuser, "selectedOptionuser...");
    setSelectedUser(selectedOptionuser);
  };

  const handleOnSelectBranch = (selectedOptionbranch) => {
    console.log(selectedOptionbranch, "selectedOptionbranch...");
    setSelectedBranch(selectedOptionbranch);
  };

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      {
        header: "Project name",
        key: "productname",
        width: 35,
      },
      {
        header: "User name",
        key: "createre_name",
        width: 20,
      },
      {
        header: "Branch",
        key: "branch",
        width: 12,
      },
      {
        header: "Worked On Date",
        key: "date",
        width: 15,
      },
      {
        header: "Login-Break Hours",
        key: "totalnet_hours",
        width: 15,
      },
      {
        header: "TimeSheetHours",
        key: "hours",
        width: 15,
      },
      {
        header: "Year",
        key: "year",
        width: 8,
      },
      {
        header: "Budget Hours",
        key: "budget_hours",
        width: 15,
      },
      {
        header: "Your Total Time Invested",
        key: "current_year_hours",
        width: 15,
      },
      {
        header: "Activity",
        key: "activity",
        width: 20,
      },
      {
        header: "Sub Activity",
        key: "subactivity",
        width: 20,
      },
      {
        header: "Remarks",
        key: "remarks",
        width: 15,
      },
    ];

    const promise = Promise.all(
      filtertimesheetdata.map(async (product, index) => {
        let date = product?.date;
        let year;

        if (date && date.includes("29/03/2024")) {
          year = date.split("/")[2];
        }
        sheet.addRow({
          id: product?.id,
          productname: product?.problem_title,
          // project: product?.project,
          branch: product?.branch,
          createre_name: product?.createre_name,
          date: product?.date,
          totalnet_hours: product?.totalnet_hours,
          hours: product?.hours,
          year: new Date().getFullYear(),
          budget_hours: product?.budget_hours,
          current_year_hours: product?.current_year_hours,
          activity: product?.activity,
          subactivity: product?.subactivity,
          remarks: product?.remarks,
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Timesheet_hours.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  const exportExcelFile_Empwise = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      {
        header: "Project name",
        key: "problem_title",
        width: 35,
      },
      {
        header: "Allocated Hours",
        key: "allocated_horus",
        width: 18,
      },
      {
        header: "Rate",
        key: "employeerate",
        width: 12,
      },
      {
        header: "Actual project hours",
        key: "totalactulprojecthorus",
        width: 20,
      },
      {
        header: "Budgeted Spend",
        key: "budetedspend",
        width: 18,
      },
      {
        header: "Actual Spend",
        key: "actualspend",
        width: 15,
      },
      {
        header: "Difference",
        key: "difference",
        width: 10,
      },
    ];

    const promise = Promise.all(
      filtertimesheetdata_empwise.map(async (product, index) => {
        let date = product?.date;
        let year;

        if (date && date.includes("29/03/2024")) {
          year = date.split("/")[2];
        }
        sheet.addRow({
          id: product?.id,
          problem_title: product?.problem_title,
          allocated_horus: product?.allocated_horus,
          employeerate: product?.employeerate,
          totalactulprojecthorus: product?.totalactulprojecthorus,
          budetedspend: product?.budetedspend,
          actualspend: product?.actualspend,
          difference: product?.difference,
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const fileName =
          selectedtype == "Project Wise"
            ? "Timesheet_hours.xlsx"
            : "Timesheet_hours_Empwise.xlsx";
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName; // Set the file name based on the condition
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };
  // moment(from_date).format("DD-MM-YY"),
  // moment(to_date).format("DD-MM-YY"),
  // employeewise api
  const gettimesheetreport_empwise = async (
    from_date,
    to_date,
    project,
    selectedUser_Id
  ) => {
    setSearchbtn(true);
    try {
      const requestBody = {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        problem_title: project || "",
        employee_id: emp_id,
      };

      // Check if selectedUser_Id is available
      if (selectedUser_Id) {
        requestBody.individual_emp_id = selectedUser_Id;
      }

      // Check if from_date and to_date are valid dates
      const fromDateFormatted = moment(from_date).format("DD-MM-YY");
      const toDateFormatted = moment(to_date).format("DD-MM-YY");

      if (
        fromDateFormatted != "Invalid date" &&
        toDateFormatted != "Invalid date"
      ) {
        requestBody.fromdt = fromDateFormatted;
        requestBody.todt = toDateFormatted;
      } else {
        requestBody.fromdt = "";
        requestBody.todt = "";
      }

      const res = await axios.post(
        baseurl + "/expo_access_api/projectwisetimesheetdata/",
        requestBody
      );
      console.log(res, "gettimesheetreport_empwise");
      const { data } = res; // Destructure response data

      if (data && data[0]?.DATA?.projectwisetimesheetlist) {
        const message = data[0].MSG;
        setDatainfo_empwise(message);
        setTimesheetdata_empwise(res?.data[0]?.DATA?.projectwisetimesheetlist);
        setFiltertimesheetdata_empwise(
          res?.data[0]?.DATA?.projectwisetimesheetlist
        );
        setSearchbtn(false);
      } else {
        const message = data[0].MSG;
        setDatainfo_empwise(message);
        setTimesheetdata_empwise([]);
        setFiltertimesheetdata_empwise([]);
        setSearchbtn(false);
      }
      // setIsLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div className="text-center mt-2">
        <img
          src={`${baseurl}/public/images/supportportal/myreport.png`}
          alt="track-order"
          style={{ width: "80px", height: "80px" }}
        />
        <h1 className="login-header">
          {userdetails?.username}, View your timesheet reports!
        </h1>
        <p className="sub-header">
          Review your timesheet reports with precision and clarity. Stay
          informed about your work hours,
          {isMobile ? "" : <br />}
          tasks completed, and project progress effortlessly.
        </p>
      </div>
      <div className="d-flex justify-content-center align-items-center mx-3">
        <Row className="d-flex justify-content-around align-items-center">
          <Col sm="12" xl="2">
            <Form className="theme-form">
              <FormGroup
                className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
              >
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  From Date:
                </Label>
                <InputGroup
                  className="date flex-nowrap"
                  id="dt-minimum"
                  data-target-input="nearest"
                >
                  <DatePicker
                    className="form-control datetimepicker-input digits input-register"
                    selected={from_date}
                    onChange={handleChangeFromTime}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy "
                    // minDate={moment().startOf('month').toDate()}
                    // maxDate={moment().endOf('month').toDate()}
                  />
                  <div
                    className="input-group-text"
                    data-target="#dt-date"
                    data-toggle="datetimepicker"
                  >
                    <i className="fa fa-calendar"></i>
                  </div>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>

          {/* </Col> */}

          <Col sm="12" xl="2">
            <Form className="theme-form">
              <FormGroup className={` label-gap ${isMobile ? "" : "mb-4"}`}>
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  To Date:
                </Label>
                {/* <Col xl="5" sm="7" lg="8"> */}
                <InputGroup
                  className="date flex-nowrap"
                  id="dt-minimum"
                  data-target-input="nearest"
                >
                  <DatePicker
                    className="form-control datetimepicker-input digits input-register"
                    selected={to_date}
                    onChange={handleChangeToTime}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy "
                    // minDate={from_date}
                    // maxDate={moment().endOf('month').toDate()}
                  />
                  <div
                    className="input-group-text"
                    data-target="#dt-date"
                    data-toggle="datetimepicker"
                  >
                    <i className="fa fa-calendar"></i>
                  </div>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>

          <Col sm="12" xl="2">
            <Form className="theme-form">
              <FormGroup
                className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
                // style={{ width: "230px" }}
              >
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  Enter Project Name:
                </Label>
                <div>
                  <Input
                    className="form-control register-full input-register input-project"
                    type="text"
                    placeholder="Enter project..."
                    value={searchproject}
                    onChange={(e) => {
                      setSearchproject(e.target.value);
                    }}
                  />
                </div>
              </FormGroup>
            </Form>
          </Col>

          <Col sm="12" xl="2">
            <Form className="theme-form">
              <FormGroup
                className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
              >
                <Label
                  className={`col-form-label timesheet-label ${
                    isMobile ? "" : ""
                  }`}
                >
                  Select User:
                </Label>
                <div className="product-select input-user">
                  <ReactSelect
                    className=""
                    options={employeelist.map((option) => ({
                      value: option.employee_id,
                      label: option.name,
                    }))}
                    isClearable={true}
                    value={selectedUser}
                    onChange={(selectedoption) => {
                      handleOnSelectUser(selectedoption);
                    }}
                    // onMenuClose={(clearoption) => handleOnClearUser(clearoption)} // This will trigger when the dropdown menu is closed, after a selection or clear
                    menuPortalTarget={document.body} // Render the dropdown outside parent containers
                    placeholder="Select user..." // Change the placeholder to "Select user"
                  />
                </div>
              </FormGroup>
            </Form>
          </Col>
          {selectedtype != "Employee Wise" && (
            <Col sm="12" xl="2">
              <Form className="theme-form">
                <FormGroup
                  className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
                >
                  <Label
                    className={`col-form-label timesheet-label ${
                      isMobile ? "" : ""
                    }`}
                  >
                    Select Branch:
                  </Label>
                  <div className="product-select input-user">
                    <ReactSelect
                      className=""
                      options={branchlist.map((option) => ({
                        value: option.branch_id,
                        label: option.name,
                      }))}
                      isClearable={true}
                      value={selectedBranch}
                      onChange={(selectedoption) => {
                        handleOnSelectBranch(selectedoption);
                      }}
                      menuPortalTarget={document.body} // Render the dropdown outside parent containers
                      placeholder="Select branch..." // Change the placeholder to "Select user"
                    />
                  </div>
                </FormGroup>
              </Form>
            </Col>
          )}

          {IS_ADMIN == "Y" && (
            <Col sm="12" xl="2">
              <Form className="theme-form">
                <FormGroup
                  className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
                  // style={{ width: "230px" }}
                >
                  <Label
                    className={`col-form-label timesheet-label ${
                      isMobile ? "" : ""
                    }`}
                  >
                    Select type:
                  </Label>
                  <div>
                    <Form.Select
                      className="register-full input-register select-font"
                      // id="country"
                      value={selectedtype}
                      onChange={(e) => {
                        console.log(e.target.value, "e.target.value... type");
                        setSelectedtype(e.target.value);
                        if (
                          e.target.value == "Project Wise" &&
                          timesheetdata.length == 0
                        ) {
                          gettimesheetreport(
                            from_date,
                            to_date,
                            searchproject,
                            selectedUser?.value,
                            selectedBranch?.value
                          );
                        } else if (
                          e.target.value == "Employee Wise" &&
                          timesheetdata_empwise.length == 0
                        ) {
                          gettimesheetreport_empwise(
                            from_date,
                            to_date,
                            searchproject,
                            selectedUser?.value
                          );
                        }
                        // getfinancialyearwisereport(e.target.value);
                      }}
                      required
                    >
                      {Types.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </FormGroup>
              </Form>
            </Col>
          )}
          <Col
            sm="12"
            xl="12"
            className="d-flex align-items-center justify-content-center"
          >
            <Button
              className="searchbtn "
              onClick={() => {
                if (selectedtype == "Project Wise") {
                  setTimesheetdata([]);
                  gettimesheetreport(
                    from_date,
                    to_date,
                    searchproject,
                    selectedUser?.value,
                    selectedBranch?.value
                  );
                } else {
                  setTimesheetdata_empwise([]);
                  gettimesheetreport_empwise(
                    from_date,
                    to_date,
                    searchproject,
                    selectedUser?.value
                  );
                }
              }}
              disabled={searchbtn}
              // style={{ marginTop: "10px" }}
            >
              Search
            </Button>
          </Col>
        </Row>
      </div>
      {/* </Col> */}
      {/* </div> */}
      {isMobile ? (
        selectedtype == "Project Wise" ? (
          <Container>
            {data_info == "Data Not Found" ? (
              <div
                className="text-center mt-3 py-3"
                style={{ backgroundColor: "#dfdcdc" }}
              >
                No Records found
              </div>
            ) : timesheetdata?.length == 0 ? (
              <div className="text-center mt-3">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <div>
                <div className="d-flex  justify-content-center align-items-center my-3">
                  <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Search all fields..."
                    value={searchtext}
                    onChange={handleSearch}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    className="searchbtn my-2"
                    onClick={() => {
                      if (selectedtype == "Project Wise") {
                        exportExcelFile();
                      } else {
                        exportExcelFile_Empwise();
                      }
                    }}
                  >
                    Export
                  </Button>
                </div>
                <DataTable
                  //  className="custom-data-table"
                  columns={columns}
                  data={filtertimesheetdata}
                  pagination
                  paginationComponentOptions={paginationOptions}
                  customStyles={customStyles}
                  expandableRows
                  expandableRowExpanded={(row) => row === currentRow}
                  expandOnRowClicked
                  onRowClicked={(row) => setCurrentRow(row)}
                  onRowExpandToggled={(bool, row) => {
                    setCurrentRow(row);
                  }}
                  expandableRowsComponent={ExpandedComponent}
                  persistTableHead={true}
                />
                <div
                  className="text-end fw-bold my-2"
                  style={{ color: "#098048" }}
                >
                  Total Timesheet hours:{" "}
                  <span className="">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                      className="badge rounded-pill text-bg-success"
                      style={{ fontSize: "13px" }}
                    >
                      {formatHoursToTime(totalSum)}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div>
              </div>
            )}
          </Container>
        ) : (
          <Container>
            {/* "Employee wise data mobileview" */}
            {data_info_empwise == "Data Not Found" ? (
              <div
                className="text-center mt-3 py-3"
                style={{ backgroundColor: "#dfdcdc" }}
              >
                No Records found
              </div>
            ) : timesheetdata_empwise?.length == 0 ? (
              <div className="text-center mt-3">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <div>
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Search all fields..."
                    value={searchtext_empwise}
                    onChange={handleSearch_empwise}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    className="searchbtn my-2"
                    onClick={() => {
                      if (selectedtype == "Project Wise") {
                        exportExcelFile();
                      } else {
                        exportExcelFile_Empwise();
                      }
                    }}
                  >
                    Export
                  </Button>
                </div>
                <DataTable
                  //  className="custom-data-table"
                  columns={columns_empwise}
                  data={filtertimesheetdata_empwise}
                  pagination
                  paginationComponentOptions={paginationOptions}
                  customStyles={customStyles}
                  expandableRows
                  expandableRowExpanded={(row) => row === currentRow}
                  expandOnRowClicked
                  onRowClicked={(row) => setCurrentRow(row)}
                  onRowExpandToggled={(bool, row) => {
                    setCurrentRow(row);
                  }}
                  expandableRowsComponent={ExpandedComponent_Empwise}
                  persistTableHead={true}
                />
                {/* <div
                  className="text-end fw-bold my-2"
                  style={{ color: "#098048" }}
                >
                  Total Timesheet hours:{" "}
                  <span className="">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                      className="badge rounded-pill text-bg-success"
                      style={{ fontSize: "13px" }}
                    >
                      {formatHoursToTime(totalSum)}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div> */}
              </div>
            )}
          </Container>
        )
      ) : selectedtype == "Project Wise" ? (
        <div>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : timesheetdata.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="export-timesheet d-flex justify-content-between my-2">
                <div>
                  <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Search all fields..."
                    value={searchtext}
                    onChange={handleSearch}
                  />
                </div>
                <div>
                  <Button
                    className="searchbtn my-2"
                    onClick={() => {
                      if (selectedtype == "Project Wise") {
                        exportExcelFile();
                      } else {
                        exportExcelFile_Empwise();
                      }
                    }}
                  >
                    Export
                  </Button>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <DataTable
                  // className="table-extra"
                  className="custom-data-table data-timesheetproject"
                  columns={columns}
                  data={filtertimesheetdata}
                  pagination
                  paginationComponentOptions={paginationOptions}
                  customStyles={customStyles}
                  persistTableHead={true}
                />
                <div
                  className="text-center fw-bold my-2"
                  style={{ color: "#098048" }}
                >
                  Total Timesheet hours:{" "}
                  <span>
                    &nbsp;&nbsp;
                    <span
                      className="badge rounded-pill text-bg-success"
                      style={{ fontSize: "13px" }}
                    >
                      {formatHoursToTime(totalSum)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {/* "Employee wise dtaa" */}
          {data_info_empwise == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : timesheetdata_empwise.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div
                className={`d-flex justify-content-between my-2 ${
                  IS_ADMIN === "Y"
                    ? "export-timesheet-employeewise-admin"
                    : "export-timesheet-employeewise"
                }`}
              >
                <div>
                  <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Search all fields..."
                    value={searchtext_empwise}
                    onChange={handleSearch_empwise}
                  />
                </div>
                <div>
                  <Button
                    className="searchbtn my-2"
                    onClick={() => {
                      if (selectedtype == "Project Wise") {
                        exportExcelFile();
                      } else {
                        exportExcelFile_Empwise();
                      }
                    }}
                  >
                    Export
                  </Button>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <DataTable
                  // className="table-extra"
                  className="custom-data-table data-timesheetproject"
                  columns={columns_empwise}
                  data={filtertimesheetdata_empwise}
                  pagination
                  paginationComponentOptions={paginationOptions}
                  customStyles={customStyles}
                  persistTableHead={true}
                />
                {/* <div
                  className="text-center fw-bold my-2"
                  style={{ color: "#098048" }}
                >
                  Total Timesheet hours:{" "}
                  <span>
                    &nbsp;&nbsp;
                    <span
                      className="badge rounded-pill text-bg-success"
                      style={{ fontSize: "13px" }}
                    >
                      {formatHoursToTime(totalSum)}
                    </span>
                  </span>
                </div> */}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Timesheetreport;

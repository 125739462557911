import { FaSignOutAlt } from "react-icons/fa";
const is_admin = localStorage.getItem("is_admin");
const showtask = localStorage.getItem("showtask");
const IS_POS = process.env.REACT_APP_IS_POS;

// timesheetportal
const TimesheetMenuItems = [
  {
    title: "Dashboard",
    icon: "home",
    type: "link",
    path: `${process.env.PUBLIC_URL}/timesheetportal`,
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  {
    title: "My projects",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/viewproject`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  showtask == "Y" && {
    title: "My tasks",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/viewtask`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  {
    title: "Timesheet report",
    icon: "widget",
    path: `/project`,
    type: "sub",
    badge: "badge badge-light-primary",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/timesheetreport`,
        title: "My projects",
        type: "link",
      },
      showtask == "Y" && {
        path: `${process.env.PUBLIC_URL}/timesheetreport-task`,
        title: "My tasks",
        type: "link",
      },
    ],
  },
  {
    title: "Working hours",
    icon: "widget",
    path: ``,
    type: "sub",
    badge: "badge badge-light-primary",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/workinghoursreport`,
        title: "My projects",
        type: "link",
        active: false,
      },
      showtask == "Y" && {
        path: `${process.env.PUBLIC_URL}/workinghoursreport-task`,
        title: "My tasks",
        type: "link",
        active: false,
      },
    ],
  },
  {
    title: "My login hours",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/loginhoursreport`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  is_admin == "Y" && {
    title: "Missing Logout list",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/missinglogoutreport`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  is_admin != "Y" && {
    title: "My Absent list",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/absencereport`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  is_admin == "Y" && {
    title: "Leave Approval list",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/approvalslist`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  {
    title: "My break hours",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/breakhoursreport`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  is_admin == "Y" && {
    title: "Attendence Report",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/attendencereport`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  is_admin == "Y" && {
    title: "Monthly Timesheet Report",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/monthlytimesheetreport`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  is_admin == "Y" && {
    title: "Employeewise Report",
    icon: "project",
    path: `${process.env.PUBLIC_URL}/employeewisereport`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
  {
    title: "Logout",
    icon: <FaSignOutAlt />,
    path: `${process.env.PUBLIC_URL}/login`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    children: [],
  },
];

// Pos
const PosMenuItems = [
  {
    title: "Dashboard",
    icon: "home",
    type: "link",
    path: `${process.env.PUBLIC_URL}/dashboardposmain`,
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    // children: [],
  },
  {
    title: "Logout",
    icon: <FaSignOutAlt />,
    path: `${process.env.PUBLIC_URL}/login`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    // children: [],
  },
];

// Honest chart
const HonestChartItems = [
  {
    title: "Dashboard",
    icon: "home",
    type: "link",
    path: `${process.env.PUBLIC_URL}/dashboard`,
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    // children: [],
  },
  {
    title: "Logout",
    icon: <FaSignOutAlt />,
    path: `${process.env.PUBLIC_URL}/login`,
    type: "link",
    badge: "badge badge-light-primary",
    // badgetxt: "5",
    active: false,
    // children: [],
  },
];

export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      ...(IS_POS == "0" ? TimesheetMenuItems : []),
      ...(IS_POS == "1" ? PosMenuItems : []),
      ...(IS_POS == "3" ? HonestChartItems : []),
      // ...(IS_POS === "2" ? otherPosItems : []),
      // Add more conditions as needed
    ],
  },
];

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import myreport from "../../../src/assets/images/supportportal/myreport.png";
import { isMobile } from "react-device-detect";
// import { Container, Spinner } from "react-bootstrap";
import myworkinghours from "../../../src/assets/images/supportportal/my_working_hours.png";
import myloginhours from "../../../src/assets/images/supportportal/my_login_hours.png";
import mybreakhours from "../../../src/assets/images/supportportal/my_break_hours.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Spinner,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import moment from "moment";

const Workingreport_task = () => {
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);
  const [searchtext, setSearchtext] = useState("");
  const [from_date, setFromdate] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [to_date, setTodate] = useState(moment().toDate());
  const [workinghoursdata, setWorkinghoursdata] = useState([]);
  const [workinghoursdata_emp, setWorkinghoursdataemp] = useState([]);
  const [filterworkinghoursdata, setFilterworkinghoursdata] = useState([]);
  const [data_info, setDatainfo] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [firstrow, setFirstrow] = useState(null);
  const [searchbtn, setSearchbtn] = useState(false);
  const config_product_code_display = localStorage.getItem(
    "config_product_code_display"
  );
  console.log(data_info, "data_info");
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const firstRow =
    filterworkinghoursdata.length > 0 ? filterworkinghoursdata[0] : null;

  useEffect(() => {
    // Set the first row as the initially expanded row
    if (firstRow) {
      setCurrentRow(firstRow);
      setFirstrow(filterworkinghoursdata[0]);
      getWorkingHours_employee(firstRow.task_id);
    }
  }, [firstRow]);

  const isHighlightRow = (row) => {
    // Check if the row is the first row or the currently expanded row
    return row === firstRow || row === currentRow;
  };

  const handleRowExpandToggle = (row) => {
    console.log(row, "row")
    if (currentRow && currentRow.task_id !== row.task_id) {
      // Collapse the previously expanded row if it's not the same as the clicked row
      setCurrentRow(null);
    }
    getWorkingHours_employee(row.task_id);
    setCurrentRow(row);
  };

  console.log(currentRow, "currentrow");
  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  const handleChangeFromTime = (date) => {
    setFromdate(date);
  };

  const handleChangeToTime = (date) => {
    setTodate(date);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // // filter
  // const handleSearch = (e) => {
  //   const searchValue = e.target.value.toLowerCase();
  //   setSearchtext(searchValue);

  //   const filteredItems = workinghoursdata.filter((item) => {
  //     return Object.keys(item).some((key) => {
  //       if (item[key]) {
  //         const cleanedItem = item[key]
  //           .toLowerCase()
  //           .replace(/[^a-z0-9]+/g, "") // Remove special characters
  //           .replace(/\s+/g, " ") // Replace multiple spaces with a single space
  //           .trim();
  //         const cleanedSearchValue = searchValue
  //           .replace(/[^a-z0-9]+/g, "") // Remove special characters
  //           .replace(/\s+/g, " ") // Replace multiple spaces with a single space
  //           .trim();

  //         return cleanedItem.includes(cleanedSearchValue);
  //       }
  //       return false;
  //     });
  //   });

  //   console.log(filteredItems, "data");

  //   setFilterworkinghoursdata(filteredItems);
  // };

  // filter
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);
  
    const filteredItems = workinghoursdata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] && typeof item[key] === 'string') {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
  
          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });
  
    console.log(filteredItems, "data");
  
    setFilterworkinghoursdata(filteredItems);
  };

  const columns = [];

  if (isMobile) {
    // Define columns for mobile view
    columns.push(
      {
        name: "Date",
        selector: (row) => row.date,
        width: "100px",
        // sortable: true,
      },
      {
        name: (
          <span>
            Project
            <br />
            Name
          </span>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              {/* {config_product_code_display == "Y" && row?.product_code
                ? `(${row.product_code})`
                : ""} */}
            </div>
          </>
        ),
        width: "130px",
        // sortable: true,
      },
      {
        name: (
          <span>
            Your Total
            <br />
            Time Invested
          </span>
        ),
        selector: (row) =>  <span className="badge rounded-pill text-bg-success"> {row.current_year_hours ? row.current_year_hours : "---"}</span>,
        width: "110px",
        // sortable: true,
      }
    );
  } else {
    // Define columns for desktop view
    columns.push(
      {
        name: "Date",
        selector: (row) => row.date,
        width: "100px",
        // sortable: true,
      },
      {
        name: "Project Name",
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.problem_title ? row.problem_title : "---"}{" "}
              {/* {config_product_code_display == "Y" && row?.product_code
                ? `(${row.product_code})`
                : ""} */}
            </div>
          </>
        ),
        width: "300px",
        // sortable: true,
      },
      {
        name: (
          <span>
            Your Total
            Time
            Invested
          </span>
        ),
        selector: (row) => <span className="badge rounded-pill text-bg-success"> {row.current_year_hours ? row.current_year_hours : "---"}</span>,
        width: "130px",
        // sortable: true,
        style: {
          display : "flex !important",
          justifyContent: "center !important",
        }
      },
      {
        name: "Year",
        selector: (row) => new Date().getFullYear(),
        width: "80px",
        // sortable: true,
        style: {
          display : "flex !important",
          justifyContent: "center !important",
        }
      },
      {
        name: (
          <span>
            Budget
            <br />
            hours
          </span>
        ),
        selector: (row) => (row.budget_hours ? row.budget_hours : "---"),
        width: "100px",
        // sortable: true,
      }
    );
  }

  // const ExpandedComponent = ({ data }) => (
  //   <div>
  //     <p style={{ marginBottom: "0px" }}>
  //       <span className="expand-header">Working hours</span> :
  //       <span className="expand-subheader"> {data.working_h}</span>
  //     </p>
  //   </div>
  // );

  const ExpandedComponent = ({ data }) => (
    <>
      {isMobile ? (
        <>
          <p style={{ marginBottom: "0px", marginBlock: "0px" }}>
            <span className="expand-header">Year</span> :
            <span className="expand-subheader">{new Date().getFullYear()}</span>
          </p>
          <p style={{ marginBottom: "0px", marginBlock: "0px" }}>
            <span className="expand-header">Budget hours</span> :
            <span className="expand-subheader">
              {data.budget_hours ? data.budget_hours : "---"}
            </span>
          </p>
        </>
      ) : (
        ""
      )}

      <div className="my-2">
        <Table striped bordered hover size="sm">
          {workinghoursdata_emp.length != 0 ? (
            <>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Username</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {workinghoursdata_emp.map((row, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{row.employee_name}</td>
                    <td>{row.working_h}</td>
                  </tr>
                ))}
              </tbody>{" "}
            </>
          ) : (
            <div className="text-center fw-bold">No data found</div>
          )}
        </Table>
      </div>
    </>
  );

  // filterdata
  const applyDateFilter = (from_date, to_date) => {
    if (from_date && to_date) {
      const filteredData = workinghoursdata.filter((item) => {
        const attDate = moment(item.date, "DD/MM/YYYY"); // Parse date with moment.js
        const isAfterOrEqualFromDate = attDate.isSameOrAfter(
          moment(from_date, "DD/MM/YYYY"),
          "day"
        );
        const isBeforeOrEqualToDate = attDate.isSameOrBefore(
          moment(to_date, "DD/MM/YYYY"),
          "day"
        );
        return isAfterOrEqualFromDate && isBeforeOrEqualToDate;
      });
      console.log("applydatafilter:", filteredData); // Log the array directly without concatenation
      setFilterworkinghoursdata(filteredData);
    } else {
      // If start date or end date is not selected, set filtered data to empty array
      setFilterworkinghoursdata([]);
    }
  };

  const getWorkingHours = async (from_date, to_date) => {
    setSearchbtn(true);
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/task_working_hours/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          employee_id: emp_id,
          fromdt: moment(from_date).format("DD-MM-YY"),
          todt: moment(to_date).format("DD-MM-YY"),
        },
      );

      const { data } = response; // Destructure response data

      if (data && data[0]?.DATA?.working_hours) {
        const message = data[0].MSG;
        setDatainfo(message);
        const workingHours = data[0].DATA.working_hours;
        setWorkinghoursdata(workingHours); // Combined state updates
        setFilterworkinghoursdata(workingHours);
        setSearchbtn(false);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setWorkinghoursdata([]);
        setFilterworkinghoursdata([]);
        setSearchbtn(false);
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  const getWorkingHours_employee = async (orderid) => {
    // console.log(a,"1414")
    // console.log(b, "1515")
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/task_working_hours_in_details/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          employee_id: emp_id,
          order_id: orderid,
          fromdt: moment(from_date).format("DD-MM-YY"),
          todt: moment(to_date).format("DD-MM-YY"),
        },
      );

      const { data } = response; // Destructure response data
      console.log(data, "working_emp");

      if (data && data[0]?.DATA?.working_hours) {
        const workingHours = data[0].DATA.working_hours;
        setWorkinghoursdataemp(workingHours); // Combined state updates
      } else {
        // const message = data[0].MSG;
        // setDatainfo(message);
        setWorkinghoursdataemp([]);
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  useEffect(() => {
    getWorkingHours(from_date, to_date);
    // getWorkingHours_employee()
  }, []);

  const dashboardboxes = [
    {
      text: <span>My Timesheet</span>,
      img: `${baseurl}/public/images/supportportal/myreport.png`,
      linkTo: `${process.env.PUBLIC_URL}/timesheetreport`, // Specify the path you want to link to
    },
    {
      text: (
        <span>
          My login
          <br />
          hours
        </span>
      ),
      img: `${baseurl}/public/images/supportportal/my_login_hours.png`,
      linkTo: `${process.env.PUBLIC_URL}/loginhoursreport`, // Specify the path you want to link to
    },
    {
      text: (
        <span>
          My break
          <br />
          hours
        </span>
      ),
      img: `${baseurl}/public/images/supportportal/my_break_hours.png`,
      linkTo: `${process.env.PUBLIC_URL}/breakhoursreport`, // Specify the path you want to link to
    },
  ];

  return (
    <div>
      <div className="text-center">
        <img
          src={`${baseurl}/public/images/supportportal/my_working_hours.png`}
          alt="track-order"
          style={{ width: "80px", height: "80px" }}
        />
        <h1 className="login-header">
          {userdetails?.username}, View your Working-hours reports!
        </h1>
        <p className="sub-header">
          Review your timesheet reports with precision and clarity. Stay
          informed about your work hours,
          {isMobile ? "" : <br />}
          tasks completed, and project progress effortlessly.
        </p>
        {/* <h1 className="report-txt">Which report do you want to see?</h1> */}
      </div>
      {/* <div className="d-flex flex-wrap justify-content-center align-items-center gap-4 py-2 mx-5">
        {dashboardboxes.map((box, index) => (
          <>
            <Link to={box.linkTo} key={index}>
              <div
                className={`report-box`}
                style={{
                  cursor: "pointer",
                  height: "100px",
                  width: "100px",
                }}
              >
                <div className="mt-3" style={{ textAlign: "center" }}>
                  <img className="report-img" src={box.img} alt={box.text} />
                </div>
                <h4
                  className="report-box-text text-center mt-1 fw-bold"
                  style={{ fontSize: "10px", color: "black" }}
                >
                  {box.text}
                </h4>
              </div>
            </Link>
          </>
        ))}
      </div> */}
        {/* <Col sm="12" xl="6"> */}
        <div className="d-flex justify-content-center align-items-center mx-3">
      <Row className="d-flex justify-content-around align-items-center">
        <Col sm="12" xl="5">
          <Form className="theme-form">
            <FormGroup
              className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
            >
              <Label
                className={`col-form-label timesheet-label ${
                  isMobile ? "" : ""
                }`}
              >
                From Date:
              </Label>
              <InputGroup
                className="date"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits input-register"
                  selected={from_date}
                  onChange={handleChangeFromTime}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy "
                  // minDate={moment().startOf('month').toDate()}
                  // maxDate={moment().endOf('month').toDate()}
                />
                <div
                  className="input-group-text"
                  data-target="#dt-date"
                  data-toggle="datetimepicker"
                >
                  <i className="fa fa-calendar"></i>
                </div>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>

        {/* </Col> */}

        <Col sm="12" xl="4">
          <Form className="theme-form">
            <FormGroup className={` label-gap ${isMobile ? "" : "mb-4"}`}>
              <Label
                className={`col-form-label timesheet-label ${
                  isMobile ? "" : ""
                }`}
              >
                To Date:
              </Label>
              {/* <Col xl="5" sm="7" lg="8"> */}
              <InputGroup
                className="date flex-nowrap"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits input-register"
                  selected={to_date}
                  onChange={handleChangeToTime}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy "
                  // minDate={from_date}
                  // maxDate={moment().endOf('month').toDate()}
                />
                <div
                  className="input-group-text"
                  data-target="#dt-date"
                  data-toggle="datetimepicker"
                >
                  <i className="fa fa-calendar"></i>
                </div>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>

        <Col sm="12" xl="3" className="align-items-center justify-content-center" style={{marginTop:"10px"}}>
          {/* <Col xl="5" sm="7" lg="8"> */}
          <Button
            className="searchbtn "
            onClick={() => getWorkingHours(from_date, to_date)}
            disabled={searchbtn}
          >
            Search
          </Button>
        </Col>
      </Row>
      </div>
        {/* </Col> */}
      {/* <div className="d-flex justify-content-center align-items-center">
        <Button
          className="searchbtn "
          onClick={() => getWorkingHours(from_date, to_date)}
          disabled={searchbtn}
        >
          Search
        </Button>
      </div> */}
      {isMobile ? (
        <Container>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : workinghoursdata?.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-center my-3">
                <Input
                  className="form-control register-full input-register"
                  type="text"
                  placeholder="Search all fields..."
                  value={searchtext}
                  onChange={handleSearch}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button className="searchbtn my-2">
                  <CSVLink
                    className="export-link"
                    data={filterworkinghoursdata}
                  >
                    Export
                  </CSVLink>
                </Button>
              </div>
              <DataTable
                // className="custom-data-table"
                columns={columns}
                data={filterworkinghoursdata}
                pagination
                paginationComponentOptions={paginationOptions}
                customStyles={customStyles}
                expandableRows
                expandableRowExpanded={(row) => row === currentRow}
                expandOnRowClicked
                onRowClicked={(row) => {
                  console.log(row, "row");
                  handleRowExpandToggle(row);
                }}
                onRowExpandToggled={(bool, row) => {
                  console.log(row, "rowwww");
                  console.log(bool, "bool");
                  handleRowExpandToggle(row);
                }}
                expandableRowsComponent={ExpandedComponent}
                persistTableHead={true}
              />
            </div>
          )}
        </Container>
      ) : (
        <div>
          {data_info == "Data Not Found" ? (
            <div
              className="text-center mt-3 py-3"
              style={{ backgroundColor: "#dfdcdc" }}
            >
              No Records found
            </div>
          ) : workinghoursdata?.length == 0 ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div>
              <div className="export-working d-flex justify-content-between my-2">
                <div>
                  <Input
                    className="form-control register-full input-register"
                    type="text"
                    placeholder="Search all fields..."
                    value={searchtext}
                    onChange={handleSearch}
                  />
                </div>
                <div>
                  <Button className="searchbtn my-2">
                    <CSVLink
                      className="export-link"
                      data={filterworkinghoursdata}
                    >
                      Export
                    </CSVLink>
                  </Button>
                </div>
              </div>
              <DataTable
                className="custom-data-table data-workingtask"
                columns={columns}
                data={filterworkinghoursdata}
                pagination
                paginationComponentOptions={paginationOptions}
                customStyles={customStyles}
                expandableRows
                expandableRowExpanded={(row) => row === currentRow}
                expandOnRowClicked
                onRowClicked={(row) => {
                  console.log(row, "row");
                  handleRowExpandToggle(row);
                }}
                onRowExpandToggled={(bool, row) => {
                  console.log(row, "rowwww");
                  console.log(bool, "bool");
                  handleRowExpandToggle(row);
                }}
                expandableRowsComponent={ExpandedComponent}
                persistTableHead={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Workingreport_task;

// const [expandedRowId, setExpandedRowId] = useState(null);

// <DataTable
//   className="custom-data-table"
//   columns={columns}
//   data={filterworkinghoursdata}
//   pagination
//   paginationComponentOptions={paginationOptions}
//   customStyles={customStyles}
//   expandableRows
//   expandableRowExpanded={(row) => {
//     if (expandedRowId !== null && expandedRowId !== row.id) { // Check if a different row is currently expanded
//       setExpandedRowId(null); // Collapse the previously expanded row
//     }

//     if (row.index === 0) { // Check if it's the first row
//       return true; // Expand the first row by default
//     }

//     return row.defaultExpanded; // Toggle expansion for other rows
//   }}
//   expandOnRowClicked
//   onRowClicked={(row) => setCurrentRow(row)}
//   onRowExpandToggled={(bool, row) => {
//     setExpandedRowId(bool ? row.id : null); // Update the expanded row ID based on the expansion state
//     getWorkingHours_employee(row.support_order_id);
//     setCurrentRow(row);
//   }}
//   expandableRowsComponent={ExpandedComponent}
// />

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import myreport from "../../../src/assets/images/supportportal/myreport.png";
import { isMobile } from "react-device-detect";
import {
  Button,
  Col,
  Container,
  Spinner,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import myworkinghours from "../../../src/assets/images/supportportal/my_working_hours.png";
import myloginhours from "../../../src/assets/images/supportportal/my_login_hours.png";
import mybreakhours from "../../../src/assets/images/supportportal/my_break_hours.png";
import { useEffect } from "react";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { MdAddCircle, MdEditSquare, MdThumbUp } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import log_logout_edit from "../../assets/images/log_logout_edit.jpg";
import { Image } from "../../AbstractElements";
import { additem, addlogindata } from "../../utils/supportrequestSlice";
import { useHistory } from "react-router-dom";
import ReactSelect from "react-select";
import { Tooltip as ReactTooltip } from "react-tooltip";
const ExcelJS = require("exceljs");

const AbsentEmployeereport = () => {
  // const baseurl = "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const logindata = useSelector(
    (store) => store.supportrequest.login_logout_data
  );
  const dispatch = useDispatch();
  console.log(logindata, "logindata...");
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  const is_admin = localStorage.getItem("is_admin");
  const navigate = useNavigate();

  console.log(baseurl, "from store");
  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);
  const [searchtext, setSearchtext] = useState("");
  const [from_date, setFromdate] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [to_date, setTodate] = useState(moment().toDate());
  const [loginhoursdata, setLoginhoursdata] = useState([]);
  const [filterloginhoursdata, setFilterloginhoursdata] = useState([]);
  const [data_info, setDatainfo] = useState("");
  const [searchbtn, setSearchbtn] = useState(false);
  const [approval_btn, setApprovalbtn] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  const supportlist = useSelector((store) => store.supportrequest.projectdata);
  const [projectdata, setProjectdata] = useState(supportlist);
  const [selectedProject, setSelectedproject] = useState({});
  console.log(projectdata, "projectdata...");
  console.log(selectedProject, "selectedProject...");

  const handleOnSelectProject = (selectedOptionproject) => {
    console.log(selectedOptionproject, "selectedOptionuser...");
    setSelectedproject(selectedOptionproject);
  };

  // for modal
  const [modal, setModal] = useState(false);
  const [project_modal, setProjectmodal] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle_projectmodal = () => setProjectmodal(!project_modal);

  const [remark, setRemark] = useState("");

  const handleChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  console.log(filterloginhoursdata, "filtereddata");
  console.log(loginhoursdata, "logindata");

  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  const handleChangeFromTime = (date) => {
    setFromdate(date);
  };

  const handleChangeToTime = (date) => {
    setTodate(date);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // filter
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = loginhoursdata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key]) {
          const cleanedItem = String(item[key])
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilterloginhoursdata(filteredItems);
  };

  const [searchusername, setSearchusername] = useState("");

  // filter by username
  const filterByUsername = (e) => {
    const inputText = e.target.value;
    setSearchusername(inputText);
    // Function to remove extra spaces and special characters from a string
    const cleanString = (inputString) => {
      return inputString
        .trim()
        .replace(/[^a-z0-9]+/g, "") // Remove special characters
        .replace(/\s+/g, " ");
    };

    if (!inputText || cleanString(inputText).length === 0) {
      setFilterloginhoursdata(loginhoursdata);
      return;
    }

    // Convert the searchTerm to lowercase for case-insensitive search
    const searchTermCleaned = cleanString(searchusername.toLowerCase());

    // Use Array.filter to filter objects based on problem_title
    const filteredData = loginhoursdata.filter((item) =>
      cleanString(item?.employee_name?.toLowerCase())?.includes(
        searchTermCleaned
      )
    );

    setFilterloginhoursdata(filteredData);
  };

  // for edit login time
  const [rowData, setRowData] = useState({
    id: "",
    date: "",
    login_time: "",
    logout_time: "",
    login_hours: "",
  }); // To store the current row data
  console.log(rowData, "rowData");

  const handleEditClick = (row) => {
    setRowData({
      id: row.employee_id || "", // Use the value from the row if available, otherwise default to an empty string
      date: row.date || "",
      login_time: row.login_time || "",
      logout_time: row.logout_time || "",
      login_hours: row.login_hours || "",
    });
    toggle(); // Open the modal
  };

  const handleEditProject = (row) => {
    setRowData({
      id: row.employee_id || "", // Use the value from the row if available, otherwise default to an empty string
      date: row.date || "",
      login_time: row.login_time || "",
      logout_time: row.logout_time || "",
      login_hours: row.login_hours || "",
    });
    toggle_projectmodal(); // Open the project modal
  };

  const handleLoginTimeChange = (e) => {
    const inputTime = e.target.value;

    // Updated validation logic here
    const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9]:[0-5][0-9] (AM|PM)$/i;
    console.log(timeRegex, "timeRegex...");

    const formattedTime = inputTime.toUpperCase(); // Convert AM/pm to uppercase
    setRowData((prevData) => ({
      ...prevData,
      login_time: formattedTime,
    }));
    // updateLoginHours({ ...rowData, login_time: formattedTime });
  };

  const handleLogOutTimeChange = (e) => {
    const inputTime = e.target.value;

    // Your existing validation logic here
    const timeRegex = /^(0[0-9]|1[0-2]):[0-5][0-9]:[0-5][0-9] (AM|PM)$/i;

    const formattedTime = inputTime.toUpperCase(); // Convert AM/pm to uppercase
    setRowData((prevData) => ({
      ...prevData,
      logout_time: formattedTime,
    }));
    // updateLoginHours({ ...rowData, logout_time: inputTime });
  };

  const updateLoginHours = (data) => {
    if (data.login_time && data.logout_time) {
      const loginTime = moment(data.login_time, "hh:mm:ss A");
      const logoutTime = moment(data.logout_time, "hh:mm:ss A");
      console.log(loginTime, "loginTime...");
      console.log(logoutTime, "logoutTime...");
      const durations = moment.duration(logoutTime.diff(loginTime));

      const hours = Math.floor(durations.asHours());
      const minutes = Math.round(durations.asMinutes() % 60);
      console.log(hours, "hours");
      console.log(minutes, "minutes");

      if (hours < 0 || minutes < 0) {
        // Display an error message using your toast library
        Swal.fire({
          icon: "error",
          title: "Invalid Time Range",
          text: "Login Time is greater than Logout Time",
        });
        setRowData((prevData) => ({
          ...prevData,
          login_hours: "", // Assuming login_hours is the property in rowData
        }));
        return false; // Time range is invalid
      } else {
        let timedifference = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        console.log(timedifference, "timedifference");
        setRowData((prevData) => ({
          ...prevData,
          login_hours: timedifference, // Assuming login_hours is the property in rowData
        }));
        return true; // Time range is valid
      }
    }
    return true; // If login_time or logout_time is missing, treat as valid
  };

  const isTimeFormatValid = (timeString) => {
    return moment(timeString, "hh:mm:ss A", true).isValid();
  };

  const UpdateLogin_Logouttime = async (timedetail) => {
    console.log(timedetail, "timedetail..");
    setApprovalbtn(true);

    if (
      !isTimeFormatValid(timedetail.login_time) ||
      !isTimeFormatValid(timedetail.logout_time)
    ) {
      Swal.fire({
        icon: "error",
        title: "Invalid Time Format",
        text: "Please enter the Login Time and Logout Time in the format HH:mm:ss AM/PM. For example, 10:10:00 AM.",
      });
      console.log("Invalid time format");
      setApprovalbtn(false);
      return; // Exit the function early if time format is invalid
    }

    const isValidTimeRange = updateLoginHours(timedetail);

    if (!isValidTimeRange) {
      setApprovalbtn(false);
      return; // Exit the function early if time range is invalid
    }

    // convert in format time
    const formatted_Date = moment(timedetail.date, "DD-MM-YYYY").format(
      "DD-MM-YY"
    );

    const formattedLogin_Date = moment(timedetail.date, "DD-MM-YYYY").format(
      "DD-MM-YY"
    );
    const formattedLogin_Time = moment(
      timedetail.login_time,
      "hh:mm:ss A"
    ).format("hh:mma");
    const dateTimeLogin_String = `${formattedLogin_Date} ${formattedLogin_Time}`;

    const formattedLogout_Date = moment(timedetail.date, "DD-MM-YYYY").format(
      "DD-MM-YY"
    );
    const formattedLogout_Time = moment(
      timedetail.logout_time,
      "hh:mm:ss A"
    ).format("hh:mma");
    const dateTimeLogout_String = `${formattedLogout_Date} ${formattedLogout_Time}`;

    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/AbsenceemployeeData_update/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          employee_id: emp_id,
          status: 1,
          date: formatted_Date,
          in_time: dateTimeLogin_String,
          out_time: dateTimeLogout_String,
          remarks: remark || "",
        },
      );

      console.log(response, "response....");
      const { data } = response; // Destructure response data

      if (data && data[0]?.STATUS === "success") {
        toggle();
        setFilterloginhoursdata([]);
        setLoginhoursdata([]);
        setRowData({});
        setRemark("");
        // setApprovalbtn(false)
        toast.success(data[0]?.MSG, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getloginhours(from_date, to_date);
      } else {
        setApprovalbtn(false);
        toast.error(data[0]?.MSG, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      // setApprovalbtn(false)
      console.error("Error retrieving working hours:", error.response || error);
    } finally {
      setApprovalbtn(false);
    }
  };

  const [tooltip, showTooltip] = useState(true);

  const columns = [];

  if (isMobile) {
    // Define columns for mobile view
    columns.push(
      // {
      //   name: "User Id",
      //   selector: (row) => row.employee_id,
      //   width: "100px",
      // },
      {
        name: (
          <div>
            User Name
            <Input
              type="text"
              className="form-control register-full column-register"
              placeholder="Search username..."
              value={searchusername}
              onChange={(e) => {
                filterByUsername(e);
              }}
              style={{
                fontSize: "0.8rem",
                width: "90%",
                marginInline: "2px",
              }}
            />
          </div>
        ),
        selector: (row) => row.employee_name,
        width: "150px",
        // sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.date,
        width: "110px",
        // sortable: true,
      },
      {
        name: "",
        cell: (row) => (
          <>
            {row.status == 0 && (
              <>
                <MdEditSquare
                  color="#005e32"
                  size={20}
                  data-tooltip-id="editIconTooltip"
                  onClick={(e) => {
                    handleEditClick(row);
                  }}
                  style={{ cursor: "pointer", marginRight: "10px" }}
                />
                <ReactTooltip
                  id="editIconTooltip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                  content="click here to edit login time and logout time."
                  globalEventOff="click" // Hide tooltip on click
                />
              </>
            )}
            {row.status == 2 && (
              <>
                <MdAddCircle
                  color="#005e32"
                  size={20}
                  data-tooltip-id="addIconTooltip"
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  onClick={() => handleEditProject(row)}
                />
                <MdThumbUp
                  color="#005e32"
                  size={20}
                  data-tooltip-id="thumbIconTooltip"
                  style={{ cursor: "pointer" }}
                  onClick={() => confirmCompletion(row.att_id)}
                />
                <ReactTooltip
                  id="addIconTooltip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                  content="Click here to add timesheet."
                />
                <ReactTooltip
                  id="thumbIconTooltip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                  content="Click here to mark as completed"
                />
              </>
            )}
          </>
        ),
        width: "80px",
      }
    );
  } else {
    // Define columns for desktop view
    columns.push(
      {
        name: (
          <div>
            User Name
            <Input
              type="text"
              className="form-control register-full column-register"
              placeholder="Search username..."
              value={searchusername}
              onChange={(e) => {
                filterByUsername(e);
              }}
              style={{
                fontSize: "0.8rem",
                width: "90%",
                marginInline: "2px",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <div>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {" "}
              {row.employee_name ? row.employee_name : "---"}
            </div>
          </div>
        ),
        width: "230px",
        // sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.date,
        width: "110px",
        // sortable: true,
      },
      {
        name: "Login Time",
        selector: (row) => row.login_time,
        width: "120px",
        // sortable: true,
      },
      {
        name: "Logout Time",
        selector: (row) => row.logout_time,
        width: "120px",
        // sortable: true,
      },
      {
        name: "Login Hour",
        selector: (row) => row.login_hours,
        width: "120px",
        // sortable: true,
      },
      {
        name: "Remark",
        selector: (row) => (
          <div
            style={{
              textAlign: "start",
              overflow: "hidden",
              whiteSpace: "normal",
            }}
          >
            {row.remarks ? row.remarks : "---"}
          </div>
        ),
        width: "150px",
        // sortable: true,
      },
      {
        name: "Status",
        selector: (row) => (
          <div
            style={{
              textAlign: "start",
              overflow: "hidden",
              whiteSpace: "normal",
            }}
          >
            {row.status == 0
              ? "Not sent for approval"
              : row.status == 1
              ? "Sent for approval"
              : row.status == 2
              ? "Request Approved"
              : row.status == 3
              ? "Request rejected"
              : row.status == 4
              ? "Completed"
              : ""}
          </div>
          //      <div
          //      style={{
          //          color:
          //              row.status == 0 ? '#ff3131' :
          //              row.status == 1 ? '#005e32' :
          //              row.status == 2 ? '#007bff' :
          //              row.status == 3 ? '#6c757d' :
          //              row.status == 4 ? '#28a745' : '',
          //              textAlign: "start",
          //              overflow: "hidden",
          //              whiteSpace: "normal",
          //      }}
          //  >
          //      {row.status == 0 ? 'Not sent for approval' :
          //       row.status == 1 ? 'Sent for approval' :
          //       row.status == 2 ? 'Request Approved' :
          //       row.status == 3 ? 'Request rejected' :
          //       row.status == 4 ? 'Completed' : ''}
          //  </div>
        ),
        width: "100px",
      },
      {
        name: "Actions",
        cell: (row) => (
          <>
            {row.status == 0 && (
              <>
                <MdEditSquare
                  color="#005e32"
                  size={20}
                  data-tooltip-id="editIconTooltip"
                  onClick={(e) => {
                    handleEditClick(row);
                  }}
                  style={{ cursor: "pointer", marginRight: "10px" }}
                />
                <ReactTooltip
                  id="editIconTooltip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                  // globalEventOff="click" // Hide tooltip on click
                  content="click here to edit login time and logout time."
                />
              </>
            )}
            {row.status == 2 && (
              <>
                <MdAddCircle
                  color="#005e32"
                  size={20}
                  data-tooltip-id="addIconTooltip"
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  onClick={() => {
                    handleEditProject(row);
                  }}
                />
                <MdThumbUp
                  color="#005e32"
                  size={20}
                  data-tooltip-id="thumbIconTooltip"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    confirmCompletion(row.att_id);
                  }}
                />
                <ReactTooltip
                  id="addIconTooltip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                  content="Click here to add timesheet."
                />
                <ReactTooltip
                  id="thumbIconTooltip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                  content="Click here to mark as completed"
                />
              </>
            )}
          </>
        ),
        width: "100px",
      }
    );
  }

  const ExpandedComponent = ({ data }) => (
    <div>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Login Time</span> :
        <span className="expand-subheader"> {data.login_time}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Logout Time</span> :
        <span className="expand-subheader"> {data.logout_time}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Login Hour</span> :
        <span className="expand-subheader"> {data.login_hours}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Remark</span> :
        <span className="expand-subheader"> {data.remarks ? data.remarks : "---"}</span>
      </p>
      <p style={{ marginBottom: "0px" }}>
        <span className="expand-header">Status</span> :
        <span className="expand-subheader">
          {" "}
          {data.status == 0
            ? "Not sent for approval"
            : data.status == 1
            ? "Sent for approval"
            : data.status == 2
            ? "Request Approved"
            : data.status == 3
            ? "Request rejected"
            : data.status == 4
            ? "Completed"
            : ""}
        </span>
      </p>
    </div>
  );

  const getloginhours = async (from_date, to_date) => {
    setSearchbtn(true);
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/employeeAbsencedata/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          Employee_id: emp_id,
        },
      );

      const { data } = response; // Destructure response data

      if (data && data[0]?.DATA?.Absenceemployeedata) {
        const message = data[0].MSG;
        setDatainfo(message);
        const Absenceemployeedata = data[0].DATA.Absenceemployeedata;
        setLoginhoursdata(Absenceemployeedata); // Combined state updates
        setFilterloginhoursdata(Absenceemployeedata);
        setSearchbtn(false);
      } else {
        const message = data[0].MSG;
        setDatainfo(message);
        setLoginhoursdata([]);
        setFilterloginhoursdata([]);
        setSearchbtn(false);
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  const confirmCompletion = (attId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to add timesheet after completing this process!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, complete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        Absenceemployee_completed(attId);
      }
    });
  };

  const Absenceemployee_completed = async (id) => {
    try {
      const response = await axios.post(
        `${baseurl}/expo_access_api/Absenceemployee_completed/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          att_id: id,
          status: 4,
        },
      );

      const { data } = response; // Destructure response data

      if (data && data[0]?.STATUS == "success") {
        setFilterloginhoursdata([]);
        setLoginhoursdata([]);
        getloginhours(from_date, to_date);
      } else {
        toast.error(data[0]?.MSG, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error retrieving working hours:", error.response || error);
    }
  };

  // const getSupportRequest = async (data) => {
  //   try {
  //     const res = await axios.post(
  //       baseurl + "/expo_access_api/search_support_api/",
  //       {
  //         AUTHORIZEKEY: AUTHORIZE_KEY,
  //         created_by: emp_id,
  //       }
  //     );
  //     console.log(res, "supportrequest");

  //     if (res && res.data && res.data[0].DATA) {
  //       setProjectdata(res?.data[0]?.DATA?.supportlist);
  //       dispatch(additem(res?.data[0]?.DATA?.supportlist));
  //       localStorage.setItem(
  //         "object_type",
  //         res?.data[0]?.DATA?.supportlist[0]?.object_type
  //       );
  //     } else {
  //       setProjectdata([]);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };

  useEffect(() => {
    getloginhours(from_date, to_date);
  }, []);

  // useEffect(() => {
  //   if (supportlist.length == 0) {
  //     // If supportlist is an empty array, make the API call
  //     getSupportRequest();
  //   } else {
  //     // If supportlist already has data, set projectdata
  //     setProjectdata(supportlist);
  //   }
  // }, []);

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      { header: "User name", key: "username", width: 30 },
      {
        header: "Date",
        key: "date",
        width: 15,
      },
      {
        header: "Login Time",
        key: "login_time",
        width: 15,
      },
      {
        header: "Logout Time",
        key: "logout_time",
        width: 15,
      },
      {
        header: "Login Hour",
        key: "login_hour",
        width: 15,
      },
      {
        header: "Status",
        key: "status",
        width: 20,
      },
    ];

    const promise = Promise.all(
      filterloginhoursdata.map(async (product, index) => {
        sheet.addRow({
          id: product?.att_id,
          username: product?.employee_name,
          date: product?.date,
          login_time: product?.login_time,
          logout_time: product?.logout_time,
          login_hour: product?.login_hours,
          status:
            product?.status == 1
              ? "Pending for approval"
              : product?.status == 2
              ? "Request Approved"
              : product?.status == 3
              ? "Request rejected"
              : product?.status == 4
              ? "Completed"
              : "",
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Absence_report.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <>
      <div>
        <div className="text-center mt-2">
          <img
            src={`${baseurl}/public/images/supportportal/my_login_hours.png`}
            alt="track-order"
            style={{ width: "80px", height: "80px" }}
          />
          <h1 className="login-header">
            {userdetails?.username}, View your Absence reports!
          </h1>
          <p className="sub-header">
            Review your timesheet reports with precision and clarity. Stay
            informed about your work hours,
            {isMobile ? "" : <br />}
            tasks completed, and project progress effortlessly.
          </p>
        </div>
        {/* <Col sm="12" xl="3"> */}
        <div className="d-flex justify-content-center align-items-center mx-3 d-none">
          <Row className="d-flex justify-content-around align-items-center">
            <Col sm="12" xl="5">
              <Form className="theme-form">
                <FormGroup
                  className={`row label-gap d-flex ${isMobile ? "" : "mb-4"}`}
                >
                  <Label
                    className={`col-form-label timesheet-label ${
                      isMobile ? "" : ""
                    }`}
                  >
                    From Date:
                  </Label>
                  <InputGroup
                    className="date"
                    id="dt-minimum"
                    data-target-input="nearest"
                  >
                    <DatePicker
                      className="form-control datetimepicker-input digits input-register"
                      selected={from_date}
                      onChange={handleChangeFromTime}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy "
                      // minDate={moment().startOf('month').toDate()}
                      // maxDate={moment().endOf('month').toDate()}
                    />
                    <div
                      className="input-group-text"
                      data-target="#dt-date"
                      data-toggle="datetimepicker"
                    >
                      <i className="fa fa-calendar"></i>
                    </div>
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>

            {/* </Col> */}

            <Col sm="12" xl="4">
              <Form className="theme-form">
                <FormGroup className={` label-gap ${isMobile ? "" : "mb-4"}`}>
                  <Label
                    className={`col-form-label timesheet-label ${
                      isMobile ? "" : ""
                    }`}
                  >
                    To Date:
                  </Label>
                  {/* <Col xl="5" sm="7" lg="8"> */}
                  <InputGroup
                    className="date flex-nowrap"
                    id="dt-minimum"
                    data-target-input="nearest"
                  >
                    <DatePicker
                      className="form-control datetimepicker-input digits input-register"
                      selected={to_date}
                      onChange={handleChangeToTime}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy "
                      // minDate={from_date}
                      // maxDate={moment().endOf('month').toDate()}
                    />
                    <div
                      className="input-group-text"
                      data-target="#dt-date"
                      data-toggle="datetimepicker"
                    >
                      <i className="fa fa-calendar"></i>
                    </div>
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>

            <Col
              sm="12"
              xl="3"
              className="align-items-center justify-content-center"
              style={{ marginTop: "10px" }}
            >
              {/* <Col xl="5" sm="7" lg="8"> */}
              <Button
                className="searchbtn "
                onClick={() => {
                  setLoginhoursdata([]);
                  setFilterloginhoursdata([]);
                  getloginhours(from_date, to_date);
                }}
                disabled={searchbtn}
              >
                Search
              </Button>
            </Col>
          </Row>
        </div>
        {/* </Col> */}
        {/* </div> */}
        {isMobile ? (
          <Container>
            {data_info == "Data Not Found" ? (
              <div
                className="text-center mt-3 py-3"
                style={{ backgroundColor: "#dfdcdc" }}
              >
                No Records found
              </div>
            ) : loginhoursdata?.length == 0 ? (
              <div className="text-center mt-3">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <>
                <div>
                  <div className="d-flex justify-content-center align-items-center my-3">
                    <Input
                      className="form-control register-full input-register"
                      type="text"
                      placeholder="Search all fields..."
                      value={searchtext}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="searchbtn my-2"
                      onClick={exportExcelFile}
                    >
                      Export
                    </Button>
                  </div>
                  <DataTable
                    //  className="custom-data-table"
                    columns={columns}
                    data={filterloginhoursdata}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    customStyles={customStyles}
                    expandableRows
                    expandableRowExpanded={(row) => row === currentRow}
                    expandOnRowClicked
                    onRowClicked={(row) => setCurrentRow(row)}
                    onRowExpandToggled={(bool, row) => {
                      setCurrentRow(row);
                    }}
                    expandableRowsComponent={ExpandedComponent}
                    persistTableHead={true}
                  />
                </div>
              </>
            )}
          </Container>
        ) : (
          <div>
            {data_info == "Data Not Found" ? (
              <div
                className="text-center mt-3 py-3"
                style={{ backgroundColor: "#dfdcdc" }}
              >
                No Records found
              </div>
            ) : loginhoursdata?.length == 0 ? (
              <div className="text-center mt-3">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <>
                <div>
                  <div className="export-absence d-flex justify-content-between my-2">
                    <div>
                      <Input
                        className="form-control register-full input-register"
                        type="text"
                        placeholder="Search all fields..."
                        value={searchtext}
                        onChange={handleSearch}
                      />
                    </div>
                    <div>
                      <Button
                        className="searchbtn my-2"
                        onClick={exportExcelFile}
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                  <DataTable
                    className="custom-data-table data-loginreport"
                    columns={columns}
                    data={filterloginhoursdata}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    customStyles={customStyles}
                    persistTableHead={true}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* Edit logintime and logouttime  */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        keyboard={false}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={toggle}>Edit Login/Logout Times</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col md={6}>
                <Image
                  attrImage={{
                    className: "img-fluid",
                    src: `${baseurl}/public/images/supportportal/log_logout_edit.jpg`,
                    alt: "log_logout",
                    // onClick: () => Openatrributemodal(item.product_id,index),
                  }}
                />
              </Col>
              <Col md={6}>
                <Row>
                  <Col sm="12" xl="12">
                    <Form className="theme-form">
                      <FormGroup
                        className={`row formdiv-margin ${
                          isMobile ? "" : "mb-2"
                        }`}
                      >
                        <Label
                          className={`col-sm-4 col-form-label timesheet-label ${
                            isMobile ? "text-start" : "text-end"
                          }`}
                          htmlFor="inputEmail3"
                        >
                          Login Time:
                        </Label>
                        <Col sm="5">
                          <Input
                            className="form-control register-full input-register select-font"
                            type="text"
                            // placeholder="Projectname"
                            value={rowData.login_time} // Pass the login time from rowData
                            onChange={handleLoginTimeChange}
                          />
                        </Col>
                        <Col sm="3">
                          <div className="fw-bold">
                            Time format: 10:10:00 AM
                          </div>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm="12" xl="12">
                    <Form className="theme-form">
                      <FormGroup
                        className={`row formdiv-margin ${
                          isMobile ? "" : "mb-2"
                        }`}
                      >
                        <Label
                          className={`col-sm-4 col-form-label timesheet-label ${
                            isMobile ? "text-start" : "text-end"
                          }`}
                          htmlFor="inputEmail3"
                        >
                          Logout Time:
                        </Label>
                        <Col sm="5">
                          <Input
                            className="form-control register-full input-register select-font"
                            type="text"
                            // placeholder="Projectname"
                            value={rowData.logout_time} // Pass the logout time from rowData
                            onChange={handleLogOutTimeChange}
                          />
                        </Col>
                        <Col sm="3">
                          <div className="fw-bold">
                            Time format: 10:10:00 AM
                          </div>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm="12" xl="12">
                    <Form className="theme-form">
                      <FormGroup
                        className={`row formdiv-margin ${
                          isMobile ? "" : "mb-2"
                        }`}
                      >
                        <Label
                          className={`col-sm-4 col-form-label timesheet-label ${
                            isMobile ? "text-start" : "text-end"
                          }`}
                          htmlFor="inputEmail3"
                        >
                          Login Hour:
                        </Label>
                        <Col sm="5">
                          <Input
                            className="form-control input-gray select-font"
                            type="text"
                            // placeholder="Projectname"
                            value={rowData.login_hours} // Pass the logout time from rowData
                            disabled
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col sm="12" xl="12">
                    <Form className="theme-form">
                      <FormGroup
                        className={`row formdiv-margin ${
                          isMobile ? "" : "mb-2"
                        }`}
                      >
                        <Label
                          className={`col-sm-4 col-form-label timesheet-label ${
                            isMobile ? "text-start" : "text-end"
                          }`}
                          htmlFor="inputEmail3"
                        >
                          Remarks:
                        </Label>
                        <Col sm="8">
                          <Input
                            className="form-control register-full input-register"
                            type="textarea"
                            rows="3"
                            placeholder="Type your remarks here..."
                            value={remark} // Pass the logout time from rowData
                            onChange={handleChangeRemark}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            className="searchbtn btn btn-primary"
            onClick={() => {
              UpdateLogin_Logouttime(rowData);
            }}
            disabled={approval_btn}
          >
            Send Approval
          </Button>
          <Button
            className="searchbtn btn btn-primary"
            onClick={() => {
              toggle();
              setRowData({});
              setRemark("");
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* project modal */}
      <Modal
        isOpen={project_modal}
        toggle={toggle_projectmodal}
        keyboard={false}
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            toggle_projectmodal();
            setRowData({});
            setSelectedproject({});
          }}
        >
          Select Project
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col sm="12">
                <Form className="theme-form">
                  <FormGroup className="row formdiv-margin">
                    <Label
                      className="col-sm-4 col-form-label fs-6 fw-bold"
                      htmlFor="projectSelect"
                    >
                      Project:
                    </Label>
                    <Col sm="8">
                      <div className="product-select input-user">
                        <ReactSelect
                          className=""
                          options={projectdata.map((project) => ({
                            value: project.id,
                            label: project.problem_title,
                          }))}
                          isClearable={true}
                          value={selectedProject}
                          onChange={(selectedoption) => {
                            handleOnSelectProject(selectedoption);
                          }}
                          // onMenuClose={(clearoption) => handleOnClearUser(clearoption)} // This will trigger when the dropdown menu is closed, after a selection or clear
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }} // Render the dropdown outside parent containers
                          placeholder="Select project..." // Change the placeholder to "Select user"
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            className="searchbtn btn btn-primary"
            onClick={() => {
              if (Object.keys(selectedProject).length == 0) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Please select a Project before adding a timesheet!",
                });
              } else {
                navigate(
                  `${process.env.PUBLIC_URL}/addtimesheet/${selectedProject?.value}`,
                  { state: { absence_date: rowData?.date } }
                );
                setRowData({});
                setSelectedproject({});
              }
            }}
          >
            Go to Add Timesheet
          </Button>
          <Button
            className="searchbtn btn btn-primary"
            onClick={() => {
              toggle_projectmodal();
              setRowData({});
              setSelectedproject({});
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AbsentEmployeereport;

import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const [login, setLogin] = useState(JSON.parse(localStorage.getItem("login")));
  const [authenticated, setAuthenticated] = useState(false);
  console.log(localStorage.getItem("login") + "privateroute");

  // useEffect(() => {
  //   setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
  //   localStorage.setItem("authenticated", authenticated);
  //   localStorage.setItem("login", login);
  // }, [login,authenticated]);
  return login ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />;
};

export default PrivateRoute;

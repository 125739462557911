// draftSlice.js
import { createSlice } from "@reduxjs/toolkit";

const draftSlice = createSlice({
  name: "drafts",
  initialState: {
    count: 0,
  },
  reducers: {
    incrementDraftCount: (state) => {
      state.count += 1;
    },
    decrementDraftCount: (state) => {
      state.count -= 1;
    },
    setDraftCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { incrementDraftCount, decrementDraftCount, setDraftCount } =
  draftSlice.actions;
export default draftSlice.reducer;

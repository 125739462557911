import React, { Fragment, useState } from "react";
import Language from "./Language";
import Searchbar from "./Searchbar";
import Notificationbar from "./Notificationbar";
import MoonLight from "./MoonLight";
import CartHeader from "./CartHeader";
import BookmarkHeader from "./BookmarkHeader";
import UserHeader from "./UserHeader";
import { UL } from "../../../AbstractElements";
import { Col } from "reactstrap";
import { Button } from "react-bootstrap";
import punchin from "../../../../src/assets/images/supportportal/punchin.png";
import punchout from "../../../../src/assets/images/supportportal/punchout.png";
import breakin from "../../../../src/assets/images/supportportal/breakin.png";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useSelector } from "react-redux";
import { useUserDispatch, useUserState } from "../../../context/Usercontext";

const RightHeader = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [attr_id, setAttrid] = useState("");
  const [break_id, setBreakid] = useState("");
  // const [punchInTime, setPunchInTime] = useState('');
  const logindetail = useUserState();
  console.log(logindetail, "details");

  const [PunchIn, setPunchIn] = useState(true);
  const [PunchOut, setPunchOut] = useState(false);
  const [BreakIn, setBreakIn] = useState(false);
  const [BreakOut, setBreakOut] = useState(false);
  const [PunchInbtn, setPunchInbtn] = useState(false);
  const [PunchOutbtn, setPunchOutbtn] = useState(false);
  const [BreakInbtn, setBreakInbtn] = useState(false);
  const [BreakOutbtn, setBreakOutbtn] = useState(false);
  // const [Punchinbtn, setPunchinbtn] = useState(false);

  const emp_in = logindetail.status.emp_in_time;
  const emp_out = logindetail.status.emp_out_time;
  const break_in_id = logindetail.status.break_id;
  console.log(break_in_id, "break_id");
  // emp_breakid

  // useEffect(() => {
  //   if (break_in_id != 0 && break_in_id?.trim() != "") {
  //     console.log("breakid....");
  //     localStorage.setItem("punchout", true);
  //     localStorage.setItem("breakin", false);
  //     localStorage.setItem("punchin", false);
  //     localStorage.setItem("breakout", true);
  //     localStorage.setItem("breakid", break_in_id);
  //     // window.location.reload()
  //   }
  // }, []);

  // useEffect(() => {
  //   if (emp_in != "" && emp_out == "" && localStorage.getItem("attr_id") != null) {
  //     localStorage.setItem("punchout", true);
  //     localStorage.setItem("breakin", true);
  //     localStorage.setItem("punchin", false);
  //     localStorage.setItem("breakout", false);
  //   }
  // }, []);

  useEffect(() => {
    if (process.env.REACT_APP_IS_POS == 0) {
      const punchin = localStorage.getItem("punchin");
      const punchout = localStorage.getItem("punchout");
      const breakin = localStorage.getItem("breakin");
      const breakout = localStorage.getItem("breakout");
      const attr_id = localStorage.getItem("attr_id");
      const breakid = localStorage.getItem("breakid");

      if (punchin != null) {
        setPunchIn(punchin === "true");
      }
      if (punchout != null) {
        setPunchOut(punchout === "true");
      }
      if (breakin != null) {
        setBreakIn(breakin === "true");
      }
      if (breakout != null) {
        setBreakOut(breakout == "true");
      }
      if (attr_id != null) {
        setAttrid(attr_id);
      }
      if (breakid != null) {
        setBreakid(breakid);
      }
    }
  }, []);

  const getBreakOutIDByAttrID = async (id) => {
    try {
      const res = await axios.post(
        `${baseurl}/expo_access_api/getBreakOutIDByAttrID/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          att_id: localStorage.getItem("attr_id"),
        }
      );
      console.log(res, "getBreakOutIDByAttrID");

      if (
        res?.data &&
        res.data[0]?.STATUS == "SUCCESS" &&
        res.data[0]?.DATA?.emp_breakid > 0 &&
        res.data[0]?.DATA?.emp_in_time != "" &&
        res.data[0]?.DATA?.emp_out_time == ""
      ) {
        setPunchOut(true);
        localStorage.setItem("punchout", true);
        setBreakIn(false);
        localStorage.setItem("breakin", false);
        setPunchIn(false);
        localStorage.setItem("punchin", false);
        setBreakOut(true);
        localStorage.setItem("breakout", true);
        localStorage.setItem("breakid", res.data[0]?.DATA?.emp_breakid);
      } else if (
        !res.data[0]?.DATA?.emp_breakid &&
        res.data[0]?.DATA?.emp_in_time != "" &&
        res.data[0]?.DATA?.emp_out_time == ""
      ) {
        setPunchOut(true);
        localStorage.setItem("punchout", true);
        setBreakIn(true);
        localStorage.setItem("breakin", true);
        setPunchIn(false);
        localStorage.setItem("punchin", false);
        setBreakOut(false);
        localStorage.setItem("breakout", false);
      } else if (
        !res.data[0]?.DATA?.emp_breakid &&
        res.data[0]?.DATA?.emp_in_time != "" &&
        res.data[0]?.DATA?.emp_out_time != ""
      ) {
        setPunchOut(false);
        localStorage.setItem("punchout", false);
        setBreakIn(false);
        localStorage.setItem("breakin", false);
        setPunchIn(false);
        localStorage.setItem("punchin", false);
        setBreakOut(false);
        localStorage.setItem("breakout", false);
      }
      // else {
      //   setPunchOut(false)
      //   localStorage.setItem("punchout", false);
      //   setBreakIn(false)
      //   localStorage.setItem("breakin", false);
      //   setPunchIn(true)
      //   localStorage.setItem("punchin", true);
      //   setBreakOut(false)
      //   localStorage.setItem("breakout", false);
      // }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // useEffect(() => {
  //   if(localStorage.getItem("attr_id") != null){
  //     console.log("attrid useeefect call")
  //     getBreakOutIDByAttrID();
  //   }
  //   else{
  //       setPunchOut(false)
  //       localStorage.setItem("punchout", false);
  //       setBreakIn(false)
  //       localStorage.setItem("breakin", false);
  //       setPunchIn(true)
  //       localStorage.setItem("punchin", true);
  //       setBreakOut(false)
  //       localStorage.setItem("breakout", false);
  //   }
  // }, []);

  const getAttrID = async (id) => {
    try {
      const res = await axios.post(
        `${baseurl}/expo_access_api/getBreakOutIDByAttrID/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          att_id: localStorage.getItem("attr_id"),
        }
      );
      console.log(res, "getBreakOutIDByAttrID");

      if (res?.data && res.data[0]?.STATUS == "SUCCESS") {
        localStorage.setItem("emp_in_time", res.data[0]?.DATA?.emp_in_time);
        localStorage.setItem("emp_out_time", res.data[0]?.DATA?.emp_out_time);
      } else {
        console.log("Error submitting form:");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    process.env.REACT_APP_IS_POS == 0 && getAttrID();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_IS_POS == 0) {
      if (
        (localStorage.getItem("emp_in_time") != "" &&
          localStorage.getItem("emp_out_time") != "") ||
        (localStorage.getItem("emp_in_time") == "" &&
          localStorage.getItem("emp_out_time") == "")
      ) {
        // console.log("attrid useeefect call")
        // setPunchOut(false)
        // localStorage.setItem("punchout", false);
        // setBreakIn(false)
        // localStorage.setItem("breakin", false);
        // setPunchIn(true)
        // localStorage.setItem("punchin", true);
        // setBreakOut(false)
        // localStorage.setItem("breakout", false);
      } else {
        getBreakOutIDByAttrID();
      }
    }
  }, []);

  // Punch in
  const getPunchin = async (id) => {
    setPunchInbtn(true);
    const currentTime = moment();
    const formattedTime = currentTime.format("DD-MM-YY h:mm a");

    try {
      const res = await axios.post(
        `${baseurl}/expo_access_api/employee_punch_in_api/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          employee_id: emp_id,
          datetime: formattedTime,
        }
      );

      console.log(res);

      if (
        res?.data &&
        res.data[0]?.STATUS == "success" &&
        res.data[0]?.MSG == "Punch in Successfully"
      ) {
        console.log("Punch in Successfully");
        localStorage.setItem("attr_id", res.data[0]?.Id);
        setPunchOut(true);
        localStorage.setItem("punchout", true);
        setPunchIn(false);
        localStorage.setItem("punchin", false);
        setBreakIn(true);
        localStorage.setItem("breakin", true);
        setAttrid(res.data[0]?.Id);
        setPunchInbtn(false);
        getAttrID();
        window.location.reload();
        // localStorage.removeItem("emp_in_time")
        // localStorage.removeItem("emp_out_time")

        toast.success("Punch in Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        console.log(res.data[0]?.MSG || "Something gone wrong");
        toast.error("You have already Punched-in Punched-out for today!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log("Error submitting form:", error);
      toast.error("Error while punchin!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setPunchInbtn(false);
    }
  };

  // Punchout
  const getPunchout = async (id) => {
    setPunchOutbtn(true);
    const currentTime = moment();
    const formattedTime = currentTime.format("DD-MM-YY h:mm a");

    try {
      const res = await axios.post(
        `${baseurl}/expo_access_api/employee_punch_out_api/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          employee_id: emp_id,
          datetime: formattedTime,
          id: attr_id,
        }
      );

      console.log(res);

      if (
        res?.data &&
        res.data[0]?.STATUS == "success" &&
        res.data[0]?.MSG == "Punch out Successfully"
      ) {
        toast.success("Punch out Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // getAttrID();
        setPunchOut(false);
        localStorage.setItem("punchout", false);
        setPunchIn(false);
        localStorage.setItem("punchin", false);
        setBreakIn(false);
        localStorage.setItem("breakin", false);
        setBreakOut(false);
        localStorage.setItem("breakout", false);
        setAttrid("");
        localStorage.removeItem("attr_id");
        window.location.reload();
      } else {
        console.log("Punchout failed");
        toast.error("Punchout failed!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error while punchout!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setPunchOutbtn(false);
    }
  };

  // Breakin
  const getBreakin = async (id) => {
    setBreakInbtn(true);
    const currentTime = moment();
    console.log(currentTime + "timing......");

    const formattedTime = currentTime.format("DD-MM-YY h:mm a");
    try {
      const res = await axios.post(
        `${baseurl}/expo_access_api/employee_break_in_out_api/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          att_id: attr_id,
          break_type: "in",
          datetime: formattedTime,
        }
      );

      console.log(res);

      if (
        res?.data &&
        res.data[0]?.STATUS == "success" &&
        res.data[0]?.MSG == "successfully Break in"
      ) {
        toast.success("Break in Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setBreakIn(false);
        localStorage.setItem("breakin", false);
        setBreakOut(true);
        localStorage.setItem("breakout", true);
        setBreakid(res.data[0]?.id_break);
        localStorage.setItem("breakid", res.data[0]?.id_break);
      } else {
        console.log("Breakin failed");
        toast.error("Breakin failed!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error while breakin!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setBreakInbtn(false);
    }
  };

  // Breakout
  const getBreakout = async (id) => {
    setBreakOutbtn(true);
    const currentTime = moment();
    console.log(currentTime + "timing......");

    const formattedTime = currentTime.format("DD-MM-YY h:mm a");
    try {
      const res = await axios.post(
        `${baseurl}/expo_access_api/employee_break_in_out_api/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          id: localStorage.getItem("breakid"),
          att_id: attr_id,
          break_type: "out",
          datetime: formattedTime,
        }
      );

      console.log(res);

      if (
        res?.data &&
        res.data[0]?.STATUS == "success" &&
        res.data[0]?.MSG == "successfully Break out"
      ) {
        toast.success("Break out Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setBreakIn(true);
        localStorage.setItem("breakin", true);
        setBreakOut(false);
        localStorage.setItem("breakout", false);
        setBreakid("");
        localStorage.removeItem("breakid");
      } else {
        console.log("Breakout failed");
        toast.error("Breakout failed!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error while breakout!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      console.log("definately running");
      setBreakOutbtn(false);
    }
  };

  return (
    <>
      <Fragment>
        <Col
          xxl="7"
          xl="6"
          md="7"
          sm="9"
          className="nav-right pull-right right-header col-10 p-0 ms-auto"
        >
          {/* <Col md="8"> */}
          <UL attrUL={{ className: "btn-div flex-row" }}>
            {process.env.REACT_APP_IS_POS == 1 ||
            process.env.REACT_APP_IS_POS == 2 ||
            process.env.REACT_APP_IS_POS == 3 ? (
              <UserHeader />
            ) : (
              <>
                {emp_in != "" && emp_out == "" ? (
                  <>
                    {" "}
                    <Button
                      variant=""
                      size="sm"
                      className={
                        isMobile
                          ? "punchoutbtn headerbtn-mobile"
                          : "punchoutbtn headerbtn-common"
                      }
                      onClick={() => {
                        // setPunchOut(false);
                        // localStorage.setItem("punchout", false);
                        // setPunchIn(false);
                        // localStorage.setItem("punchin", false);
                        // setBreakIn(false);
                        // localStorage.setItem("breakin", false);
                        // setBreakOut(false);
                        // localStorage.setItem("breakout", false);
                        getPunchout(emp_id);
                      }}
                      disabled={PunchOutbtn}
                    >
                      <img
                        src={`${baseurl}/public/images/supportportal/punchout.png`}
                        className={
                          isMobile ? "btnmobile-icon me-0" : "btn-icon me-0"
                        }
                        alt="Punchout"
                      />
                      Punch-Out
                    </Button>
                    <Button
                      variant=""
                      size="sm"
                      className={
                        isMobile
                          ? "breakbtn headerbtn-mobile"
                          : "breakbtn headerbtn-common"
                      }
                      onClick={() => {
                        // setBreakIn(false);
                        // localStorage.setItem("breakin", false);
                        // setBreakOut(true);
                        // localStorage.setItem("breakout", true);
                        getBreakin(emp_id);
                      }}
                      disabled={BreakInbtn}
                    >
                      <img
                        src={`${baseurl}/public/images/supportportal/breakin.png`}
                        className={
                          isMobile ? "btnmobile-icon me-2" : "btn-icon me-2"
                        }
                        alt="Breakin"
                      />
                      Break-In
                    </Button>
                  </>
                ) : (
                  <>
                    {PunchIn && (
                      <Button
                        variant=""
                        id="punchin"
                        size="sm"
                        className={
                          isMobile
                            ? "punchinbtn headerbtn-mobile"
                            : "punchinbtn headerbtn-common"
                        }
                        onClick={() => {
                          // setPunchOut(true);
                          // localStorage.setItem("punchout", true);
                          // setPunchIn(false);
                          // localStorage.setItem("punchin", false);
                          // setBreakIn(true);
                          // localStorage.setItem("breakin", true);
                          getPunchin(emp_id);
                        }}
                        disabled={PunchInbtn}
                      >
                        <img
                          src={`${baseurl}/public/images/supportportal/punchin.png`}
                          className={
                            isMobile ? "btnmobile-icon me-2" : "btn-icon me-2"
                          }
                          alt="Punchin"
                        />
                        Punch-In
                      </Button>
                    )}
                    {PunchOut && (
                      <Button
                        variant=""
                        size="sm"
                        className={
                          isMobile
                            ? "punchoutbtn headerbtn-mobile"
                            : "punchoutbtn headerbtn-common"
                        }
                        onClick={() => {
                          // setPunchOut(false);
                          // localStorage.setItem("punchout", false);
                          // setPunchIn(false);
                          // localStorage.setItem("punchin", false);
                          // setBreakIn(false);
                          // localStorage.setItem("breakin", false);
                          // setBreakOut(false);
                          // localStorage.setItem("breakout", false);
                          getPunchout(emp_id);
                        }}
                        disabled={PunchOutbtn}
                      >
                        <img
                          src={`${baseurl}/public/images/supportportal/punchout.png`}
                          className={
                            isMobile ? "btnmobile-icon me-0" : "btn-icon me-0"
                          }
                          alt="Punchout"
                        />
                        Punch-Out
                      </Button>
                    )}
                    {BreakIn && (
                      <Button
                        variant=""
                        size="sm"
                        className={
                          isMobile
                            ? "breakbtn headerbtn-mobile"
                            : "breakbtn headerbtn-common"
                        }
                        onClick={() => {
                          // setBreakIn(false);
                          // localStorage.setItem("breakin", false);
                          // setBreakOut(true);
                          // localStorage.setItem("breakout", true);
                          getBreakin(emp_id);
                        }}
                        disabled={BreakInbtn}
                      >
                        <img
                          src={`${baseurl}/public/images/supportportal/breakin.png`}
                          className={
                            isMobile ? "btnmobile-icon me-2" : "btn-icon me-2"
                          }
                          alt="Breakin"
                        />
                        Break-In
                      </Button>
                    )}
                    {BreakOut && (
                      <Button
                        variant=""
                        size="sm"
                        className={
                          isMobile
                            ? "breakbtn headerbtn-mobile"
                            : "breakbtn headerbtn-common"
                        }
                        onClick={() => {
                          getBreakout(emp_id);
                          // setBreakIn(true);
                          // localStorage.setItem("breakin", true);
                          // setBreakOut(false);
                          // localStorage.setItem("breakout", false);
                        }}
                        disabled={BreakOutbtn}
                      >
                        <img
                          src={`${baseurl}/public/images/supportportal/breakin.png`}
                          className={
                            isMobile ? "btnmobile-icon me-0" : "btn-icon me-0"
                          }
                          alt="Breakout"
                        />
                        Break-Out
                      </Button>
                    )}
                  </>
                )}

                {/* <Language />
          <Searchbar />
          <BookmarkHeader />
          <MoonLight />
          <CartHeader />
          <Notificationbar /> */}
                <UserHeader />
              </>
            )}
          </UL>
          {/* </Col> */}
        </Col>
      </Fragment>
      <ToastContainer />
    </>
  );
};

export default RightHeader;

import React from "react";
import myproject from "../../../src/assets/images/supportportal/myproject.png";
import addicon from "../../../src/assets/images/supportportal/addicon.png";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Loader from "../../Layout/Loader";
import { useUserState } from "../../context/Usercontext";
import { useDispatch, useSelector } from "react-redux";
import { additem } from "../../utils/supportrequestSlice";
import { CSVLink } from "react-csv";
import { Input } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Col, Row } from "react-bootstrap";
import { FormGroup, InputGroup, Label } from "reactstrap";
import Badge from "react-bootstrap/Badge";

const FinancialYearWiseReport = () => {
  // const baseurl =  "https://hofficedemo4.ondemandcrm.co";
  const baseurl = useSelector((store) => store.baseUrl);
  const supportlist = useSelector((store) => store.supportrequest.projectdata);
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [searchtext, setSearchtext] = useState("");
  const [data_info, setDatainfo] = useState("");
  const [financialyearwisedata, setFinancialyearwisedata] = useState([]);
  const [filteredfinancialyearwisedata, setFilteredfinancialyearwisedata] =
    useState([]);
  const [searchproject, setSearchproject] = useState("");
  const ExcelJS = require("exceljs");

  const [financialYears, setFinancialYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  console.log(selectedYear, "selectedYear...");

  console.log(financialyearwisedata, "financialyearwisedata...");

  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [emp_id, setEmpid] = useState(
    JSON.parse(localStorage.getItem("employeeid"))
  );
  const [userdetails, setUserdetails] = useState(
    JSON.parse(localStorage.getItem("userdetails"))
  );
  const [currentRow, setCurrentRow] = useState(null);

  const configContactDisplay = localStorage.getItem("config_contact_display");
  const timesheet_enable_on_login = localStorage.getItem(
    "timesheet_enable_on_login"
  );
  console.log(timesheet_enable_on_login, "timesheet_enable_on_login..");
  console.log(configContactDisplay, "configContactDisplay..");

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    if (userdetails != null) {
      setUserdetails(userdetails);
    }
  }, []);

  console.log(supportlist);
  console.log(filteredfinancialyearwisedata, "filtereddata");

  useEffect(() => {
    const emp_id = JSON.parse(localStorage.getItem("employeeid")); // Replace 'yourKey' with your actual key
    console.log(emp_id);
    if (emp_id) {
      setEmpid(emp_id);
    }
  }, []);
  // const {emp_id} = useUserState()
  // console.log(emp_id + "umangrtkghnfgdfkg")

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const customStyles = {
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold", // Make the title bold
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("setSelectedYear... call again.......");
    // Set the default selected year to the last element on initial render or data update
    setSelectedYear(financialYears[financialYears.length - 1]);
  }, [financialYears]);

  // for financial yearwise data
  useEffect(() => {
    // Fetch financial year report only when selectedYear has a value
    if (selectedYear) {
      getfinancialyearwisereport(selectedYear);
    }
    // Fetch the list of financial years regardless of selectedYear
  }, [selectedYear]);

  useEffect(() => {
    getfinancialyearlist();
  }, []);

  // filter
  const handleSearch = (e) => {
    console.log("handleSearch");
    const searchValue = e.target.value.toLowerCase();
    setSearchtext(searchValue);

    const filteredItems = financialyearwisedata.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] && typeof item[key] === "string") {
          const cleanedItem = item[key]
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();
          const cleanedSearchValue = searchValue
            .replace(/[^a-z0-9]+/g, "") // Remove special characters
            .replace(/\s+/g, " ") // Replace multiple spaces with a single space
            .trim();

          return cleanedItem.includes(cleanedSearchValue);
        }
        return false;
      });
    });

    console.log(filteredItems, "data");

    setFilteredfinancialyearwisedata(filteredItems);
  };

  const filterByProject = (e) => {
    const inputText = e.target.value;
    setSearchproject(inputText);
    // Function to remove extra spaces and special characters from a string
    const cleanString = (inputString) => {
      return inputString
        .trim()
        .replace(/[^a-z0-9]+/g, "") // Remove special characters
        .replace(/\s+/g, " ");
    };

    if (!inputText || cleanString(inputText).length === 0) {
      setFilteredfinancialyearwisedata(financialyearwisedata);
      return;
    }

    // Convert the searchTerm to lowercase for case-insensitive search
    const searchTermCleaned = cleanString(searchproject.toLowerCase());

    // Use Array.filter to filter objects based on problem_title
    const filteredData = financialyearwisedata.filter((item) =>
      cleanString(item.name.toLowerCase()).includes(searchTermCleaned)
    );

    setFilteredfinancialyearwisedata(filteredData);
  };

  const getfinancialyearwisereport = async (selectedYear) => {
    try {
      const res = await axios.post(
        `${baseurl}/expo_access_api/financialyearwisereport/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          year: selectedYear,
        }
      );

      console.log(res, "getfinancialyearwisereport");

      const data = res?.data?.[0]?.financialyearwisereport?.Data || [];
      const message = res?.data?.[0]?.MSG || "";

      setDatainfo(message);
      setFinancialyearwisedata(data);
      setFilteredfinancialyearwisedata(data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const getfinancialyearlist = async () => {
    try {
      const res = await axios.post(
        `${baseurl}/expo_access_api/financialyear/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
        },
      );

      console.log(res, "getfinancialyearlist");

      const yearsObject =
        res?.data?.[0]?.financialyearwisereport?.financialyear || {};
      const yearsArray = Object.values(yearsObject);

      setFinancialYears(yearsArray);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const columns = [];

  const startMonth = selectedYear?.split("-")[0]?.slice(2) + "-April";
  const endMonth = selectedYear?.split("-")[1]?.slice(2) + "-March";

  const months = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];

  let displayMonths = months
    .slice(months.indexOf(startMonth?.split("-")[1] ?? "April"), months.length)
    .concat(
      months.slice(0, months.indexOf(endMonth?.split("-")[1] ?? "March") + 1)
    )
    .slice(0, 12); // Get only 12 months

  const startYear = selectedYear?.split("-")[0]?.slice(2);
  const endYear = selectedYear?.split("-")[1]?.slice(2);

  console.log(startYear, "startYear...");
  console.log(endYear, "endYear...");

  if (isMobile) {
    columns.push(
      {
        name: (
          <div>
            <span className="" style={{ fontSize: "11px" }}>
              Name of Employee
            </span>
            <Input
              type="text"
              className="form-control register-full column-register"
              placeholder="Search employee..."
              value={searchproject}
              onChange={(e) => {
                filterByProject(e);
              }}
              style={{
                fontSize: "0.8rem",
                width: "90%",
                marginInline: "2px",
              }}
            />
          </div>
        ),
        selector: (row) => (
          <>
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row.name ? row.name : "---"}
            </div>
            <div style={{ textAlign: "start" }}>{row.title}</div>
          </>
        ),
        width: "150px",
        // sortable: true,
      },
      {
        name: <span style={{ fontSize: "11px" }}>Total</span>,
        selector: (row) => (
          <>
            <Badge pill bg="success" className="badge-green-color">
              {row.total
                ? row.total == "0"
                  ? "0"
                  : row.total.split(":").slice(0, 2).join(":")
                : "---"}{" "}
            </Badge>
          </>
        ),
        width: "95px",
        // sortable: true,
        // Set width to 0 if "Client Name" is hidden
      },
      {
        name: <span style={{ fontSize: "11px" }}>Average</span>,
        selector: (row) => (
          <>
            <Badge pill bg="success" className="badge-green-color">
              {row.avg
                ? row.avg == "0"
                  ? "0"
                  : row.avg.split(":").slice(0, 2).join(":")
                : "---"}{" "}
            </Badge>
          </>
        ),
        width: "95px",
        // sortable: true,
        // Set width to 0 if "Client Name" is hidden
      }
    );
  } else {
    columns.push({
      name: (
        <div>
          <span className="" style={{ fontSize: "11px" }}>
            Name of Employee
          </span>
          <Input
            type="text"
            className="form-control register-full column-register my-1"
            placeholder="Search employee..."
            value={searchproject}
            onChange={(e) => {
              filterByProject(e);
            }}
            style={{
              fontSize: "0.8rem",
              width: "90%",
              marginInline: "2px",
            }}
          />
        </div>
      ),
      selector: (row) => (
        <>
          <div
            style={{
              textAlign: "start",
              overflow: "hidden",
              whiteSpace: "normal",
            }}
          >
            {row.name ? row.name : "---"}
          </div>
          <div style={{ textAlign: "start" }}>{row.title}</div>
        </>
      ),
      width: "120px",
      // sortable: true,
    });

    displayMonths.forEach((month) => {
      const displayMonthYear =
        months.indexOf(month) >= months.indexOf("January")
          ? `${month.slice(0, 3)}-${endYear}`
          : `${month.slice(0, 3)}-${startYear}`;

      const formattedDisplayMonthYear = (
        <span
          style={{ fontSize: "11px" }}
          dangerouslySetInnerHTML={{
            __html: displayMonthYear.replace("-", "-<br />"),
          }}
        />
      );

      columns.push({
        name: (
          <span style={{ fontSize: "11px" }}>{formattedDisplayMonthYear}</span>
        ),
        selector: (row) => (
          <>
            {console.log(row[month], "row[month]....")}
            <div
              style={{
                textAlign: "start",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {row[month][0] !== undefined
                ? row[month][0] == "0"
                  ? "0"
                  : row[month][0]?.split(":")?.slice(0, 2).join(":")
                : "---"}
              /<br />
              {row[month][1] !== undefined
                ? row[month][1] == "0"
                  ? "0"
                  : row[month][1]?.split(":")?.slice(0, 2).join(":")
                : "---"}
            </div>
          </>
        ),
        width: "78px",
        // sortable: true,
        // Set width to 0 if "Client Name" is hidden
      });
    });

    columns.push(
      {
        name: <span style={{ fontSize: "11px" }}>Total Working</span>,
        selector: (row) => (
          <>
            <Badge pill bg="success" className="badge-green-color">
              {row?.totalmonthlyworkinghoursreadable
                ? row?.totalmonthlyworkinghoursreadable == "0"
                  ? "0"
                  : row?.totalmonthlyworkinghoursreadable
                      .split(":")
                      .slice(0, 2)
                      .join(":")
                : "---"}
            </Badge>
          </>
        ),
        width: "90px",
        // sortable: true,
        // Set width to 0 if "Client Name" is hidden
      },
      {
        name: <span style={{ fontSize: "11px" }}>Total Timesheet</span>,
        selector: (row) => (
          <>
            <Badge pill bg="success" className="badge-green-color">
              {row?.total
                ? row?.total == "0"
                  ? "0"
                  : row?.total.split(":").slice(0, 2).join(":")
                : "---"}
            </Badge>
          </>
        ),
        width: "90px",
        // sortable: true,
        // Set width to 0 if "Client Name" is hidden
      },
      {
        name: <span style={{ fontSize: "11px" }}>Average Timesheet</span>,
        selector: (row) => (
          <>
            <Badge pill bg="success" className="badge-green-color">
              {row.avg
                ? row.avg == "0"
                  ? "0"
                  : row.avg.split(":").slice(0, 2).join(":")
                : "---"}
            </Badge>
          </>
        ),
        width: "90px",
        // sortable: true,
        // Set width to 0 if "Client Name" is hidden
      }
    );
  }

  const ExpandedComponent = ({ data }) => (
    <div>
      {displayMonths.map((month) => {
        const displayMonthYear =
          months.indexOf(month) >= months.indexOf("January")
            ? `${month}-${endYear}`
            : `${month}-${startYear}`;

        return (
          <p style={{ marginBottom: "0px" }}>
            <span className="expand-header">{displayMonthYear}</span> :
            <span className="expand-subheader">
              {data[month] !== undefined
                ? data[month] == "0"
                  ? "0"
                  : data[month].split(":").slice(0, 2).join(":")
                : "---"}{" "}
            </span>
          </p>
        );
      })}
    </div>
  );

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 15;

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" }, // Font color (white)
    };

    sheet.columns = [
      {
        header: "Project name",
        key: "productname",
        width: 45,
      },
      // {
      //   header: "Project",
      //   key: "project",
      //   width: 18,
      // },
      {
        header: "Branch",
        key: "branch",
        width: 12,
      },
      {
        header: "Budget Hours",
        key: "budget_hours",
        width: 15,
      },
      {
        header: "Your Total Time Invested",
        key: "current_year_hours",
        width: 15,
      },
      {
        header: "Project start date",
        key: "projectstartdate",
        width: 12,
      },
      {
        header: "Project assign days",
        key: "projectrunningdays",
        width: 12,
      },
      {
        header: "Project due date",
        key: "projectduedate",
        width: 12,
      },
      {
        header: "Year",
        key: "year",
        width: 8,
      },
      {
        header: "Priority",
        key: "priority",
        width: 20,
      },
      {
        header: "Status",
        key: "status",
        width: 20,
      },
    ];

    const promise = Promise.all(
      filteredfinancialyearwisedata.map(async (product, index) => {
        let date = product?.date;
        let year;

        if (date && date.includes("29/03/2024")) {
          year = date.split("/")[2];
        }
        sheet.addRow({
          id: product?.id,
          productname: product?.problem_title,
          // project: product?.project,
          branch: product?.branch_name,
          budget_hours: product?.budget_hours,
          current_year_hours: product?.current_year_hours,
          projectstartdate: product?.projectstartdate,
          projectrunningdays: product?.projectrunningdays,
          projectduedate: product?.projectduedate,
          year: new Date().getFullYear(),
          priority: product?.priority,
          status: product?.status,
        });
      })
    );

    promise.then(() => {
      // Apply green color to the header for columns with data
      for (let i = 1; i <= sheet.columns.length; i++) {
        if (
          sheet
            .getColumn(i)
            .values.some((value) => value !== undefined && value !== null)
        ) {
          const headerCell = sheet.getRow(1).getCell(i);
          headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008000" },
          };
        }
      }

      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "My_Projects.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <div>
      {financialyearwisedata.length == 0 ? (
        // <Loader isloading={isLoading} setIsloading={setIsLoading} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <>
          <div className="text-center">
            <img
              src={`${baseurl}/public/images/supportportal/myproject.png`}
              alt="track-order"
              style={{ width: "80px", height: "80px" }}
            />
            <h1 className="login-header">
              {userdetails?.username}, View your Monthly Timesheet Report!
            </h1>
            <p className="sub-header">
              Manage your projects and effortlessly add timesheets all in one
              place. Stay organized, track your
              {isMobile ? "" : <br />}
              progress, and ensure accurate time recording with ease.
            </p>
          </div>

          <FormGroup
            className={`row formdiv-margin d-flex justify-content-center align-items-center ${
              isMobile ? "mx-1" : "mb-2"
            }`}
          >
            <Label
              className={`col-sm-1 col-form-label timesheet-label ${
                isMobile ? "" : "text-end"
              }`}
              htmlFor="inputPassword3"
            >
              Year:
            </Label>
            <Col sm="2">
              <Form.Select
                className="register-full input-register select-font"
                // id="country"
                value={selectedYear}
                onChange={(e) => {
                  console.log(e.target.value, "e.target.value... year");
                  setSelectedYear(e.target.value);
                  setFinancialyearwisedata([]);
                  setFilteredfinancialyearwisedata([]);
                  // getfinancialyearwisereport(e.target.value);
                }}
                required
              >
                {financialYears.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </FormGroup>

          <div className="table-column-adjust">
            {isMobile ? (
              <Container>
                {data_info == "Data Not Found" ? (
                  <div
                    className="text-center mt-3 py-3"
                    style={{ backgroundColor: "#dfdcdc" }}
                  >
                    No Records found
                  </div>
                ) : financialyearwisedata?.length == 0 ? (
                  <div className="text-center mt-3">
                    <Spinner animation="border" variant="success" />
                  </div>
                ) : (
                  <>
                    <div>
                      <div className="d-flex justify-content-center align-items-center my-3">
                        <Input
                          className="form-control register-full input-register"
                          type="text"
                          placeholder="Search all fields..."
                          value={searchtext}
                          onChange={handleSearch}
                        />
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="searchbtn my-2"
                          onClick={exportExcelFile}
                        >
                          Export
                        </Button>
                      </div>
                      <DataTable
                        //  className="custom-data-table"
                        columns={columns}
                        data={filteredfinancialyearwisedata}
                        pagination
                        paginationComponentOptions={paginationOptions}
                        customStyles={customStyles}
                        expandableRows
                        expandableRowExpanded={(row) => row === currentRow}
                        expandOnRowClicked
                        onRowClicked={(row) => setCurrentRow(row)}
                        onRowExpandToggled={(bool, row) => {
                          setCurrentRow(row);
                        }}
                        expandableRowsComponent={ExpandedComponent}
                        persistTableHead={true}
                      />
                    </div>
                  </>
                )}
              </Container>
            ) : (
              <div>
                {data_info == "Data Not Found" ? (
                  <div
                    className="text-center mt-3 py-3"
                    style={{ backgroundColor: "#dfdcdc" }}
                  >
                    No Records found
                  </div>
                ) : financialyearwisedata?.length == 0 ? (
                  <div className="text-center mt-3">
                    <Spinner animation="border" variant="success" />
                  </div>
                ) : (
                  <>
                    <div className="mx-2">
                      <div className="export-viewfinancial d-flex justify-content-between my-2">
                        <div>
                          <Input
                            className="form-control register-full input-register"
                            type="text"
                            placeholder="Search all fields..."
                            value={searchtext}
                            onChange={handleSearch}
                          />
                        </div>
                        <div>
                          <Button
                            className="searchbtn my-2"
                            onClick={exportExcelFile}
                          >
                            Export
                          </Button>
                        </div>
                      </div>
                      <DataTable
                        className="custom-data-table data-project"
                        columns={columns}
                        data={filteredfinancialyearwisedata}
                        pagination
                        paginationComponentOptions={paginationOptions}
                        customStyles={customStyles}
                        persistTableHead={true}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FinancialYearWiseReport;
